$target-platform: charles;
.highlighted-row {
  font-weight: 500;
  background-color: #f1f3f5; }




.order-complete-container {
  h4 {
    margin-bottom: 32px; }
  .order-complete-main-text {
    margin-bottom: 32px;
    text-align: left; }
  .order-complete-svg {
    svg {
      width: 80px;
      margin-bottom: 32px; } }

  .order-complete-link-wrapper {
    margin-bottom: 24px;
    a:first-of-type {
      margin-right: 32px; } } }
