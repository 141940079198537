$target-platform: charles;
.therapy-recommendation-card {
  .therapy-card-title {
    font-size: 18px; }

  .therapy-card-body {
    gap: 1rem;

    .therapy-image {
      svg {
        width: 100px; } } } }
