$target-platform: charles;
@import "~constants/sass/vars";

.coupon-wrapper {
  margin-bottom: 32px;
  .coupon-title {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px; }

  .coupon-input-wrapper {
    display: flex;
    max-height: 40px;
    .coupon-text-input {
      border: 1px solid black;
      border-radius: 16px 0 0 16px;
      padding-left: 16px; }
    .btn-sm {
      border-radius: 0 16px 16px 0;
      border: 1px solid black;
      border-left: 0; } } }
