$target-platform: charles;
@import "~constants/sass/vars";

.agenda-sommeil-summary-cta {
  width: 100%;

  svg {
    width: 56px; }

  button {
    svg {
      width: 24px; }

    &:hover svg * {
      fill: $white; } } }

.icon-32px {
  svg {
    width: 32px; } }

.icon-40px {
  svg {
    width: 40px; } }

.icon-24px {
  svg {
    width: 24px; } }

.icon-20px {
  svg {
    width: 20px; } }

.icon-16px {
  svg {
    width: 16px; } }

.calendar-summary-modal {
  max-width: none;
  width: 95vw;

  .modal-content {
    border: 2px solid black;
    border-radius: 20px;
    padding: 0;
    background-color: $white;

    @media (min-width: $bootstrap-break-md) {
      box-shadow: 10px 10px 0 black;
      padding: 24px 32px;

      .calendar-table-container {
        height: 70vh;
        overflow-y: scroll; } } }

  .icon-dot svg {
    width: 14px; } }

.sleep-calendar-container {
  img.icon-legend {
    width: 30px;
    height: 30px;
    z-index: 999;
    position: relative; // to make z-index work
    top: -1px; } // use a svg that does not suck to fix this gap?

  .icon-dot {
    svg {
      width: 14px; } }

  a.sleep-calendar-settings-toggle {
    cursor: pointer;

    svg {
      width: 16px;
      margin-left: 5px; } } }


.sleep-calendar-summary-table-caption {
  li {
    .progress {
      width: 30px;
      border-radius: 0; } } }
.sleep-calendar-summary-table {
  .sleep-calendar-summary-legend-rotated {
    // transform: rotate(90deg)
 }    // white-space: nowrap

  .force-cell-line-width {
    width: 960px !important; } // 4*24h*10px

  tbody {
    tr {
      border-top: 1px solid #dee2e6; } }

  .sleep-calendar-line-cell {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;

    .progress {
      height: 100% !important; }

    img {
      display: flex;
      margin-top: -1px;
      position: relative; } } }

.scroll-x {
  overflow-x: scroll; }

.calendar-summary {
  width: fit-content;
  width: -moz-fit-content; }

.bg-charles-sommeil-sleeping {
  background-color: #57A8F2 !important; }

.bg-charles-sommeil-awake {
  background-color: rgba(#57A8F2, 0.3) !important; }
