$target-platform: charles;
@import "~constants/sass/vars";

.teleconsultation-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;

  @media (min-width: $bootstrap-break-md) {
    flex-direction: row; }

  .teleconsultation-doctor {
    flex: 3 1 100%; }

  .teleconsultation-actions {
    flex: 1 1 100%;

    @media (min-width: $bootstrap-break-md) {
      flex: 1 0 220px; } }

  .teleconsultation-appointment {
    flex: 1 1 100%;
    align-self: stretch;

    @media (min-width: $bootstrap-break-md) {
      flex: 1 0 220px; } }

  .teleconsultation-doctor {
    text-align: center;
    @media (min-width: $bootstrap-break-md) {
      text-align: left; } }

  .teleconsultation-doctor-image-container {
    flex: 0 0 auto;

    .teleconsultation-doctor-image-img {
      height: 136px;
      width: 136px;
      border: 2px solid #000000;
      border-radius: 30px;
      z-index: 1; } }

  .teleconsultation-appointment {
    @media (max-width: $bootstrap-break-md) {
      padding-top: 1.5rem;
      border-top: 1px solid black; }
    @media (min-width: $bootstrap-break-md) {
      padding-left: 1.5rem;
      border-left: 1px solid black; }

    .teleconsultation-appointment-date {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: .5rem;

      svg {
        height: 24px; } } } }
