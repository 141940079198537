$target-platform: charles;
@import "~constants/sass/vars";

$selected-variant-border-width: 3px;

.page.phytotherapy {
  .container-content {
    padding-top: 4rem; } }

.doctors-selection {
  border: $selected-variant-border-width solid $bg-dark;
  position: relative;

  .doctors-selection-svg {
    display: block;
    svg {
      max-width: 300px; }

    path {
      fill: $bg-dark; } } }

.phyto-product-container {
  margin: auto auto 4rem auto;
  &::after {
    content: ' ';
    position: relative;
    width: 100px;
    maring: auto;
    border-bottom: 1px solid grey; }

  .phyto-product-description-image-container {
    margin-bottom: 48px; }

  img.phyto-product-image {
    border: 2px solid $black;
    filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
    border-radius: 16px;
    max-width: 240px; }

  .phyto-product-ext-link {
    text-decoration: underline;
    a {
      color: black; } }

  .phyto-product-description {
    line-height: 1.2; }

  .product-indication {
    font-weight: 500; }

  &:not(:last-child) {
    margin-bottom: 4rem; } }


.variant-card {

  max-width: 270px;
  margin: auto;
  @media (min-width: $bootstrap-break-md) {
    width: 100%; }

  .variant-card-inner {
    position: relative;

    .variant-recommended {
      display: none;
      position: absolute;
      top: -$selected-variant-border-width;
      left: 0;
      right: 0; }

    .variant-recommended-text {
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      z-index: 2;
      font-weight: 500;
      width: 90%;
      margin: auto;
      height: 40px;
      margin-top: 8px; }

    .variant-card-header {
      font-size: 22px;
      font-weight: 500;

      .variant-img {
        max-width: 44px;
        svg {
          width: 100%; } } }

    .variant-card-body {
      font-size: 22px;
      font-weight: 600px; }

    .variant-card-footer {
      .variant-subscription-info {
        font-style: italic;
        color: rgb(109, 107, 107);
        font-size: 13px; }
      .variant-subscription-promotion {
        font-weight: 500;
        font-size: 14px; } }

    &.most-popular-variant, &.recommended-variant {
      .variant-recommended-text {
        display: flex; }
      .variant-recommended-svg {
        display: block;
        width: 60px;
        position: absolute;
        top: -38px;
        right: -43px; } }

    &.selected {
      border: $selected-variant-border-width solid $cyan; } } }

.phyto-product-contraindication {
  white-space: pre-wrap;
  color: $v2-muted-text-color; }

.phyto-product-img {
  border-radius: 5px; }


.new-phyto-product-container {
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  flex-direction: column;
  @media(min-width: $bootstrap-break-lg) {
    flex-direction: row;
    max-width: 900px; }
  .phyto-product-info {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
    margin-bottom: 24px;
    @media(min-width: $bootstrap-break-lg) {
      width: 100%;
      margin-bottom: 0;
      padding-right: 32px;
      border-bottom: none;
      border-right: 1px solid black; }
    img.phyto-product-image {
      border: 2px solid $black;
      border-radius: 16px;
      width: 100%;
      max-width: 280px;
      margin: 0 auto 16px;
      @media(min-width: $bootstrap-break-lg) {
        width: 200px;
        height: 200px;
        object-fit: cover;
        filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
        max-width: initial;
        margin: 0 auto 58px; } }
    .phyto-product-text {
      display: flex;
      flex-direction: column;
      .phyto-product-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        &>div {
          text-align: right; } }
      .phyto-product-description {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 8px;
        .product-indication {
          font-weight: 500;
          font-size: 16px; } }
      .phyto-product-sheet {
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        margin-bottom: 16px;
        @media(min-width: $bootstrap-break-lg) {
          text-decoration: none;
          font-weight: 700; } }
      .phyto-product-contre-indication {
        margin-bottom: 16px;
        @media(min-width: $bootstrap-break-lg) {
          margin-bottom: 32px; }
        .phyto-product-contre-indication-title {
          display: flex;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 8px;
          @media(min-width: $bootstrap-break-lg) {
            display: none; }
          &>div {
            margin: 0 8px 0 8px; }
          .icon {
            width: 24px;
            &--up {
              transform: rotate(180deg); } } }
        .phyto-product-contre-indication-description {
          font-size: 14px;
          font-weight: 400;
          transition: all 0.2s ease-in-out;
          height: 0;
          overflow: hidden;
          @media(min-width: $bootstrap-break-lg) {
            height: initial;
            text-decoration: underline; } } }
      .phyto-product-reminder {
        font-style: italic;
        font-weight: 400;
        .icon {
          width: 16px; } } } }

  .phyto-subscription-info {
    display: flex;
    flex-direction: column;
    @media(min-width: $bootstrap-break-lg) {
      width: 288px;
      min-width: 288px;
      margin-left: 16px; }
    .phyto-variant-input {
      margin-top: 32px;
      .phyto-variant-label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        input {
          margin-top: 6px;
          margin-right: 16px; }
        .phyto-variant-info {
          .phyto-variant-option {
            font-weight: 500;
            margin-bottom: 6px;
            .phyto-variant-option-discount {
              border-radius: 34px;
              padding: 4px 12px;
              display: inline-block;
              font-size: 14px;
              background-color: #FDD09B;
              @media(min-width: $bootstrap-break-lg) {
                margin-left: 4px; } } }
          .phyto-variant-price {
            .phyto-variant-main-price {
              font-weight: 500; } }
          .phyto-variant-disposition {
            margin-top: 8px; }
          .phyto-variant-argument {
            padding-inline-start: 16px; }
          .phyto-variant-reminder {
            font-style: italic;
            font-weight: 400;
            display: flex;
            .phyto-variant-icon-wrapper {
              width: 16px; }
            .icon {
              display: flex;
              width: 16px;
              margin-right: 10px; } } } } } }

  .phyto-variant-button {
    width: 100%; } }
