$target-platform: charles;
@import "~constants/sass/vars";

.table-agenda-sommeil {
  tr {
    border-top: 1px solid #CCCCCC; }

  th {
    text-align: right;
    line-height: 100%;
    vertical-align: middle;
    width: 25px; }

  @media (max-width: $bootstrap-break-sm) {
    td, th {
      padding: 0.1rem; } } }

.sleep-calendar-items-form-actions {
  width: 100%;

  button[disabled] {
    opacity: 0; } }

.sleep-calendar-items-form {
  min-width: 270px;

  .sleep-calendar-title {
    svg {
      width: 32px; } }

  .form-label {
    font-weight: bold; }

  .calendar-cell-btn {
    min-width: 60px;
    height: 40px;
    line-height: 100%;
    vertical-align: middle;
    background: #EDF3FF;
    box-sizing: border-box;
    border-radius: 16px;

    &:focus {
      outline: none;
      box-shadow: none; }

    &.is-bedtime, &.is-sleep {
      border: 2px solid #4992FF;
      background: #B8D0FF; }

    &.is-waketime, &.is-out-of-bed {
      border: 2px solid #FFA149;
      background: #FFEBB8; }

    &.is-nightime-awake, &.is-nap {
      background: #FFF8F8;
      border: 2px solid #D3692D; }

    &.before-sleep {
      background: #B8D0FF; }

    &.during-sleep {
      background: #213661; }

    &.waking-up {
      background: #FFEBB8; }

    &:disabled {
      opacity: 0.95; }

    @media (min-width: $bootstrap-break-md) {
      width: 100%; } } }

.sticky-wrapper {
  position: relative;
  height: 1rem;

  .sticky {
    position: sticky;
    top: 0;
    z-index: 9999;

    .sticky-inner {
      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; } }

  .sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 9999;

    .sticky-inner {
      background-color: white;
      position: fixed;
      padding: 1rem;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; } } }

