/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #6389FF;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d93868;
  --orange: #f5a623;
  --yellow: #ffec7d;
  --green: #20c997;
  --teal: #20c997;
  --cyan: #71e0dc;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #000000;
  --secondary: #fff;
  --success: #71e0dc;
  --info: #71e0dc;
  --warning: #ffec7d;
  --danger: #D33030;
  --light: #f8f9fa;
  --dark: #343a40;
  --c-blue: #3D7791;
  --c-rust: #AF6345;
  --c-green: #4F785F;
  --c-yellow: #E1C341;
  --c-beige: #FAF8F3;
  --c-red: #D33030;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(17, 28, 43, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
     -moz-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: inherit;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5em; }

button {
  -moz-border-radius: 0;
       border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(17, 28, 43, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  -moz-border-radius: 1rem;
       border-radius: 1rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  -moz-border-radius: 0.2rem;
       border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-flex: 0 0 20%;
     -moz-box-flex: 0;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
     -moz-box-flex: 0;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
     -moz-box-flex: 0;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
     -moz-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
     -moz-box-flex: 0;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
     -moz-box-flex: 0;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
     -moz-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
     -moz-box-flex: 0;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
     -moz-box-flex: 0;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
     -moz-box-flex: 0;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
     -moz-box-flex: 0;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
     -moz-box-flex: 0;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
     -moz-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
     -moz-box-ordinal-group: 0;
          order: -1; }

.order-last {
  -webkit-order: 13;
     -moz-box-ordinal-group: 14;
          order: 13; }

.order-0 {
  -webkit-order: 0;
     -moz-box-ordinal-group: 1;
          order: 0; }

.order-1 {
  -webkit-order: 1;
     -moz-box-ordinal-group: 2;
          order: 1; }

.order-2 {
  -webkit-order: 2;
     -moz-box-ordinal-group: 3;
          order: 2; }

.order-3 {
  -webkit-order: 3;
     -moz-box-ordinal-group: 4;
          order: 3; }

.order-4 {
  -webkit-order: 4;
     -moz-box-ordinal-group: 5;
          order: 4; }

.order-5 {
  -webkit-order: 5;
     -moz-box-ordinal-group: 6;
          order: 5; }

.order-6 {
  -webkit-order: 6;
     -moz-box-ordinal-group: 7;
          order: 6; }

.order-7 {
  -webkit-order: 7;
     -moz-box-ordinal-group: 8;
          order: 7; }

.order-8 {
  -webkit-order: 8;
     -moz-box-ordinal-group: 9;
          order: 8; }

.order-9 {
  -webkit-order: 9;
     -moz-box-ordinal-group: 10;
          order: 9; }

.order-10 {
  -webkit-order: 10;
     -moz-box-ordinal-group: 11;
          order: 10; }

.order-11 {
  -webkit-order: 11;
     -moz-box-ordinal-group: 12;
          order: 11; }

.order-12 {
  -webkit-order: 12;
     -moz-box-ordinal-group: 13;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-flex: 0 0 20%;
       -moz-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(17, 28, 43, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(17, 28, 43, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8b8b8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-primary:hover {
  background-color: #ababab; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ababab; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d7f6f5; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b5efed; }

.table-hover .table-success:hover {
  background-color: #c2f1f0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c2f1f0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d7f6f5; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b5efed; }

.table-hover .table-info:hover {
  background-color: #c2f1f0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c2f1f0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffadb; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fff5bb; }

.table-hover .table-warning:hover {
  background-color: #fff6c2; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff6c2; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f3c5c5; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e89393; }

.table-hover .table-danger:hover {
  background-color: #efb0b0; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #efb0b0; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-c-blue,
.table-c-blue > th,
.table-c-blue > td {
  background-color: #c9d9e0; }

.table-c-blue th,
.table-c-blue td,
.table-c-blue thead th,
.table-c-blue tbody + tbody {
  border-color: #9ab8c6; }

.table-hover .table-c-blue:hover {
  background-color: #b9ced7; }
  .table-hover .table-c-blue:hover > td,
  .table-hover .table-c-blue:hover > th {
    background-color: #b9ced7; }

.table-c-rust,
.table-c-rust > th,
.table-c-rust > td {
  background-color: #e9d3cb; }

.table-c-rust th,
.table-c-rust td,
.table-c-rust thead th,
.table-c-rust tbody + tbody {
  border-color: #d5ae9e; }

.table-hover .table-c-rust:hover {
  background-color: #e1c4b9; }
  .table-hover .table-c-rust:hover > td,
  .table-hover .table-c-rust:hover > th {
    background-color: #e1c4b9; }

.table-c-green,
.table-c-green > th,
.table-c-green > td {
  background-color: #ced9d2; }

.table-c-green th,
.table-c-green td,
.table-c-green thead th,
.table-c-green tbody + tbody {
  border-color: #a3b9ac; }

.table-hover .table-c-green:hover {
  background-color: #c0cec5; }
  .table-hover .table-c-green:hover > td,
  .table-hover .table-c-green:hover > th {
    background-color: #c0cec5; }

.table-c-yellow,
.table-c-yellow > th,
.table-c-yellow > td {
  background-color: #f7eeca; }

.table-c-yellow th,
.table-c-yellow td,
.table-c-yellow thead th,
.table-c-yellow tbody + tbody {
  border-color: #efe09c; }

.table-hover .table-c-yellow:hover {
  background-color: #f4e7b4; }
  .table-hover .table-c-yellow:hover > td,
  .table-hover .table-c-yellow:hover > th {
    background-color: #f4e7b4; }

.table-c-beige,
.table-c-beige > th,
.table-c-beige > td {
  background-color: #fefdfc; }

.table-c-beige th,
.table-c-beige td,
.table-c-beige thead th,
.table-c-beige tbody + tbody {
  border-color: #fcfbf9; }

.table-hover .table-c-beige:hover {
  background-color: #f8f0e9; }
  .table-hover .table-c-beige:hover > td,
  .table-hover .table-c-beige:hover > th {
    background-color: #f8f0e9; }

.table-c-red,
.table-c-red > th,
.table-c-red > td {
  background-color: #f3c5c5; }

.table-c-red th,
.table-c-red td,
.table-c-red thead th,
.table-c-red tbody + tbody {
  border-color: #e89393; }

.table-hover .table-c-red:hover {
  background-color: #efb0b0; }
  .table-hover .table-c-red:hover > td,
  .table-hover .table-c-red:hover > th {
    background-color: #efb0b0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(17, 28, 43, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(10, 16, 25, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(10, 16, 25, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: -webkit-calc(1.5em + 1.5rem + 2px);
  height: -moz-calc(1.5em + 1.5rem + 2px);
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  -moz-border-radius: 1rem;
       border-radius: 1rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #404040;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: -webkit-calc(0.75rem + 1px);
  padding-top: -moz-calc(0.75rem + 1px);
  padding-top: calc(0.75rem + 1px);
  padding-bottom: -webkit-calc(0.75rem + 1px);
  padding-bottom: -moz-calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: -webkit-calc(0.5rem + 1px);
  padding-top: -moz-calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
  padding-bottom: -webkit-calc(0.5rem + 1px);
  padding-bottom: -moz-calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: -webkit-calc(0.25rem + 1px);
  padding-top: -moz-calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
  padding-bottom: -webkit-calc(0.25rem + 1px);
  padding-bottom: -moz-calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: -webkit-calc(1.5em + 0.5rem + 2px);
  height: -moz-calc(1.5em + 0.5rem + 2px);
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  -moz-border-radius: 0.2rem;
       border-radius: 0.2rem; }

.form-control-lg {
  height: -webkit-calc(1.5em + 1rem + 2px);
  height: -moz-calc(1.5em + 1rem + 2px);
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  -moz-border-radius: 0.3rem;
       border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #71e0dc; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(113, 224, 220, 0.9);
  -moz-border-radius: 1rem;
       border-radius: 1rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #71e0dc;
  padding-right: -webkit-calc(1.5em + 1.5rem);
  padding-right: -moz-calc(1.5em + 1.5rem);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2371e0dc' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right -webkit-calc(0.375em + 0.375rem) center;
  background-position: right -moz-calc(0.375em + 0.375rem) center;
  background-position: right calc(0.375em + 0.375rem) center;
  -webkit-background-size: -webkit-calc(0.75em + 0.75rem) -webkit-calc(0.75em + 0.75rem);
     -moz-background-size: -moz-calc(0.75em + 0.75rem) -moz-calc(0.75em + 0.75rem);
          background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #71e0dc;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: -webkit-calc(1.5em + 1.5rem);
  padding-right: -moz-calc(1.5em + 1.5rem);
  padding-right: calc(1.5em + 1.5rem);
  background-position: top -webkit-calc(0.375em + 0.375rem) right -webkit-calc(0.375em + 0.375rem);
  background-position: top -moz-calc(0.375em + 0.375rem) right -moz-calc(0.375em + 0.375rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #71e0dc;
  padding-right: -webkit-calc(0.75em + 3.125rem);
  padding-right: -moz-calc(0.75em + 3.125rem);
  padding-right: calc(0.75em + 3.125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2371e0dc' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) -webkit-calc(0.75em + 0.75rem) no-repeat;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2371e0dc' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) -moz-calc(0.75em + 0.75rem) no-repeat;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2371e0dc' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #71e0dc;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #71e0dc; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #71e0dc; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #71e0dc; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #9be9e6;
  background-color: #9be9e6; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
     -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #71e0dc; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #71e0dc; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #71e0dc;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
     -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #D33030; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(211, 48, 48, 0.9);
  -moz-border-radius: 1rem;
       border-radius: 1rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #D33030;
  padding-right: -webkit-calc(1.5em + 1.5rem);
  padding-right: -moz-calc(1.5em + 1.5rem);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D33030' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D33030' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right -webkit-calc(0.375em + 0.375rem) center;
  background-position: right -moz-calc(0.375em + 0.375rem) center;
  background-position: right calc(0.375em + 0.375rem) center;
  -webkit-background-size: -webkit-calc(0.75em + 0.75rem) -webkit-calc(0.75em + 0.75rem);
     -moz-background-size: -moz-calc(0.75em + 0.75rem) -moz-calc(0.75em + 0.75rem);
          background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #D33030;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: -webkit-calc(1.5em + 1.5rem);
  padding-right: -moz-calc(1.5em + 1.5rem);
  padding-right: calc(1.5em + 1.5rem);
  background-position: top -webkit-calc(0.375em + 0.375rem) right -webkit-calc(0.375em + 0.375rem);
  background-position: top -moz-calc(0.375em + 0.375rem) right -moz-calc(0.375em + 0.375rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #D33030;
  padding-right: -webkit-calc(0.75em + 3.125rem);
  padding-right: -moz-calc(0.75em + 3.125rem);
  padding-right: calc(0.75em + 3.125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D33030' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D33030' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) -webkit-calc(0.75em + 0.75rem) no-repeat;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D33030' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D33030' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) -moz-calc(0.75em + 0.75rem) no-repeat;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D33030' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D33030' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #D33030;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D33030; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D33030; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #D33030; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #dc5a5a;
  background-color: #dc5a5a; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
     -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #D33030; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D33030; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #D33030;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
     -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-flow: row wrap;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex: 0 0 auto;
         -moz-box-flex: 0;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-flow: row wrap;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 0.125rem solid transparent;
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  line-height: 1.1875rem;
  -moz-border-radius: 1rem;
       border-radius: 1rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.3; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }
  .btn-primary:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-success {
  color: #212529;
  background-color: #71e0dc;
  border-color: #71e0dc; }
  .btn-success:hover {
    color: #212529;
    background-color: #52d9d4;
    border-color: #47d7d2; }
  .btn-success:focus, .btn-success.focus {
    color: #212529;
    background-color: #52d9d4;
    border-color: #47d7d2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #71e0dc;
    border-color: #71e0dc; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #212529;
    background-color: #47d7d2;
    border-color: #3dd5cf; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5); }

.btn-info {
  color: #212529;
  background-color: #71e0dc;
  border-color: #71e0dc; }
  .btn-info:hover {
    color: #212529;
    background-color: #52d9d4;
    border-color: #47d7d2; }
  .btn-info:focus, .btn-info.focus {
    color: #212529;
    background-color: #52d9d4;
    border-color: #47d7d2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #71e0dc;
    border-color: #71e0dc; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #212529;
    background-color: #47d7d2;
    border-color: #3dd5cf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(101, 196, 193, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffec7d;
  border-color: #ffec7d; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffe657;
    border-color: #ffe54a; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #ffe657;
    border-color: #ffe54a;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 206, 112, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(222, 206, 112, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 206, 112, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffec7d;
    border-color: #ffec7d; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffe54a;
    border-color: #ffe33d; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 206, 112, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(222, 206, 112, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 206, 112, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #D33030;
  border-color: #D33030; }
  .btn-danger:hover {
    color: #fff;
    background-color: #b62727;
    border-color: #ac2424; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #b62727;
    border-color: #ac2424;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #D33030;
    border-color: #D33030; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ac2424;
    border-color: #a12222; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-c-blue {
  color: #fff;
  background-color: #3D7791;
  border-color: #3D7791; }
  .btn-c-blue:hover {
    color: #fff;
    background-color: #326176;
    border-color: #2e5a6d; }
  .btn-c-blue:focus, .btn-c-blue.focus {
    color: #fff;
    background-color: #326176;
    border-color: #2e5a6d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(90, 139, 162, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(90, 139, 162, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(90, 139, 162, 0.5); }
  .btn-c-blue.disabled, .btn-c-blue:disabled {
    color: #fff;
    background-color: #3D7791;
    border-color: #3D7791; }
  .btn-c-blue:not(:disabled):not(.disabled):active, .btn-c-blue:not(:disabled):not(.disabled).active,
  .show > .btn-c-blue.dropdown-toggle {
    color: #fff;
    background-color: #2e5a6d;
    border-color: #2a5264; }
    .btn-c-blue:not(:disabled):not(.disabled):active:focus, .btn-c-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-c-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(90, 139, 162, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(90, 139, 162, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(90, 139, 162, 0.5); }

.btn-c-rust {
  color: #fff;
  background-color: #AF6345;
  border-color: #AF6345; }
  .btn-c-rust:hover {
    color: #fff;
    background-color: #94533a;
    border-color: #8a4e37; }
  .btn-c-rust:focus, .btn-c-rust.focus {
    color: #fff;
    background-color: #94533a;
    border-color: #8a4e37;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(187, 122, 97, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(187, 122, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(187, 122, 97, 0.5); }
  .btn-c-rust.disabled, .btn-c-rust:disabled {
    color: #fff;
    background-color: #AF6345;
    border-color: #AF6345; }
  .btn-c-rust:not(:disabled):not(.disabled):active, .btn-c-rust:not(:disabled):not(.disabled).active,
  .show > .btn-c-rust.dropdown-toggle {
    color: #fff;
    background-color: #8a4e37;
    border-color: #814933; }
    .btn-c-rust:not(:disabled):not(.disabled):active:focus, .btn-c-rust:not(:disabled):not(.disabled).active:focus,
    .show > .btn-c-rust.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(187, 122, 97, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(187, 122, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(187, 122, 97, 0.5); }

.btn-c-green {
  color: #fff;
  background-color: #4F785F;
  border-color: #4F785F; }
  .btn-c-green:hover {
    color: #fff;
    background-color: #40614d;
    border-color: #3b5947; }
  .btn-c-green:focus, .btn-c-green.focus {
    color: #fff;
    background-color: #40614d;
    border-color: #3b5947;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 140, 119, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(105, 140, 119, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(105, 140, 119, 0.5); }
  .btn-c-green.disabled, .btn-c-green:disabled {
    color: #fff;
    background-color: #4F785F;
    border-color: #4F785F; }
  .btn-c-green:not(:disabled):not(.disabled):active, .btn-c-green:not(:disabled):not(.disabled).active,
  .show > .btn-c-green.dropdown-toggle {
    color: #fff;
    background-color: #3b5947;
    border-color: #365241; }
    .btn-c-green:not(:disabled):not(.disabled):active:focus, .btn-c-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-c-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 140, 119, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(105, 140, 119, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(105, 140, 119, 0.5); }

.btn-c-yellow {
  color: #212529;
  background-color: #E1C341;
  border-color: #E1C341; }
  .btn-c-yellow:hover {
    color: #212529;
    background-color: #d9b722;
    border-color: #ceae21; }
  .btn-c-yellow:focus, .btn-c-yellow.focus {
    color: #212529;
    background-color: #d9b722;
    border-color: #ceae21;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 171, 61, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(196, 171, 61, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(196, 171, 61, 0.5); }
  .btn-c-yellow.disabled, .btn-c-yellow:disabled {
    color: #212529;
    background-color: #E1C341;
    border-color: #E1C341; }
  .btn-c-yellow:not(:disabled):not(.disabled):active, .btn-c-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-c-yellow.dropdown-toggle {
    color: #212529;
    background-color: #ceae21;
    border-color: #c3a51f; }
    .btn-c-yellow:not(:disabled):not(.disabled):active:focus, .btn-c-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-c-yellow.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(196, 171, 61, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(196, 171, 61, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(196, 171, 61, 0.5); }

.btn-c-beige {
  color: #212529;
  background-color: #FAF8F3;
  border-color: #FAF8F3; }
  .btn-c-beige:hover {
    color: #212529;
    background-color: #efe8d8;
    border-color: #ebe3cf; }
  .btn-c-beige:focus, .btn-c-beige.focus {
    color: #212529;
    background-color: #efe8d8;
    border-color: #ebe3cf;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(217, 216, 213, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(217, 216, 213, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(217, 216, 213, 0.5); }
  .btn-c-beige.disabled, .btn-c-beige:disabled {
    color: #212529;
    background-color: #FAF8F3;
    border-color: #FAF8F3; }
  .btn-c-beige:not(:disabled):not(.disabled):active, .btn-c-beige:not(:disabled):not(.disabled).active,
  .show > .btn-c-beige.dropdown-toggle {
    color: #212529;
    background-color: #ebe3cf;
    border-color: #e7dec6; }
    .btn-c-beige:not(:disabled):not(.disabled):active:focus, .btn-c-beige:not(:disabled):not(.disabled).active:focus,
    .show > .btn-c-beige.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(217, 216, 213, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(217, 216, 213, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(217, 216, 213, 0.5); }

.btn-c-red {
  color: #fff;
  background-color: #D33030;
  border-color: #D33030; }
  .btn-c-red:hover {
    color: #fff;
    background-color: #b62727;
    border-color: #ac2424; }
  .btn-c-red:focus, .btn-c-red.focus {
    color: #fff;
    background-color: #b62727;
    border-color: #ac2424;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5); }
  .btn-c-red.disabled, .btn-c-red:disabled {
    color: #fff;
    background-color: #D33030;
    border-color: #D33030; }
  .btn-c-red:not(:disabled):not(.disabled):active, .btn-c-red:not(:disabled):not(.disabled).active,
  .show > .btn-c-red.dropdown-toggle {
    color: #fff;
    background-color: #ac2424;
    border-color: #a12222; }
    .btn-c-red:not(:disabled):not(.disabled):active:focus, .btn-c-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-c-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(218, 79, 79, 0.5); }

.btn-outline-primary {
  color: #000000;
  border-color: #000000; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #000000;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-secondary {
  color: #fff;
  border-color: #fff; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-success {
  color: #71e0dc;
  border-color: #71e0dc; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #71e0dc;
    border-color: #71e0dc; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #71e0dc;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #71e0dc;
    border-color: #71e0dc; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5); }

.btn-outline-info {
  color: #71e0dc;
  border-color: #71e0dc; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #71e0dc;
    border-color: #71e0dc; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #71e0dc;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #71e0dc;
    border-color: #71e0dc; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5); }

.btn-outline-warning {
  color: #ffec7d;
  border-color: #ffec7d; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffec7d;
    border-color: #ffec7d; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffec7d;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffec7d;
    border-color: #ffec7d; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5); }

.btn-outline-danger {
  color: #D33030;
  border-color: #D33030; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #D33030;
    border-color: #D33030; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #D33030;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #D33030;
    border-color: #D33030; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-c-blue {
  color: #3D7791;
  border-color: #3D7791; }
  .btn-outline-c-blue:hover {
    color: #fff;
    background-color: #3D7791;
    border-color: #3D7791; }
  .btn-outline-c-blue:focus, .btn-outline-c-blue.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5); }
  .btn-outline-c-blue.disabled, .btn-outline-c-blue:disabled {
    color: #3D7791;
    background-color: transparent; }
  .btn-outline-c-blue:not(:disabled):not(.disabled):active, .btn-outline-c-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-c-blue.dropdown-toggle {
    color: #fff;
    background-color: #3D7791;
    border-color: #3D7791; }
    .btn-outline-c-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-c-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-c-blue.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5); }

.btn-outline-c-rust {
  color: #AF6345;
  border-color: #AF6345; }
  .btn-outline-c-rust:hover {
    color: #fff;
    background-color: #AF6345;
    border-color: #AF6345; }
  .btn-outline-c-rust:focus, .btn-outline-c-rust.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5); }
  .btn-outline-c-rust.disabled, .btn-outline-c-rust:disabled {
    color: #AF6345;
    background-color: transparent; }
  .btn-outline-c-rust:not(:disabled):not(.disabled):active, .btn-outline-c-rust:not(:disabled):not(.disabled).active,
  .show > .btn-outline-c-rust.dropdown-toggle {
    color: #fff;
    background-color: #AF6345;
    border-color: #AF6345; }
    .btn-outline-c-rust:not(:disabled):not(.disabled):active:focus, .btn-outline-c-rust:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-c-rust.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5); }

.btn-outline-c-green {
  color: #4F785F;
  border-color: #4F785F; }
  .btn-outline-c-green:hover {
    color: #fff;
    background-color: #4F785F;
    border-color: #4F785F; }
  .btn-outline-c-green:focus, .btn-outline-c-green.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5); }
  .btn-outline-c-green.disabled, .btn-outline-c-green:disabled {
    color: #4F785F;
    background-color: transparent; }
  .btn-outline-c-green:not(:disabled):not(.disabled):active, .btn-outline-c-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-c-green.dropdown-toggle {
    color: #fff;
    background-color: #4F785F;
    border-color: #4F785F; }
    .btn-outline-c-green:not(:disabled):not(.disabled):active:focus, .btn-outline-c-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-c-green.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5); }

.btn-outline-c-yellow {
  color: #E1C341;
  border-color: #E1C341; }
  .btn-outline-c-yellow:hover {
    color: #212529;
    background-color: #E1C341;
    border-color: #E1C341; }
  .btn-outline-c-yellow:focus, .btn-outline-c-yellow.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5); }
  .btn-outline-c-yellow.disabled, .btn-outline-c-yellow:disabled {
    color: #E1C341;
    background-color: transparent; }
  .btn-outline-c-yellow:not(:disabled):not(.disabled):active, .btn-outline-c-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-c-yellow.dropdown-toggle {
    color: #212529;
    background-color: #E1C341;
    border-color: #E1C341; }
    .btn-outline-c-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-c-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-c-yellow.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5); }

.btn-outline-c-beige {
  color: #FAF8F3;
  border-color: #FAF8F3; }
  .btn-outline-c-beige:hover {
    color: #212529;
    background-color: #FAF8F3;
    border-color: #FAF8F3; }
  .btn-outline-c-beige:focus, .btn-outline-c-beige.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5); }
  .btn-outline-c-beige.disabled, .btn-outline-c-beige:disabled {
    color: #FAF8F3;
    background-color: transparent; }
  .btn-outline-c-beige:not(:disabled):not(.disabled):active, .btn-outline-c-beige:not(:disabled):not(.disabled).active,
  .show > .btn-outline-c-beige.dropdown-toggle {
    color: #212529;
    background-color: #FAF8F3;
    border-color: #FAF8F3; }
    .btn-outline-c-beige:not(:disabled):not(.disabled):active:focus, .btn-outline-c-beige:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-c-beige.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5); }

.btn-outline-c-red {
  color: #D33030;
  border-color: #D33030; }
  .btn-outline-c-red:hover {
    color: #fff;
    background-color: #D33030;
    border-color: #D33030; }
  .btn-outline-c-red:focus, .btn-outline-c-red.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5); }
  .btn-outline-c-red.disabled, .btn-outline-c-red:disabled {
    color: #D33030;
    background-color: transparent; }
  .btn-outline-c-red:not(:disabled):not(.disabled):active, .btn-outline-c-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-c-red.dropdown-toggle {
    color: #fff;
    background-color: #D33030;
    border-color: #D33030; }
    .btn-outline-c-red:not(:disabled):not(.disabled):active:focus, .btn-outline-c-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-c-red.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5); }

.btn-link {
  font-weight: 400;
  color: inherit;
  text-decoration: none; }
  .btn-link:hover {
    color: inherit;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  -moz-border-radius: 0.3rem;
       border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 0.5rem;
  font-size: 1rem;
  line-height: 1.1875rem;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 28, 43, 0.15);
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #000000; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.125rem; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  -moz-border-radius-topright: 0;
       border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
       border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  -moz-border-radius-topleft: 0;
       border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
       border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -0.125rem; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    -moz-border-radius-bottomright: 0;
         border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
         border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    -moz-border-radius-topleft: 0;
         border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
         border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
     -moz-box-align: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
            flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    -moz-border-radius-topleft: 0;
         border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
         border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      -moz-border-radius-topleft: 0;
           border-top-left-radius: 0;
      -moz-border-radius-bottomleft: 0;
           border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    -moz-border-radius-topright: 0;
         border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
         border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    -moz-border-radius-topright: 0;
         border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
         border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: -moz-box;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #000000;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: -webkit-calc(1.5em + 1rem + 2px);
  height: -moz-calc(1.5em + 1rem + 2px);
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  -moz-border-radius: 0.3rem;
       border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: -webkit-calc(1.5em + 0.5rem + 2px);
  height: -moz-calc(1.5em + 0.5rem + 2px);
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  -moz-border-radius: 0.2rem;
       border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  -moz-border-radius-topright: 0;
       border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
       border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  -moz-border-radius-topleft: 0;
       border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
       border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #000000;
    background-color: #000000; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #404040; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #595959;
    border-color: #595959; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #000000;
  background-color: #000000; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5); }

.custom-radio .custom-control-label::before {
  -moz-border-radius: 50%;
       border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    -moz-border-radius: 0.5rem;
         border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: -webkit-calc(0.25rem + 2px);
    top: -moz-calc(0.25rem + 2px);
    top: calc(0.25rem + 2px);
    left: -webkit-calc(-2.25rem + 2px);
    left: -moz-calc(-2.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: -webkit-calc(1rem - 4px);
    width: -moz-calc(1rem - 4px);
    width: calc(1rem - 4px);
    height: -webkit-calc(1rem - 4px);
    height: -moz-calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    -moz-border-radius: 0.5rem;
         border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -o-transform 0.15s ease-in-out;
    -moz-transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-transform 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -moz-transform 0.15s ease-in-out, -o-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        -o-transition: none;
        -moz-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
       -moz-transform: translateX(0.75rem);
         -o-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 0, 0, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: -webkit-calc(1.5em + 1.5rem + 2px);
  height: -moz-calc(1.5em + 1.5rem + 2px);
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 1px solid #000000;
  -moz-border-radius: 1rem;
       border-radius: 1rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #404040;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: -webkit-calc(1.5em + 0.5rem + 2px);
  height: -moz-calc(1.5em + 0.5rem + 2px);
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: -webkit-calc(1.5em + 1rem + 2px);
  height: -moz-calc(1.5em + 1rem + 2px);
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: -webkit-calc(1.5em + 1.5rem + 2px);
  height: -moz-calc(1.5em + 1.5rem + 2px);
  height: calc(1.5em + 1.5rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: -webkit-calc(1.5em + 1.5rem + 2px);
  height: -moz-calc(1.5em + 1.5rem + 2px);
  height: calc(1.5em + 1.5rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #404040;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: -webkit-calc(1.5em + 1.5rem + 2px);
  height: -moz-calc(1.5em + 1.5rem + 2px);
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #000000;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: -webkit-calc(1.5em + 1.5rem);
    height: -moz-calc(1.5em + 1.5rem);
    height: calc(1.5em + 1.5rem);
    padding: 0.75rem 1rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    -moz-border-radius: 0 1rem 1rem 0;
         border-radius: 0 1rem 1rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
              box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      -moz-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
           box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #000000;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        -o-transition: none;
        -moz-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #595959; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #000000;
    border: 0;
    -moz-border-radius: 1rem;
         border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        -o-transition: none;
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #595959; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    -moz-border-radius: 1rem;
         border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #000000;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        -o-transition: none;
        -moz-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #595959; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    -moz-border-radius-topleft: 1rem;
         border-top-left-radius: 1rem;
    -moz-border-radius-topright: 1rem;
         border-top-right-radius: 1rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    -moz-border-radius-topleft: 0;
         border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
         border-top-right-radius: 0; }

.nav-pills .nav-link {
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #000000; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
     -moz-box-flex: 1;
          flex-grow: 1;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: -moz-box !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(17, 28, 43, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(17, 28, 43, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(17, 28, 43, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(17, 28, 43, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(17, 28, 43, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(17, 28, 43, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(17, 28, 43, 0.5);
  border-color: rgba(17, 28, 43, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2817, 28, 43, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(17, 28, 43, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(17, 28, 43, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(17, 28, 43, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(17, 28, 43, 0.125);
  -moz-border-radius: 1rem;
       border-radius: 1rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      -moz-border-radius-topleft: -moz-calc(1rem - 1px);
           border-top-left-radius: -webkit-calc(1rem - 1px);
           border-top-left-radius: calc(1rem - 1px);
      -moz-border-radius-topright: -moz-calc(1rem - 1px);
           border-top-right-radius: -webkit-calc(1rem - 1px);
           border-top-right-radius: calc(1rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      -moz-border-radius-bottomright: -moz-calc(1rem - 1px);
           border-bottom-right-radius: -webkit-calc(1rem - 1px);
           border-bottom-right-radius: calc(1rem - 1px);
      -moz-border-radius-bottomleft: -moz-calc(1rem - 1px);
           border-bottom-left-radius: -webkit-calc(1rem - 1px);
           border-bottom-left-radius: calc(1rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(17, 28, 43, 0.03);
  border-bottom: 1px solid rgba(17, 28, 43, 0.125); }
  .card-header:first-child {
    -moz-border-radius: -moz-calc(1rem - 1px) -moz-calc(1rem - 1px) 0 0;
         border-radius: -webkit-calc(1rem - 1px) -webkit-calc(1rem - 1px) 0 0;
         border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(17, 28, 43, 0.03);
  border-top: 1px solid rgba(17, 28, 43, 0.125); }
  .card-footer:last-child {
    -moz-border-radius: 0 0 -moz-calc(1rem - 1px) -moz-calc(1rem - 1px);
         border-radius: 0 0 -webkit-calc(1rem - 1px) -webkit-calc(1rem - 1px);
         border-radius: 0 0 calc(1rem - 1px) calc(1rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  -moz-border-radius: -moz-calc(1rem - 1px);
       border-radius: -webkit-calc(1rem - 1px);
       border-radius: calc(1rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  -moz-border-radius-topleft: -moz-calc(1rem - 1px);
       border-top-left-radius: -webkit-calc(1rem - 1px);
       border-top-left-radius: calc(1rem - 1px);
  -moz-border-radius-topright: -moz-calc(1rem - 1px);
       border-top-right-radius: -webkit-calc(1rem - 1px);
       border-top-right-radius: calc(1rem - 1px); }

.card-img,
.card-img-bottom {
  -moz-border-radius-bottomright: -moz-calc(1rem - 1px);
       border-bottom-right-radius: -webkit-calc(1rem - 1px);
       border-bottom-right-radius: calc(1rem - 1px);
  -moz-border-radius-bottomleft: -moz-calc(1rem - 1px);
       border-bottom-left-radius: -webkit-calc(1rem - 1px);
       border-bottom-left-radius: calc(1rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -webkit-flex: 1 0;
         -moz-box-flex: 1;
              flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-flex: 1 0;
         -moz-box-flex: 1;
              flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        -moz-border-radius-topright: 0;
             border-top-right-radius: 0;
        -moz-border-radius-bottomright: 0;
             border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          -moz-border-radius-topright: 0;
               border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          -moz-border-radius-bottomright: 0;
               border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        -moz-border-radius-topleft: 0;
             border-top-left-radius: 0;
        -moz-border-radius-bottomleft: 0;
             border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          -moz-border-radius-topleft: 0;
               border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          -moz-border-radius-bottomleft: 0;
               border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      -moz-border-radius-bottomright: 0;
           border-bottom-right-radius: 0;
      -moz-border-radius-bottomleft: 0;
           border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      -moz-border-radius-topleft: 0;
           border-top-left-radius: 0;
      -moz-border-radius-topright: 0;
           border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      -moz-border-radius: 0;
           border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: inherit;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: inherit;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  -moz-border-radius-topleft: 1rem;
       border-top-left-radius: 1rem;
  -moz-border-radius-bottomleft: 1rem;
       border-bottom-left-radius: 1rem; }

.page-item:last-child .page-link {
  -moz-border-radius-topright: 1rem;
       border-top-right-radius: 1rem;
  -moz-border-radius-bottomright: 1rem;
       border-bottom-right-radius: 1rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #000000;
  border-color: #000000; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  -moz-border-radius-topleft: 0.3rem;
       border-top-left-radius: 0.3rem;
  -moz-border-radius-bottomleft: 0.3rem;
       border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  -moz-border-radius-topright: 0.3rem;
       border-top-right-radius: 0.3rem;
  -moz-border-radius-bottomright: 0.3rem;
       border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  -moz-border-radius-topleft: 0.2rem;
       border-top-left-radius: 0.2rem;
  -moz-border-radius-bottomleft: 0.2rem;
       border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  -moz-border-radius-topright: 0.2rem;
       border-top-right-radius: 0.2rem;
  -moz-border-radius-bottomright: 0.2rem;
       border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.5em 1.5em;
  font-size: 100%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  -moz-border-radius: 1rem;
       border-radius: 1rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -moz-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 1.5em;
  padding-left: 1.5em;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.badge-primary {
  color: #fff;
  background-color: #000000; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: black; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #fff; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-success {
  color: #212529;
  background-color: #71e0dc; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #47d7d2; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5); }

.badge-info {
  color: #212529;
  background-color: #71e0dc; }
  a.badge-info:hover, a.badge-info:focus {
    color: #212529;
    background-color: #47d7d2; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(113, 224, 220, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffec7d; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ffe54a; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 236, 125, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #D33030; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ac2424; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-c-blue {
  color: #fff;
  background-color: #3D7791; }
  a.badge-c-blue:hover, a.badge-c-blue:focus {
    color: #fff;
    background-color: #2e5a6d; }
  a.badge-c-blue:focus, a.badge-c-blue.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(61, 119, 145, 0.5); }

.badge-c-rust {
  color: #fff;
  background-color: #AF6345; }
  a.badge-c-rust:hover, a.badge-c-rust:focus {
    color: #fff;
    background-color: #8a4e37; }
  a.badge-c-rust:focus, a.badge-c-rust.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(175, 99, 69, 0.5); }

.badge-c-green {
  color: #fff;
  background-color: #4F785F; }
  a.badge-c-green:hover, a.badge-c-green:focus {
    color: #fff;
    background-color: #3b5947; }
  a.badge-c-green:focus, a.badge-c-green.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(79, 120, 95, 0.5); }

.badge-c-yellow {
  color: #212529;
  background-color: #E1C341; }
  a.badge-c-yellow:hover, a.badge-c-yellow:focus {
    color: #212529;
    background-color: #ceae21; }
  a.badge-c-yellow:focus, a.badge-c-yellow.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(225, 195, 65, 0.5); }

.badge-c-beige {
  color: #212529;
  background-color: #FAF8F3; }
  a.badge-c-beige:hover, a.badge-c-beige:focus {
    color: #212529;
    background-color: #ebe3cf; }
  a.badge-c-beige:focus, a.badge-c-beige.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(250, 248, 243, 0.5); }

.badge-c-red {
  color: #fff;
  background-color: #D33030; }
  a.badge-c-red:hover, a.badge-c-red:focus {
    color: #fff;
    background-color: #ac2424; }
  a.badge-c-red:focus, a.badge-c-red.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(211, 48, 48, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  -moz-border-radius: 0.3rem;
       border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  -moz-border-radius: 0;
       border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #080d15;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-primary hr {
    border-top-color: #ababab; }
  .alert-primary .alert-link {
    color: black; }

.alert-secondary {
  color: #8d9299;
  background-color: white;
  border-color: white; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #737880; }

.alert-success {
  color: #438287;
  background-color: #e3f9f8;
  border-color: #d7f6f5; }
  .alert-success hr {
    border-top-color: #c2f1f0; }
  .alert-success .alert-link {
    color: #326165; }

.alert-info {
  color: #438287;
  background-color: #e3f9f8;
  border-color: #d7f6f5; }
  .alert-info hr {
    border-top-color: #c2f1f0; }
  .alert-info .alert-link {
    color: #326165; }

.alert-warning {
  color: #8d8856;
  background-color: #fffbe5;
  border-color: #fffadb; }
  .alert-warning hr {
    border-top-color: #fff6c2; }
  .alert-warning .alert-link {
    color: #6d6943; }

.alert-danger {
  color: #76262e;
  background-color: #f6d6d6;
  border-color: #f3c5c5; }
  .alert-danger hr {
    border-top-color: #efb0b0; }
  .alert-danger .alert-link {
    color: #4f1a1f; }

.alert-light {
  color: #898f97;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #6f757e; }

.alert-dark {
  color: #232c36;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #0f1317; }

.alert-c-blue {
  color: #284b60;
  background-color: #d8e4e9;
  border-color: #c9d9e0; }
  .alert-c-blue hr {
    border-top-color: #b9ced7; }
  .alert-c-blue .alert-link {
    color: #192f3c; }

.alert-c-rust {
  color: #634139;
  background-color: #efe0da;
  border-color: #e9d3cb; }
  .alert-c-rust hr {
    border-top-color: #e1c4b9; }
  .alert-c-rust .alert-link {
    color: #432c26; }

.alert-c-green {
  color: #314c46;
  background-color: #dce4df;
  border-color: #ced9d2; }
  .alert-c-green hr {
    border-top-color: #c0cec5; }
  .alert-c-green .alert-link {
    color: #1d2d29; }

.alert-c-yellow {
  color: #7d7336;
  background-color: #f9f3d9;
  border-color: #f7eeca; }
  .alert-c-yellow hr {
    border-top-color: #f4e7b4; }
  .alert-c-yellow .alert-link {
    color: #595227; }

.alert-c-beige {
  color: #8a8e93;
  background-color: #fefefd;
  border-color: #fefdfc; }
  .alert-c-beige hr {
    border-top-color: #f8f0e9; }
  .alert-c-beige .alert-link {
    color: #70747a; }

.alert-c-red {
  color: #76262e;
  background-color: #f6d6d6;
  border-color: #f3c5c5; }
  .alert-c-red hr {
    border-top-color: #efb0b0; }
  .alert-c-red .alert-link {
    color: #4f1a1f; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.progress-bar {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #000000;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 1rem 1rem;
     -moz-background-size: 1rem 1rem;
          background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
     -moz-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
         -moz-animation: none;
              animation: none; } }

.media {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(17, 28, 43, 0.125); }
  .list-group-item:first-child {
    -moz-border-radius-topleft: inherit;
         border-top-left-radius: inherit;
    -moz-border-radius-topright: inherit;
         border-top-right-radius: inherit; }
  .list-group-item:last-child {
    -moz-border-radius-bottomright: inherit;
         border-bottom-right-radius: inherit;
    -moz-border-radius-bottomleft: inherit;
         border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    -moz-border-radius-bottomleft: 1rem;
         border-bottom-left-radius: 1rem;
    -moz-border-radius-topright: 0;
         border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    -moz-border-radius-topright: 1rem;
         border-top-right-radius: 1rem;
    -moz-border-radius-bottomleft: 0;
         border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      -moz-border-radius-bottomleft: 1rem;
           border-bottom-left-radius: 1rem;
      -moz-border-radius-topright: 0;
           border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      -moz-border-radius-topright: 1rem;
           border-top-right-radius: 1rem;
      -moz-border-radius-bottomleft: 0;
           border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      -moz-border-radius-bottomleft: 1rem;
           border-bottom-left-radius: 1rem;
      -moz-border-radius-topright: 0;
           border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      -moz-border-radius-topright: 1rem;
           border-top-right-radius: 1rem;
      -moz-border-radius-bottomleft: 0;
           border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      -moz-border-radius-bottomleft: 1rem;
           border-bottom-left-radius: 1rem;
      -moz-border-radius-topright: 0;
           border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      -moz-border-radius-topright: 1rem;
           border-top-right-radius: 1rem;
      -moz-border-radius-bottomleft: 0;
           border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      -moz-border-radius-bottomleft: 1rem;
           border-bottom-left-radius: 1rem;
      -moz-border-radius-topright: 0;
           border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      -moz-border-radius-topright: 1rem;
           border-top-right-radius: 1rem;
      -moz-border-radius-bottomleft: 0;
           border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  -moz-border-radius: 0;
       border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #080d15;
  background-color: #b8b8b8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #080d15;
    background-color: #ababab; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #080d15;
    border-color: #080d15; }

.list-group-item-secondary {
  color: #8d9299;
  background-color: white; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #8d9299;
    background-color: #f2f2f2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #8d9299;
    border-color: #8d9299; }

.list-group-item-success {
  color: #438287;
  background-color: #d7f6f5; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #438287;
    background-color: #c2f1f0; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #438287;
    border-color: #438287; }

.list-group-item-info {
  color: #438287;
  background-color: #d7f6f5; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #438287;
    background-color: #c2f1f0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #438287;
    border-color: #438287; }

.list-group-item-warning {
  color: #8d8856;
  background-color: #fffadb; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #8d8856;
    background-color: #fff6c2; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #8d8856;
    border-color: #8d8856; }

.list-group-item-danger {
  color: #76262e;
  background-color: #f3c5c5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #76262e;
    background-color: #efb0b0; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #76262e;
    border-color: #76262e; }

.list-group-item-light {
  color: #898f97;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #898f97;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #898f97;
    border-color: #898f97; }

.list-group-item-dark {
  color: #232c36;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #232c36;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #232c36;
    border-color: #232c36; }

.list-group-item-c-blue {
  color: #284b60;
  background-color: #c9d9e0; }
  .list-group-item-c-blue.list-group-item-action:hover, .list-group-item-c-blue.list-group-item-action:focus {
    color: #284b60;
    background-color: #b9ced7; }
  .list-group-item-c-blue.list-group-item-action.active {
    color: #fff;
    background-color: #284b60;
    border-color: #284b60; }

.list-group-item-c-rust {
  color: #634139;
  background-color: #e9d3cb; }
  .list-group-item-c-rust.list-group-item-action:hover, .list-group-item-c-rust.list-group-item-action:focus {
    color: #634139;
    background-color: #e1c4b9; }
  .list-group-item-c-rust.list-group-item-action.active {
    color: #fff;
    background-color: #634139;
    border-color: #634139; }

.list-group-item-c-green {
  color: #314c46;
  background-color: #ced9d2; }
  .list-group-item-c-green.list-group-item-action:hover, .list-group-item-c-green.list-group-item-action:focus {
    color: #314c46;
    background-color: #c0cec5; }
  .list-group-item-c-green.list-group-item-action.active {
    color: #fff;
    background-color: #314c46;
    border-color: #314c46; }

.list-group-item-c-yellow {
  color: #7d7336;
  background-color: #f7eeca; }
  .list-group-item-c-yellow.list-group-item-action:hover, .list-group-item-c-yellow.list-group-item-action:focus {
    color: #7d7336;
    background-color: #f4e7b4; }
  .list-group-item-c-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #7d7336;
    border-color: #7d7336; }

.list-group-item-c-beige {
  color: #8a8e93;
  background-color: #fefdfc; }
  .list-group-item-c-beige.list-group-item-action:hover, .list-group-item-c-beige.list-group-item-action:focus {
    color: #8a8e93;
    background-color: #f8f0e9; }
  .list-group-item-c-beige.list-group-item-action.active {
    color: #fff;
    background-color: #8a8e93;
    border-color: #8a8e93; }

.list-group-item-c-red {
  color: #76262e;
  background-color: #f3c5c5; }
  .list-group-item-c-red.list-group-item-action:hover, .list-group-item-c-red.list-group-item-action:focus {
    color: #76262e;
    background-color: #efb0b0; }
  .list-group-item-c-red.list-group-item-action.active {
    color: #fff;
    background-color: #76262e;
    border-color: #76262e; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #111c2b;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #111c2b;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  -webkit-flex-basis: 350px;
          flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(17, 28, 43, 0.1);
     -moz-box-shadow: 0 0.25rem 0.75rem rgba(17, 28, 43, 0.1);
          box-shadow: 0 0.25rem 0.75rem rgba(17, 28, 43, 0.1);
  opacity: 0;
  -moz-border-radius: 0.25rem;
       border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -moz-border-radius-topleft: -moz-calc(0.25rem - 1px);
       border-top-left-radius: -webkit-calc(0.25rem - 1px);
       border-top-left-radius: calc(0.25rem - 1px);
  -moz-border-radius-topright: -moz-calc(0.25rem - 1px);
       border-top-right-radius: -webkit-calc(0.25rem - 1px);
       border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out, -moz-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out, -moz-transform 0.3s ease-out, -o-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
       -moz-transform: translate(0, -50px);
         -o-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        -moz-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
         -o-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
       -moz-transform: scale(1.02);
         -o-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  max-height: -webkit-calc(100% - 1rem);
  max-height: -moz-calc(100% - 1rem);
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: -webkit-calc(100vh - 1rem);
    max-height: -moz-calc(100vh - 1rem);
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  min-height: -webkit-calc(100% - 1rem);
  min-height: -moz-calc(100% - 1rem);
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: -webkit-calc(100vh - 1rem);
    height: -moz-calc(100vh - 1rem);
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 28, 43, 0.2);
  -moz-border-radius: 0.3rem;
       border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #111c2b; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  -moz-border-radius-topleft: -moz-calc(0.3rem - 1px);
       border-top-left-radius: -webkit-calc(0.3rem - 1px);
       border-top-left-radius: calc(0.3rem - 1px);
  -moz-border-radius-topright: -moz-calc(0.3rem - 1px);
       border-top-right-radius: -webkit-calc(0.3rem - 1px);
       border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
     -moz-box-flex: 1;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  -moz-border-radius-bottomright: -moz-calc(0.3rem - 1px);
       border-bottom-right-radius: -webkit-calc(0.3rem - 1px);
       border-bottom-right-radius: calc(0.3rem - 1px);
  -moz-border-radius-bottomleft: -moz-calc(0.3rem - 1px);
       border-bottom-left-radius: -webkit-calc(0.3rem - 1px);
       border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: -webkit-calc(100% - 3.5rem);
    max-height: -moz-calc(100% - 3.5rem);
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: -webkit-calc(100vh - 3.5rem);
      max-height: -moz-calc(100vh - 3.5rem);
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: -webkit-calc(100% - 3.5rem);
    min-height: -moz-calc(100% - 3.5rem);
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: -webkit-calc(100vh - 3.5rem);
      height: -moz-calc(100vh - 3.5rem);
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #111c2b; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #111c2b; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #111c2b; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #111c2b; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #111c2b;
  -moz-border-radius: 1rem;
       border-radius: 1rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(17, 28, 43, 0.2);
  -moz-border-radius: 0.3rem;
       border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: -webkit-calc(-0.5rem - 1px);
    bottom: -moz-calc(-0.5rem - 1px);
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(17, 28, 43, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: -webkit-calc(-0.5rem - 1px);
    left: -moz-calc(-0.5rem - 1px);
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(17, 28, 43, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: -webkit-calc(-0.5rem - 1px);
    top: -moz-calc(-0.5rem - 1px);
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(17, 28, 43, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: -webkit-calc(-0.5rem - 1px);
    right: -moz-calc(-0.5rem - 1px);
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(17, 28, 43, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  -moz-border-radius-topleft: -moz-calc(0.3rem - 1px);
       border-top-left-radius: -webkit-calc(0.3rem - 1px);
       border-top-left-radius: calc(0.3rem - 1px);
  -moz-border-radius-topright: -moz-calc(0.3rem - 1px);
       border-top-right-radius: -webkit-calc(0.3rem - 1px);
       border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: -o-transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out, -moz-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out, -moz-transform 0.6s ease-in-out, -o-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
     -moz-transform: translateX(100%);
       -o-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
     -moz-transform: translateX(-100%);
       -o-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
     -moz-transform: none;
       -o-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  -o-transition: opacity 0s 0.6s;
  -moz-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  -moz-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      -o-transition: none;
      -moz-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    -webkit-flex: 0 1 auto;
       -moz-box-flex: 0;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    -moz-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        -moz-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-moz-keyframes spinner-border {
  to {
    -moz-transform: rotate(360deg);
         transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  -moz-border-radius: 50%;
       border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
     -moz-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@-moz-keyframes spinner-grow {
  0% {
    -moz-transform: scale(0);
         transform: scale(0); }
  50% {
    opacity: 1;
    -moz-transform: none;
         transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
       -moz-transform: none;
         -o-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  -moz-border-radius: 50%;
       border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
     -moz-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
       -moz-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #000000 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: black !important; }

.bg-secondary {
  background-color: #fff !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important; }

.bg-success {
  background-color: #71e0dc !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #47d7d2 !important; }

.bg-info {
  background-color: #71e0dc !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #47d7d2 !important; }

.bg-warning {
  background-color: #ffec7d !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe54a !important; }

.bg-danger {
  background-color: #D33030 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ac2424 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-c-blue {
  background-color: #3D7791 !important; }

a.bg-c-blue:hover, a.bg-c-blue:focus,
button.bg-c-blue:hover,
button.bg-c-blue:focus {
  background-color: #2e5a6d !important; }

.bg-c-rust {
  background-color: #AF6345 !important; }

a.bg-c-rust:hover, a.bg-c-rust:focus,
button.bg-c-rust:hover,
button.bg-c-rust:focus {
  background-color: #8a4e37 !important; }

.bg-c-green {
  background-color: #4F785F !important; }

a.bg-c-green:hover, a.bg-c-green:focus,
button.bg-c-green:hover,
button.bg-c-green:focus {
  background-color: #3b5947 !important; }

.bg-c-yellow {
  background-color: #E1C341 !important; }

a.bg-c-yellow:hover, a.bg-c-yellow:focus,
button.bg-c-yellow:hover,
button.bg-c-yellow:focus {
  background-color: #ceae21 !important; }

.bg-c-beige {
  background-color: #FAF8F3 !important; }

a.bg-c-beige:hover, a.bg-c-beige:focus,
button.bg-c-beige:hover,
button.bg-c-beige:focus {
  background-color: #ebe3cf !important; }

.bg-c-red {
  background-color: #D33030 !important; }

a.bg-c-red:hover, a.bg-c-red:focus,
button.bg-c-red:hover,
button.bg-c-red:focus {
  background-color: #ac2424 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #000000 !important; }

.border-secondary {
  border-color: #fff !important; }

.border-success {
  border-color: #71e0dc !important; }

.border-info {
  border-color: #71e0dc !important; }

.border-warning {
  border-color: #ffec7d !important; }

.border-danger {
  border-color: #D33030 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-c-blue {
  border-color: #3D7791 !important; }

.border-c-rust {
  border-color: #AF6345 !important; }

.border-c-green {
  border-color: #4F785F !important; }

.border-c-yellow {
  border-color: #E1C341 !important; }

.border-c-beige {
  border-color: #FAF8F3 !important; }

.border-c-red {
  border-color: #D33030 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  -moz-border-radius: 0.2rem !important;
       border-radius: 0.2rem !important; }

.rounded {
  -moz-border-radius: 1rem !important;
       border-radius: 1rem !important; }

.rounded-top {
  -moz-border-radius-topleft: 1rem !important;
       border-top-left-radius: 1rem !important;
  -moz-border-radius-topright: 1rem !important;
       border-top-right-radius: 1rem !important; }

.rounded-right {
  -moz-border-radius-topright: 1rem !important;
       border-top-right-radius: 1rem !important;
  -moz-border-radius-bottomright: 1rem !important;
       border-bottom-right-radius: 1rem !important; }

.rounded-bottom {
  -moz-border-radius-bottomright: 1rem !important;
       border-bottom-right-radius: 1rem !important;
  -moz-border-radius-bottomleft: 1rem !important;
       border-bottom-left-radius: 1rem !important; }

.rounded-left {
  -moz-border-radius-topleft: 1rem !important;
       border-top-left-radius: 1rem !important;
  -moz-border-radius-bottomleft: 1rem !important;
       border-bottom-left-radius: 1rem !important; }

.rounded-lg {
  -moz-border-radius: 0.3rem !important;
       border-radius: 0.3rem !important; }

.rounded-circle {
  -moz-border-radius: 50% !important;
       border-radius: 50% !important; }

.rounded-pill {
  -moz-border-radius: 50rem !important;
       border-radius: 50rem !important; }

.rounded-0 {
  -moz-border-radius: 0 !important;
       border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: -moz-inline-box !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
     -moz-box-orient: horizontal !important;
     -moz-box-direction: normal !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
     -moz-box-orient: vertical !important;
     -moz-box-direction: normal !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
     -moz-box-orient: horizontal !important;
     -moz-box-direction: reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
     -moz-box-orient: vertical !important;
     -moz-box-direction: reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
     -moz-box-flex: 1 !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
     -moz-box-flex: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
     -moz-box-flex: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
     -moz-box-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
     -moz-box-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
     -moz-box-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
     -moz-box-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
     -moz-box-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
     -moz-box-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
     -moz-box-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
     -moz-box-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
     -moz-box-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
       -moz-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
       -moz-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
       -moz-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
       -moz-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
       -moz-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
       -moz-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
       -moz-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
       -moz-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
       -moz-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
       -moz-box-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
       -moz-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
       -moz-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
       -moz-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
       -moz-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
       -moz-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
       -moz-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
       -moz-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
       -moz-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
       -moz-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
       -moz-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
       -moz-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
       -moz-box-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
       -moz-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
       -moz-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
       -moz-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
       -moz-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
       -moz-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
       -moz-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
       -moz-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
       -moz-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
       -moz-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
       -moz-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
       -moz-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
       -moz-box-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
       -moz-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
       -moz-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: normal !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: normal !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
       -moz-box-flex: 1 !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
       -moz-box-flex: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
       -moz-box-flex: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
       -moz-box-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
       -moz-box-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
       -moz-box-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
       -moz-box-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
       -moz-box-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
       -moz-box-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
       -moz-box-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
       -moz-box-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
       -moz-box-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(17, 28, 43, 0.075) !important;
     -moz-box-shadow: 0 0.125rem 0.25rem rgba(17, 28, 43, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(17, 28, 43, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(17, 28, 43, 0.15) !important;
     -moz-box-shadow: 0 0.5rem 1rem rgba(17, 28, 43, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(17, 28, 43, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(17, 28, 43, 0.175) !important;
     -moz-box-shadow: 0 1rem 3rem rgba(17, 28, 43, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(17, 28, 43, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #000000 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: black !important; }

.text-secondary {
  color: #fff !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

.text-success {
  color: #71e0dc !important; }

a.text-success:hover, a.text-success:focus {
  color: #32d2cd !important; }

.text-info {
  color: #71e0dc !important; }

a.text-info:hover, a.text-info:focus {
  color: #32d2cd !important; }

.text-warning {
  color: #ffec7d !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffe131 !important; }

.text-danger {
  color: #D33030 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #972020 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-c-blue {
  color: #3D7791 !important; }

a.text-c-blue:hover, a.text-c-blue:focus {
  color: #264b5b !important; }

.text-c-rust {
  color: #AF6345 !important; }

a.text-c-rust:hover, a.text-c-rust:focus {
  color: #78442f !important; }

.text-c-green {
  color: #4F785F !important; }

a.text-c-green:hover, a.text-c-green:focus {
  color: #314a3a !important; }

.text-c-yellow {
  color: #E1C341 !important; }

a.text-c-yellow:hover, a.text-c-yellow:focus {
  color: #b89b1d !important; }

.text-c-beige {
  color: #FAF8F3 !important; }

a.text-c-beige:hover, a.text-c-beige:focus {
  color: #e4d9bd !important; }

.text-c-red {
  color: #D33030 !important; }

a.text-c-red:hover, a.text-c-red:focus {
  color: #972020 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(17, 28, 43, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
       -moz-box-shadow: none !important;
            box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #111c2b; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.btn-primary {
  color: #fff;
  background-color: #000000;
  border-color: #000000; }
  .btn-primary:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    border-color: transparent; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    border-color: transparent;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
       -moz-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    border-color: transparent; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
         -moz-box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.charles-btn {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }
  .charles-btn:focus {
    outline: none;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none; }

.btn {
  font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif; }
  .btn.btn-secondary:hover:not(:disabled):not(.disabled) {
    background-color: #000000;
    border-color: #000000;
    color: #fff; }
  .btn .button-loader {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    text-align: left;
    margin-right: .4em; }
    .btn .button-loader *, .btn .button-loader *::before, .btn .button-loader *::after {
      -webkit-box-sizing: content-box;
         -moz-box-sizing: content-box;
              box-sizing: content-box; }
  .btn.loading.disabled {
    opacity: 0.6; }
  .btn.loading svg {
    display: none; }
  .btn .button-inline-svg {
    display: block;
    margin: auto .625rem auto 0;
    height: 18px;
    width: 18px; }
  .btn.btn-small {
    padding: 10px 24px; }

a.btn.link-btn {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: block; }

.form-control:focus {
  border-width: 2px;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none; }

.form-label {
  font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
  font-weight: 500;
  font-size: .875rem; }

.invalid-feedback {
  font-weight: 500;
  font-size: .875rem; }

a {
  font-weight: 600; }

.font-weight-semi {
  font-weight: 500; }

.c-pill-fill-green {
  color: white;
  background-color: #50E19C;
  border-color: #50E19C; }

.c-pill-outline-green {
  color: #50E19C;
  border: 2px solid #50E19C;
  background-color: none; }

.c-pill-outline-black {
  color: black;
  border: 2px solid black;
  background-color: none; }

.c-pill-fill-red {
  background-color: #DA4242;
  border-color: #DA4242;
  color: white; }

.agenda-sommeil-cta {
  width: 100%; }
  .agenda-sommeil-cta svg {
    width: 32px; }

.bulletpoint-container .bulletpoint-item {
  text-align: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  min-width: 140px; }
  .bulletpoint-container .bulletpoint-item .values-section {
    vertical-align: middle;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    cursor: pointer; }
  .bulletpoint-container .bulletpoint-item h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: lighter;
    margin: 0; }
  .bulletpoint-container .bulletpoint-item .hour-value {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    margin: 0; }
  .bulletpoint-container .bulletpoint-item .action {
    padding-top: 5px;
    padding-left: 10px;
    font-size: 14px; }

.prescription-table {
  border: 1px solid #d5d5dd;
  -moz-border-radius: 3px;
       border-radius: 3px;
  margin: 15px 0;
  text-align: left;
  padding: 0px 8px; }
  .prescription-table span {
    font-weight: 450; }
  .prescription-table ul {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .prescription-table li {
    margin-bottom: 8px; }
    .prescription-table li a {
      text-decoration: underline; }
    .prescription-table li label {
      margin-bottom: 0px; }
    .prescription-table li h4 {
      color: #495057;
      font-size: 15px;
      font-weight: 450; }

.phyto-prescription-explanation {
  text-align: left;
  padding: 10px 0;
  line-height: 1.3em;
  font-size: 1.1em;
  font-style: italic; }
  .phyto-prescription-explanation .explanation-highlight {
    font-weight: 500; }

@media (min-width: 1340px) {
  .home-page-carousel {
    margin-right: -1.875rem; } }

.home-page-carousel .swiper-slide {
  max-width: 400px; }

.treatments-container .carousel-cta {
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal; }
  .treatments-container .carousel-cta svg {
    width: 23px !important; }

.treatments-container.d-flex .home-page-carousel {
  margin-right: 10px !important; }

.price-card-offer-custom-padding {
  padding: 24px 31px !important; }

@media (min-width: 576px) {
  .home-page-card {
    width: 100%;
    max-width: 327px; } }

.home-page-card .program-variant-bullets .program-variant-row {
  margin-bottom: 24px; }
  .home-page-card .program-variant-bullets .program-variant-row:last-of-type {
    margin-bottom: 48px; }

.home-page-card .home-page-card-list {
  list-style-position: inside;
  padding-left: 0; }

.home-page-card.program-recommendation-card {
  min-height: 400px; }

.pharma-icon svg {
  width: 18px; }

.prescription-header {
  display: grid;
  grid-template-columns: 100px auto auto; }
  .prescription-header .header-item .doctor-image {
    border: 2px solid #000; }
  .prescription-header .header-item.prescription-expiry {
    padding-top: 35px;
    text-align: right;
    font-size: 14px; }
  .prescription-header .header-item.doctor-infos {
    padding-top: 5px; }
    @media (min-width: 768px) {
      .prescription-header .header-item.doctor-infos {
        padding-top: 20px; } }
    .prescription-header .header-item.doctor-infos .expiry-ordo {
      margin-top: 15px;
      font-size: 14px; }
    .prescription-header .header-item.doctor-infos .doctor-specialty {
      font-size: 14px; }

.treatment-title {
  margin-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CDCDCD; }
  .treatment-title .pharma-icon svg {
    width: 32px; }

.prescription-items {
  padding-left: 20px; }
  .prescription-items li {
    padding-top: 16px; }
  .prescription-items .molecule-details {
    text-decoration: underline; }

.chatbox-v1 .chatroom {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  margin: .5em; }
  .chatbox-v1 .chatroom .aligned-to-left {
    text-align: left; }
  .chatbox-v1 .chatroom .aligned-to-center {
    text-align: center;
    list-style-position: inside; }
  .chatbox-v1 .chatroom .aligned-to-right {
    text-align: right;
    list-style-position: inside; }
  .chatbox-v1 .chatroom .underlined-message {
    text-decoration: underline; }
  .chatbox-v1 .chatroom .bold-message {
    font-weight: bold; }
  .chatbox-v1 .chatroom .italic-message {
    font-style: italic; }
  @media (min-width: 576px) {
    .chatbox-v1 .chatroom {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between; } }
  .chatbox-v1 .chatroom ul {
    padding: 1em .8em 0; }
  .chatbox-v1 .chatroom li {
    background-color: transparent;
    background-color: initial;
    padding: 0; }
  .chatbox-v1 .chatroom .chat-form {
    margin-top: 10px;
    color: #495057;
    cursor: pointer;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    line-height: 3em;
    outline: none;
    width: 100%;
    min-height: 100px; }
    .chatbox-v1 .chatroom .chat-form .tox-tinymce {
      margin-bottom: 10px; }
    .chatbox-v1 .chatroom .chat-form .tox-statusbar {
      display: none; }
    .chatbox-v1 .chatroom .chat-form .chatbox-textarea {
      border: 0;
      margin-top: 20px;
      margin-bottom: 10px;
      line-height: 1.5em;
      font-size: 1em;
      border: solid 1px #d5d5dd;
      padding: 5px 10px; }
      @media (min-width: 576px) {
        .chatbox-v1 .chatroom .chat-form .chatbox-textarea {
          font-size: 1em; } }
  .chatbox-v1 .chatroom button[type='submit'] {
    background-color: #000000;
    border: 0;
    color: #fff;
    font-size: .8em;
    font-weight: 500;
    text-align: center;
    min-height: 30px; }
    @media (min-width: 576px) {
      .chatbox-v1 .chatroom button[type='submit'] {
        font-size: 1em; } }
    .chatbox-v1 .chatroom button[type='submit']:hover {
      background-color: black; }

.chatbox-v1 .chats {
  background-color: #fff;
  border: solid 1px #d5d5dd;
  -moz-border-radius-topright: 3px;
       border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
       border-top-left-radius: 3px;
  text-align: left; }
  .chatbox-v1 .chats .chat-list {
    max-height: 550px;
    overflow-y: scroll;
    padding: 5px 10px; }

.chatbox-v1 .chat-patient-notification {
  background-color: #000000;
  color: white;
  font-size: .8em;
  margin: 0;
  opacity: 0;
  padding: .5em;
  text-align: center;
  width: 100%;
  -webkit-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  transition: opacity 1s ease;
  display: none;
  margin-top: 20px; }
  @media (min-width: 576px) {
    .chatbox-v1 .chat-patient-notification {
      font-size: .9em; } }
  @media (min-width: 768px) {
    .chatbox-v1 .chat-patient-notification {
      font-size: 1em;
      padding: 1em; } }
  .chatbox-v1 .chat-patient-notification.active {
    opacity: 1;
    display: block; }

.chatbox-v1 .chat-block-info {
  position: relative; }
  .chatbox-v1 .chat-block-info .message-body {
    white-space: pre-wrap; }
    .chatbox-v1 .chat-block-info .message-body:hover + .chat-block-date {
      opacity: 1; }

.chatbox-v1 .chat-message-container {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }

.chatbox-v1 .chat-block-date {
  color: #495057;
  font-size: .8em;
  margin: 0 1rem;
  opacity: 1;
  -webkit-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease; }
  @media (min-width: 576px) {
    .chatbox-v1 .chat-block-date {
      font-size: 1em; } }
  @media (min-width: 768px) {
    .chatbox-v1 .chat-block-date {
      opacity: 0; } }
  .chatbox-v1 .chat-block-date span {
    display: block;
    font-size: .8em; }

.chatbox-v1 .chat {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: .8em; }
  .chatbox-v1 .chat .displayed-message, .chatbox-v1 .chat .message-body p, .chatbox-v1 .chat .message-body ul, .chatbox-v1 .chat .message-body ol {
    padding: 0px;
    max-width: 100%; }
  .chatbox-v1 .chat .message-body {
    -webkit-transition: margin-right .3s ease;
    -o-transition: margin-right .3s ease;
    -moz-transition: margin-right .3s ease;
    transition: margin-right .3s ease;
    word-break: break-word; }
    .chatbox-v1 .chat .message-body a {
      text-decoration: underline; }
    .chatbox-v1 .chat .message-body ul, .chatbox-v1 .chat .message-body ol {
      margin: 0px 0px 0px 15px; }
    .chatbox-v1 .chat .message-body li br {
      display: none; }
  .chatbox-v1 .chat.left p {
    background-color: #f1f3f5; }
    .chatbox-v1 .chat.left p a {
      color: black; }
      .chatbox-v1 .chat.left p a:hover {
        color: #339af0; }
  .chatbox-v1 .chat.left .chat-block-date {
    right: -4rem; }
  .chatbox-v1 .chat.right {
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
            justify-content: flex-end; }
    .chatbox-v1 .chat.right .chat-message-container {
      -webkit-flex-direction: row-reverse;
         -moz-box-orient: horizontal;
         -moz-box-direction: reverse;
              flex-direction: row-reverse; }
    .chatbox-v1 .chat.right p {
      background-color: #000000;
      color: #fff; }
      .chatbox-v1 .chat.right p a {
        color: #fff; }
        .chatbox-v1 .chat.right p a:hover {
          color: #f1f3f5; }
    .chatbox-v1 .chat.right .chat-block-date {
      left: -5rem; }
  @media (min-width: 576px) {
    .chatbox-v1 .chat {
      background-color: #fff;
      font-size: .8em;
      list-style: none; } }
  .chatbox-v1 .chat p {
    line-height: 1.3em;
    margin: .5em 0;
    max-width: 95%;
    padding: .8em; }
    @media (min-width: 576px) {
      .chatbox-v1 .chat p {
        padding: 1em; } }
    @media (min-width: 768px) {
      .chatbox-v1 .chat p {
        max-width: 80%;
        padding: 1.2em; } }
  .chatbox-v1 .chat .chat-block-date {
    bottom: .8em;
    text-align: center;
    width: 3.125rem; }

@media (min-width: 576px) {
  .chatbox-v1 .chat-box-container {
    margin-right: 2.5em;
    width: 100%; } }

.chatbox-v1 .video {
  list-style: none;
  width: 100%; }

.block-header {
  margin-top: 2rem; }
  .block-header .block-header-title .icon svg {
    width: 32px; }

.block-with-header-inside .block-header {
  margin-top: 0;
  margin-bottom: 1rem; }
  .block-with-header-inside .block-header .block-header-title {
    margin-left: 0; }

.block-content {
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  -webkit-box-shadow: 10px 10px 0 black;
     -moz-box-shadow: 10px 10px 0 black;
          box-shadow: 10px 10px 0 black;
  padding: 24px 32px;
  margin-bottom: 16px;
  background-color: #fff; }
  .block-content .block-with-header-inside {
    padding-top: 32px; }

.block-content-md {
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  background-color: #fff;
  padding: 6px; }
  @media (min-width: 576px) {
    .block-content-md {
      padding: 24px 32px;
      -webkit-box-shadow: 10px 10px 0 black;
         -moz-box-shadow: 10px 10px 0 black;
              box-shadow: 10px 10px 0 black; } }

.new-block-content {
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  -webkit-box-shadow: 10px 10px 0 black;
     -moz-box-shadow: 10px 10px 0 black;
          box-shadow: 10px 10px 0 black;
  padding: 32px 24px;
  margin-bottom: 16px;
  background-color: #fff; }
  .new-block-content .block-with-header-inside {
    padding-top: 32px; }

.chatbox-v2-component .ongoing-chats-container-desktop {
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  -webkit-box-shadow: 10px 10px 0 black;
     -moz-box-shadow: 10px 10px 0 black;
          box-shadow: 10px 10px 0 black;
  margin-right: 10px;
  background-color: #fff;
  height: 60vh;
  min-height: 500px;
  overflow: hidden; }
  .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-left {
    min-width: 320px;
    overflow-y: scroll;
    border-right: 2px solid #111c2b; }
    .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-left .nav-pills.ongoing-doctor-list {
      width: 320px; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-left .nav-pills.ongoing-doctor-list .nav-link {
        -moz-border-radius: 0;
             border-radius: 0; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-left .nav-pills.ongoing-doctor-list .nav-link.active {
        background-color: #EEEEEE; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-left .nav-pills.ongoing-doctor-list .ongoing-chats-header {
        background-color: #3D7791;
        color: white; }
  .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 {
    height: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between; }
    .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      margin: .5em; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .aligned-to-left {
        text-align: left; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .aligned-to-center {
        text-align: center;
        list-style-position: inside; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .aligned-to-right {
        text-align: right;
        list-style-position: inside; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .underlined-message {
        text-decoration: underline; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .bold-message {
        font-weight: bold; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .italic-message {
        font-style: italic; }
      @media (min-width: 576px) {
        .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-justify-content: space-between;
             -moz-box-pack: justify;
                  justify-content: space-between; } }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom ul {
        padding: 1em .8em 0; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom li {
        background-color: transparent;
        background-color: initial;
        padding: 0; }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .chat-form {
        margin-top: 10px;
        color: #495057;
        cursor: pointer;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-flex-grow: 1;
           -moz-box-flex: 1;
                flex-grow: 1;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        line-height: 3em;
        outline: none;
        width: 100%;
        min-height: 100px; }
        .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .chat-form .tox-tinymce {
          margin-bottom: 10px; }
        .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .chat-form .tox-statusbar {
          display: none; }
        .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .chat-form .chatbox-textarea {
          border: 0;
          margin-top: 20px;
          margin-bottom: 10px;
          line-height: 1.5em;
          font-size: 1em;
          border: solid 1px #d5d5dd;
          padding: 5px 10px; }
          @media (min-width: 576px) {
            .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chatroom .chat-form .chatbox-textarea {
              font-size: 1em; } }
    .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chat-form-disable {
      height: 131px;
      width: 100%;
      display: grid;
      place-items: center;
      border-top: 2px solid black; }
      @media (max-width: 576px) {
        .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chat-form-disable {
          display: none; } }
      .chatbox-v2-component .ongoing-chats-container-desktop .ongoing-chats-right .chatbox-v2 .chat-form-disable .chat-form-disable-text {
        font-weight: 500;
        font-size: 1.25rem; }

.chatbox-v2-component .show-finished-consult .show-consult-toggle {
  margin-bottom: 24px; }
  .chatbox-v2-component .show-finished-consult .show-consult-toggle svg {
    width: 16px; }

.chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chat {
  border: 2px solid #111c2b;
  -moz-border-radius: 20px;
       border-radius: 20px;
  -webkit-box-shadow: 10px 10px 0 #111c2b;
     -moz-box-shadow: 10px 10px 0 #111c2b;
          box-shadow: 10px 10px 0 #111c2b;
  margin-right: 10px;
  background-color: #F5F5F5; }
  .chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chat .ongoing-chat-doctor .doctor-toggle {
    font-size: 14px; }
    .chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chat .ongoing-chat-doctor .doctor-toggle svg {
      width: 16px; }
  .chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chat .ongoing-chat-footer .chat-list {
    border-top: 2px solid #111c2b;
    -moz-border-radius-bottomleft: 20px;
         border-bottom-left-radius: 20px;
    -moz-border-radius-bottomright: 20px;
         border-bottom-right-radius: 20px;
    height: 400px; }
  .chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chat .ongoing-chat-footer .chat-form {
    border-top: 2px solid #111c2b; }

.chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chats-container-mobile-svg {
  width: 16px;
  margin-right: 4px; }
  .chatbox-v2-component .ongoing-chats-container-mobile .ongoing-chats-container-mobile-svg * {
    fill: black; }

.chatbox-v2-component .ongoing-chats-doctor {
  cursor: pointer;
  width: 100%; }
  .chatbox-v2-component .ongoing-chats-doctor:not(.active).finished {
    opacity: .6; }
  .chatbox-v2-component .ongoing-chats-doctor .doctor-image {
    border: 2px solid #000000; }
  .chatbox-v2-component .ongoing-chats-doctor .doctor-right .unread-message {
    font-size: 14px;
    font-weight: bold;
    color: #3D7791 !important; }
  .chatbox-v2-component .ongoing-chats-doctor .doctor-right .doctor-right-name {
    font-size: 16px;
    font-weight: bold;
    color: #111c2b !important; }
  .chatbox-v2-component .ongoing-chats-doctor .doctor-right .doctor-right-specialty {
    font-size: 14px;
    font-weight: normal;
    color: #111c2b !important; }
  .chatbox-v2-component .ongoing-chats-doctor .doctor-right .doctor-right-last-message {
    color: #888888 !important;
    height: 45px;
    font-size: 14px;
    font-weight: normal;
    text-overflow: ellipsis;
    max-width: 145px;
    overflow: hidden; }
  .chatbox-v2-component .ongoing-chats-doctor .doctor-right .doctor-right-last-message-at {
    color: #757575 !important;
    -webkit-align-self: self-start;
            align-self: self-start;
    font-weight: 500;
    font-size: 14px; }

.chatbox-v2-component .chat-list {
  background-color: #fff;
  text-align: left;
  overflow-y: scroll;
  padding: 5px 10px; }

.chatbox-v2-component .chat-patient-notification {
  background-color: #000000;
  color: white;
  font-size: .8em;
  margin: 0;
  opacity: 0;
  padding: .5em;
  text-align: center;
  width: 100%;
  -webkit-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  transition: opacity 1s ease;
  display: none;
  margin-top: 20px; }
  @media (min-width: 576px) {
    .chatbox-v2-component .chat-patient-notification {
      font-size: .9em; } }
  @media (min-width: 768px) {
    .chatbox-v2-component .chat-patient-notification {
      font-size: 1em;
      padding: 1em; } }
  .chatbox-v2-component .chat-patient-notification.active {
    opacity: 1;
    display: block; }

.chatbox-v2-component .chat-block-info {
  position: relative; }
  .chatbox-v2-component .chat-block-info .message-body {
    white-space: pre-wrap; }
    .chatbox-v2-component .chat-block-info .message-body:hover + .chat-block-date {
      opacity: 1; }

.chatbox-v2-component .chat-message-container {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }

.chatbox-v2-component .chat-block-date {
  color: #495057;
  font-size: .8em;
  margin: 0 1rem;
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease; }
  @media (min-width: 576px) {
    .chatbox-v2-component .chat-block-date {
      font-size: 1em; } }
  @media (min-width: 768px) {
    .chatbox-v2-component .chat-block-date {
      opacity: 0; } }
  .chatbox-v2-component .chat-block-date span {
    display: block;
    font-size: .8em; }

.chatbox-v2-component .chat {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: .8em; }
  .chatbox-v2-component .chat .displayed-message, .chatbox-v2-component .chat p.message-body p, .chatbox-v2-component .chat p.message-body ul, .chatbox-v2-component .chat p.message-body ol {
    padding: 0px;
    max-width: 100%; }
  .chatbox-v2-component .chat p.message-body {
    -webkit-transition: margin-right .3s ease;
    -o-transition: margin-right .3s ease;
    -moz-transition: margin-right .3s ease;
    transition: margin-right .3s ease;
    word-break: break-word;
    border: 2px solid #111c2b;
    -moz-border-radius: 20px;
         border-radius: 20px; }
    .chatbox-v2-component .chat p.message-body a {
      text-decoration: underline; }
    .chatbox-v2-component .chat p.message-body ul, .chatbox-v2-component .chat p.message-body ol {
      margin: 0px 0px 0px 15px; }
    .chatbox-v2-component .chat p.message-body li br {
      display: none; }
  .chatbox-v2-component .chat.left p {
    background-color: #111c2b;
    color: #fff; }
    .chatbox-v2-component .chat.left p a {
      color: black; }
      .chatbox-v2-component .chat.left p a:hover {
        color: #339af0; }
  .chatbox-v2-component .chat.left .chat-block-date {
    right: -4rem; }
  .chatbox-v2-component .chat.right {
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
            justify-content: flex-end; }
    .chatbox-v2-component .chat.right .chat-message-container {
      -webkit-flex-direction: row-reverse;
         -moz-box-orient: horizontal;
         -moz-box-direction: reverse;
              flex-direction: row-reverse; }
    .chatbox-v2-component .chat.right p {
      background-color: #fff;
      color: #111c2b; }
      .chatbox-v2-component .chat.right p a {
        color: #111c2b; }
        .chatbox-v2-component .chat.right p a:hover {
          color: #111c2b; }
    .chatbox-v2-component .chat.right .chat-block-date {
      left: -5rem; }
  @media (min-width: 576px) {
    .chatbox-v2-component .chat {
      font-size: .8em;
      list-style: none; } }
  .chatbox-v2-component .chat p {
    line-height: 1.3em;
    margin: .5em 0;
    max-width: 95%;
    padding: .8em; }
    @media (min-width: 576px) {
      .chatbox-v2-component .chat p {
        padding: 1em; } }
    @media (min-width: 768px) {
      .chatbox-v2-component .chat p {
        max-width: 80%;
        padding: 1.2em; } }
  .chatbox-v2-component .chat .chat-block-date {
    bottom: .8em;
    text-align: center;
    width: 3.125rem; }

@media (min-width: 576px) {
  .chatbox-v2-component .chat-box-container {
    margin-right: 2.5em;
    width: 100%; } }

.chatbox-v2-component .video {
  list-style: none;
  width: 100%; }

.chatbox-v2-component .chat-form-disable {
  display: none; }

@media (min-width: 576px) {
  .display-row {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; } }

.chat-form-block {
  margin-bottom: 1em; }
  @media (min-width: 576px) {
    .chat-form-block {
      margin-bottom: 3em;
      margin-right: 3em; } }

.chat-title {
  line-height: 1.3em; }

.chat-cta {
  color: #002874;
  text-decoration: underline; }

.chat-picto path {
  fill: #6389FF; }

.chat-picto svg {
  height: 1.5em; }

.chat-link {
  color: #002874; }

.contact-form-container .contact-form-highlight {
  color: #52A2D1;
  font-weight: 600; }

.contact-form-container .contact-form-textarea {
  min-height: 200px; }

.contact-form-container .icon-send {
  display: inline-block;
  margin-right: .5em; }
  .contact-form-container .icon-send svg {
    height: 24px;
    margin-right: 8px; }
  .contact-form-container .icon-send path {
    fill: #fff; }

.coupon-wrapper {
  margin-bottom: 32px; }
  .coupon-wrapper .coupon-title {
    font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px; }
  .coupon-wrapper .coupon-input-wrapper {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    max-height: 40px; }
    .coupon-wrapper .coupon-input-wrapper .coupon-text-input {
      border: 1px solid black;
      -moz-border-radius: 16px 0 0 16px;
           border-radius: 16px 0 0 16px;
      padding-left: 16px; }
    .coupon-wrapper .coupon-input-wrapper .btn-sm {
      -moz-border-radius: 0 16px 16px 0;
           border-radius: 0 16px 16px 0;
      border: 1px solid black;
      border-left: 0; }

.credit-card {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  border: 1px solid #a4a4a4;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  font-weight: 500;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: .5em;
  max-width: 350px;
  min-width: 250px;
  padding: .25em;
  width: 100%; }
  .credit-card .card {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
  .credit-card .credit-card-icon svg {
    height: 3em;
    width: 4em; }
  .credit-card .number {
    margin: .5em; }
  .credit-card .exp {
    color: #a4a4a4;
    margin: .5em; }
  .credit-card .delete-icon {
    cursor: pointer; }
    .credit-card .delete-icon svg {
      height: 1.2em;
      width: 1.2em; }

.doctor-profile-container {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background-color: white;
  text-align: left; }
  @media (max-width: 576px) {
    .doctor-profile-container {
      padding: 24px 20px; } }
  .doctor-profile-container .doctor-main-profile {
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
            flex: 1 1 auto;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
            order: 1; }
  .doctor-profile-container .doctor-profile-right-block {
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
            order: 3; }
    @media (max-width: 768px) {
      .doctor-profile-container .doctor-profile-right-block {
        -webkit-flex: 1 0 100%;
           -moz-box-flex: 1;
                flex: 1 0 100%;
        margin-top: 0.5rem;
        border-top: 1px solid black;
        padding-top: 1.5rem; } }
    @media (min-width: 768px) {
      .doctor-profile-container .doctor-profile-right-block {
        -webkit-flex: 0 0 30%;
           -moz-box-flex: 0;
                flex: 0 0 30%;
        border-left: 1px solid black;
        padding-left: 1.5rem; } }
  .doctor-profile-container .doctor-details-container {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
            order: 2;
    margin-top: 1.5rem;
    border-top: 1px solid black;
    padding-top: 1.5rem; }
    @media (min-width: 768px) {
      .doctor-profile-container .doctor-details-container {
        -webkit-order: 4;
           -moz-box-ordinal-group: 5;
                order: 4; } }
  .doctor-profile-container .doctor-profile-img {
    border: 2px solid black;
    -moz-border-radius: 1rem;
         border-radius: 1rem;
    height: 88px;
    width: 88px; }
    @media (min-width: 768px) {
      .doctor-profile-container .doctor-profile-img {
        -moz-border-radius: 1.875rem;
             border-radius: 1.875rem;
        height: 136px;
        width: 136px; } }
  .doctor-profile-container .doctor-profile-see-profile {
    margin-top: 1rem;
    font-weight: 600;
    cursor: pointer; }
    .doctor-profile-container .doctor-profile-see-profile:hover {
      text-decoration: underline; }
    .doctor-profile-container .doctor-profile-see-profile .icon svg {
      width: 16px;
      margin-top: -4px;
      margin-right: 4px; }
  .doctor-profile-container .doctor-details-container {
    display: none;
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: max-height, .25s;
    -o-transition: max-height, .25s;
    -moz-transition: max-height, .25s;
    transition: max-height, .25s; }
    .doctor-profile-container .doctor-details-container.active {
      display: block;
      margin-top: 1rem;
      max-height: 150px; }
  .doctor-profile-container .doctor-profile-right-block {
    text-align: center; }
  .doctor-profile-container .doctor-profile-selected-slot {
    grid-row-gap: 8px;
    row-gap: 8px; }
    .doctor-profile-container .doctor-profile-selected-slot svg {
      height: 24px; }
  .doctor-profile-container .doctor-profile-availability, .doctor-profile-container .doctor-profile-unavailability {
    margin-bottom: 1rem; }

.doctor-selection-container .consultation-choice-title {
  padding-bottom: 1.5em; }

.doctor-selection-container .consultation-choices {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: stretch;
     -moz-box-align: stretch;
          align-items: stretch;
  margin-top: 16px;
  margin-bottom: 24px; }

.doctor-selection-container .consultation-choice-medium-svg {
  margin-right: 1.25rem; }
  .doctor-selection-container .consultation-choice-medium-svg svg {
    height: 24px; }

.infos-consultation {
  text-align: left; }
  .infos-consultation .infos-consultation-item {
    cursor: pointer; }
    .infos-consultation .infos-consultation-item .infos-consultation-item-title {
      cursor: pointer;
      padding-right: 1em;
      position: relative;
      text-align: left;
      width: 100%; }
    .infos-consultation .infos-consultation-item .infos-consultation-item-body {
      height: 0;
      overflow: hidden;
      -webkit-transition: min-height, height, .5s;
      -o-transition: min-height, height, .5s;
      -moz-transition: min-height, height, .5s;
      transition: min-height, height, .5s;
      padding-left: 5px;
      border-left: 2px solid #000000; }
    .infos-consultation .infos-consultation-item.active .infos-consultation-item-title, .infos-consultation .infos-consultation-item:hover .infos-consultation-item-title {
      color: #000000; }
    .infos-consultation .infos-consultation-item.active .infos-consultation-item-body {
      height: auto;
      min-height: 40px; }

.onboarding-charles-advantages {
  text-align: left;
  padding-left: 0;
  list-style: none;
  padding: 1rem 0;
  white-space: pre-wrap; }
  .onboarding-charles-advantages:first-child {
    padding-top: 0; }
  .onboarding-charles-advantages .onboarding-charles-advantage {
    padding-top: 1rem;
    padding-bottom: 1rem; }
    .onboarding-charles-advantages .onboarding-charles-advantage svg {
      height: 32px;
      margin-right: 8px; }
    .onboarding-charles-advantages .onboarding-charles-advantage-details {
      margin-top: 1rem; }
    .onboarding-charles-advantages .onboarding-charles-advantage .onboarding-charles-advantage-main.clickable {
      cursor: pointer; }
    .onboarding-charles-advantages .onboarding-charles-advantage .onboarding-charles-advantage-main .expand-button::before {
      display: inline-block;
      content: "";
      height: 8px;
      width: 8px;
      border-right: 0 solid #000;
      border-top: 0 solid #000;
      border-color: #000;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-transform: rotate(135deg);
         -moz-transform: rotate(135deg);
           -o-transform: rotate(135deg);
              transform: rotate(135deg);
      -webkit-transition-duration: .2s;
         -moz-transition-duration: .2s;
           -o-transition-duration: .2s;
              transition-duration: .2s;
      margin-right: 2px; }
    .onboarding-charles-advantages .onboarding-charles-advantage .onboarding-charles-advantage-main.expanded .expand-button::before {
      -webkit-transform: rotate(-45deg);
         -moz-transform: rotate(-45deg);
           -o-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .onboarding-charles-advantages.with-details .onboarding-charles-advantage:not(:last-child) {
    border-bottom: 1px solid black; }
  .onboarding-charles-advantages:not(:last-child) {
    border-bottom: 2px solid black; }

.onboarding-charles-advantages.white-variant .onboarding-charles-advantage-main-item {
  color: white; }

.onboarding-charles-advantages.white-variant .expand-button::before {
  border-right: 0 solid #ffffff;
  border-top: 0 solid #ffffff;
  border-color: #ffffff;
  border-style: solid;
  border-width: 2px 2px 0 0; }

.lucas-change-onboarding-container {
  padding: .5rem;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  text-align: center;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem; }
  .lucas-change-onboarding-container .lucas-change-onboarding-container-side {
    margin-top: 1rem; }
  @media (min-width: 1340px) {
    .lucas-change-onboarding-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      grid-column-gap: 1rem;
      -webkit-column-gap: 1rem;
         -moz-column-gap: 1rem;
              column-gap: 1rem; }
      .lucas-change-onboarding-container .lucas-change-onboarding-container-main {
        -webkit-flex: 1 1 auto;
           -moz-box-flex: 1;
                flex: 1 1 auto; }
      .lucas-change-onboarding-container .lucas-change-onboarding-container-side {
        margin-top: 0;
        -webkit-flex: 0 1 400px;
           -moz-box-flex: 0;
                flex: 0 1 400px;
        margin-left: auto;
        padding-left: 50px;
        border-left: 1px solid #E7E5E1; } }
  .lucas-change-onboarding-container .lucas-change-onboarding-container-main-element {
    margin: auto; }
    @media (min-width: 1340px) {
      .lucas-change-onboarding-container .lucas-change-onboarding-container-main-element-custom-small {
        max-width: 600px; } }
    .lucas-change-onboarding-container .lucas-change-onboarding-container-main-element-small {
      max-width: 600px; }
    .lucas-change-onboarding-container .lucas-change-onboarding-container-main-element-medium {
      max-width: 800px; }
    .lucas-change-onboarding-container .lucas-change-onboarding-container-main-element-auto {
      max-width: none; }

.consultation-slots-container {
  margin-top: 16px; }
  .consultation-slots-container .consultation-day-slots-container {
    margin-bottom: 16px; }
    .consultation-slots-container .consultation-day-slots-container .consultation-day-slots-timezone-disclaimer {
      font-size: 14px; }
    .consultation-slots-container .consultation-day-slots-container .consultation-day-slots-bucket-title {
      font-weight: 600;
      margin-top: 24px; }
    .consultation-slots-container .consultation-day-slots-container .consultation-day-slots-all {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 8px;
      row-gap: 8px;
      grid-column-gap: 8px;
      -webkit-column-gap: 8px;
         -moz-column-gap: 8px;
              column-gap: 8px;
      max-width: 500px;
      margin: 1.5rem auto; }
      @media screen and (min-width: 768px) {
        .consultation-slots-container .consultation-day-slots-container .consultation-day-slots-all {
          grid-template-columns: repeat(4, 1fr); } }
  .consultation-slots-container .consultation-slots-all-doctors .consultation-slot-all-doctors-day-container {
    margin: 1.5rem auto; }
  .consultation-slots-container .consultation-slots-all-doctors .consultation-slots-all-doctors-day-title {
    font-size: 14px;
    font-weight: 600; }
  .consultation-slots-container .consultation-slots-all-doctors .consultation-slots-all-doctors-slots-container {
    display: grid;
    grid-row-gap: 8px;
    row-gap: 8px;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    grid-template-columns: repeat(3, 1fr); }
    @media screen and (min-width: 768px) {
      .consultation-slots-container .consultation-slots-all-doctors .consultation-slots-all-doctors-slots-container {
        grid-template-columns: repeat(6, 1fr); } }
  .consultation-slots-container .consultation-slots-all-doctors .consultation-slots-show-more {
    text-align: center;
    cursor: pointer;
    font-weight: 600; }

.pharmacist-profile {
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 40px; }
  @media (max-width: 767px) {
    .pharmacist-profile {
      padding: 1em; } }
  .pharmacist-profile .pharmacist-name {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    color: #1D1D1D;
    font-size: 20px;
    font-weight: bold; }
  .pharmacist-profile .pharmacy-reinsurance-wrapper {
    grid-gap: 8px;
    gap: 8px;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .pharmacist-profile .pharmacy-reinsurance-wrapper .pharmacy-reinsurance {
      -moz-border-radius: 8px;
           border-radius: 8px;
      border: 1px solid #79747E;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      padding: 4px 16px 4px 8px;
      font-weight: normal;
      font-size: 14px; }
      .pharmacist-profile .pharmacy-reinsurance-wrapper .pharmacy-reinsurance .pharmacy-reinsurance-icon {
        width: 16px; }
  .pharmacist-profile .pharmacy-reinsurance-title {
    margin-top: 28px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500; }
  .pharmacist-profile .pharmacy-info-icon {
    cursor: pointer; }
    .pharmacist-profile .pharmacy-info-icon svg {
      width: 20px; }
      @media (min-width: 768px) {
        .pharmacist-profile .pharmacy-info-icon svg {
          width: 22px; } }
  .pharmacist-profile .pharmacist-name-hidden {
    display: none; }
  .pharmacist-profile .pharmacy-address-info:hover .pharmacist-name-hidden {
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
  .pharmacist-profile .pharmacist-address {
    color: #1D1D1D;
    font-weight: 400; }

.popin {
  position: relative; }
  .popin .popin-content {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    visibility: hidden;
    width: 180px;
    background-color: white;
    -moz-border-radius: 16px;
         border-radius: 16px;
    padding: 8px 12px;
    text-align: left;
    position: absolute;
    z-index: 1;
    top: 125%;
    right: 9px;
    -webkit-transform: translate(10%);
       -moz-transform: translate(10%);
         -o-transform: translate(10%);
            transform: translate(10%);
    border: 1px solid black;
    font-size: 14px;
    font-weight: 400; }
    @media (min-width: 992px) {
      .popin .popin-content {
        right: 11px; } }
    .popin .popin-content::after {
      content: "";
      position: absolute;
      bottom: 100%;
      right: 13px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #000000 transparent; }
      @media (min-width: 992px) {
        .popin .popin-content::after {
          right: 12px; } }

.popin-content.show-popin {
  visibility: visible;
  -webkit-transition: opacity 1s;
  -o-transition: opacity 1s;
  -moz-transition: opacity 1s;
  transition: opacity 1s; }

.health-professional-info-container {
  background-color: #fff;
  border: solid 1px #f1f3f5;
  color: #111c2b;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  margin: .5em;
  padding: .5em; }
  @media (min-width: 576px) {
    .health-professional-info-container {
      -webkit-align-items: flex-start;
         -moz-box-align: start;
              align-items: flex-start;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between; } }
  @media (min-width: 768px) {
    .health-professional-info-container {
      -webkit-flex-direction: colum;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: colum;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center; } }
  @media (max-width: 768px) {
    .health-professional-info-container .center-block {
      -webkit-order: 99;
         -moz-box-ordinal-group: 100;
              order: 99; } }
  .health-professional-info-container .health-profesionnal-qualifications {
    list-style: inherit;
    text-align: left;
    font-size: 0.9em; }
  .health-professional-info-container.pharmacist {
    border: solid 1px #6389FF; }
  .health-professional-info-container .left-block,
  .health-professional-info-container .right-block {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
    @media (min-width: 576px) {
      .health-professional-info-container .left-block,
      .health-professional-info-container .right-block {
        -webkit-align-items: center;
           -moz-box-align: center;
                align-items: center; } }
  .health-professional-info-container .left-block {
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    @media (min-width: 576px) {
      .health-professional-info-container .left-block {
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
                justify-content: flex-start; } }
    @media (max-width: 576px) {
      .health-professional-info-container .left-block {
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center; } }
  .health-professional-info-container .right-block {
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    font-size: .8em;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center; }
    @media (min-width: 992px) {
      .health-professional-info-container .right-block {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row; } }

.health-professional-info {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  width: 100%; }
  @media (min-width: 576px) {
    .health-professional-info {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }

.selected-slot-day {
  color: #111c2b;
  font-size: 1.3em;
  font-weight: 500; }

.selected-slot-hour {
  color: #111c2b;
  font-size: 1.3em;
  font-weight: 500;
  margin-left: 1.5em;
  text-align: center; }
  @media (min-width: 576px) {
    .selected-slot-hour {
      margin-left: .5em; } }

.health-pro-name span, .health-pro-name a {
  color: #111c2b; }

.health-pro-identity {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  height: 3.5em;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  margin: .7em;
  text-align: left; }

.doctor-image {
  -moz-border-radius: .5em;
       border-radius: .5em;
  height: 5em;
  width: 5em;
  margin: .5em 0; }

.health-pro-info {
  color: #8dabc4;
  font-size: .9em;
  margin: .3em 0;
  text-align: center; }
  @media (min-width: 576px) {
    .health-pro-info {
      text-align: left; } }

.filled-green-circle, .filled-red-circle {
  background-color: #20c997;
  -moz-border-radius: 50%;
       border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px; }

.filled-red-circle {
  background-color: #d93868; }

.hours-available {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  margin-bottom: 2em; }
  @media (min-width: 576px) {
    .hours-available {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      margin-top: 1em; } }

.recap {
  background-color: #38d9a9;
  border: #38d9a9; }
  .recap:hover {
    color: #fff; }

.recap-card {
  margin: 0; }



.status-steps-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  margin: 0 0 .5em;
  position: relative;
  width: 100%; }
  .status-steps-container::after {
    border-bottom: 1px #d5d5dd solid;
    bottom: 50%;
    content: '';
    margin: 0 2em;
    position: absolute;
    width: -webkit-calc(100% - 4em);
    width: -moz-calc(100% - 4em);
    width: calc(100% - 4em);
    z-index: 0; }
  @media screen and (min-width: 992px) {
    .status-steps-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }
  .status-steps-container .step {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    background-color: #fff;
    border: 1px solid #d5d5dd;
    -moz-border-radius: 3px;
         border-radius: 3px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
            flex: 1 1;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    margin: .5em 0;
    padding: 1em 1.5em;
    z-index: 1; }
    @media screen and (min-width: 992px) {
      .status-steps-container .step {
        margin: 1.5em; } }
    @media screen and (min-width: 1340px) {
      .status-steps-container .step {
        margin: 2em; } }
    .status-steps-container .step p {
      color: #3f3f3f;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 0;
      text-align: center; }
  .status-steps-container .icon-status {
    -moz-border-radius: 100px;
         border-radius: 100px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    height: 25px;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    position: relative;
    text-align: center;
    top: -2px;
    width: 25px; }
    .status-steps-container .icon-status.validated, .status-steps-container .icon-status.confirmed, .status-steps-container .icon-status.started, .status-steps-container .icon-status.finished, .status-steps-container .icon-status.exhaust {
      background-color: #38d9a9; }
    .status-steps-container .icon-status.in_progress {
      background-color: #38d9a9; }
    .status-steps-container .icon-status.none, .status-steps-container .icon-status.pending {
      background-color: #f5a623; }
      .status-steps-container .icon-status.none::before, .status-steps-container .icon-status.pending::before {
        color: #fff;
        content: '!';
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: 2px; }
    .status-steps-container .icon-status.canceled {
      background-color: #d93868; }
      .status-steps-container .icon-status.canceled::before {
        color: #fff;
        content: 'x';
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: 1px; }
    .status-steps-container .icon-status svg {
      position: relative;
      top: 2px;
      width: 80%; }

.last-consultation {
  text-align: center; }
  .last-consultation .button.xl {
    font-size: .8em;
    height: auto;
    line-height: normal; }
    .last-consultation .button.xl span {
      line-height: 2em; }
    @media screen and (min-width: 375px) {
      .last-consultation .button.xl {
        height: 3.125em;
        line-height: 3.125em; }
        .last-consultation .button.xl span {
          line-height: 3.125em; } }
    @media screen and (min-width: 576px) {
      .last-consultation .button.xl {
        font-size: 1em; } }
  .last-consultation .button.grey {
    height: 3.125em;
    line-height: 3.125em; }
  .last-consultation .download-link {
    text-decoration: underline; }
    .last-consultation .download-link a {
      cursor: pointer;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      font-size: .8em;
      height: 30px;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; }
      @media screen and (min-width: 576px) {
        .last-consultation .download-link a {
          font-size: 1em; } }

.custom-slider .navigation-controls {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center; }

.custom-slider .navigation-control {
  position: relative;
  padding: 0;
  -moz-border-radius: 50%;
       border-radius: 50%;
  height: 48px;
  width: 48px; }
  .custom-slider .navigation-control:after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid; }
  .custom-slider .navigation-control.slider-prev:after {
    top: 18px;
    left: 20px;
    -webkit-transform: rotate(-135deg);
       -moz-transform: rotate(-135deg);
         -o-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  .custom-slider .navigation-control.slider-next:after {
    top: 18px;
    left: 18px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg); }

.custom-slider .swiper-pagination-bullets {
  display: -webkit-flex;
  display: -moz-box;
  display: flex; }
  .custom-slider .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px; }
    .custom-slider .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #000000; }

.custom-slider .swiper-scrollbar {
  display: none; }

.custom-slider.custom-slider-stretch-items .swiper-wrapper {
  -webkit-align-items: stretch;
     -moz-box-align: stretch;
          align-items: stretch; }

.custom-slider.custom-slider-stretch-items .swiper-slide {
  height: auto; }

.custom-slider.custom-slider-stretch-items .block-content {
  height: 98%; }

.therapy-recommendation-card .therapy-card-title {
  font-size: 18px; }

.therapy-recommendation-card .therapy-card-body {
  grid-gap: 1rem;
  gap: 1rem; }
  .therapy-recommendation-card .therapy-card-body .therapy-image svg {
    width: 100px; }

.login-container {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin: 1em;
  margin-top: 25px;
  max-width: 448px;
  width: 100%; }
  @media (max-width: 576px) {
    .login-container .block-content {
      margin-left: 20px;
      margin-right: 30px;
      padding: 20px 24px; } }

.login-form {
  width: 100%; }

.login-block {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding-top: 1em; }
  .login-block .form-label {
    font-weight: 600;
    font-family: "MaisonNeue"; }
  .login-block .forgot-password {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #000; }
  .login-block h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px; }
    @media (max-width: 576px) {
      .login-block h4 {
        font-size: 18px;
        line-height: 24px; } }

.login-page {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex: 1 0;
     -moz-box-flex: 1;
          flex: 1 0;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  text-align: center; }
  .login-page form {
    text-align: left; }
  .login-page a {
    text-decoration: underline; }

.logo {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  position: relative; }
  .logo img {
    margin: 0 auto;
    padding: 25px 0;
    width: 200px; }
    @media (max-width: 576px) {
      .logo img {
        width: 120px; } }

.show-comment-button {
  height: 30px !important;
  margin-top: 0px !important; }

.import-button {
  text-align: center; }

.medical-docs-container {
  font-weight: 500; }
  .medical-docs-container .documents-not-found {
    padding: 1em;
    margin: 1em;
    font-size: 18px;
    font-weight: 500;
    text-align: center; }
  .medical-docs-container .sent-documents {
    border-bottom: 1px solid #000; }
  .medical-docs-container .documents-list .document-row {
    padding: 15px 0px; }
    @media (max-width: 768px) {
      .medical-docs-container .documents-list .document-row {
        border-bottom: 1px solid #a4a4a4; } }
    .medical-docs-container .documents-list .document-row .filename {
      word-wrap: break-word; }
    .medical-docs-container .documents-list .document-row svg {
      width: 20px;
      height: 20px; }
    .medical-docs-container .documents-list .document-row .check-comment {
      text-decoration: underline;
      cursor: pointer; }

.medical-upload-form {
  text-align: center; }
  .medical-upload-form input:not(.button) {
    display: none; }
  .medical-upload-form svg {
    width: 20px;
    height: 20px; }

.navbar.personal-space-navbar {
  background-color: white;
  height: 69px;
  padding: 1rem 1.5rem;
  -webkit-box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between; }
  @media (min-width: 1340px) {
    .navbar.personal-space-navbar {
      display: none; } }
  .navbar.personal-space-navbar .navbar-brand svg {
    height: 37px; }
  .navbar.personal-space-navbar .navbar-burger {
    cursor: pointer; }
    .navbar.personal-space-navbar .navbar-burger svg {
      width: 24px; }

.notification-over {
  background-color: #fff;
  -moz-border-radius-topleft: 6px;
       border-top-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
       border-bottom-left-radius: 6px;
  -webkit-box-shadow: 0 2px 4px 0 #bdbdbd;
     -moz-box-shadow: 0 2px 4px 0 #bdbdbd;
          box-shadow: 0 2px 4px 0 #bdbdbd;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: .8em;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: height 1s;
  -o-transition: height 1s;
  -moz-transition: height 1s;
  transition: height 1s;
  width: 100%;
  max-width: 447px;
  z-index: 2020; }
  .notification-over.active {
    min-height: 70px;
    width: 100%; }
  .notification-over .button-box {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif; }
    .notification-over .button-box .button-box-close {
      color: rgba(29, 29, 29, 0.3);
      -webkit-align-self: center;
              align-self: center;
      margin-bottom: 0px; }
  .notification-over .picto-container {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .notification-over.info .picto-container {
    background-color: #6389FF; }
  .notification-over.error .picto-container {
    background-color: #d93868; }
  .notification-over.warning .picto-container {
    background-color: #f5a623; }
  .notification-over.success .picto-container {
    background-color: #38d9a9; }
  @media (min-width: 768px) {
    .notification-over {
      height: 113px;
      right: -370px;
      top: 20px;
      -webkit-transition: width .5s, right 1s;
      -o-transition: width .5s, right 1s;
      -moz-transition: width .5s, right 1s;
      transition: width .5s, right 1s;
      width: 0; }
      .notification-over.active {
        height: 113px;
        right: 20px;
        width: 370px; } }
  .notification-over button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-left: auto;
    padding: 0 20px; }
    .notification-over button:focus {
      outline: none; }
  .notification-over .icon-state {
    -webkit-align-self: center;
            align-self: center;
    padding-left: 8px;
    width: 44px; }

.picto-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  width: 16px; }
  .picto-container .icon {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    height: 30px;
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    margin: 0 auto;
    width: 30px; }

.message-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 18px;
  width: 294px; }
  .message-container .message-body {
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    overflow: auto;
    font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif; }
    .message-container .message-body h6 {
      color: #111c2b;
      font-size: 14px;
      font-weight: 700;
      margin: 0; }
    .message-container .message-body p {
      color: rgba(29, 29, 29, 0.6);
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 400; }

.notification-separator-barre {
  border: 1px solid rgba(29, 29, 29, 0.3);
  min-height: 71px; }

.notification {
  background-color: #fff;
  -moz-border-radius-topleft: 6px;
       border-top-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
       border-bottom-left-radius: 6px;
  -webkit-box-shadow: 0 2px 4px 0 #bdbdbd;
     -moz-box-shadow: 0 2px 4px 0 #bdbdbd;
          box-shadow: 0 2px 4px 0 #bdbdbd;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: .8em;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: height 1s;
  -o-transition: height 1s;
  -moz-transition: height 1s;
  transition: height 1s;
  width: 100%;
  max-width: 447px;
  z-index: 2020; }
  .notification.active {
    min-height: 70px;
    width: 100%; }
  .notification .button-box {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif; }
    .notification .button-box .button-box-close {
      color: rgba(29, 29, 29, 0.3);
      -webkit-align-self: center;
              align-self: center;
      margin-bottom: 0px; }
  .notification .picto-container {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .notification.info .picto-container {
    background-color: #6389FF; }
  .notification.error .picto-container {
    background-color: #d93868; }
  .notification.warning .picto-container {
    background-color: #f5a623; }
  .notification.success .picto-container {
    background-color: #38d9a9; }
  @media (min-width: 768px) {
    .notification {
      min-height: 70px;
      right: -370px;
      top: 20px;
      -webkit-transition: width .5s, right 1s;
      -o-transition: width .5s, right 1s;
      -moz-transition: width .5s, right 1s;
      transition: width .5s, right 1s;
      width: 0; }
      .notification.active {
        min-height: 70px;
        right: 20px;
        width: 370px; } }
  .notification button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-left: auto;
    padding: 0 20px; }
    .notification button:focus {
      outline: none; }
  .notification .icon-state {
    -webkit-align-self: center;
            align-self: center;
    padding-left: 8px;
    width: 44px; }

.picto-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  width: 16px; }
  .picto-container .icon {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    height: 30px;
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    margin: 0 auto;
    width: 30px; }

.message-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 18px;
  width: 294px; }
  .message-container .message-body {
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    overflow: auto;
    font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif; }
    .message-container .message-body h6 {
      color: #111c2b;
      font-size: 14px;
      font-weight: 700;
      margin: 0; }
    .message-container .message-body p {
      color: rgba(29, 29, 29, 0.6);
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 400; }

.notification-separator-barre {
  border: 1px solid rgba(29, 29, 29, 0.3);
  min-height: 70px;
  width: 2px; }

.registered-card-container .card-container-header {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 8px; }

.registered-card-container .paymentCardTitle {
  color: #111c2b;
  font-size: 125% !important;
  font-weight: 400; }

.registered-card-container .paymentExpiry {
  color: #111c2b;
  font-size: 1em;
  font-weight: 400;
  text-align: right; }

.registered-card-container .card-list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: .5rem;
  overflow: hidden;
  text-align: left; }
  .registered-card-container .card-list .card-list-element {
    display: block;
    position: relative;
    padding-top: .5rem;
    padding-bottom: .5rem; }
    .registered-card-container .card-list .card-list-element label {
      z-index: 9;
      cursor: pointer;
      margin: 0;
      -o-transition: all 0.25s linear;
      -moz-transition: all 0.25s linear;
      transition: all 0.25s linear;
      -webkit-transition: all 0.25s linear; }
    .registered-card-container .card-list .card-list-element:hover label {
      color: #000000; }
    .registered-card-container .card-list .card-list-element.expired {
      color: #aaaaaa; }
      .registered-card-container .card-list .card-list-element.expired .card-list-element-type {
        text-decoration: line-through; }

.registered-card-container .newCard {
  text-align: left;
  text-align: initial;
  padding: 2%;
  color: #6389FF !important;
  font-weight: 450 !important;
  cursor: pointer;
  list-style-type: disc; }
  .registered-card-container .newCard:hover {
    text-decoration: underline; }

.registered-card-container .acceptCguError {
  color: red !important; }
  .registered-card-container .acceptCguError a {
    color: red !important; }

.credit-card-block-v2 .switch-credit-card-method {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  display: block;
  text-align: right;
  margin: 1rem 0 2rem; }

.credit-card-block-v2 .credit-card-security {
  font-weight: bold;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
          align-items: flex-end;
  margin-bottom: 1.5em; }
  .credit-card-block-v2 .credit-card-security .credit-card-payment-options {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    -webkit-align-items: stretch;
       -moz-box-align: stretch;
            align-items: stretch;
    -webkit-box-shadow: 0 1px 0 0 #000;
       -moz-box-shadow: 0 1px 0 0 #000;
            box-shadow: 0 1px 0 0 #000; }
    .credit-card-block-v2 .credit-card-security .credit-card-payment-options .credit-card-payment-option {
      width: 50%;
      cursor: pointer;
      margin: 0;
      text-align: center;
      padding: 20px 5px; }
      .credit-card-block-v2 .credit-card-security .credit-card-payment-options .credit-card-payment-option.selected {
        -webkit-box-shadow: 0 -3px 0 0 #000 inset;
           -moz-box-shadow: 0 -3px 0 0 #000 inset;
                box-shadow: 0 -3px 0 0 #000 inset; }
      .credit-card-block-v2 .credit-card-security .credit-card-payment-options .credit-card-payment-option input[type='radio'] {
        vertical-align: middle;
        display: none; }
      .credit-card-block-v2 .credit-card-security .credit-card-payment-options .credit-card-payment-option svg {
        height: 1rem; }

.credit-card-block-v2 .cgu-label {
  font-size: .8rem;
  cursor: pointer; }

.field-block {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between; }
  .field-block .field-container {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    text-align: left;
    margin-bottom: .25em;
    margin-top: .25em; }
    @media (min-width: 576px) {
      .field-block .field-container {
        margin-top: .5em;
        margin-bottom: .5em; } }
    .field-block .field-container .input-text {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center; }
  .field-block .field-container-mid {
    -webkit-flex-basis: 49%;
            flex-basis: 49%; }
    @media (min-width: 576px) {
      .field-block .field-container-mid {
        -webkit-flex-basis: 48%;
                flex-basis: 48%; } }
  .field-block .input {
    margin: 0 !important; }

.doctor-info {
  color: #8dabc4;
  font-size: .8em;
  margin: .5em;
  text-align: center; }
  @media (min-width: 576px) {
    .doctor-info {
      font-size: 1em; } }
  @media (min-width: 768px) {
    .doctor-info {
      margin: .75em; } }
  @media (min-width: 992px) {
    .doctor-info {
      margin: 1em; } }
  .doctor-info::before, .doctor-info::after {
    border-top: 0.01em solid #a4a4a4;
    content: '';
    display: table-cell;
    opacity: .5;
    width: 50%; }

.payment-block {
  background-color: white;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding: 12px;
  min-height: auto; }
  @media (min-width: 768px) {
    .payment-block {
      padding: 14px 33px 49px; } }

.payment-container {
  margin: auto;
  max-width: 600px;
  text-align: left; }

.payment-form {
  width: 100%; }

.payment-name .input-label,
.payment-number .input-label,
.payment-expiry .input-label,
.payment-verification_value .input-label {
  font-size: .6em;
  text-align: left; }

@media (min-width: 576px) {
  .payment-name,
  .payment-number,
  .payment-expiry,
  .payment-verification_value {
    font-size: 1em; } }

.accept-checkbox {
  cursor: pointer;
  font-size: 1em;
  margin: 0.5em 0;
  text-align: left;
  font-size: 0.8em; }
  .accept-checkbox input {
    margin-right: .5em;
    width: auto;
    width: initial; }
  .accept-checkbox [type='checkbox'].checked, .accept-checkbox [type='checkbox']:not(.checked),
  .accept-checkbox [type='radio'].checked,
  .accept-checkbox [type='radio']:not(.checked) {
    left: -9999px;
    position: absolute; }
  .accept-checkbox [type='checkbox'].checked + label,
  .accept-checkbox [type='checkbox']:not(.checked) + label,
  .accept-checkbox [type='radio'].checked + label,
  .accept-checkbox [type='radio']:not(.checked) + label {
    cursor: pointer;
    display: inline-block;
    padding-left: 35px;
    font-size: 0.8em;
    position: relative; }
  .accept-checkbox [type='checkbox'].checked + label::before,
  .accept-checkbox [type='checkbox']:not(.checked) + label::before,
  .accept-checkbox [type='radio'].checked + label::before,
  .accept-checkbox [type='radio']:not(.checked) + label::before {
    background: #fff;
    content: '';
    height: 18px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 18px; }
  .accept-checkbox [type='checkbox'].checked + label::before,
  .accept-checkbox [type='radio'].checked + label::before {
    border: 1px solid #000;
    -moz-border-radius: .2em;
         border-radius: .2em; }
  .accept-checkbox [type='checkbox']:not(.checked) + label::before,
  .accept-checkbox [type='radio']:not(.checked) + label::before {
    border: 1px solid #000;
    -moz-border-radius: .2em;
         border-radius: .2em; }
  .accept-checkbox [type='checkbox'].checked + label::after,
  .accept-checkbox [type='checkbox']:not(.checked) + label::after,
  .accept-checkbox [type='radio'].checked + label::after,
  .accept-checkbox [type='radio']:not(.checked) + label::after {
    background: url('data:image/svg+xml,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L4 6.5L9 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    content: '';
    height: 12px;
    left: 3px;
    position: absolute;
    top: -webkit-calc(50% - 6px);
    top: -moz-calc(50% - 6px);
    top: calc(50% - 6px);
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    -moz-transition: all .2s ease;
    transition: all .2s ease;
    width: 12px; }
  .accept-checkbox [type='checkbox']:not(.checked) + label::after,
  .accept-checkbox [type='radio']:not(.checked) + label::after {
    opacity: 0;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0); }
  .accept-checkbox [type='checkbox'].checked + label::after,
  .accept-checkbox [type='radio'].checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1); }
  .accept-checkbox [type='checkbox']:not(.checked) + label.error-field::before,
  .accept-checkbox [type='radio']:not(.checked) + label.error-field::before {
    border: 1px solid #d93868; }
  .accept-checkbox label {
    color: #000;
    font-size: 12px;
    font-weight: 400; }
    .accept-checkbox label .pharmacist-delivery-note {
      font-size: 14px;
      padding-top: 20px; }
  .accept-checkbox .label-cgu {
    width: 100%; }
  .accept-checkbox .accept-cgu-label {
    text-align: left;
    width: 100%; }

.oldCard {
  text-align: left;
  text-align: initial;
  padding: 2%;
  color: #6389FF;
  font-weight: 450;
  cursor: pointer;
  list-style-type: disc;
  padding-bottom: 4%;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  justify-self: flex-start; }
  .oldCard:hover {
    text-decoration: underline; }

.paypal-payment-form .paypal-branding {
  text-align: center;
  padding: 30px; }

.paypal-payment-form .paypal-label {
  font-size: 25px; }

.paypal-payment-form .paypal-logo {
  margin: auto;
  margin-top: 20px;
  width: 50%; }

.perso-info-block {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  font-weight: 400; }
  .perso-info-block .text {
    border: 1px solid #f1f3f5; }

.perso-info-content {
  color: #495057;
  font-size: 14px;
  margin-top: 5px; }

.perso-info-input-content {
  margin: 0;
  padding: 0; }
  .perso-info-input-content .react-phone-number-input__phone {
    border: 0;
    height: 32px; }
  .perso-info-input-content .error-input-text {
    border-color: #d93868; }

.perso-info-label {
  color: #a4a4a4;
  font-size: .7em; }

.personnal-info-button {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  margin: auto;
  max-width: 288px;
  text-decoration: none; }
  .personnal-info-button button {
    border: 1px;
    text-decoration: none; }

.doctor-info-input input {
  background-color: #fff;
  border: none;
  -moz-border-radius: .2em;
       border-radius: .2em;
  color: #495057;
  cursor: pointer;
  outline: none;
  padding: 0;
  width: 100%; }

.link-pharmacy {
  text-decoration: none; }
  .link-pharmacy:hover {
    text-decoration: none; }

.accept-terms {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }

.pharmacy-container::after {
  content: "";
  clear: both;
  display: table; }

.pharmacy-container .column {
  float: left;
  width: 8%;
  padding: 5px; }

.pharmacy-container .delivery-img {
  padding-right: 0px;
  padding-top: 10px; }
  .pharmacy-container .delivery-img .img-fluid {
    width: 113px; }

.pharmacy-container .delivery-infos-block {
  float: left;
  width: 92%;
  padding: 5px; }
  .pharmacy-container .delivery-infos-block .delivery-infos {
    font-weight: bold;
    color: #708DBB;
    line-height: 1.3em; }
    .pharmacy-container .delivery-infos-block .delivery-infos li {
      font-size: 12px; }

.popup {
  display: none;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 11; }
  .popup.active {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    position: fixed; }
  .popup .overlay {
    background-color: #000000;
    height: 100%;
    opacity: .8;
    position: absolute;
    width: 100%; }
  .popup .overlay-v2 {
    background-color: #f1f3f5;
    height: 100%;
    opacity: .8;
    position: absolute;
    width: 100%; }
  .popup .popup-container, .popup .popup-container-v2 {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    background-color: #fff;
    -moz-border-radius: 3px;
         border-radius: 3px;
    margin: auto;
      margin-max-width: fit-content;
    max-height: -webkit-fill-available;
    overflow: auto;
    position: relative;
    width: 90%;
    z-index: 100; }
    @media (min-width: 576px) {
      .popup .popup-container, .popup .popup-container-v2 {
        width: 55%;
        left: 150px; } }
  .popup .popup-container-v2 {
    border: 2px solid #002874;
    -moz-border-radius: 10px;
         border-radius: 10px; }
    .popup .popup-container-v2 .close-popup {
      text-align: center; }
      .popup .popup-container-v2 .close-popup .close-popup-button {
        color: #6389FF;
        text-decoration: underline;
        cursor: pointer; }
  .popup .popup-header {
    background-color: black;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    max-height: 70px;
    padding: 20px; }
    .popup .popup-header h2 {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin: 0; }
    .popup .popup-header .icon {
      cursor: pointer;
      display: inline-block;
      width: 20px; }
      .popup .popup-header .icon path {
        fill: #fff; }
  .popup .popup-body {
    color: #3f3f3f;
    padding: 15px; }
    @media (min-width: 576px) {
      .popup .popup-body {
        padding: 32px; } }
    .popup .popup-body h2 {
      font-size: 16px;
      font-weight: 450; }
    .popup .popup-body p {
      font-size: 14px;
      font-weight: normal;
      opacity: .8; }

.prescription-preview {
  width: 100% !important; }

.analysis-notice {
  color: white;
  padding: 4px 16px 3.5px 16px;
  -moz-border-radius: 8px;
       border-radius: 8px;
  width: auto;
  font-size: 12px;
  margin-right: 0px; }
  .analysis-notice.consultation_needed {
    background-color: #FF270E; }
  .analysis-notice.consultation_not_needed {
    background-color: #26D126; }

.close-popup {
  text-align: center; }

.doctor-bloc-note {
  border: 2px solid #ccc;
  background-color: #eee;
  -moz-border-radius: 5px;
       border-radius: 5px;
  padding: 16px;
  margin: 16px 0; }
  .doctor-bloc-note::after {
    content: "";
    clear: both;
    display: table; }
  .doctor-bloc-note .doctor-name {
    color: #228be6;
    font-size: 18px; }
  .doctor-bloc-note .consultation-result {
    font-size: 16px;
    color: #000;
    margin-right: 5px; }
  .doctor-bloc-note .analysis-infos {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.1px;
    line-height: 1.24;
    margin-right: 15px;
    margin-top: 8px; }
  .doctor-bloc-note .extra-margin {
    margin-top: 15px; }
  @media (min-width: 576px) {
    .doctor-bloc-note .container-warning {
      margin-left: 100px; }
    .doctor-bloc-note .doctor-image {
      float: left;
      margin-right: 20px;
      -moz-border-radius: 50%;
           border-radius: 50%; }
    .doctor-bloc-note .analysis-comment-body {
      margin-left: 100px;
      margin-top: 15px;
      font-size: 14px;
      line-height: 1.5; } }
  @media (max-width: 576px) {
    .doctor-bloc-note {
      text-align: center; }
      .doctor-bloc-note .doctor-image {
        margin: auto;
        float: none;
        display: block; }
      .doctor-bloc-note .analysis-comment-body {
        text-align: left;
        line-height: 1.5;
        font-size: 14px; } }

.analysis-warn, .analysis-warn::before, .analysis-warn::after {
  position: relative;
  padding: 0;
  margin: 0 !important; }

.analysis-warning-message {
  color: #FF270E;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px; }

.analysis-warn {
  font-size: 14px;
  color: red; }
  .analysis-warn.analysis-warning {
    display: inline-block;
    top: 0.225em;
    width: 1.15em;
    height: 1.15em;
    overflow: hidden;
    border: none;
    background-color: transparent;
    -moz-border-radius: 0.625em;
         border-radius: 0.625em; }
    .analysis-warn.analysis-warning::before {
      content: "";
      display: block;
      top: -0.08em;
      left: 0.0em;
      position: absolute;
      border: transparent 0.6em solid;
      border-bottom-color: #fd3;
      border-bottom-width: 1em;
      border-top-width: 0; }
    .analysis-warn.analysis-warning::after {
      color: #d93868 !important;
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 0 1px;
      text-align: center;
      font-family: "Garamond";
      content: "!";
      font-size: 0.65em;
      font-weight: bold;
      color: #FFF; }

@charset "UTF-8";
.prescription-card .prescription-card-origin-icon svg {
  width: 4rem; }

.prescription-card .prescription-card-list {
  list-style-type: none; }
  .prescription-card .prescription-card-list .prescription-card-item svg {
    width: 24px;
    height: 24px;
    margin-right: 8px; }
  .prescription-card .prescription-card-list .prescription-card-item .prescription-card-item-dosage::before {
    content: "\2022";
    margin: 0 8px; }
  .prescription-card .prescription-card-list .prescription-card-item-show-more {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    font-family: 'Sweet Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    cursor: pointer; }
    .prescription-card .prescription-card-list .prescription-card-item-show-more svg {
      width: 16px;
      margin-right: 8px; }

.prescription-card .prescription-card-expired {
  opacity: 0.40; }

.prescription-card .expiry-label {
  color: #d93868;
  text-align: center; }


.page.phytotherapy .container-content {
  padding-top: 4rem; }

.doctors-selection {
  border: 3px solid #000000;
  position: relative; }
  .doctors-selection .doctors-selection-svg {
    display: block; }
    .doctors-selection .doctors-selection-svg svg {
      max-width: 300px; }
    .doctors-selection .doctors-selection-svg path {
      fill: #000000; }

.phyto-product-container {
  margin: auto auto 4rem auto; }
  .phyto-product-container::after {
    content: ' ';
    position: relative;
    width: 100px;
    maring: auto;
    border-bottom: 1px solid grey; }
  .phyto-product-container .phyto-product-description-image-container {
    margin-bottom: 48px; }
  .phyto-product-container img.phyto-product-image {
    border: 2px solid #111c2b;
    -webkit-filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
    -moz-border-radius: 16px;
         border-radius: 16px;
    max-width: 240px; }
  .phyto-product-container .phyto-product-ext-link {
    text-decoration: underline; }
    .phyto-product-container .phyto-product-ext-link a {
      color: black; }
  .phyto-product-container .phyto-product-description {
    line-height: 1.2; }
  .phyto-product-container .product-indication {
    font-weight: 500; }
  .phyto-product-container:not(:last-child) {
    margin-bottom: 4rem; }

.variant-card {
  max-width: 270px;
  margin: auto; }
  @media (min-width: 768px) {
    .variant-card {
      width: 100%; } }
  .variant-card .variant-card-inner {
    position: relative; }
    .variant-card .variant-card-inner .variant-recommended {
      display: none;
      position: absolute;
      top: -3px;
      left: 0;
      right: 0; }
    .variant-card .variant-card-inner .variant-recommended-text {
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      text-align: center;
      font-size: 14px;
      z-index: 2;
      font-weight: 500;
      width: 90%;
      margin: auto;
      height: 40px;
      margin-top: 8px; }
    .variant-card .variant-card-inner .variant-card-header {
      font-size: 22px;
      font-weight: 500; }
      .variant-card .variant-card-inner .variant-card-header .variant-img {
        max-width: 44px; }
        .variant-card .variant-card-inner .variant-card-header .variant-img svg {
          width: 100%; }
    .variant-card .variant-card-inner .variant-card-body {
      font-size: 22px;
      font-weight: 600px; }
    .variant-card .variant-card-inner .variant-card-footer .variant-subscription-info {
      font-style: italic;
      color: #6d6b6b;
      font-size: 13px; }
    .variant-card .variant-card-inner .variant-card-footer .variant-subscription-promotion {
      font-weight: 500;
      font-size: 14px; }
    .variant-card .variant-card-inner.most-popular-variant .variant-recommended-text, .variant-card .variant-card-inner.recommended-variant .variant-recommended-text {
      display: -webkit-flex;
      display: -moz-box;
      display: flex; }
    .variant-card .variant-card-inner.most-popular-variant .variant-recommended-svg, .variant-card .variant-card-inner.recommended-variant .variant-recommended-svg {
      display: block;
      width: 60px;
      position: absolute;
      top: -38px;
      right: -43px; }
    .variant-card .variant-card-inner.selected {
      border: 3px solid #71e0dc; }

.phyto-product-contraindication {
  white-space: pre-wrap;
  color: #647591; }

.phyto-product-img {
  -moz-border-radius: 5px;
       border-radius: 5px; }

.new-phyto-product-container {
  padding-left: 22px;
  padding-right: 22px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 992px) {
    .new-phyto-product-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      max-width: 900px; } }
  .new-phyto-product-container .phyto-product-info {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    border-bottom: 1px solid black;
    margin-bottom: 24px; }
    @media (min-width: 992px) {
      .new-phyto-product-container .phyto-product-info {
        width: 100%;
        margin-bottom: 0;
        padding-right: 32px;
        border-bottom: none;
        border-right: 1px solid black; } }
    .new-phyto-product-container .phyto-product-info img.phyto-product-image {
      border: 2px solid #111c2b;
      -moz-border-radius: 16px;
           border-radius: 16px;
      width: 100%;
      max-width: 280px;
      margin: 0 auto 16px; }
      @media (min-width: 992px) {
        .new-phyto-product-container .phyto-product-info img.phyto-product-image {
          width: 200px;
          height: 200px;
          -o-object-fit: cover;
             object-fit: cover;
          -webkit-filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
                  filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
          max-width: none;
          max-width: initial;
          margin: 0 auto 58px; } }
    .new-phyto-product-container .phyto-product-info .phyto-product-text {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column; }
      .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-title {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
                justify-content: space-between;
        margin-bottom: 8px; }
        .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-title > div {
          text-align: right; }
      .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-description {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 8px; }
        .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-description .product-indication {
          font-weight: 500;
          font-size: 16px; }
      .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-sheet {
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        margin-bottom: 16px; }
        @media (min-width: 992px) {
          .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-sheet {
            text-decoration: none;
            font-weight: 700; } }
      .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication {
        margin-bottom: 16px; }
        @media (min-width: 992px) {
          .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication {
            margin-bottom: 32px; } }
        .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-title {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 8px; }
          @media (min-width: 992px) {
            .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-title {
              display: none; } }
          .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-title > div {
            margin: 0 8px 0 8px; }
          .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-title .icon {
            width: 24px; }
            .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-title .icon--up {
              -webkit-transform: rotate(180deg);
                 -moz-transform: rotate(180deg);
                   -o-transform: rotate(180deg);
                      transform: rotate(180deg); }
        .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-description {
          font-size: 14px;
          font-weight: 400;
          -webkit-transition: all 0.2s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
          height: 0;
          overflow: hidden; }
          @media (min-width: 992px) {
            .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-contre-indication .phyto-product-contre-indication-description {
              height: auto;
              height: initial;
              text-decoration: underline; } }
      .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-reminder {
        font-style: italic;
        font-weight: 400; }
        .new-phyto-product-container .phyto-product-info .phyto-product-text .phyto-product-reminder .icon {
          width: 16px; }
  .new-phyto-product-container .phyto-subscription-info {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column; }
    @media (min-width: 992px) {
      .new-phyto-product-container .phyto-subscription-info {
        width: 288px;
        min-width: 288px;
        margin-left: 16px; } }
    .new-phyto-product-container .phyto-subscription-info .phyto-variant-input {
      margin-top: 32px; }
      .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-align-items: flex-start;
           -moz-box-align: start;
                align-items: flex-start;
        cursor: pointer; }
        .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label input {
          margin-top: 6px;
          margin-right: 16px; }
        .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-option {
          font-weight: 500;
          margin-bottom: 6px; }
          .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-option .phyto-variant-option-discount {
            -moz-border-radius: 34px;
                 border-radius: 34px;
            padding: 4px 12px;
            display: inline-block;
            font-size: 14px;
            background-color: #FDD09B; }
            @media (min-width: 992px) {
              .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-option .phyto-variant-option-discount {
                margin-left: 4px; } }
        .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-price .phyto-variant-main-price {
          font-weight: 500; }
        .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-disposition {
          margin-top: 8px; }
        .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-argument {
          -webkit-padding-start: 16px;
             -moz-padding-start: 16px;
                  padding-inline-start: 16px; }
        .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-reminder {
          font-style: italic;
          font-weight: 400;
          display: -webkit-flex;
          display: -moz-box;
          display: flex; }
          .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-reminder .phyto-variant-icon-wrapper {
            width: 16px; }
          .new-phyto-product-container .phyto-subscription-info .phyto-variant-input .phyto-variant-label .phyto-variant-info .phyto-variant-reminder .icon {
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            width: 16px;
            margin-right: 10px; }
  .new-phyto-product-container .phyto-variant-button {
    width: 100%; }

.lessons-list-item {
  cursor: pointer; }
  .lessons-list-item:hover {
    background-color: #ECECEC; }
  .lessons-list-item.selected {
    background-color: #ECECEC; }
    .lessons-list-item.selected .lessons-list-item-title {
      text-decoration: underline; }
  .lessons-list-item .lessons-list-item-thumbnail {
    min-width: 110px;
    min-height: 74px;
    -moz-border-radius: 8px;
         border-radius: 8px;
    border: 1px solid #000000; }
    .lessons-list-item .lessons-list-item-thumbnail .lesson-list-icon svg {
      width: 18px;
      position: relative;
      bottom: -18px; }
      .lessons-list-item .lessons-list-item-thumbnail .lesson-list-icon svg path {
        fill: rgba(0, 0, 0, 0.5); }
  .lessons-list-item .lessons-list-item-duration {
    color: white; }

.attendance-survey-container {
  background-color: white;
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px; }
  .attendance-survey-container .sv_main span {
    word-break: initial; }
  .attendance-survey-container .sv_page_title {
    padding: 32px 32px 24px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center; }
    .attendance-survey-container .sv_page_title .sv-string-viewer {
      min-width: 300px;
      -webkit-flex: 1 1 100%;
         -moz-box-flex: 1;
              flex: 1 1 100%;
      width: 100%; }
  .attendance-survey-container .question-header {
    text-align: left;
    margin-bottom: 1rem;
    -webkit-align-self: flex-start;
            align-self: flex-start; }
  .attendance-survey-container .sv_row {
    padding: 0 32px 0;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
    .attendance-survey-container .sv_row:not(:last-child) {
      padding-bottom: 3rem; }
    .attendance-survey-container .sv_row .sv_qstn {
      -webkit-align-items: flex-start;
         -moz-box-align: start;
              align-items: flex-start; }
      .attendance-survey-container .sv_row .sv_qstn .question-title {
        font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif;
        font-size: 16px;
        font-weight: 500;
        text-align: left; }
      .attendance-survey-container .sv_row .sv_qstn textarea {
        resize: vertical !important;
        min-height: 160px;
        max-height: 500px; }
      .attendance-survey-container .sv_row .sv_qstn > div {
        width: 100%; }
      @media (min-width: 768px) {
        .attendance-survey-container .sv_row .sv_qstn .sv_qcbc {
          display: grid;
          grid-template-columns: 35% 35%;
          grid-template-rows: auto auto;
          grid-auto-flow: column;
          width: 100%; }
          .attendance-survey-container .sv_row .sv_qstn .sv_qcbc > .survey-item {
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            -webkit-flex-basis: 35%;
                    flex-basis: 35%; }
            .attendance-survey-container .sv_row .sv_qstn .sv_qcbc > .survey-item.checked .question-label {
              -webkit-box-shadow: none;
                 -moz-box-shadow: none;
                      box-shadow: none; } }
      .attendance-survey-container .sv_row .sv_qstn .question-label {
        text-align: left;
        background-color: transparent;
        background-color: initial;
        padding: 0 1rem 0 0;
        min-width: 0;
        min-width: initial;
        border: medium none currentColor;
        border: initial;
        font-weight: normal;
        font-weight: initial;
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                box-shadow: none;
        -webkit-box-shadow: initial;
           -moz-box-shadow: initial;
                box-shadow: initial; }
        .attendance-survey-container .sv_row .sv_qstn .question-label:active, .attendance-survey-container .sv_row .sv_qstn .question-label:focus, .attendance-survey-container .sv_row .sv_qstn .question-label.active, .attendance-survey-container .sv_row .sv_qstn .question-label.checked {
          -webkit-box-shadow: none !important;
             -moz-box-shadow: none !important;
                  box-shadow: none !important; }
        .attendance-survey-container .sv_row .sv_qstn .question-label .sv_q_radiogroup_control_item {
          display: inline !important;
          display: initial !important;
          margin-right: 1rem; }
  .attendance-survey-container .sv_q {
    width: 100% !important; }
    @media (min-width: 576px) {
      .attendance-survey-container .sv_q {
        width: 60% !important; } }
  .attendance-survey-container .sv_q_matrix .sv_q_m_cell:first-child {
    padding-left: 0; }
    @media (min-width: 768px) {
      .attendance-survey-container .sv_q_matrix .sv_q_m_cell:first-child {
        padding-left: 12px; } }
  @media (min-width: 768px) {
    .attendance-survey-container .sv_q_matrix thead tr:first-child td {
      border-top: none; }
    .attendance-survey-container .sv_q_matrix thead tr:first-child th {
      border-top: none;
      border-bottom-width: 1px;
      min-width: 0;
      min-width: initial; } }
  .attendance-survey-container .sv_completed_page, .attendance-survey-container .sd-completedpage {
    padding: 20px 10px; }
  .attendance-survey-container .onboarding-container-footer {
    margin-top: 2rem;
    margin-bottom: 2rem; }

.program-lessons-list-container {
  border: 2px solid #000000;
  -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -moz-border-radius: 16px;
       border-radius: 16px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: -webkit-calc(100vw * 0.45);
  height: -moz-calc(100vw * 0.45);
  height: calc(100vw * 0.45);
  margin-top: 15px;
  background-color: #fff; }
  .program-lessons-list-container .program-lessons-list {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll; }
  @media (max-width: 768px) {
    .program-lessons-list-container {
      height: auto;
      overflow-y: visible;
      border: none;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none; }
      .program-lessons-list-container .program-lessons-list {
        height: auto;
        overflow-y: visible;
        background-color: #FAF8F3;
        position: relative; }
        .program-lessons-list-container .program-lessons-list .lessons-list-item.selected {
          background-color: transparent; } }
  @media (min-width: 768px) {
    .program-lessons-list-container {
      margin-top: 0px; } }
  @media (min-width: 1340px) {
    .program-lessons-list-container {
      height: 600px; } }

.lessons-list-container .button {
  width: inherit; }

.program-presentation-button {
  margin-top: 32px;
  margin-bottom: 40px; }

.video-thumbnail-container {
  padding-bottom: 56.25%;
  height: 0;
  position: relative; }
  .video-thumbnail-container #video-player {
    background: #C9C9C9; }
  .video-thumbnail-container #video-player, .video-thumbnail-container .video-thumbnail-cover {
    border: 2px solid #000000;
    -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-border-radius: 16px;
         border-radius: 16px;
    overflow: hidden;
    z-index: 1; }
  .video-thumbnail-container .video-thumbnail-cover {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    line-height: 1.2em;
    font-weight: 500;
    font-size: 14px; }
    @media (min-width: 768px) {
      .video-thumbnail-container .video-thumbnail-cover {
        font-size: 18px; } }
  .video-thumbnail-container img.video-thumbnail-cover-image {
    -moz-border-radius: 16px;
         border-radius: 16px; }

@media (min-width: 992px) {
  .program-presentation {
    margin-left: -8px;
    margin-right: -8px; } }

.program-presentation .program-section .img-fluid {
  width: 40px; }

.program-presentation .program-section:not(:first-child) {
  padding-top: 40px; }

.program-presentation .program-section:not(:last-child) {
  padding-bottom: 40px; }

.program-presentation .program-section-content {
  padding: 20px; }
  .program-presentation .program-section-content .program-section-image {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%; }

.program-presentation .program-section-title {
  text-align: center;
  margin-bottom: 30px; }

.program-presentation .text-wrapper {
  display: -webkit-flex;
  display: -moz-box;
  display: flex; }

@media (max-width: 756px) {
  .program-presentation .argument-box {
    margin-bottom: 2em; } }

.program-presentation .program-bullet-icon {
  margin-bottom: 24px; }

.program-presentation .program-efficiency-icon {
  margin-bottom: 24px; }

.program-presentation .program-efficiency-label {
  font-weight: 500; }

.program-presentation .program-why-efficient-wrapper {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-evenly;
     -moz-box-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }
  @media (min-width: 992px) {
    .program-presentation .program-why-efficient-wrapper {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      -webkit-align-items: initial;
         -moz-box-align: initial;
              align-items: initial; } }

.program-presentation .program-why-efficient-card {
  background-color: #FFFFFF;
  max-width: 300px;
  width: 100%;
  -moz-border-radius: 8px;
       border-radius: 8px;
  max-height: 570px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding: 16px 24px;
  margin: 16px 8px; }
  @media (min-width: 992px) {
    .program-presentation .program-why-efficient-card {
      margin: 0 8px; } }

@media (min-width: 992px) {
  .program-presentation .program-how-it-works-bullet-container:nth-child(2) {
    margin-top: 64px; }
  .program-presentation .program-how-it-works-bullet-container:nth-child(3) {
    margin-top: 128px; } }

.program-presentation .program-how-it-works-bullet-container .program-how-it-works-bullet-content {
  padding: 22px; }
  .program-presentation .program-how-it-works-bullet-container .program-how-it-works-bullet-content .program-how-it-works-icon {
    background-color: white;
    -moz-border-radius: 8px;
         border-radius: 8px;
    width: 360px;
    padding: 28px;
    -webkit-box-shadow: 0 0 15px #0000001A;
       -moz-box-shadow: 0 0 15px #0000001A;
            box-shadow: 0 0 15px #0000001A; }
  .program-presentation .program-how-it-works-bullet-container .program-how-it-works-bullet-content .program-how-it-works-bullet-content-body {
    max-width: 360px;
    margin: auto; }
    .program-presentation .program-how-it-works-bullet-container .program-how-it-works-bullet-content .program-how-it-works-bullet-content-body .program-how-it-works-number {
      color: #647591;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .program-presentation .program-how-it-works-bullet-container .program-how-it-works-bullet-content .program-how-it-works-bullet-content-body .program-how-it-works-number {
          margin-bottom: 40px; } }

.program-presentation.mia .program-section-title {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  margin-bottom: 0; }

.academy-testimonial {
  height: 100%;
  padding: 20px;
  background-color: white; }
  .academy-testimonial .academy-testimonial-author svg {
    width: 20px !important;
    margin-right: 10px; }

.program-testimony-div {
  max-width: 900px; }

.testimony-program {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 1340px) {
    .testimony-program {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      padding-top: 64px; } }
  .testimony-program .testimony-program-text {
    width: 100%; }
  .testimony-program .testimony-program-title {
    padding-top: 56px;
    font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
    font-size: 24px;
    margin-bottom: 16px; }
    @media (min-width: 1340px) {
      .testimony-program .testimony-program-title {
        padding-top: 0;
        font-size: 32px; } }
  .testimony-program .testimony-program-subtitle {
    font-size: 16px;
    margin-bottom: 40px; }
  .testimony-program .testimony-program-author {
    padding-top: 16px; }
  .testimony-program .navigation-controls {
    margin-top: 32px !important; }
  .testimony-program .testimony-program-carousel {
    width: 100%;
    max-width: 492px; }
    .testimony-program .testimony-program-carousel .testimony-program-carousel-slide .testimony-program-carousel-slide-div {
      padding: 29px 25px; }

.testimony-program-charles {
  padding-bottom: 76px;
  margin-left: -1rem;
  margin-right: -1rem; }

.program-testimony-wrapper {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  background-color: white;
  -webkit-box-shadow: 0 0 6px #0000001A;
     -moz-box-shadow: 0 0 6px #0000001A;
          box-shadow: 0 0 6px #0000001A;
  -moz-border-radius: 8px;
       border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
  max-width: 100%; }
  @media (min-width: 992px) {
    .program-testimony-wrapper {
      max-width: 60%; } }
  .program-testimony-wrapper:nth-child(2) {
    -webkit-align-self: flex-end;
            align-self: flex-end; }

.program-testimony-icon {
  margin-right: 4px; }
  .program-testimony-icon svg {
    width: 30px; }

.program-testimony-text {
  color: #647591;
  font-size: 16px; }

.program-testimony-info {
  color: #647591;
  font-size: 16px;
  font-weight: 600;
  -webkit-align-self: flex-end;
          align-self: flex-end; }

.landing-text-accordion {
  font-size: 20px;
  line-height: 1.2rem;
  text-align: left;
  color: #647591; }
  .landing-text-accordion .accordion__button {
    font-weight: bold;
    font-size: 18px;
    outline: none;
    color: #1F3A64; }
  .landing-text-accordion .accordion__panel {
    font-size: 18px;
    line-height: 1.1rem;
    padding: 0; }

.mia-text-accordion {
  font-size: 20px;
  line-height: 1.2rem;
  text-align: left;
  color: #003060; }
  .mia-text-accordion .program-section-title {
    padding-top: 16px; }
  .mia-text-accordion .accordion-item {
    margin-bottom: 16px;
    background-color: #FFF6E9;
    padding-bottom: 16px;
    max-width: 824px;
    width: 100%; }
    @media (min-width: 768px) {
      .mia-text-accordion .accordion-item {
        min-width: 300px; } }
    .mia-text-accordion .accordion-item:last-child {
      margin-bottom: 0; }
  .mia-text-accordion .accordion-item-div {
    width: 30px;
    margin-right: 16px;
    margin-left: 10px; }
    .mia-text-accordion .accordion-item-div .accordion-button-icon {
      -webkit-background-size: 30px 30px;
         -moz-background-size: 30px 30px;
              background-size: 30px 30px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      background-color: #F9D6C2;
      padding: 6px;
      width: 30px !important;
      height: 30px !important; }
      .mia-text-accordion .accordion-item-div .accordion-button-icon svg {
        width: 18px;
        height: 18px; }
  .mia-text-accordion .accordion__button {
    padding-top: 16px;
    padding-bottom: 0; }
  .mia-text-accordion .accordion-button-label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600; }
    .mia-text-accordion .accordion-button-label * {
      margin-bottom: 0;
      margin-top: 0; }
    @media (min-width: 992px) {
      .mia-text-accordion .accordion-button-label {
        font-size: 1rem;
        font-weight: 500; } }
  .mia-text-accordion .program-section-content {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
  .mia-text-accordion .accordion-body {
    background-color: #FFFFFF;
    font-size: 14px;
    margin: 16px 24px 0 24px;
    padding: 8px 16px; }

.faq-custom-accordion-button {
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer; }
  .faq-custom-accordion-button:focus {
    outline: none; }

.accordion-button-label {
  font-size: 20px;
  font-weight: 700 !important; }
  .accordion-button-label * {
    line-height: 1.4em;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0; }

.accordion__button {
  cursor: pointer;
  padding: 20px 0 8px;
  width: 100%;
  border: none;
  position: relative; }

.accordion__button:focus {
  outline: none; }

.accordion__button .accordion-chevron-wrapper {
  height: 24px;
  width: 24px;
  background-color: #002865;
  -moz-border-radius: 50%;
       border-radius: 50%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }
  @media (min-width: 576px) {
    .accordion__button .accordion-chevron-wrapper {
      height: 28px;
      width: 28px; } }

.accordion__button .accordion-chevron {
  position: relative;
  color: white; }

.accordion__button .accordion-chevron:before {
  content: ' ';
  display: block;
  height: 10px;
  width: 10px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
     -moz-transform: rotate(-45deg);
       -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-left: -3px;
  text-decoration: none; }

.accordion__button[aria-expanded='true'] .accordion-chevron::before,
.accordion__button[aria-selected='true'] .accordion-chevron::before {
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
       -o-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-left: 1px;
  margin-bottom: 2px;
  text-decoration: none; }

.accordion__panel {
  padding: 20px 0;
  -webkit-animation: fadein 0.35s ease-in;
     -moz-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in;
  -webkit-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  transition: visibility 0.5s; }

.accordion-body {
  font-size: 18px;
  margin-left: 38px;
  -webkit-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  transition: visibility 0.5s; }
  .accordion-body .accordion-item {
    margin-bottom: 16px;
    background-color: #FFF6E9;
    padding-bottom: 16px;
    min-width: 300px;
    max-width: 824px;
    width: 75vw; }
    .accordion-body .accordion-item:last-child {
      margin-bottom: 0; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.academy-section-coach {
  background-color: #F9F9F9; }
  .academy-section-coach .academy-coach-container {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
    .academy-section-coach .academy-coach-container h2 {
      font-size: 25px;
      color: #1F3A64;
      font-weight: 500;
      text-align: center;
      width: 321px;
      margin: 32px 0; }
      @media (min-width: 992px) {
        .academy-section-coach .academy-coach-container h2 {
          font-size: 35px;
          width: auto;
          margin: 56px 0; } }

.featured-doctor .featured-image {
  -moz-border-radius: 30px;
       border-radius: 30px;
  -webkit-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
  border: 2px solid black;
  height: 246px;
  width: 327px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top; }
  @media screen and (max-width: 320px) {
    .featured-doctor .featured-image {
      width: 280px; } }

.featured-doctor span {
  width: 100%;
  max-width: 590px;
  font-size: 15px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 16px;
  color: #1F3A64; }

.featured-doctor .description {
  width: 100%;
  max-width: 590px;
  color: #1F3A64;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 24px; }

.profile {
  margin-left: 60px;
  -moz-border-radius: 4px;
       border-radius: 4px;
  padding: 16px; }
  .profile .doctor-informations {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
    @media (min-width: 992px) {
      .profile .doctor-informations {
        -webkit-align-items: flex-start;
           -moz-box-align: start;
                align-items: flex-start; } }
    .profile .doctor-informations p {
      color: #647591;
      font-size: 18px;
      margin-bottom: 24px; }
  .profile .doctor-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0; }
  .profile .doctor-presentation {
    font-size: 14px;
    color: #1F3A64;
    margin-bottom: 0;
    text-align: justify; }
  .profile .doctor-description {
    font-size: 14px;
    color: #1F3A64;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .profile .doctor-description {
        -webkit-padding-start: 20px;
           -moz-padding-start: 20px;
                padding-inline-start: 20px; } }
  @media (max-width: 768px) {
    .profile {
      margin: auto; } }
  .profile .program-access {
    color: white;
    margin-left: -6px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center; }
  .profile .buttonStyle {
    background-color: #6d9eeb !important;
    border-color: grey;
    width: 100%;
    -moz-border-radius: 9px;
         border-radius: 9px;
    font-size: 17px;
    font-weight: bold;
    padding: 5px; }

@media (min-width: 992px) {
  .profile.mia {
    padding: 0;
    width: 384px; } }

.profile.mia .doctor-informations {
  margin-top: 24px; }
  .profile.mia .doctor-informations:first-child {
    margin-top: 0; }

.profile.mia .doctor-description {
  font-size: 14px;
  color: #1F3A64;
  margin-bottom: 0;
  margin-top: 8px; }
  @media (min-width: 992px) {
    .profile.mia .doctor-description {
      -webkit-padding-start: initial;
         -moz-padding-start: initial;
              padding-inline-start: initial; } }

.doctor-rounded-thumbnail {
  width: 70px; }

.coach-details-content {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 768px) {
    .coach-details-content {
      width: 308px; } }
  @media (min-width: 1340px) {
    .coach-details-content {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      width: auto;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      margin-top: 64px;
      -webkit-align-items: flex-start;
         -moz-box-align: start;
              align-items: flex-start; } }

.coach-details-title {
  text-align: center; }

.testimony-section {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }
  @media (min-width: 992px) {
    .testimony-section {
      padding-bottom: 14px; } }
  .testimony-section .testimony-book-div {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    max-width: 920px; }
    @media (min-width: 1250px) {
      .testimony-section .testimony-book-div {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row; } }
    .testimony-section .testimony-book-div .testimony-book {
      display: none;
      height: 298px;
      background-color: white;
      color: #003060;
      font-size: 16px;
      -webkit-align-self: center;
              align-self: center; }
      .testimony-section .testimony-book-div .testimony-book:nth-child(3) {
        margin-top: 56px;
        margin-left: 0;
        margin-right: 40px;
        -webkit-align-self: flex-end;
                align-self: flex-end; }
      @media (min-width: 768px) {
        .testimony-section .testimony-book-div .testimony-book {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          margin-left: 40px;
          -webkit-align-self: flex-start;
                  align-self: flex-start; } }
      .testimony-section .testimony-book-div .testimony-book .testimony-svg-div {
        width: 0; }
        .testimony-section .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-svg {
          position: relative; }
        .testimony-section .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-flower {
          left: -30px;
          top: -30px; }
          .testimony-section .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-flower svg {
            height: 60px; }
        .testimony-section .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-target {
          left: -20px;
          top: 240px; }
          .testimony-section .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-target svg {
            height: 60px; }
      .testimony-section .testimony-book-div .testimony-book .name-age {
        font-weight: 600;
        margin-top: 8px; }
      .testimony-section .testimony-book-div .testimony-book .left-page {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        width: 286px;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        border: 1px solid #003060;
        padding-left: 30px;
        padding-right: 30px;
        border-right: none; }
        @media (min-width: 992px) {
          .testimony-section .testimony-book-div .testimony-book .left-page {
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                    justify-content: space-between;
            padding-top: 16px;
            padding-bottom: 16px; } }
      .testimony-section .testimony-book-div .testimony-book .right-page {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        width: 286px;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        border: 1px solid #003060;
        border-left: 0; }
        @media (min-width: 992px) {
          .testimony-section .testimony-book-div .testimony-book .right-page {
            padding: 16px 20px 16px 30px;
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                    justify-content: space-between; } }
    .testimony-section .testimony-book-div .border-div {
      border: 1px solid #003060;
      border-left: 0;
      width: 5px; }
  .testimony-section .responsive-testimony-book {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    background-color: #FFFFFF;
    border: 1px solid #003060;
    padding: 16px;
    font-size: 14px;
    margin: 16px; }
    @media (min-width: 768px) {
      .testimony-section .responsive-testimony-book {
        display: none; } }
    .testimony-section .responsive-testimony-book .responsive-testimony-author {
      margin-top: 16px;
      font-weight: bold; }
  .testimony-section .cta-testimony-desktop {
    margin-top: 56px;
    margin-bottom: 44px; }

.about-program {
  margin-left: -1rem;
  margin-right: -1rem;
  color: white; }
  @media (min-width: 1340px) {
    .about-program {
      padding: 48px 39px 0px 39px; } }
  .about-program .about-program-container {
    padding-bottom: 60px;
    border-bottom: 1px solid black; }
    .about-program .about-program-container .about-program-h2 {
      color: white;
      padding-top: 48px;
      text-align: center; }
      @media (min-width: 1340px) {
        .about-program .about-program-container .about-program-h2 {
          text-align: left;
          margin-bottom: 60px; } }
    @media (min-width: 1340px) {
      .about-program .about-program-container .about-program-second-col {
        margin-top: -45px; } }
    .about-program .about-program-container .about-program-block {
      padding: 32px; }
      .about-program .about-program-container .about-program-block .about-program-block-content {
        margin-bottom: 40px; }
      .about-program .about-program-container .about-program-block .about-program-block-bottom {
        font-weight: 700; }
    .about-program .about-program-container .about-program-block-image {
      width: 70px;
      display: block;
      -webkit-align-self: flex-end;
              align-self: flex-end; }

.hiw-program {
  margin-left: -1rem;
  margin-right: -1rem;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 1340px) {
    .hiw-program {
      padding: 48px 39px 0px 39px; } }
  .hiw-program h2 {
    position: relative;
    z-index: 2; }
  .hiw-program .hiw-program-black-underline {
    display: block;
    width: 183px;
    -webkit-align-self: center;
            align-self: center;
    position: relative;
    z-index: 1;
    top: -22px; }
  .hiw-program .hiw-program-container {
    margin-top: 96px; }
    .hiw-program .hiw-program-container .hiw-start-button {
      margin-bottom: 40px; }
      @media (min-width: 1340px) {
        .hiw-program .hiw-program-container .hiw-start-button {
          margin-bottom: 88px; } }
  @media (min-width: 1340px) {
    .hiw-program .hiw-program-row {
      margin-bottom: 64px; } }
  .hiw-program .hiw-program-row .hiw-program-col {
    margin-bottom: 64px; }
    @media (min-width: 1340px) {
      .hiw-program .hiw-program-row .hiw-program-col {
        margin-bottom: 0; } }
    .hiw-program .hiw-program-row .hiw-program-col:last-child {
      margin-bottom: 32px; }
      @media (min-width: 1340px) {
        .hiw-program .hiw-program-row .hiw-program-col:last-child {
          margin-bottom: 0; } }
    .hiw-program .hiw-program-row .hiw-program-col:first-child .hiw-program-col-img {
      overflow: visible;
      overflow: initial; }
      .hiw-program .hiw-program-row .hiw-program-col:first-child .hiw-program-col-img img {
        display: block;
        width: auto;
        width: initial;
        height: 321px;
        margin-top: -79px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px; }
        @media screen and (min-width: 320px) {
          .hiw-program .hiw-program-row .hiw-program-col:first-child .hiw-program-col-img img {
            width: 100%; } }
    .hiw-program .hiw-program-row .hiw-program-col .hiw-program-col-img {
      -moz-border-radius: 30px;
           border-radius: 30px;
      -webkit-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
         -moz-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid black;
      width: 100%;
      height: 246px;
      overflow: hidden; }
      .hiw-program .hiw-program-row .hiw-program-col .hiw-program-col-img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .hiw-program .hiw-program-row .hiw-program-col .hiw-program-col-title {
      margin-top: 32px;
      margin-bottom: 16px; }

.program-press {
  padding-top: 32px; }
  .program-press .container {
    padding-bottom: 40px;
    border-bottom: 1px solid black; }
  .program-press .h4 {
    margin-bottom: 24px; }
  .program-press .program-press-img-wrapper {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    border-top: 1px solid black;
    padding-top: 28px;
    margin-bottom: 26px; }
    .program-press .program-press-img-wrapper .program-press-img svg {
      max-height: 48px;
      max-width: 121px;
      width: 100%; }
      @media (min-width: 1340px) {
        .program-press .program-press-img-wrapper .program-press-img svg {
          max-height: 56px;
          max-width: 157px;
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
             object-fit: contain; } }
  .program-press .program-press-find-out-more {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center; }
    .program-press .program-press-find-out-more > div {
      font-weight: 700;
      font-size: 16px; }
    .program-press .program-press-find-out-more .program-press-find-out-more-arrow {
      margin-left: 10px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-transform: rotate(180deg);
         -moz-transform: rotate(180deg);
           -o-transform: rotate(180deg);
              transform: rotate(180deg);
      width: 16px; }

.detail-program {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-top: 48px; }
  @media (min-width: 1340px) {
    .detail-program {
      padding-top: 64px; } }
  .detail-program h2 {
    margin-bottom: 16px; }
  .detail-program .container {
    max-width: none;
    max-width: initial; }
    @media (min-width: 768px) {
      .detail-program .container {
        max-width: 720px; } }
  .detail-program .detail-program-desc {
    margin-bottom: 40px; }
    @media (min-width: 1340px) {
      .detail-program .detail-program-desc {
        margin-bottom: 64px; } }
  .detail-program .detail-program-carousel-slide {
    padding-left: 15px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column; }
    .detail-program .detail-program-carousel-slide .detail-carousel-special-image {
      -o-object-position: right;
         object-position: right; }
    .detail-program .detail-program-carousel-slide img {
      -webkit-align-self: center;
              align-self: center;
      width: 100%;
      max-width: 271px;
      height: 276px;
      -o-object-fit: cover;
         object-fit: cover;
      -moz-border-radius: 30px;
           border-radius: 30px;
      -webkit-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
         -moz-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid black;
      margin-bottom: 24px; }
    .detail-program .detail-program-carousel-slide .detail-program-carousel-slide-title {
      margin-bottom: 8px;
      font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
      font-size: 18px;
      font-weight: 500;
      text-align: left; }
  .detail-program .navigation-controls {
    margin-bottom: 0px !important; }
  @media (min-width: 1340px) {
    .detail-program .detail-program-xl-container {
      max-width: 1090px; } }
  .detail-program .detail-program-xl-container .detail-program-xl-carousel-slide {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    margin-bottom: 24px; }
    .detail-program .detail-program-xl-container .detail-program-xl-carousel-slide img {
      width: 240px;
      height: 167px;
      -o-object-fit: cover;
         object-fit: cover;
      -moz-border-radius: 30px;
           border-radius: 30px;
      -webkit-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
         -moz-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid black;
      margin-right: 28px; }
    .detail-program .detail-program-xl-container .detail-program-xl-carousel-slide .detail-program-carousel-slide-title {
      margin-bottom: 8px;
      font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
      font-size: 20px;
      font-weight: 500;
      text-align: left; }
  .detail-program .detail-program-xl-container .detail-program-xl-border-container {
    padding-bottom: 64px;
    border-bottom: 1px solid black; }

.efficient-program {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  .efficient-program .efficient-program-h2 {
    font-size: 24px;
    padding-top: 64px; }
    @media (min-width: 1340px) {
      .efficient-program .efficient-program-h2 {
        font-size: 2rem;
        margin-bottom: 16px; } }
  .efficient-program .efficient-program-description {
    padding-right: 39px;
    padding-left: 39px;
    font-size: 1rem; }
    @media (min-width: 1340px) {
      .efficient-program .efficient-program-description {
        -webkit-align-self: center;
                align-self: center;
        width: 100%;
        max-width: 807px; } }
  .efficient-program .efficient-program-container {
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px; }
    @media (min-width: 1340px) {
      .efficient-program .efficient-program-container {
        padding-top: 64px; } }
    .efficient-program .efficient-program-container .efficient-program-col {
      padding-top: 64px; }
      @media (min-width: 1340px) {
        .efficient-program .efficient-program-container .efficient-program-col {
          padding-top: 0px; } }
      .efficient-program .efficient-program-container .efficient-program-col:nth-child(1) {
        padding-top: 0px; }
      .efficient-program .efficient-program-container .efficient-program-col .efficient-program-col-img {
        border: 1px solid black;
        -moz-border-radius: 30px;
             border-radius: 30px;
        -webkit-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
           -moz-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 246px;
        -o-object-fit: cover;
           object-fit: cover; }
        @media (min-width: 1340px) {
          .efficient-program .efficient-program-container .efficient-program-col .efficient-program-col-img {
            width: 334px;
            height: 288px; } }
      .efficient-program .efficient-program-container .efficient-program-col .efficient-program-col-title {
        font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
        padding-top: 32px;
        font-size: 20px; }
      .efficient-program .efficient-program-container .efficient-program-col .efficient-program-col-text {
        padding-top: 16px;
        font-size: 16px;
        text-align: left; }
        @media (min-width: 1340px) {
          .efficient-program .efficient-program-container .efficient-program-col .efficient-program-col-text {
            padding-top: 8px; } }
    .efficient-program .efficient-program-container .efficient-program-button {
      padding-top: 32px; }
      @media (min-width: 1340px) {
        .efficient-program .efficient-program-container .efficient-program-button {
          padding-top: 40px; } }
    .efficient-program .efficient-program-container .efficient-program-border {
      padding-top: 40px; }
      @media (min-width: 1340px) {
        .efficient-program .efficient-program-container .efficient-program-border {
          padding-top: 88px; } }

.programe-faq-1 {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding-bottom: 148px; }
  @media (min-width: 1340px) {
    .programe-faq-1 {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      padding-left: 24px;
      padding-right: 24px; } }
  .programe-faq-1 .programe-faq-container {
    width: 100%; }
  .programe-faq-1 .program-faq-title {
    margin-top: 24px;
    margin-bottom: 0;
    font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif; }
  .programe-faq-1 .program-faq-text {
    font-size: 16px;
    margin-bottom: 32px; }
  .programe-faq-1 .program-faq-block {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    padding: 29px 25px;
    width: 100%;
    height: 100%;
    max-width: 384px;
    max-height: 378px; }
    @media (min-width: 1340px) {
      .programe-faq-1 .program-faq-block {
        margin-left: 64px; } }
    @media screen and (max-width: 320px) {
      .programe-faq-1 .program-faq-block {
        max-height: 500px !important;
        max-width: 500px !important; } }
    .programe-faq-1 .program-faq-block .program-faq-block-title {
      font-size: 1.25rem;
      font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
      margin-bottom: 28px; }
    .programe-faq-1 .program-faq-block .program-faq-block-bullet {
      font-size: 1rem;
      cursor: pointer;
      margin-bottom: 16px; }
      .programe-faq-1 .program-faq-block .program-faq-block-bullet a {
        font-weight: 400;
        font-size: 1rem;
        color: black; }
    .programe-faq-1 .program-faq-block .program-faq-block-svg {
      margin-right: 8px; }
      .programe-faq-1 .program-faq-block .program-faq-block-svg svg {
        width: 100%;
        max-width: 32px; }

.creator-program {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media (min-width: 1340px) {
    .creator-program {
      padding-bottom: 88px; } }
  .creator-program h2 {
    margin-bottom: 16px;
    text-align: center; }
  .creator-program .creator-program-paragraph {
    margin-bottom: 40px; }
  .creator-program .creator-program-caption {
    margin-top: 54px; }
    @media (min-width: 1340px) {
      .creator-program .creator-program-caption {
        margin-top: 0;
        margin-left: 40px; } }



.responsive-tabs {
  text-align: center; }
  .responsive-tabs .responsive-tabs-item-title {
    cursor: pointer;
    font-weight: bold; }

.responsive-tabs-desktop {
  position: relative;
  background-color: white;
  border: 3px solid black;
  -moz-border-radius: 1.875rem;
       border-radius: 1.875rem;
  -webkit-box-shadow: 10px 10px 0 black;
     -moz-box-shadow: 10px 10px 0 black;
          box-shadow: 10px 10px 0 black;
  margin-right: 10px;
  margin-bottom: 16px;
  overflow: hidden; }
  .responsive-tabs-desktop .responsive-tabs-titles {
    color: white;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    border-bottom: 3px solid black;
    background-color: #3D7791; }
    .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-titles-arrow {
      cursor: pointer;
      -webkit-align-self: center;
              align-self: center;
      width: 18px; }
      .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-titles-arrow svg path {
        stroke: white; }
      .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-titles-arrow svg line {
        stroke: white; }
      .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-titles-arrow-back {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg); }
    .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-titles-container {
      -webkit-flex-grow: 1;
         -moz-box-flex: 1;
              flex-grow: 1;
      display: -webkit-flex;
      display: -moz-box;
      display: flex; }
    .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-item-title {
      margin-top: 9px;
      padding: 14px 14px 24px;
      border: 3px solid transparent;
      margin-bottom: -3px;
      white-space: nowrap;
      -webkit-flex-grow: 1;
         -moz-box-flex: 1;
              flex-grow: 1;
      text-align: center;
      max-width: 300px; }
      .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-item-title.disabled {
        opacity: 0.5; }
      .responsive-tabs-desktop .responsive-tabs-titles .responsive-tabs-item-title.selected {
        color: black;
        border: 3px solid black;
        background-color: white;
        border-bottom-width: 0;
        -moz-border-radius: 1rem;
             border-radius: 1rem;
        -moz-border-radius-bottomleft: 0;
             border-bottom-left-radius: 0;
        -moz-border-radius-bottomright: 0;
             border-bottom-right-radius: 0;
        -webkit-box-shadow: 10px 10px 0 black;
           -moz-box-shadow: 10px 10px 0 black;
                box-shadow: 10px 10px 0 black; }
  .responsive-tabs-desktop .responsive-tabs-content {
    background-color: white;
    z-index: 100;
    position: relative;
    padding: 14px; }

.responsive-tabs-mobile .responsive-tabs-item {
  border: 2px solid black;
  -moz-border-radius: 1.25rem;
       border-radius: 1.25rem;
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: #3D7791; }
  .responsive-tabs-mobile .responsive-tabs-item.disabled {
    opacity: 0.5; }
  .responsive-tabs-mobile .responsive-tabs-item .responsive-tabs-item-title {
    padding: 1rem;
    color: white;
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
    .responsive-tabs-mobile .responsive-tabs-item .responsive-tabs-item-title .expand-button::before {
      display: inline-block;
      content: "";
      height: 8px;
      width: 8px;
      border-right: 0 solid white;
      border-top: 0 solid #white;
      border-color: #white;
      border-style: solid;
      border-width: 2px 2px 0 0;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-transform: rotate(135deg);
         -moz-transform: rotate(135deg);
           -o-transform: rotate(135deg);
              transform: rotate(135deg);
      -webkit-transition-duration: .2s;
         -moz-transition-duration: .2s;
           -o-transition-duration: .2s;
              transition-duration: .2s;
      margin-right: 2px; }
    .responsive-tabs-mobile .responsive-tabs-item .responsive-tabs-item-title.selected .expand-button::before {
      -webkit-transform: rotate(-45deg);
         -moz-transform: rotate(-45deg);
           -o-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  .responsive-tabs-mobile .responsive-tabs-item .responsive-tabs-content {
    border-top: 2px solid black;
    background-color: white;
    z-index: 100;
    position: relative;
    padding: 1rem; }

.secure-challenge-popup {
  width: 100%;
  max-width: 300px;
  margin: auto;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  margin-bottom: 30px;
  grid-row-gap: 16px;
  row-gap: 16px;
  text-align: center; }

.secure-challenge-loader {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  text-align: left; }
  .secure-challenge-loader *, .secure-challenge-loader *::before, .secure-challenge-loader *::after {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box; }

.secure-challenge-svg svg {
  width: 32px; }

.c-stepper-container {
  max-width: 650px;
  -webkit-flex: auto;
     -moz-box-flex: 1;
          flex: auto; }
  .c-stepper-container.c-stepper-container-unlimited {
    max-width: none;
    max-width: initial; }

.c-stepper {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding: 0;
  margin: 0; }
  @media screen and (min-width: 576px) {
    .c-stepper {
      margin-bottom: .5rem; } }

.c-stepper__item {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
          flex: 1 1;
  text-align: center;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between; }
  .c-stepper__item .c-stepper__circle:before {
    content: "";
    display: block;
    width: 0.5625rem;
    height: 0.5625rem;
    -moz-border-radius: 50%;
         border-radius: 50%;
    background-color: black;
    opacity: 0.5;
    margin: 0 auto; }
  .c-stepper__item:not(:last-child) .c-stepper__circle:after {
    display: block;
    content: "";
    position: relative;
    bottom: 50%;
    width: -webkit-calc(100% - 0.5625rem - -webkit-calc(0.375rem * 2));
    width: -moz-calc(100% - 0.5625rem - -moz-calc(0.375rem * 2));
    width: calc(100% - 0.5625rem - calc(0.375rem * 2));
    left: -webkit-calc(50% + -webkit-calc(0.5625rem / 2 + 0.375rem));
    left: -moz-calc(50% + -moz-calc(0.5625rem / 2 + 0.375rem));
    left: calc(50% + calc(0.5625rem / 2 + 0.375rem));
    height: 1px;
    background-color: #e0e0e0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
            order: -1; }
  .c-stepper__item.progtrckr-done .c-stepper__circle:before, .c-stepper__item.progtrckr-doing .c-stepper__circle:before {
    background-color: black;
    opacity: 1; }
  .c-stepper__item.progtrckr-done .c-stepper__circle:after, .c-stepper__item.progtrckr-doing .c-stepper__circle:after {
    background-color: black; }
  .c-stepper__item.progtrckr-todo .c-stepper__circle:before {
    background-color: white;
    border: 1px solid #AAAAAA;
    opacity: 1; }
  .c-stepper__item.progtrckr-todo .c-stepper__circle:after {
    background-color: #AAAAAA; }
  .c-stepper__item.progtrckr-todo .c-stepper__title {
    color: #808080; }

.c-stepper__title {
  font-weight: 500;
  font-size: .875rem;
  margin-bottom: .5rem;
  line-height: 1; }

.c-stepper__desc {
  color: grey;
  font-size: .7rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem; }

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  -moz-border-radius: 8px;
       border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 90%;
}

.btn-close {
  width: 5px;
  height: 7px;
  padding: 15px;
}

.help-message-footer {
  text-align: center;
  font-size: 12px;
  color: black;
  font-weight: 600;
}
.setup-media-wizard .grant-wording {
  font-weight: 700;
  position: relative; }

.setup-media-wizard .grant-underline {
  position: absolute;
  top: 20px;
  left: -25%;
  width: 150%; }

.setup-media-wizard .central-icon {
  width: 180px;
  display: block;
  margin: 20px auto; }
  @media (max-width: 576px) {
    .setup-media-wizard .central-icon {
      width: 100px; } }

.setup-media-wizard .configuration-error-message {
  color: red;
  font-weight: bold; }

.setup-media-wizard .logo-icon {
  display: block;
  width: 126px;
  height: 126px;
  margin: 20px auto; }


.sidebar {
  background-color: #fff;
  color: #888888;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  height: 100vh;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  width: 20.3125em;
  min-height: 100vh;
  overflow: auto;
  -webkit-transition: margin-left .2s;
  -o-transition: margin-left .2s;
  -moz-transition: margin-left .2s;
  transition: margin-left .2s;
  z-index: 1500;
  padding: 1.5rem 1rem 1rem;
  border-right: 2px solid #000000; }
  .sidebar.animate {
    left: 0;
    position: absolute;
    z-index: 1039;
    margin-left: -20.3125em; }
    @media screen and (min-width: 1340px) {
      .sidebar.animate {
        left: 0;
        position: inherit;
        margin: 0; } }
  .sidebar.active {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    margin-left: 0; }
  .sidebar .logo-container {
    display: block;
    margin: 2.3em auto;
    width: 150px; }
    .sidebar .logo-container svg g {
      fill: #000000; }
  .sidebar .sidebar-top {
    -webkit-flex: 0 0;
       -moz-box-flex: 0;
            flex: 0 0; }
    .sidebar .sidebar-top .toggle-menu-button {
      background-color: transparent;
      border: 0;
      float: right;
      position: relative;
      right: 10px;
      top: 5px; }
      @media screen and (min-width: 1340px) {
        .sidebar .sidebar-top .toggle-menu-button {
          display: none; } }
    .sidebar .sidebar-top .toggle-menu-icon {
      display: inline-block;
      line-height: 6em;
      vertical-align: middle;
      width: 2em;
      -webkit-transform: rotate(180deg);
         -moz-transform: rotate(180deg);
           -o-transform: rotate(180deg);
              transform: rotate(180deg); }
      .sidebar .sidebar-top .toggle-menu-icon svg path {
        fill: #000000; }
    .sidebar .sidebar-top .sidebar-support-number {
      color: #111c2b;
      font-weight: normal;
      text-decoration: none; }
    .sidebar .sidebar-top .dropdown {
      position: relative; }
    .sidebar .sidebar-top .dropdown__list {
      -webkit-transition: max-height .6s ease-out;
      -o-transition: max-height .6s ease-out;
      -moz-transition: max-height .6s ease-out;
      transition: max-height .6s ease-out;
      max-height: 0;
      overflow: hidden;
      margin: 0; }
      .sidebar .sidebar-top .dropdown__list li span.label {
        font-weight: 300;
        margin-left: 1rem; }
    .sidebar .sidebar-top .dropdown__list--active {
      max-height: 800px;
      opacity: 1; }
    .sidebar .sidebar-top .dropdown__list-item {
      cursor: pointer;
      list-style: none;
      position: relative; }
      .sidebar .sidebar-top .dropdown__list-item .dropdown__toggler--active span.icon {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg); }
  .sidebar .sidebar-item {
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 500;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    outline: 0;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    width: 100%; }
    .sidebar .sidebar-item .icon {
      display: inline-block;
      line-height: 3rem;
      vertical-align: middle;
      width: 1.125em; }
    .sidebar .sidebar-item .label {
      line-height: 3rem;
      vertical-align: middle; }
    .sidebar .sidebar-item.active {
      color: #000000; }
      .sidebar .sidebar-item.active .icon path, .sidebar .sidebar-item.active .icon * {
        fill: #000000; }
      .sidebar .sidebar-item.active .label {
        opacity: 1;
        border-bottom: 2px solid #000000;
        padding-bottom: 0.5em; }
    .sidebar .sidebar-item:hover {
      color: #000000;
      text-decoration: none; }
  .sidebar ul.sidebar-item-subitems {
    margin-left: 1rem;
    font-size: 0.8em; }

.agenda-sommeil-summary-cta {
  width: 100%; }
  .agenda-sommeil-summary-cta svg {
    width: 56px; }
  .agenda-sommeil-summary-cta button svg {
    width: 24px; }
  .agenda-sommeil-summary-cta button:hover svg * {
    fill: #fff; }

.icon-32px svg {
  width: 32px; }

.icon-40px svg {
  width: 40px; }

.icon-24px svg {
  width: 24px; }

.icon-20px svg {
  width: 20px; }

.icon-16px svg {
  width: 16px; }

.calendar-summary-modal {
  max-width: none;
  width: 95vw; }
  .calendar-summary-modal .modal-content {
    border: 2px solid black;
    -moz-border-radius: 20px;
         border-radius: 20px;
    padding: 0;
    background-color: #fff; }
    @media (min-width: 768px) {
      .calendar-summary-modal .modal-content {
        -webkit-box-shadow: 10px 10px 0 black;
           -moz-box-shadow: 10px 10px 0 black;
                box-shadow: 10px 10px 0 black;
        padding: 24px 32px; }
        .calendar-summary-modal .modal-content .calendar-table-container {
          height: 70vh;
          overflow-y: scroll; } }
  .calendar-summary-modal .icon-dot svg {
    width: 14px; }

.sleep-calendar-container img.icon-legend {
  width: 30px;
  height: 30px;
  z-index: 999;
  position: relative;
  top: -1px; }

.sleep-calendar-container .icon-dot svg {
  width: 14px; }

.sleep-calendar-container a.sleep-calendar-settings-toggle {
  cursor: pointer; }
  .sleep-calendar-container a.sleep-calendar-settings-toggle svg {
    width: 16px;
    margin-left: 5px; }

.sleep-calendar-summary-table-caption li .progress {
  width: 30px;
  -moz-border-radius: 0;
       border-radius: 0; }

.sleep-calendar-summary-table .force-cell-line-width {
  width: 960px !important; }

.sleep-calendar-summary-table tbody tr {
  border-top: 1px solid #dee2e6; }

.sleep-calendar-summary-table .sleep-calendar-line-cell {
  height: 30px;
  line-height: 30px;
  vertical-align: middle; }
  .sleep-calendar-summary-table .sleep-calendar-line-cell .progress {
    height: 100% !important; }
  .sleep-calendar-summary-table .sleep-calendar-line-cell img {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    margin-top: -1px;
    position: relative; }

.scroll-x {
  overflow-x: scroll; }

.calendar-summary {
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content; }

.bg-charles-sommeil-sleeping {
  background-color: #57A8F2 !important; }

.bg-charles-sommeil-awake {
  background-color: rgba(87, 168, 242, 0.3) !important; }

.table-agenda-sommeil tr {
  border-top: 1px solid #CCCCCC; }

.table-agenda-sommeil th {
  text-align: right;
  line-height: 100%;
  vertical-align: middle;
  width: 25px; }

@media (max-width: 576px) {
  .table-agenda-sommeil td, .table-agenda-sommeil th {
    padding: 0.1rem; } }

.sleep-calendar-items-form-actions {
  width: 100%; }
  .sleep-calendar-items-form-actions button[disabled] {
    opacity: 0; }

.sleep-calendar-items-form {
  min-width: 270px; }
  .sleep-calendar-items-form .sleep-calendar-title svg {
    width: 32px; }
  .sleep-calendar-items-form .form-label {
    font-weight: bold; }
  .sleep-calendar-items-form .calendar-cell-btn {
    min-width: 60px;
    height: 40px;
    line-height: 100%;
    vertical-align: middle;
    background: #EDF3FF;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -moz-border-radius: 16px;
         border-radius: 16px; }
    .sleep-calendar-items-form .calendar-cell-btn:focus {
      outline: none;
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none; }
    .sleep-calendar-items-form .calendar-cell-btn.is-bedtime, .sleep-calendar-items-form .calendar-cell-btn.is-sleep {
      border: 2px solid #4992FF;
      background: #B8D0FF; }
    .sleep-calendar-items-form .calendar-cell-btn.is-waketime, .sleep-calendar-items-form .calendar-cell-btn.is-out-of-bed {
      border: 2px solid #FFA149;
      background: #FFEBB8; }
    .sleep-calendar-items-form .calendar-cell-btn.is-nightime-awake, .sleep-calendar-items-form .calendar-cell-btn.is-nap {
      background: #FFF8F8;
      border: 2px solid #D3692D; }
    .sleep-calendar-items-form .calendar-cell-btn.before-sleep {
      background: #B8D0FF; }
    .sleep-calendar-items-form .calendar-cell-btn.during-sleep {
      background: #213661; }
    .sleep-calendar-items-form .calendar-cell-btn.waking-up {
      background: #FFEBB8; }
    .sleep-calendar-items-form .calendar-cell-btn:disabled {
      opacity: 0.95; }
    @media (min-width: 768px) {
      .sleep-calendar-items-form .calendar-cell-btn {
        width: 100%; } }

.sticky-wrapper {
  position: relative;
  height: 1rem; }
  .sticky-wrapper .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9999; }
    .sticky-wrapper .sticky .sticky-inner {
      background-color: white;
      -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
         -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1; }
  .sticky-wrapper .sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 9999; }
    .sticky-wrapper .sticky-bottom .sticky-inner {
      background-color: white;
      position: fixed;
      padding: 1rem;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }

.store-redirection-screen-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
  .store-redirection-screen-container .store-redirection-screen {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    text-align: center;
    margin-top: 150px; }
    @media (min-width: 576px) {
      .store-redirection-screen-container .store-redirection-screen {
        margin-top: 200px; } }
    .store-redirection-screen-container .store-redirection-screen .store-redirection-name {
      color: #44962B; }
    .store-redirection-screen-container .store-redirection-screen .store-redirection-loader {
      margin-top: 50px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      text-align: left; }
      .store-redirection-screen-container .store-redirection-screen .store-redirection-loader *, .store-redirection-screen-container .store-redirection-screen .store-redirection-loader *::before, .store-redirection-screen-container .store-redirection-screen .store-redirection-loader *::after {
        -webkit-box-sizing: content-box;
           -moz-box-sizing: content-box;
                box-sizing: content-box; }
    .store-redirection-screen-container .store-redirection-screen .store-redirection-label {
      font-weight: 500;
      font-size: 1.2rem; }

.sb-question-body {
  background-color: #fff;
  margin-bottom: 1em;
  padding: 0; }
  @media (min-width: 576px) {
    .sb-question-body {
      margin-bottom: 0; } }

.sb-question-summary {
  display: -webkit-flex;
  display: -moz-box;
  display: flex; }

.sb-question-title {
  color: #228be6;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -.1px;
  line-height: 1.5em;
  margin: 1.5em 0 0; }

.sb-answer {
  background-color: #f1f3f5;
  color: #a4a4a4;
  font-weight: 500;
  margin: 0 1em 0 0;
  padding: .5em 1em; }

.thumbY {
  background-color: #002874 !important; }

.custom-table {
  background-color: #fff;
  border-collapse: collapse;
  padding: 30px 40px;
  width: 100%; }
  .custom-table tr {
    width: 100% !important; }
    .custom-table tr:nth-child(even) {
      background-color: #f1f3f5; }
    .custom-table tr.legend-table {
      background-color: transparent; }
      .custom-table tr.legend-table .th {
        vertical-align: center; }
    .custom-table tr.table-content {
      background-color: #F1F2F9; }
  .custom-table th {
    color: #ACACAC;
    font-size: 16px;
    font-weight: 500; }
    .custom-table th::first-letter {
      text-transform: uppercase; }
  .custom-table td {
    color: #111c2b;
    font-size: 14px;
    font-weight: 500; }
    .custom-table td a {
      color: #495057;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline; }
  .custom-table th:first-child,
  .custom-table td:first-child {
    text-align: left; }
  .custom-table th.actions,
  .custom-table td.actions {
    -webkit-align-items: baseline;
       -moz-box-align: baseline;
            align-items: baseline;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between; }
  .custom-table .button {
    margin: auto; }

.teleconsultation-container {
  text-align: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem; }
  @media (min-width: 768px) {
    .teleconsultation-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }
  .teleconsultation-container .teleconsultation-doctor {
    -webkit-flex: 3 1 100%;
       -moz-box-flex: 3;
            flex: 3 1 100%; }
  .teleconsultation-container .teleconsultation-actions {
    -webkit-flex: 1 1 100%;
       -moz-box-flex: 1;
            flex: 1 1 100%; }
    @media (min-width: 768px) {
      .teleconsultation-container .teleconsultation-actions {
        -webkit-flex: 1 0 220px;
           -moz-box-flex: 1;
                flex: 1 0 220px; } }
  .teleconsultation-container .teleconsultation-appointment {
    -webkit-flex: 1 1 100%;
       -moz-box-flex: 1;
            flex: 1 1 100%;
    -webkit-align-self: stretch;
            align-self: stretch; }
    @media (min-width: 768px) {
      .teleconsultation-container .teleconsultation-appointment {
        -webkit-flex: 1 0 220px;
           -moz-box-flex: 1;
                flex: 1 0 220px; } }
  .teleconsultation-container .teleconsultation-doctor {
    text-align: center; }
    @media (min-width: 768px) {
      .teleconsultation-container .teleconsultation-doctor {
        text-align: left; } }
  .teleconsultation-container .teleconsultation-doctor-image-container {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto; }
    .teleconsultation-container .teleconsultation-doctor-image-container .teleconsultation-doctor-image-img {
      height: 136px;
      width: 136px;
      border: 2px solid #000000;
      -moz-border-radius: 30px;
           border-radius: 30px;
      z-index: 1; }
  @media (max-width: 768px) {
    .teleconsultation-container .teleconsultation-appointment {
      padding-top: 1.5rem;
      border-top: 1px solid black; } }
  @media (min-width: 768px) {
    .teleconsultation-container .teleconsultation-appointment {
      padding-left: 1.5rem;
      border-left: 1px solid black; } }
  .teleconsultation-container .teleconsultation-appointment .teleconsultation-appointment-date {
    height: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    grid-row-gap: .5rem;
    row-gap: .5rem; }
    .teleconsultation-container .teleconsultation-appointment .teleconsultation-appointment-date svg {
      height: 24px; }


.toggle-block {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  border: 0.05em #a4a4a4 solid;
  -moz-border-radius: 5px;
       border-radius: 5px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  margin: 1.5em 0;
  padding: 1rem;
  text-align: center;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 576px) {
    .toggle-block {
      padding: 1.5em 2em;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }
  .toggle-block .toggle-button {
    height: 22px !important;
    margin-right: 1.5em;
    width: 40px !important; }
    .toggle-block .toggle-button span {
      height: 20px !important;
      width: 20px !important; }
  .toggle-block .toggle-text {
    font-size: 1em;
    font-weight: 500;
    max-width: 320px; }

.VisioBox {
  margin: .5em;
  text-align: center;
  width: 100%; }

.VisioBox-main {
  border: 1px solid #002874;
  height: 480px;
  margin: auto;
  position: relative;
  width: 100%; }

.VisioBox-control-container {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  bottom: 1em;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2; }
  .VisioBox-control-container.hidden {
    display: none; }
  .VisioBox-control-container .ots-video-control {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    background-color: #002874;
    background-position: center;
    background-repeat: no-repeat;
    -moz-border-radius: 50%;
         border-radius: 50%;
    cursor: pointer;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    height: 2.5em;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    margin: 0 20px !important;
    width: 2.5em; }
    .VisioBox-control-container .ots-video-control.power-off {
      background-color: #d93868; }
    .VisioBox-control-container .ots-video-control.hidden {
      display: none; }
    .VisioBox-control-container .ots-video-control svg {
      display: block;
      fill: #fff;
      margin: auto;
      width: 65%; }
    .VisioBox-control-container .ots-video-control .disable svg {
      display: none; }
    .VisioBox-control-container .ots-video-control.toggled .enable svg {
      display: none; }
    .VisioBox-control-container .ots-video-control.toggled .disable svg {
      display: block; }

.VisioBox-video-container {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  position: relative;
  width: 100%; }
  @media (max-width: 576px) {
    .VisioBox-video-container {
      -webkit-flex-direction: column-reverse;
         -moz-box-orient: vertical;
         -moz-box-direction: reverse;
              flex-direction: column-reverse; } }

.VisioBox-mask {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  background-color: #f1f3f5;
  color: #002874;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  height: 100%;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  position: relative;
  width: 100%; }
  .VisioBox-mask .react-spinner {
    position: absolute; }
  .VisioBox-mask .message {
    display: block;
    font-weight: 200; }
    .VisioBox-mask .message.error {
      color: #d93868; }
    .VisioBox-mask .message.with-spinner {
      position: absolute;
      top: 55%; }
    .VisioBox-mask .message.button {
      border: 1px solid #002874;
      -moz-border-radius: 6px;
           border-radius: 6px;
      line-height: 3em;
      height: 3em;
      vertical-align: middle; }
      .VisioBox-mask .message.button.error {
        border-color: #d93868; }

.VisioBox-video-container .video-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 100%;
  width: 100%; }
  .VisioBox-video-container .video-container.small {
    border: 1px solid #fff;
    height: 96px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 160px;
    z-index: 2; }
    .VisioBox-video-container .video-container.small.left {
      border: 1px solid #002874;
      left: 20px; }
  .VisioBox-video-container .video-container.hidden {
    display: none; }
  .VisioBox-video-container .video-container.active-2 .OT_subscriber {
    width: 50%; }
  .VisioBox-video-container .video-container.active-3 .OT_subscriber {
    width: -webkit-calc(100%/3) !important;
    width: -moz-calc(100%/3) !important;
    width: calc(100%/3) !important; }
  .VisioBox-video-container .video-container.active-4 {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .VisioBox-video-container .video-container.active-4 .OT_subscriber {
      height: 50% !important;
      width: 50% !important; }

.visio-box-with-setup-container {
  padding: 20px;
  background-color: lightgrey; }
  .visio-box-with-setup-container .visio-box-with-setup-content {
    max-width: 448px;
    margin: auto;
    padding: 20px;
    background-color: white;
    -moz-border-radius: 8px;
         border-radius: 8px;
    text-align: center; }


.highlighted-row {
  font-weight: 500;
  background-color: #f1f3f5; }

.checkout-resume {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  .checkout-resume .checkout-doctor-info {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row;
    margin-bottom: 28px; }
    .checkout-resume .checkout-doctor-info .checkout-doctor-info-image {
      -o-object-fit: cover;
         object-fit: cover;
      -moz-border-radius: 9px;
           border-radius: 9px;
      width: 60px;
      height: 60px;
      border: 2px solid #000000;
      -moz-border-radius: 12px;
           border-radius: 12px; }
    .checkout-resume .checkout-doctor-info .checkout-doctor-info-text {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      margin-left: 8px;
      line-height: 32px; }
      .checkout-resume .checkout-doctor-info .checkout-doctor-info-text .title {
        font-weight: 500; }
      .checkout-resume .checkout-doctor-info .checkout-doctor-info-text .subtitle {
        font-size: 14px;
        font-weight: 400; }
  .checkout-resume .checkout-resume-subtitle {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 24px; }

.checkout-stepper {
  margin-bottom: 32px; }
  .checkout-stepper .step {
    padding: 10px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    background-color: cream; }
    .checkout-stepper .step:last-child .line {
      border-left: 3px;
      z-index: -1; }
    .checkout-stepper .step .stepper-text {
      margin-left: 18px;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column; }
      .checkout-stepper .step .stepper-text .title {
        font-weight: 700; }
      .checkout-stepper .step .stepper-text .list {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400; }
    .checkout-stepper .step .v-stepper {
      position: relative; }
      .checkout-stepper .step .v-stepper .circle {
        background-color: white;
        border: 2px solid #000000;
        -moz-border-radius: 100%;
             border-radius: 100%;
        width: 40px;
        height: 40px;
        display: inline-block; }
        .checkout-stepper .step .v-stepper .circle .stepper-circle-number {
          font-size: 18px;
          font-weight: 700;
          line-height: 21.6px;
          margin-top: 4px;
          margin-left: 12.5px; }
      .checkout-stepper .step .v-stepper .line {
        top: 38px;
        left: 18px;
        height: 100%;
        position: absolute;
        border-left: 1px solid #000000; }

.checkout-resume-button {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  .checkout-resume-button .previous {
    color: black;
    background-color: white;
    border: 2px solid black; }
    .checkout-resume-button .previous:hover {
      color: white;
      background-color: black; }
  @media (min-width: 768px) {
    .checkout-resume-button {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; } }
  .checkout-resume-button .checkout-resume-button-continue {
    margin-bottom: 16px; }
    @media (min-width: 768px) {
      .checkout-resume-button .checkout-resume-button-continue {
        margin-bottom: 0px;
        margin-right: 32px; } }

.treatment-recommendation {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding: 0px;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1.5rem; }
  .treatment-recommendation .treatment-recommendation-title {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    padding-bottom: 8px;
    font-size: 1.25rem;
    line-height: 32px;
    font-weight: 500;
    border-bottom: 2px solid black; }
    @media (max-width: 576px) {
      .treatment-recommendation .treatment-recommendation-title {
        font-size: 1rem;
        line-height: 24px; } }
  .treatment-recommendation .treatment-prescription {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    border: 2px solid black;
    -moz-border-radius: 16px;
         border-radius: 16px;
    padding: 0px 0px 0px 24px;
    grid-gap: 24px;
    gap: 24px; }
    @media (max-width: 576px) {
      .treatment-recommendation .treatment-prescription {
        padding: 0px 0px 0px 16px;
        grid-gap: 16px;
        gap: 16px; } }
    .treatment-recommendation .treatment-prescription .treatment-prescription-items {
      width: 599px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      -webkit-align-items: flex-start;
         -moz-box-align: start;
              align-items: flex-start;
      padding: 16px 0px;
      grid-gap: .5rem;
      gap: .5rem;
      -webkit-order: 0;
         -moz-box-ordinal-group: 1;
              order: 0;
      align-self: stretch;
      -webkit-flex-grow: 1;
         -moz-box-flex: 1;
              flex-grow: 1; }
      @media (max-width: 992px) {
        .treatment-recommendation .treatment-prescription .treatment-prescription-items {
          width: 157px; } }
      .treatment-recommendation .treatment-prescription .treatment-prescription-items .treatment-prescription-items-list {
        display: none;
        list-style-position: inside;
        padding-left: 0;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0; }
        @media (max-width: 576px) {
          .treatment-recommendation .treatment-prescription .treatment-prescription-items .treatment-prescription-items-list {
            display: block; } }
      .treatment-recommendation .treatment-prescription .treatment-prescription-items .treatment-prescription-items-large {
        list-style-type: none;
        padding-left: 0;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0; }
        .treatment-recommendation .treatment-prescription .treatment-prescription-items .treatment-prescription-items-large .treatment-molecule-details {
          list-style-type: disc;
          margin-left: 1rem;
          padding-bottom: .5rem; }
        @media (max-width: 576px) {
          .treatment-recommendation .treatment-prescription .treatment-prescription-items .treatment-prescription-items-large {
            display: none; } }
    .treatment-recommendation .treatment-prescription .treatment-plan-btn {
      width: 105px;
      background: #C9E0EB;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      font-weight: 700 !important;
      padding: 10px;
      grid-gap: 4px;
      gap: 4px;
      border-left: 2px solid #000000;
      -moz-border-radius: 0px 16px 16px 0px;
           border-radius: 0px 16px 16px 0px;
      -webkit-order: 1;
         -moz-box-ordinal-group: 2;
              order: 1;
      align-self: stretch;
      -webkit-flex-grow: 0;
         -moz-box-flex: 0;
              flex-grow: 0;
      cursor: pointer;
      font-size: 12px;
      line-height: 14px; }
      @media (max-width: 768px) {
        .treatment-recommendation .treatment-prescription .treatment-plan-btn {
          padding: 8px !important; } }
      .treatment-recommendation .treatment-prescription .treatment-plan-btn .icon {
        margin-right: 0px !important; }
      .treatment-recommendation .treatment-prescription .treatment-plan-btn .treatment-plan-btn-label {
        text-align: center; }
      @media (max-width: 992px) {
        .treatment-recommendation .treatment-prescription .treatment-plan-btn {
          width: 89px; } }
    .treatment-recommendation .treatment-prescription .program-btn {
      padding: 0px;
      grid-gap: 10px;
      gap: 10px; }
      @media (max-width: 576px) {
        .treatment-recommendation .treatment-prescription .program-btn {
          padding: 0px 8px !important; } }
      .treatment-recommendation .treatment-prescription .program-btn .program-link, .treatment-recommendation .treatment-prescription .program-btn .external-program-link {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        -webkit-align-items: center;
           -moz-box-align: center;
                align-items: center;
        text-decoration: none;
        padding: 20px;
        grid-gap: 4px;
        gap: 4px; }
        @media (max-width: 576px) {
          .treatment-recommendation .treatment-prescription .program-btn .program-link, .treatment-recommendation .treatment-prescription .program-btn .external-program-link {
            padding: 8px; } }
      .treatment-recommendation .treatment-prescription .program-btn .external-program-link {
        padding: 0px;
        text-align: center; }
    .treatment-recommendation .treatment-prescription .treatment-order-btn-disabled {
      opacity: 0.3;
      pointer-events: none; }
    .treatment-recommendation .treatment-prescription .treatment-prescription-warning {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      border-top: 1px solid #CCCCCC;
      padding-top: .5rem;
      -webkit-order: 1;
         -moz-box-ordinal-group: 2;
              order: 1;
      -webkit-align-self: stretch;
              align-self: stretch;
      -webkit-flex-grow: 0;
         -moz-box-flex: 0;
              flex-grow: 0; }
    .treatment-recommendation .treatment-prescription .treatment-program {
      width: 599px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      padding: 16px 0px;
      grid-gap: 8px;
      gap: 8px;
      -webkit-order: 0;
         -moz-box-ordinal-group: 1;
              order: 0;
      align-self: stretch;
      -webkit-flex-grow: 1;
         -moz-box-flex: 1;
              flex-grow: 1; }
      @media (max-width: 992px) {
        .treatment-recommendation .treatment-prescription .treatment-program {
          width: 157px; } }
      .treatment-recommendation .treatment-prescription .treatment-program .treatment-program-description {
        font-size: 16px;
        line-height: 24px; }
        @media (max-width: 576px) {
          .treatment-recommendation .treatment-prescription .treatment-program .treatment-program-description {
            font-size: 14px; } }
        .treatment-recommendation .treatment-prescription .treatment-program .treatment-program-description .treatment-program-title {
          font-weight: 500; }

.text-decoration-underline {
  text-decoration: underline; }

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.green-prescription-card .h2, .green-prescription-card h2 {
  color: white; }

.prescription-program-wrapper {
  margin: 32px 0; }

.grey-prescription-card .saxenda-card-list {
  color: #808080; }

.big-green-text {
  font-size: 24px;
  font-weight: 500;
  color: #DFF39E; }

.refund-text {
  font-size: 12px;
  font-weight: 400;
  text-align: center; }
  @media (min-width: 1200px) {
    .refund-text {
      font-size: 14px; } }

.saxenda-card {
  padding: 24px !important;
  max-width: 768px; }
  .saxenda-card .saxenda-card-price {
    font-size: 24px; }
  .saxenda-card .saxenda-card-grid {
    display: grid;
    grid-template-columns: 4fr minmax(90px, 1fr);
    grid-auto-rows: 1fr;
    border-bottom: 1px solid #8294736B;
    border-right: 1px solid #8294736B;
    -moz-border-radius: 0 16px 16px 16px;
         border-radius: 0 16px 16px 16px;
    margin-bottom: 34px; }
    @media (min-width: 1200px) {
      .saxenda-card .saxenda-card-grid {
        grid-template-columns: 4fr 1fr 1fr; } }
    .saxenda-card .saxenda-card-grid > div {
      border-top: 1px solid #8294736B;
      border-left: 1px solid #8294736B;
      padding: 12px 8px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center; }
      @media (min-width: 1200px) {
        .saxenda-card .saxenda-card-grid > div {
          padding: 12px 16px; } }
      @media (min-width: 1200px) {
        .saxenda-card .saxenda-card-grid > div:nth-child(2) {
          -moz-border-radius: 16px 0 0 0;
               border-radius: 16px 0 0 0; } }
      .saxenda-card .saxenda-card-grid > div:nth-child(3) {
        -moz-border-radius: 0 16px 0 0;
             border-radius: 0 16px 0 0; }
    .saxenda-card .saxenda-card-grid .empty-cell {
      border: none;
      grid-area: 1 / 1 / 1 / 1; }
    .saxenda-card .saxenda-card-grid .double-cell {
      padding: 12px 4px; }
      @media (min-width: 1200px) {
        .saxenda-card .saxenda-card-grid .double-cell {
          grid-column: span 2; } }
    .saxenda-card .saxenda-card-grid .centered-cell {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center; }
    .saxenda-card .saxenda-card-grid .logo-cell {
      grid-column: span 2;
      -moz-border-radius: 16px 16px 0 0;
           border-radius: 16px 16px 0 0; }
      @media (min-width: 1200px) {
        .saxenda-card .saxenda-card-grid .logo-cell {
          grid-column: initial;
          -moz-border-radius: 0 16px 0 0;
               border-radius: 0 16px 0 0; } }
    @media (min-width: 1200px) {
      .saxenda-card .saxenda-card-grid .top-left-rounded {
        -moz-border-radius: 16px 0 0 0;
             border-radius: 16px 0 0 0; } }
    .saxenda-card .saxenda-card-grid .bottom-left-rounded {
      -moz-border-radius: 0 0 0 16px;
           border-radius: 0 0 0 16px; }
    .saxenda-card .saxenda-card-grid .charles-icon {
      width: 80px; }
      .saxenda-card .saxenda-card-grid .charles-icon svg path {
        fill: white; }
  .saxenda-card .saxenda-card-list {
    margin-bottom: 32px; }

.green-card-pill {
  background-color: #4F785F;
  color: white; }
  .green-card-pill .program-reduction-percent {
    color: black;
    background-color: #50E19C; }

.grey-card-pill {
  background-color: white;
  color: black; }
  .grey-card-pill .program-reduction-percent {
    background-color: #B3B3B3; }

.custom-select-dropdown {
  position: relative; }
  .custom-select-dropdown .my-custom-select {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    position: relative;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    padding: 12px 15px;
    border: 1px solid black;
    background: #FFFFFF;
    -moz-border-radius: 16px;
         border-radius: 16px;
    z-index: 20;
    cursor: pointer; }
    .custom-select-dropdown .my-custom-select .caret-icon {
      width: 16px;
      height: 16px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex; }
  .custom-select-dropdown .custom-select-options {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    position: absolute;
    top: -webkit-calc(100% - 16px);
    top: -moz-calc(100% - 16px);
    top: calc(100% - 16px);
    padding-top: 16px;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid black;
    border-top: none;
    -moz-border-radius: 0 0 16px 16px;
         border-radius: 0 0 16px 16px;
    z-index: 10;
    -webkit-transform: scaleY(0);
       -moz-transform: scaleY(0);
         -o-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top center;
       -moz-transform-origin: top center;
         -o-transform-origin: top center;
            transform-origin: top center;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    .custom-select-dropdown .custom-select-options-active {
      -webkit-transform: scaleY(1);
         -moz-transform: scaleY(1);
           -o-transform: scaleY(1);
              transform: scaleY(1); }
    .custom-select-dropdown .custom-select-options .select-option {
      padding: 12px 16px;
      cursor: pointer; }
      .custom-select-dropdown .custom-select-options .select-option:last-of-type {
        -moz-border-radius: 0 0 16px 16px;
             border-radius: 0 0 16px 16px; }
      .custom-select-dropdown .custom-select-options .select-option:hover {
        background: #C4DCFF; }

.digital-therapy-black-separator-doctor-div {
  padding-left: 24px;
  padding-right: 24px;
  background-color: #FAF8F3; }
  .digital-therapy-black-separator-doctor-div .digital-therapy-black-separator-doctor {
    background-color: #000000;
    height: 2px;
    width: 100%;
    padding: 0 10%; }

.program-presentation .program-section .program-section-block .program-section-block-title {
  color: #000000;
  margin-top: 32px;
  font-size: 24px; }
  @media (min-width: 1340px) {
    .program-presentation .program-section .program-section-block .program-section-block-title {
      width: 544px;
      font-size: 40px; } }

.program-presentation .program-section .program-section-title {
  font-size: 24px;
  margin-bottom: 0px; }

.program-presentation .program-section .program-section-text {
  margin-top: 16px;
  font-size: 16px;
  color: #000000; }

.program-presentation .program-section .program-section-content {
  padding: 0px;
  padding-top: 20px; }
  @media (min-width: 1340px) {
    .program-presentation .program-section .program-section-content {
      padding: 20px; } }
  .program-presentation .program-section .program-section-content .coach-details-content {
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
    @media (min-width: 1340px) {
      .program-presentation .program-section .program-section-content .coach-details-content {
        margin-top: 0px;
        width: 900px; } }
  .program-presentation .program-section .program-section-content .featured-doctor {
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center; }
    @media (min-width: 1340px) {
      .program-presentation .program-section .program-section-content .featured-doctor {
        padding-top: 80px; } }
    .program-presentation .program-section .program-section-content .featured-doctor .featured-image {
      width: 320px;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: top;
         object-position: top; }
      @media (min-width: 1340px) {
        .program-presentation .program-section .program-section-content .featured-doctor .featured-image {
          width: 384px; } }
      @media screen and (max-width: 320px) {
        .program-presentation .program-section .program-section-content .featured-doctor .featured-image {
          width: 280px; } }
  .program-presentation .program-section .program-section-content .profile {
    padding: 0px;
    margin-top: 44px;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center; }
    @media (min-width: 1340px) {
      .program-presentation .program-section .program-section-content .profile {
        padding: 16px; } }
    .program-presentation .program-section .program-section-content .profile .doctor-title {
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      width: 100%;
      font-size: 18px; }
    .program-presentation .program-section .program-section-content .profile .doctor-presentation {
      color: #000000;
      font-size: 16px;
      margin-top: 8px;
      width: 323px; }
      @media screen and (max-width: 320px) {
        .program-presentation .program-section .program-section-content .profile .doctor-presentation {
          width: 280px; } }

.program-presentation .program-background-1 {
  background-color: #E5E5E5; }

.program-presentation .program-background-2 {
  background-color: #FAF8F3 !important;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 70px; }

.advantages-bloc {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }
  @media (min-width: 992px) {
    .advantages-bloc {
      padding-bottom: 14px; } }
  .advantages-bloc .testimony-book-div {
    width: 100%;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    max-width: 920px; }
    @media (min-width: 1250px) {
      .advantages-bloc .testimony-book-div {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row; } }
    .advantages-bloc .testimony-book-div .testimony-book {
      display: none;
      height: 298px;
      background-color: white;
      color: #003060;
      font-size: 16px;
      -webkit-align-self: center;
              align-self: center; }
      .advantages-bloc .testimony-book-div .testimony-book:nth-child(3) {
        margin-top: 56px;
        margin-left: 0;
        margin-right: 40px;
        -webkit-align-self: flex-end;
                align-self: flex-end; }
      @media (min-width: 768px) {
        .advantages-bloc .testimony-book-div .testimony-book {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          margin-left: 40px;
          -webkit-align-self: flex-start;
                  align-self: flex-start; } }
      .advantages-bloc .testimony-book-div .testimony-book .testimony-svg-div {
        width: 0; }
        .advantages-bloc .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-svg {
          position: relative; }
        .advantages-bloc .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-flower {
          left: -30px;
          top: -30px; }
          .advantages-bloc .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-flower svg {
            height: 60px; }
        .advantages-bloc .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-target {
          left: -20px;
          top: 240px; }
          .advantages-bloc .testimony-book-div .testimony-book .testimony-svg-div .testimony-desktop-target svg {
            height: 60px; }
      .advantages-bloc .testimony-book-div .testimony-book .name-age {
        font-weight: 600;
        margin-top: 8px; }
      .advantages-bloc .testimony-book-div .testimony-book .left-page {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        width: 286px;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        border: 1px solid #003060;
        padding-left: 30px;
        padding-right: 30px;
        border-right: none; }
        @media (min-width: 992px) {
          .advantages-bloc .testimony-book-div .testimony-book .left-page {
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                    justify-content: space-between;
            padding-top: 16px;
            padding-bottom: 16px; } }
      .advantages-bloc .testimony-book-div .testimony-book .right-page {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        width: 286px;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        border: 1px solid #003060;
        border-left: 0; }
        @media (min-width: 992px) {
          .advantages-bloc .testimony-book-div .testimony-book .right-page {
            padding: 16px 20px 16px 30px;
            -webkit-justify-content: space-between;
               -moz-box-pack: justify;
                    justify-content: space-between; } }
    .advantages-bloc .testimony-book-div .border-div {
      border: 1px solid #003060;
      border-left: 0;
      width: 5px; }
  .advantages-bloc .responsive-testimony-book {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    background-color: #FFFFFF;
    border: 1px solid #003060;
    padding: 16px;
    font-size: 14px;
    margin: 16px; }
    @media (min-width: 768px) {
      .advantages-bloc .responsive-testimony-book {
        display: none; } }
    .advantages-bloc .responsive-testimony-book .responsive-testimony-author {
      margin-top: 16px;
      font-weight: bold; }
  .advantages-bloc .cta-testimony-desktop {
    margin-top: 56px;
    margin-bottom: 44px; }
  .advantages-bloc .secure-by {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #647591;
    padding: 24px 0; }
    .advantages-bloc .secure-by div {
      margin-bottom: 16px; }
  .advantages-bloc .payment-secure-logo {
    padding-left: 8px;
    padding-right: 8px; }
  .advantages-bloc .payment-first-div {
    padding-top: 24px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
    .advantages-bloc .payment-first-div .payement-bullet-div {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      width: 90%;
      max-width: 308px;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center;
      background-color: #FCFCFC;
      color: #1F3A64;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      padding: 12px 16px;
      margin-bottom: 16px;
      -webkit-box-shadow: 0 0 6px #0000001A;
         -moz-box-shadow: 0 0 6px #0000001A;
              box-shadow: 0 0 6px #0000001A;
      -moz-border-radius: 8px;
           border-radius: 8px; }
      @media (min-width: 992px) {
        .advantages-bloc .payment-first-div .payement-bullet-div {
          -webkit-flex-direction: row;
             -moz-box-orient: horizontal;
             -moz-box-direction: normal;
                  flex-direction: row;
          max-width: 100%; }
          .advantages-bloc .payment-first-div .payement-bullet-div div {
            text-align: left;
            margin-left: 1em; } }
      .advantages-bloc .payment-first-div .payement-bullet-div:last-child {
        margin-bottom: 24px; }
      .advantages-bloc .payment-first-div .payement-bullet-div img {
        height: 60px; }
      .advantages-bloc .payment-first-div .payement-bullet-div .payement-vaginisme-argument-icon {
        margin-right: 8px; }
        @media (min-width: 1340px) {
          .advantages-bloc .payment-first-div .payement-bullet-div .payement-vaginisme-argument-icon {
            margin-right: 0; } }
        .advantages-bloc .payment-first-div .payement-bullet-div .payement-vaginisme-argument-icon svg {
          height: 60px; }

.programe-faq {
  padding-left: 24px;
  padding-right: 24px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  padding-bottom: 148px; }
  @media (min-width: 1340px) {
    .programe-faq {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }
  .programe-faq .programe-faq-container {
    width: 100%; }
  .programe-faq .program-faq-title {
    margin-top: 24px;
    margin-bottom: 0;
    font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif; }
  .programe-faq .program-faq-text {
    font-size: 16px;
    margin-bottom: 32px; }
  .programe-faq .program-faq-block {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    padding: 29px 25px;
    width: 100%;
    height: 100%;
    max-width: 384px;
    max-height: 337px; }
    @media (min-width: 1340px) {
      .programe-faq .program-faq-block {
        margin-left: 64px; } }
    .programe-faq .program-faq-block .program-faq-block-title {
      font-size: 1.25rem;
      font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
      margin-bottom: 28px; }
    .programe-faq .program-faq-block .program-faq-block-bullet {
      font-size: 1rem;
      cursor: pointer;
      margin-bottom: 16px; }
      .programe-faq .program-faq-block .program-faq-block-bullet a {
        font-weight: 400;
        font-size: 1rem;
        color: black; }
    .programe-faq .program-faq-block .program-faq-block-svg {
      margin-right: 8px; }
      .programe-faq .program-faq-block .program-faq-block-svg svg {
        width: 100%;
        max-width: 32px; }

.creator-program {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media (min-width: 1340px) {
    .creator-program {
      padding-bottom: 88px; } }
  .creator-program h2 {
    margin-bottom: 16px;
    text-align: center; }
  .creator-program .creator-program-paragraph {
    margin-bottom: 40px; }
  .creator-program .creator-program-caption {
    margin-top: 54px; }
    @media (min-width: 1340px) {
      .creator-program .creator-program-caption {
        margin-top: 0;
        margin-left: 40px; } }

.academy-purchase-bloc-for-patients {
  width: 100%; }
  .academy-purchase-bloc-for-patients .onboarding-payment-div .payment-div-wrapper {
    background: white;
    margin-top: 48px;
    padding: 16px;
    padding-bottom: 0;
    -moz-border-radius: 8px;
         border-radius: 8px; }
  .academy-purchase-bloc-for-patients .onboarding-container-bloc {
    color: #002874; }
  .academy-purchase-bloc-for-patients .coupon-input-bloc {
    color: #002874; }

.quick-payment {
  max-width: 800px;
  margin: auto; }

.academy-landing-page {
  padding-bottom: 0px !important; }

.digital-therapy-main-div {
  background-color: #FFFFFF;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding: 0 0 20px 0;
  color: #000000; }
  .digital-therapy-main-div .program-section-title-descritpion {
    -webkit-align-self: start;
            align-self: start;
    font-weight: bold; }
    .digital-therapy-main-div .program-section-title-descritpion:nth-child(1) {
      padding-top: 34px; }
  .digital-therapy-main-div .hiw-program-black-underline {
    display: block;
    width: 130px;
    -webkit-align-self: center;
            align-self: center;
    position: relative;
    z-index: 1;
    top: -22px;
    margin-left: 102px; }
    @media (min-width: 1340px) {
      .digital-therapy-main-div .hiw-program-black-underline {
        margin-left: 175px;
        width: 182px; } }
    .digital-therapy-main-div .hiw-program-black-underline svg path {
      stroke: #3D7791; }
  .digital-therapy-main-div .digital-therapy-black-separator {
    background-color: #000000;
    height: 2px;
    width: 100%;
    padding: 0 10%;
    margin-bottom: 48px; }
  .digital-therapy-main-div h1 {
    color: white;
    text-align: center;
    font-size: 25px; }
    @media (min-width: 992px) {
      .digital-therapy-main-div h1 {
        font-size: 40px; } }
  .digital-therapy-main-div .digital-therapy-image {
    width: 100vw !important;
    height: 15vh !important; }
    @media (min-width: 992px) {
      .digital-therapy-main-div .digital-therapy-image {
        width: 100vw !important;
        height: 300px !important; } }
  .digital-therapy-main-div .programs-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    margin-top: 48px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    max-width: 900px; }
    .digital-therapy-main-div .programs-wrapper .program-description {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      margin-bottom: 32px;
      padding-top: 32px;
      -webkit-align-self: start;
              align-self: start; }
      @media (min-width: 992px) {
        .digital-therapy-main-div .programs-wrapper .program-description {
          margin-bottom: 56px;
          -webkit-flex-direction: row;
             -moz-box-orient: horizontal;
             -moz-box-direction: normal;
                  flex-direction: row;
          -webkit-align-items: center;
             -moz-box-align: center;
                  align-items: center; } }
      .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper {
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        -webkit-align-self: self-start;
                align-self: self-start;
        padding: 0;
        width: 100%;
        max-width: 498px; }
        @media (min-width: 575px) {
          .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper {
            padding-left: 15vw;
            padding-right: 15vw; } }
        @media (min-width: 992px) {
          .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper {
            padding-left: 0;
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                    flex-direction: column;
            padding-right: 25px; } }
        .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper a {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          font-size: 16px; }
          @media (min-width: 992px) {
            .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper a {
              font-size: 18px; } }
          .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper a .chevron-image {
            -webkit-transform: rotate(180deg);
               -moz-transform: rotate(180deg);
                 -o-transform: rotate(180deg);
                    transform: rotate(180deg);
            -webkit-transition: margin-left 0.5s;
            -o-transition: margin-left 0.5s;
            -moz-transition: margin-left 0.5s;
            transition: margin-left 0.5s; }
            .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper a .chevron-image svg {
              width: 28px;
              height: 20px;
              margin-top: -4px;
              padding-right: 10px; }
          .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper a:hover {
            text-decoration: none; }
            .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper a:hover .chevron-image {
              margin-left: 10px; }
        .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .description {
          font-size: 14px;
          font-weight: bold;
          color: #002865;
          margin-bottom: 16px; }
          @media (min-width: 992px) {
            .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .description {
              font-size: 20px;
              font-weight: 500; } }
        .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .explaination {
          margin-top: 16px !important; }
          @media (min-width: 992px) {
            .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .explaination {
              margin-top: 0;
              margin-bottom: 32px; } }
          @media screen and (max-width: 320px) {
            .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .explaination {
              width: 280px; } }
        .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .program-presentation-link {
          margin-top: 32px; }
        .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .program-title {
          font-size: 20px;
          margin-bottom: 0px !important;
          font-weight: 500; }
        .digital-therapy-main-div .programs-wrapper .program-description .text-wrapper .program-presentation-description {
          font-size: 18px;
          padding-top: 16px;
          font-weight: 500; }
    .digital-therapy-main-div .programs-wrapper .program-section-title-underline {
      width: 183px;
      margin-top: -14px; }
      @media (min-width: 992px) {
        .digital-therapy-main-div .programs-wrapper .program-section-title-underline {
          width: 660px;
          padding-left: 384px;
          margin-top: -19px; } }
      @media (min-width: 320) {
        .digital-therapy-main-div .programs-wrapper .program-section-title-underline {
          width: 261px;
          padding-left: 57px;
          margin-top: -14px; } }
  @media screen and (min-width: 320) and (max-width: 320px) {
    .digital-therapy-main-div .programs-wrapper .program-section-title-underline {
      padding-left: 56px;
      width: 259px;
      margin-top: -14px; } }
    .digital-therapy-main-div .programs-wrapper .program-section-title-underline-2 {
      width: 131px;
      margin-top: -14px; }
      @media (min-width: 992px) {
        .digital-therapy-main-div .programs-wrapper .program-section-title-underline-2 {
          width: 565px;
          padding-left: 324px;
          margin-top: -19px; } }
      @media screen and (max-width: 320px) {
        .digital-therapy-main-div .programs-wrapper .program-section-title-underline-2 {
          padding-left: -11px;
          width: 187px;
          margin-top: -14px; } }
    .digital-therapy-main-div .programs-wrapper .video-container {
      border: 2px solid #000000;
      -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
         -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
              box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
      -moz-border-radius: 16px;
           border-radius: 16px;
      overflow: hidden;
      -webkit-flex: 2 1;
         -moz-box-flex: 2;
              flex: 2 1;
      margin-top: 24px;
      width: 327px; }
      @media screen and (max-width: 320px) {
        .digital-therapy-main-div .programs-wrapper .video-container {
          width: 280px; } }
      @media (min-width: 992px) {
        .digital-therapy-main-div .programs-wrapper .video-container {
          -webkit-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
          width: 522px; }
          .digital-therapy-main-div .programs-wrapper .video-container:hover {
            -webkit-transform: scale(1.02);
               -moz-transform: scale(1.02);
                 -o-transform: scale(1.02);
                    transform: scale(1.02); } }

.program-presentation .onboarding-charles-advantage-main-item {
  margin-bottom: .5rem;
  margin-top: .5rem; }

.step-academy-payment-variants {
  display: block;
  width: 100%; }
  .step-academy-payment-variants .program-payment-variant {
    background-color: transparent;
    background-color: initial;
    width: 100%; }
    .step-academy-payment-variants .program-payment-variant .program-payment-variant-title {
      font-weight: 700;
      font-size: 18px; }
    .step-academy-payment-variants .program-payment-variant .program-payment-variant-content {
      font-size: 14px; }
    .step-academy-payment-variants .program-payment-variant .program-payment-variant-due-date {
      font-weight: bold;
      margin-right: 4px; }

.price-div {
  margin-bottom: 16px;
  margin-top: -16px; }
  .price-div .time-variant-economy {
    width: 159px;
    height: 32px;
    border: #00E094 1px solid;
    color: #00E094;
    font-size: 14px;
    font-weight: bold;
    -moz-border-radius: 4px;
         border-radius: 4px;
    position: relative;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    top: -16px;
    right: -44px;
    background-color: white;
    text-transform: uppercase; }
    .price-div .time-variant-economy.visibility-hidden {
      visibility: hidden; }

.time-variant-choice {
  -webkit-box-shadow: 0 3px 6px #0000000D;
     -moz-box-shadow: 0 3px 6px #0000000D;
          box-shadow: 0 3px 6px #0000000D;
  border: 1px solid #D9D9D9;
  -moz-border-radius: 8px;
       border-radius: 8px;
  padding: 16px;
  max-width: 250px;
  margin-bottom: 32px; }
  @media (min-width: 992px) {
    .time-variant-choice {
      margin-bottom: 0; } }
  .time-variant-choice--svg svg {
    height: 140px; }
  .time-variant-choice--title {
    font-size: 18px;
    font-weight: bold;
    color: #1F3A64;
    margin-top: 16px;
    margin-bottom: 8px; }
  .time-variant-choice--price {
    font-size: 20px;
    font-weight: bold;
    color: #1F3A64; }
  .time-variant-choice--comparative-full-price {
    margin-top: 4px;
    font-size: 12px;
    font-weight: normal;
    margin-left: 4px; }
  .time-variant-choice--bullets {
    font-size: 16px; }
    .time-variant-choice--bullets > div {
      margin-bottom: 8px; }
      .time-variant-choice--bullets > div div {
        margin-left: 4px; }
  .time-variant-choice--button {
    font-size: 16px;
    padding: 6px 32px; }

.phone-charles-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

@media (min-width: 768px) {
  .phone-charles-block {
    margin-top: 10px;
    margin-bottom: 10px; } }

.phone-charles-block p.phone-charles-block-inner {
  -moz-border-radius: 3px;
       border-radius: 3px;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  background: #6389FF;
  color: #fff;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: .7em;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  padding: .2em;
  position: inherit;
  margin-top: 1rem;
  margin-bottom: 0; }
  .phone-charles-block p.phone-charles-block-inner .icon {
    display: inline-block;
    width: 14px; }
    @media (min-width: 768px) {
      .phone-charles-block p.phone-charles-block-inner .icon {
        width: 16px; } }
  .phone-charles-block p.phone-charles-block-inner .phone-icon {
    margin-right: 5px; }
  .phone-charles-block p.phone-charles-block-inner a {
    color: #fff;
    text-decoration: none; }
  @media (min-width: 768px) {
    .phone-charles-block p.phone-charles-block-inner {
      font-size: .9em;
      padding: 0 1em; } }

.step-academy-payment-submenu {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 5px; }
  @media (min-width: 768px) {
    .step-academy-payment-submenu {
      grid-gap: 10px; } }
  .step-academy-payment-submenu .step-academy-payment-submenu-block {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    border: 1px solid #D4D4D4;
    -moz-border-radius: 4px;
         border-radius: 4px;
    padding: 5px 8px;
    cursor: pointer; }
    @media (min-width: 768px) {
      .step-academy-payment-submenu .step-academy-payment-submenu-block {
        padding: 16px; } }
    .step-academy-payment-submenu .step-academy-payment-submenu-block.selected {
      background-color: #F8FAFF;
      border: 1px solid #000000; }
    .step-academy-payment-submenu .step-academy-payment-submenu-block .step-academy-payment-submenu-title {
      -webkit-align-self: center;
              align-self: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px; }
      .step-academy-payment-submenu .step-academy-payment-submenu-block .step-academy-payment-submenu-title .step-academy-payment-submenu-price {
        font-weight: normal; }
    .step-academy-payment-submenu .step-academy-payment-submenu-block .step-academy-payment-submenu-right {
      display: -webkit-flex;
      display: -moz-box;
      display: flex; }
      .step-academy-payment-submenu .step-academy-payment-submenu-block .step-academy-payment-submenu-right .step-academy-payment-submenu-radio-input {
        -webkit-align-self: center;
                align-self: center;
        -webkit-transform: scale(1.5);
           -moz-transform: scale(1.5);
             -o-transform: scale(1.5);
                transform: scale(1.5); }
  .step-academy-payment-submenu .step-academy-payment-submenu-block-mia.selected {
    background-color: white; }

.academy-payment-container {
  margin: auto;
  max-width: 600px;
  text-align: left; }

label.no-color-on-errors {
  color: #000 !important; }

.academy-thankyou-image svg {
  width: 100px; }

.assistance .assistance-text {
  line-height: 2em; }

@media (min-width: 756px) {
  .assistance .assistance-shifted-content {
    margin-left: 4em; } }

.assistance .assistance-shifted-content .support-phone-number {
  color: #012765; }

@media (max-width: 756px) {
  .assistance .shifted-button {
    text-align: center; } }

.assistance .assistance-svg svg {
  width: 2.5em; }

.assistance .assistance-input {
  height: 10em;
  margin: 0 !important;
  margin: initial !important;
  border-color: #012765; }

.highlighted-row {
  font-weight: 500;
  background-color: #f1f3f5; }

.order-complete-container h4 {
  margin-bottom: 32px; }

.order-complete-container .order-complete-main-text {
  margin-bottom: 32px;
  text-align: left; }

.order-complete-container .order-complete-svg svg {
  width: 80px;
  margin-bottom: 32px; }

.order-complete-container .order-complete-link-wrapper {
  margin-bottom: 24px; }
  .order-complete-container .order-complete-link-wrapper a:first-of-type {
    margin-right: 32px; }

.infos-container {
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
          flex: 1 1;
  max-width: 100%; }
  @media (min-width: 768px) {
    .infos-container .section-arguments {
      max-width: 400px;
      margin-left: 20px; } }

.error-tag {
  color: #AC0E1D;
  padding-left: 36px; }

.pharmacy-choice .onboarding-charles-advantages {
  padding: 0; }

.pharmacy-choice .notice-block {
  font-size: 18px;
  margin-bottom: 16px;
  text-align: left;
  text-decoration: underline; }
  @media (max-width: 767px) {
    .pharmacy-choice .notice-block {
      padding-left: 5px;
      margin-top: 15px;
      margin-bottom: 40px;
      text-decoration: none; } }
  @media (min-width: 768px) {
    .pharmacy-choice .notice-block {
      margin-bottom: 56px; } }
  @media (min-width: 992px) {
    .pharmacy-choice .notice-block {
      margin-bottom: 16px; } }
  .pharmacy-choice .notice-block .warning-icon svg {
    width: 20px; }
    @media (min-width: 768px) {
      .pharmacy-choice .notice-block .warning-icon svg {
        width: 22px;
        padding-bottom: 4px; } }

.clinic-medical-container .clinic-medical-row {
  text-align: left;
  vertical-align: text-top;
  border-bottom: 1px solid #f1f3f5;
  padding-bottom: 20px; }
  .clinic-medical-container .clinic-medical-row svg {
    width: 20px;
    height: 20px; }
  .clinic-medical-container .clinic-medical-row .doctor-section .doctor-infos-block {
    display: grid;
    grid-template-columns: 100px auto; }
    .clinic-medical-container .clinic-medical-row .doctor-section .doctor-infos-block .doctor-full-name {
      margin-bottom: 1em; }
    .clinic-medical-container .clinic-medical-row .doctor-section .doctor-infos-block img {
      border: 2px solid #000; }
  .clinic-medical-container .clinic-medical-row .context-link {
    max-width: 210px;
    text-decoration: underline; }
    @media (max-width: 576px) {
      .clinic-medical-container .clinic-medical-row .context-link {
        text-align: center; } }
    .clinic-medical-container .clinic-medical-row .context-link a {
      max-width: 210px;
      font-weight: normal; }
  .clinic-medical-container .clinic-medical-row .date-section {
    max-width: 210px;
    font-size: 14px; }
    @media (max-width: 576px) {
      .clinic-medical-container .clinic-medical-row .date-section {
        text-align: center; } }
    .clinic-medical-container .clinic-medical-row .date-section .range {
      font-weight: 500; }

.page .prescription-purchase-container {
  max-width: 600px; }

.page .home-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between; }
  @media (min-width: 1340px) {
    .page .home-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; }
      .page .home-container > div + div {
        margin-left: 3em; } }
  .page .home-container .container-content {
    text-align: center; }
    .page .home-container .container-content .consultation-countdown {
      color: #6389FF;
      font-size: 1.2em;
      font-weight: 500;
      margin: 0; }
    .page .home-container .container-content .consultation-notification {
      font-weight: 500; }
  .page .home-container .button-container {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center; }

.medical-info .medical-info-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between; }
  @media (min-width: 992px) {
    .medical-info .medical-info-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }

.consultations-history,
.prescriptions-history {
  border-bottom: 1px solid #dee2e6;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }
  .consultations-history > *,
  .prescriptions-history > * {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between;
    margin: 10px;
    width: 100%; }
    .consultations-history > * > div,
    .prescriptions-history > * > div {
      width: 50%; }
  .consultations-history .item,
  .prescriptions-history .item {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    text-align: center; }
  .consultations-history .item-cta,
  .prescriptions-history .item-cta {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
  .consultations-history label,
  .prescriptions-history label {
    color: #a4a4a4;
    font-size: .8em;
    font-weight: 500;
    text-transform: uppercase; }
  .consultations-history p,
  .prescriptions-history p {
    font-size: .8em;
    font-weight: 500; }
  .consultations-history a,
  .prescriptions-history a {
    cursor: pointer;
    margin: auto;
    text-decoration: underline; }

.new-consultation .select-container,
.pharmacy .select-container,
.page .select-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-bottom: 30px; }

.new-consultation .select-item,
.pharmacy .select-item,
.page .select-item {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  margin-bottom: 15px; }
  @media (min-width: 576px) {
    .new-consultation .select-item,
    .pharmacy .select-item,
    .page .select-item {
      margin-bottom: 0; } }

.new-consultation .icon,
.pharmacy .icon,
.page .icon {
  display: inline-block;
  height: 26px;
  margin-right: 10px;
  width: 26px; }
  .new-consultation .icon.reverse,
  .pharmacy .icon.reverse,
  .page .icon.reverse {
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg); }

.home .container-title .icon svg g {
  fill: #fff; }

.home .health-professional-info-container .right-block .button {
  margin: .5px; }

.new-consultation .prev {
  margin-top: 40px; }

.new-consultation .consultations-block,
.new-consultation .media-block {
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -moz-border-radius: 3px;
       border-radius: 3px; }
  .new-consultation .consultations-block .block-title,
  .new-consultation .media-block .block-title {
    color: #339af0;
    text-align: left;
    width: 100%; }
  .new-consultation .consultations-block .infos-consultation,
  .new-consultation .media-block .infos-consultation {
    margin-top: 45px;
    width: 100%; }
  .new-consultation .consultations-block .info-item,
  .new-consultation .media-block .info-item {
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    border-bottom: 1px solid rgba(51, 154, 240, 0.2);
    cursor: pointer;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    padding: 15px 0; }
    .new-consultation .consultations-block .info-item label,
    .new-consultation .media-block .info-item label {
      cursor: pointer;
      font-weight: 450;
      position: relative;
      text-align: left;
      width: 100%; }
      .new-consultation .consultations-block .info-item label span,
      .new-consultation .media-block .info-item label span {
        background-color: #339af0;
        -moz-border-radius: 50px;
             border-radius: 50px;
        color: #fff;
        height: 16px;
        padding: 2px 5px 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 5px;
        width: 16px; }
    .new-consultation .consultations-block .info-item p,
    .new-consultation .media-block .info-item p {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      font-size: 15px;
      height: 0;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center;
      margin: 0;
      min-height: 0;
      overflow: hidden;
      -webkit-transition: min-height, height, .5s;
      -o-transition: min-height, height, .5s;
      -moz-transition: min-height, height, .5s;
      transition: min-height, height, .5s; }
    .new-consultation .consultations-block .info-item.active p,
    .new-consultation .media-block .info-item.active p {
      height: auto;
      margin: 1em 0;
      min-height: 40px; }
    .new-consultation .consultations-block .info-item:last-child,
    .new-consultation .media-block .info-item:last-child {
      border-bottom: 0; }

.new-consultation .media-block,
.new-consultation .name-info-block {
  width: 100%; }

.new-consultation .confirmation .container-content {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }
  .new-consultation .confirmation .container-content > * {
    text-align: center;
    width: 100%; }
  .new-consultation .confirmation .container-content .img-container {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
  @media (min-width: 992px) {
    .new-consultation .confirmation .container-content {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; }
      .new-consultation .confirmation .container-content > * {
        width: 50%; } }

@media (min-width: 768px) {
  .new-consultation .confirmation .container-content {
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column; }
    .new-consultation .confirmation .container-content > * {
      width: auto; } }

.new-consultation .confirmation .container-content .doctor-image {
  -moz-border-radius: .5em;
       border-radius: .5em;
  height: 3.5em;
  margin: 1em 0 .5em;
  width: 3.5em; }
  @media (min-width: 576px) {
    .new-consultation .confirmation .container-content .doctor-image {
      height: 3.125em;
      margin: 0 0 .5em;
      width: 3.125em; } }

.pharmacy .links-container {
  margin: 0px;
  text-align: center; }
  .pharmacy .links-container .submit-delivery {
    margin-top: 12px;
    font-size: 18px;
    width: 100%; }
  .pharmacy .links-container .button {
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    border: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    margin: 0 auto 1em; }

.pharmacist {
  margin-bottom: 1em; }
  .pharmacist.pharmacist-infos {
    text-align: left;
    padding-right: 60px;
    border-right: 1px solid #d5d5dd; }
    @media (max-width: 992px) {
      .pharmacist.pharmacist-infos {
        padding-bottom: 40px;
        padding-right: 0px;
        border-right: none; }
        .pharmacist.pharmacist-infos.pharmacist-infos-custom {
          border-bottom: 2px solid #000; } }
  .pharmacist .prescription-history-row {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 24px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
            justify-content: space-between; }
    @media (min-width: 768px) {
      .pharmacist .prescription-history-row {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row; } }
    .pharmacist .prescription-history-row:last-of-type {
      border-bottom: none; }
    .pharmacist .prescription-history-row a {
      text-decoration: underline;
      cursor: pointer; }
    .pharmacist .prescription-history-row .doctor-profile-specialty-name {
      font-weight: 500;
      font-size: 16px; }
    .pharmacist .prescription-history-row .doctor-profile-specialty-prescription {
      font-weight: 400;
      font-size: 14px; }
    .pharmacist .prescription-history-row .prescription-availability-div {
      font-size: 14px; }
      @media (min-width: 992px) {
        .pharmacist .prescription-history-row .prescription-availability-div {
          max-width: 200px;
          width: 100%; } }
      .pharmacist .prescription-history-row .prescription-availability-div .prescription-availability-date {
        font-weight: 500; }
    .pharmacist .prescription-history-row .show-prescription-button {
      margin-bottom: 32px; }
      @media (min-width: 992px) {
        .pharmacist .prescription-history-row .show-prescription-button {
          margin-bottom: 0;
          max-width: 220px;
          width: 100%; } }
    .pharmacist .prescription-history-row .prescription-delivery-wrapper {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      width: 100%;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; }
      @media (min-width: 992px) {
        .pharmacist .prescription-history-row .prescription-delivery-wrapper {
          width: auto;
          width: initial;
          -webkit-justify-content: initial;
             -moz-box-pack: initial;
                  justify-content: initial; } }
      .pharmacist .prescription-history-row .prescription-delivery-wrapper .prescription-delivery-button {
        width: 258px;
        text-decoration: none; }
        @media (min-width: 992px) {
          .pharmacist .prescription-history-row .prescription-delivery-wrapper .prescription-delivery-button {
            width: auto;
            width: initial; } }
    .pharmacist .prescription-history-row .prescription-delivery-button-placeholder {
      visibility: hidden;
      pointer-events: none; }

.consultation-history-title {
  margin-top: 48px;
  margin-bottom: 16px; }

.prescription-history-row-icon {
  margin-right: 8px; }
  .prescription-history-row-icon svg {
    width: 20px;
    height: 20px; }

.doctor-profile-div {
  min-width: 210px; }
  @media (min-width: 992px) {
    .doctor-profile-div {
      max-width: 222px;
      width: 100%; } }

.doctor-profile-picture-prescription {
  width: 60px;
  height: 60px;
  border: 1px solid black;
  -moz-border-radius: 12px;
       border-radius: 12px;
  margin-right: 8px; }

.consultation-calendar-picture {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  margin-right: 8px; }
  .consultation-calendar-picture svg {
    width: 16px;
    height: 16px; }

.consultation-calendar-text {
  font-size: 14px; }

.consultation-calendar-date {
  font-weight: 500; }

@media (min-width: 992px) {
  .consultation-history-link {
    min-width: 200px; } }

.consultations-history-row {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 32px; }
  @media (min-width: 992px) {
    .consultations-history-row {
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center; } }
  .consultations-history-row:first-of-type {
    padding-top: 0; }
  .consultations-history-row:last-of-type {
    border: none; }

.consultation-advise-card {
  font-size: 16px;
  text-decoration: underline; }
  .consultation-advise-card a {
    font-weight: 400; }

.consultation-status-label {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  width: 160px;
  -moz-border-radius: 16px;
       border-radius: 16px;
  border: 2px solid #000000;
  height: 35px;
  font-weight: 700;
  font-size: 16px; }

.started-status-label {
  background-color: #50E19C;
  color: white;
  border: none; }

.finished-status-label {
  opacity: 50%; }

.existing-cards {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start; }
  .existing-cards .credit-card {
    -webkit-flex: 0 0 23.5%;
       -moz-box-flex: 0;
            flex: 0 0 23.5%; }

.download-prescription .container-content {
  text-align: center; }
  .download-prescription .container-content p {
    color: #a4a4a4;
    font-size: 16px;
    font-weight: normal;
    margin: 15px auto;
    max-width: 300px; }

.download-prescription h3 {
  font-size: 20px; }

.download-prescription .health-professional-info-container {
  margin: 1em 0; }
  .download-prescription .health-professional-info-container img {
    -moz-border-radius: 2px solid #000;
         border-radius: 2px solid #000; }
  .download-prescription .health-professional-info-container .right-block {
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column; }
    .download-prescription .health-professional-info-container .right-block h4 {
      color: #3f3f3f;
      font-size: 13px; }
    .download-prescription .health-professional-info-container .right-block p {
      color: #a4a4a4;
      font-size: 16px;
      font-weight: normal;
      text-align: right;
      text-decoration: underline; }

.treatments-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px; }

.option-block {
  margin-bottom: 32px; }

.weight-treatment ul.exit-bullets {
  list-style: inherit;
  margin-bottom: 40px;
  padding-left: 0; }
  .weight-treatment ul.exit-bullets li {
    padding-top: 6px;
    list-style: none; }
  .weight-treatment ul.exit-bullets .exit-bullets-icon {
    margin-top: 8px; }
    @media (min-width: 768px) {
      .weight-treatment ul.exit-bullets .exit-bullets-icon {
        margin-top: 0; } }
    .weight-treatment ul.exit-bullets .exit-bullets-icon svg {
      width: 24px;
      margin-right: 8px; }

.weight-treatment-subtitle {
  background-color: #E8F6E8;
  border-left: 4px solid #388E3C;
  width: 100%;
  padding: 12px 0 12px 12px; }
  @media (min-width: 992px) {
    .weight-treatment-subtitle {
      padding-left: 32px; } }

ul.exit-bullets {
  list-style: inherit;
  margin-bottom: 40px;
  padding-left: 0; }
  @media (min-width: 768px) {
    ul.exit-bullets {
      padding-left: 40px; } }
  ul.exit-bullets li {
    padding-top: 6px;
    list-style: none; }
  ul.exit-bullets .exit-bullets-icon {
    margin-top: 8px; }
    @media (min-width: 768px) {
      ul.exit-bullets .exit-bullets-icon {
        margin-top: 0; } }
    ul.exit-bullets .exit-bullets-icon svg {
      width: 24px;
      margin-right: 8px; }

@media (min-width: 768px) {
  .check-presc-container .thumbnail-container {
    padding-left: 200px;
    text-align: right; } }

.check-presc-container .thumbnail-container .presc-thumbnail {
  -moz-border-radius: 16px;
       border-radius: 16px;
  border: 2px solid #000;
  width: 135px;
  height: 191px; }

.check-presc-container .details-container .details-wording {
  font-weight: 500;
  max-width: 340px;
  margin-bottom: 20px; }

.booking-payment-confirmed-icon {
  display: block;
  margin-bottom: 1.1875rem; }
  .booking-payment-confirmed-icon svg {
    height: 56px; }

.step-back {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  margin-bottom: 16px;
  cursor: pointer; }
  .step-back .step-back-icon svg {
    height: 16px;
    margin-right: 8px; }

.booking-payment-yellow-highlight {
  position: relative;
  display: inline-block; }
  .booking-payment-yellow-highlight::before {
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='287' height='12' viewBox='0 0 287 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8.05696C20.9349 6.461 40.0418 5.6498 59.063 4.79696C87.3406 3.5291 115.637 2.45949 143.974 2.14147C179.335 1.74461 214.875 2.07253 250.081 4.55948C261.502 5.36626 272.817 6.68062 284.207 7.58199C284.459 7.60196 285.192 7.53338 284.953 7.47404C283.69 7.16067 281.993 7.13887 280.726 7.02067C264.808 5.53535 249.214 5.12621 233.111 5.03445C191.47 4.79715 149.715 4.60371 108.107 5.98438C85.4718 6.7355 62.9732 8.67184 40.3528 9.52503C39.2277 9.56747 35.6134 9.15753 35.007 10' stroke='%23E1C341' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    -webkit-background-size: contain;
       -moz-background-size: contain;
            background-size: contain;
    background-repeat: no-repeat;
    top: 1.2em;
    bottom: -2em;
    width: 105%; }

.lucas-change-onboarding-container-main .confirmation-text {
  margin-bottom: 2rem; }

.lucas-change-onboarding-container-main .open-consultation .block-content {
  padding-bottom: 1rem; }
  @media (max-width: 576px) {
    .lucas-change-onboarding-container-main .open-consultation .block-content {
      padding-bottom: 1.42rem; } }

.lucas-change-onboarding-container-main .return-home-button {
  display: none;
  padding: 1rem 1.125rem;
  margin-top: 2rem;
  font-size: 0.9rem; }
  @media (max-width: 576px) {
    .lucas-change-onboarding-container-main .return-home-button {
      display: block !important; } }

.specialty-categories-picker .specialty-categories-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-column-gap: .5rem;
  -webkit-column-gap: .5rem;
     -moz-column-gap: .5rem;
          column-gap: .5rem; }
  @media (min-width: 768px) {
    .specialty-categories-picker .specialty-categories-container {
      grid-column-gap: 1rem;
      -webkit-column-gap: 1rem;
         -moz-column-gap: 1rem;
              column-gap: 1rem; } }
  .specialty-categories-picker .specialty-categories-container .specialty-categories-item-separator {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    display: none; }
    @media (max-width: 768px) {
      .specialty-categories-picker .specialty-categories-container .specialty-categories-item-separator:nth-child(4n) {
        -webkit-flex: 0 0 100%;
           -moz-box-flex: 0;
                flex: 0 0 100%;
        display: block; } }
  .specialty-categories-picker .specialty-categories-container .specialty-categories-item-selector {
    width: 9.3rem;
    height: 9rem;
    background-color: white;
    border: 3px solid black;
    -moz-border-radius: 1.25rem;
         border-radius: 1.25rem;
    -webkit-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    margin-bottom: .5rem; }
    @media (min-width: 1340px) {
      .specialty-categories-picker .specialty-categories-container .specialty-categories-item-selector {
        width: 11rem;
        height: 10.5rem; } }
    @media (min-width: 768px) {
      .specialty-categories-picker .specialty-categories-container .specialty-categories-item-selector {
        margin-bottom: 2rem; } }
    .specialty-categories-picker .specialty-categories-container .specialty-categories-item-selector:hover {
      -webkit-box-shadow: none;
         -moz-box-shadow: none;
              box-shadow: none; }
    @media (min-width: 768px) {
      .specialty-categories-picker .specialty-categories-container .specialty-categories-item-selector {
        -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
           -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
                flex: 0 0 auto; } }
  .specialty-categories-picker .specialty-categories-container .specialty-category-name-chevron {
    width: 6px;
    height: 6px;
    margin-left: 5px;
    margin-top: 2px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg); }

.specialty-categories-picker .specialty-category-icon {
  display: block;
  margin-bottom: 1rem; }
  .specialty-categories-picker .specialty-category-icon svg {
    width: 40px;
    height: 40px; }
    @media (min-width: 1340px) {
      .specialty-categories-picker .specialty-category-icon svg {
        width: 60px;
        height: 60px; } }

.specialty-picker .specialty-category-icon {
  display: block; }
  .specialty-picker .specialty-category-icon svg {
    width: 48px;
    height: 48px; }

.specialty-picker .teleconsultation-doctor-image-img {
  height: 69px !important;
  width: 69px !important;
  -moz-border-radius: 12px !important;
       border-radius: 12px !important; }
  @media (max-width: 576px) {
    .specialty-picker .teleconsultation-doctor-image-img {
      height: 112px !important;
      width: 112px !important; } }

.specialty-picker .block-content {
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 1rem 2rem 1.125rem;
  width: 667px; }
  @media (max-width: 576px) {
    .specialty-picker .block-content {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      padding-bottom: 3.25rem;
      width: 314px; } }

.specialty-picker .doctor-name {
  font-size: 1rem;
  line-height: 19px; }
  @media (max-width: 576px) {
    .specialty-picker .doctor-name {
      font-size: 1.125rem;
      line-height: 24px; } }

.specialty-picker .doctor-specialty {
  font-size: 0.875rem;
  line-height: 14px; }
  @media (max-width: 576px) {
    .specialty-picker .doctor-specialty {
      font-size: 1rem;
      line-height: 24px; } }

.specialty-picker .doctor-button {
  padding: 1rem 1.125rem; }

.specialty-picker .specialty-breaker {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 2px solid gray;
  margin: auto;
  width: 678px; }
  @media (max-width: 576px) {
    .specialty-picker .specialty-breaker {
      max-width: 100%; } }

.specialty-picker .home-text {
  max-width: 680px;
  margin: 0 auto 40px; }

.specialty-picker .see-more-button {
  border: 2px solid gray;
  padding: 1rem 1.5rem;
  font-weight: 600;
  -moz-border-radius: 1.25rem;
       border-radius: 1.25rem;
  min-width: 100px; }
  .specialty-picker .see-more-button svg {
    width: 16px;
    height: 16px; }
    @media (min-width: 1340px) {
      .specialty-picker .see-more-button svg {
        width: 16px;
        height: 16px; } }

.specialty-picker .specialties-list {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
     -moz-box-align: stretch;
          align-items: stretch; }
  @media (min-width: 768px) {
    .specialty-picker .specialties-list {
      grid-column-gap: 2rem;
      -webkit-column-gap: 2rem;
         -moz-column-gap: 2rem;
              column-gap: 2rem; } }
  .specialty-picker .specialties-list .specialties-list-item {
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
            flex: 0 0 100%;
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .specialty-picker .specialties-list .specialties-list-item {
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
                flex: 0 0 auto;
        margin-bottom: 1.5rem; } }
    .specialty-picker .specialties-list .specialties-list-item .specialties-list-item-button {
      width: 100%; }
      @media (min-width: 768px) {
        .specialty-picker .specialties-list .specialties-list-item .specialties-list-item-button {
          width: 280px; } }
  .specialty-picker .specialties-list .specialties-list-item-separator {
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
            flex: 0 0 auto;
    display: none; }
    @media (min-width: 768px) {
      .specialty-picker .specialties-list .specialties-list-item-separator:nth-child(4n) {
        -webkit-flex: 0 0 100%;
           -moz-box-flex: 0;
                flex: 0 0 100%;
        display: block; } }

.section-title-presentation-consult {
  font-size: 1.4rem;
  color: black; }
  @media (min-width: 992px) {
    .section-title-presentation-consult {
      font-size: 2rem;
      line-height: 40px; } }

.start-text-general {
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 1rem;
  -webkit-align-self: center;
          align-self: center;
  max-width: 700px; }

.container-family-last-step {
  padding: 0 24px;
  width: auto; }

.doctor-message-div .doctor-talks {
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  max-width: 490px;
  padding: 24px; }

.doctor-message-div .doctor-round-face {
  -moz-border-radius: 50%;
       border-radius: 50%;
  border: 2px solid black;
  height: 56px;
  width: 56px;
  z-index: 1; }

.doctor-message-div .doctor-talks {
  background: white; }

.download-prescription .thank-you-container {
  text-align: center;
  padding: 5px 15px; }
  .download-prescription .thank-you-container .prescription-icon svg {
    width: 80px;
    height: 80px; }

.download-prescription .accept-cgu-label {
  font-size: 1.2em;
  color: #a4a4a4; }
  .download-prescription .accept-cgu-label a {
    color: #a4a4a4; }

.dowload-prescription-container .download-prescription-block .block-content {
  padding-left: 20px;
  padding-right: 20px; }

.dowload-prescription-container .download-prescription-block .recommendation-title {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: black 1px solid; }
  @media (min-width: 992px) {
    .dowload-prescription-container .download-prescription-block .recommendation-title {
      font-size: 2rem;
      margin-top: 24px;
      padding-bottom: 16px; } }

.dowload-prescription-container .download-prescription-block .download-prescription-links {
  font-size: 12px;
  color: #000000;
  text-decoration: underline;
  word-break: break-word; }
  .dowload-prescription-container .download-prescription-block .download-prescription-links u {
    font-weight: 300; }

.weight-drugs-support .weight-drugs-support-card {
  margin-bottom: 60px; }

.weight-drugs-support .weight-drugs-support-title {
  margin-bottom: 32px; }
  @media (min-width: 1200px) {
    .weight-drugs-support .weight-drugs-support-title {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      margin-top: 32px; } }
  @media (min-width: 1200px) {
    .weight-drugs-support .weight-drugs-support-title .weight-drugs-support-subtitle {
      margin-top: 4px;
      margin-left: 8px; } }

.weight-drugs-support .weight-drugs-support-arguments {
  margin-bottom: 32px; }
  .weight-drugs-support .weight-drugs-support-arguments > div {
    margin-bottom: 16px; }
    @media (min-width: 1200px) {
      .weight-drugs-support .weight-drugs-support-arguments > div {
        margin-top: 24px; } }
  .weight-drugs-support .weight-drugs-support-arguments .icon {
    margin-right: 8px; }
    .weight-drugs-support .weight-drugs-support-arguments .icon svg {
      width: 16px;
      height: 16px; }

.weight-drugs-support .weight-drugs-support-image {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -24px; }
  @media (min-width: 1200px) {
    .weight-drugs-support .weight-drugs-support-image {
      margin-left: 24px;
      margin-right: 32px; } }

.weight-drugs-support label {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.weight-drugs-support .input-radio-label {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-family: 'MaisonNeue', sans-serif;
  font-size: 20px; }
  @media (min-width: 1200px) {
    .weight-drugs-support .input-radio-label {
      margin-bottom: 24px; } }
  .weight-drugs-support .input-radio-label input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background-color: var(--form-background);
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 2px solid currentColor;
    -moz-border-radius: 50%;
         border-radius: 50%;
    display: grid;
    place-content: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-right: 8px;
    margin-top: 4px; }
  .weight-drugs-support .input-radio-label input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;
    -moz-border-radius: 50%;
         border-radius: 50%;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 120ms -webkit-transform ease-in-out;
    transition: 120ms -webkit-transform ease-in-out;
    -o-transition: 120ms -o-transform ease-in-out;
    -moz-transition: 120ms transform ease-in-out, 120ms -moz-transform ease-in-out;
    transition: 120ms transform ease-in-out;
    transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out, 120ms -moz-transform ease-in-out, 120ms -o-transform ease-in-out;
    -webkit-box-shadow: inset 1em 1em var(--form-control-color);
       -moz-box-shadow: inset 1em 1em var(--form-control-color);
            box-shadow: inset 1em 1em var(--form-control-color);
    background-color: currentColor; }
  .weight-drugs-support .input-radio-label input[type="radio"]:checked::before {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1); }
  .weight-drugs-support .input-radio-label input[type="radio"]:focus {
    outline-offset: 2px; }

.weight-drugs-support .input-checkbox-label {
  display: -webkit-flex;
  display: -moz-box;
  display: flex; }
  @media (min-width: 1200px) {
    .weight-drugs-support .input-checkbox-label {
      margin-bottom: 24px;
      margin-left: 24px; } }
  .weight-drugs-support .input-checkbox-label input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    background-color: var(--form-background);
    position: relative;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 2px solid currentColor;
    -moz-border-radius: 15%;
         border-radius: 15%;
    display: grid;
    place-content: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-right: 8px;
    margin-top: 4px; }
  .weight-drugs-support .input-checkbox-label input[type="checkbox"]::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transition: 120ms -webkit-transform ease-in-out;
    transition: 120ms -webkit-transform ease-in-out;
    -o-transition: 120ms -o-transform ease-in-out;
    -moz-transition: 120ms transform ease-in-out, 120ms -moz-transform ease-in-out;
    transition: 120ms transform ease-in-out;
    transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out, 120ms -moz-transform ease-in-out, 120ms -o-transform ease-in-out;
    -webkit-transform: rotate(45deg) scale(0);
       -moz-transform: rotate(45deg) scale(0);
         -o-transform: rotate(45deg) scale(0);
            transform: rotate(45deg) scale(0); }
  .weight-drugs-support .input-checkbox-label input[type="checkbox"]:checked::before {
    -webkit-transform: rotate(45deg) scale(1);
       -moz-transform: rotate(45deg) scale(1);
         -o-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1); }
  .weight-drugs-support .input-checkbox-label input[type="checkbox"]:focus {
    outline-offset: 2px; }
  .weight-drugs-support .input-checkbox-label .error-input {
    border-color: #d93868; }

.weight-drugs-support .small-text {
  font-size: 16px; }

.error-message {
  color: #d93868; }

.consultation-footer {
  margin-top: 1rem; }

.user-medias {
  text-align: center; }

.corona-alert {
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  color: black;
  margin-bottom: 1rem;
  -moz-border-radius: 3px;
       border-radius: 3px;
  font-size: 16px; }
  .corona-alert .ca-heading {
    -moz-border-radius: 5px 5px 0 0;
         border-radius: 5px 5px 0 0;
    background-color: #EB4749;
    font-weight: bold;
    font-size: 24px;
    color: white; }
  .corona-alert .ca-body {
    background-color: white;
    -moz-border-radius: 0 0 5px 5px;
         border-radius: 0 0 5px 5px;
    font-size: 18px; }
  .corona-alert svg {
    width: 32px; }

.join-container {
  text-align: center;
  padding-top: 2.5em;
  max-width: 76%;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 576px) {
    .join-container {
      max-width: 100%; } }
  .join-container .footer {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    margin: 1em 0; }
  .join-container .block {
    text-align: left;
    max-width: 75%;
    margin: auto;
    background: #fff;
    padding: 20px;
    -moz-border-radius: 1.25rem;
         border-radius: 1.25rem;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); }
    .join-container .block .sub-block {
      text-align: center;
      padding: 1em;
      background: #FAF8F3;
      -moz-border-radius: 1.25rem;
           border-radius: 1.25rem; }
      .join-container .block .sub-block span.line {
        display: inline-block; }
      .join-container .block .sub-block svg {
        width: 135px;
        margin-right: 0.25em;
        margin-bottom: 0.5em; }
      .join-container .block .sub-block .password-section {
        background: #fff;
        border: 1px solid #111c2b;
        -moz-border-radius: 0.75rem;
             border-radius: 0.75rem;
        padding: 0.4em 1em;
        margin: 1.5em 20%;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        -webkit-align-items: center;
           -moz-box-align: center;
                align-items: center; }
        @media (max-width: 576px) {
          .join-container .block .sub-block .password-section {
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                    flex-direction: column; } }
        .join-container .block .sub-block .password-section p {
          margin: auto; }
        .join-container .block .sub-block .password-section svg {
          width: 2em;
          margin-bottom: 0; }
      .join-container .block .sub-block .list-section ul {
        padding-left: 0;
        list-style-position: inside; }
      .join-container .block .sub-block .cercle-presentation {
        width: 100%;
        margin-bottom: 1.5em; }

.main-container {
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  height: 100vh;
  background-color: #FAF8F3; }

.right-panel {
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
          flex: 1 1;
  height: 100%;
  overflow: auto; }

.page-container.page-container-unlimited {
  max-width: none;
  max-width: initial; }

.page-container {
  max-width: 1270px;
  padding-bottom: 3rem;
  margin-top: 58.64px; }
  @media (min-width: 1340px) {
    .page-container {
      margin-top: 0; } }
  .page-container .page-content-y-padding {
    padding-top: 2rem; }
    @media (min-width: 1340px) {
      .page-container .page-content-y-padding {
        padding-top: 2.5rem; } }
  .page-container .page-content-x-padding {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    @media (min-width: 1340px) {
      .page-container .page-content-x-padding {
        padding-left: 1.875rem;
        padding-right: 1.875rem; } }
  .page-container .page-content-local-cancel-pl {
    margin-left: -1.5rem; }
    @media (min-width: 1340px) {
      .page-container .page-content-local-cancel-pl {
        margin-left: -1.875rem; } }
  .page-container .page-content-local-cancel-pr {
    margin-right: -1.5rem; }
    @media (min-width: 1340px) {
      .page-container .page-content-local-cancel-pr {
        margin-right: -1.875rem; } }

.orders-history .custom-table a, .orders-history .custom-table:first-child {
  color: #d5d5dd;
  text-decoration: none; }

.orders-history ul {
  border-bottom: 1px solid #a4a4a4;
  margin: .5em;
  padding: .5em; }

.orders-history .button-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
  width: 100%; }
  .orders-history .button-container > button {
    width: 100%; }
  @media (min-width: 1024px) {
    .orders-history .button-container > button {
      max-width: 325px; } }

.orders-history p {
  font-weight: 450;
  margin-bottom: 50px; }

.mangopay-banner {
  background-color: #9F0D0D;
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  color: white;
  padding: 8px 20px;
  text-align: center;
  font-size: 12px; }
  @media (min-width: 992px) {
    .mangopay-banner {
      font-size: 14px;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      height: 40px; }
      .mangopay-banner a {
        margin-bottom: 8px; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .mangopay-banner a {
        margin-bottom: 0; } }

.rating-container {
  font-size: 16px;
  margin-top: 22px;
  display: grid;
  grid-template-columns: auto auto; }
  .rating-container .rating-in-words {
    text-align: right;
    margin-right: 8px;
    font-weight: 700;
    line-height: 27px; }
  .rating-container .rating-stars {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    grid-gap: 1px;
    gap: 1px; }
    .rating-container .rating-stars .rating-star svg {
      width: 16px; }

.phone-info {
  color: grey;
  line-height: 1.2rem;
  text-align: center; }

.stars-rating-row {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }

.form-edition {
  text-align: left; }
  .form-edition .submit-button {
    width: 100%; }

.onboarding-navbar {
  background-color: white;
  height: 80px;
  padding: 0 1rem;
  -webkit-box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1); }
  .onboarding-navbar .onboarding-navbar {
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
            flex: 1 1; }
  .onboarding-navbar .navbar-nav {
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
            flex: 1 1; }
  .onboarding-navbar .navbar {
    height: 100%;
    padding: 0; }
  .onboarding-navbar .charles-logo-svg svg {
    height: 20px; }
    @media (min-width: 576px) {
      .onboarding-navbar .charles-logo-svg svg {
        height: 50px; } }
  .onboarding-navbar .charles-academy-logo-svg svg {
    height: 36px; }
    @media (min-width: 576px) {
      .onboarding-navbar .charles-academy-logo-svg svg {
        height: 50px; } }
  .onboarding-navbar .mia-academy-logo-svg svg {
    height: 26px; }
    @media (min-width: 576px) {
      .onboarding-navbar .mia-academy-logo-svg svg {
        height: 40px; } }
  @media screen and (min-width: 576px) {
    .onboarding-navbar {
      height: 80px;
      padding: 0 2rem;
      -webkit-box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.1);
         -moz-box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.1); } }
  .onboarding-navbar .onboarding-back {
    -webkit-flex: 1 1;
       -moz-box-flex: 1;
            flex: 1 1;
    cursor: pointer; }
    .onboarding-navbar .onboarding-back .onboarding-back-icon svg {
      height: 16px; }
  .onboarding-navbar .onboarding-phone-icon svg {
    height: 16px; }
  .onboarding-navbar .onboarding-navbar-help {
    cursor: pointer;
    font-weight: normal; }
  .onboarding-navbar .onboarding-phone-help {
    border: 2px solid black;
    -moz-border-radius: 1rem;
         border-radius: 1rem;
    padding: .5rem 1rem;
    white-space: nowrap; }
    .onboarding-navbar .onboarding-phone-help svg {
      height: 21px; }

.sticky-cta {
  position: fixed;
  background-color: white;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-top: 2px solid black; }

.checkout-cart {
  font-weight: 500; }

.sidebar {
  background-color: #fff;
  color: #888888;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  height: 100vh;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  width: 20.3125em;
  min-height: 100vh;
  overflow: auto;
  -webkit-transition: margin-left .2s;
  -o-transition: margin-left .2s;
  -moz-transition: margin-left .2s;
  transition: margin-left .2s;
  z-index: 1500;
  padding: 1.5rem 1rem 1rem;
  border-right: 2px solid #000000; }
  .sidebar.animate {
    left: 0;
    position: absolute;
    z-index: 1039;
    margin-left: -20.3125em; }
    @media screen and (min-width: 1340px) {
      .sidebar.animate {
        left: 0;
        position: inherit;
        margin: 0; } }
  .sidebar.active {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    margin-left: 0; }
  .sidebar .logo-container {
    display: block;
    margin: 2.3em auto;
    width: 150px; }
    .sidebar .logo-container svg g {
      fill: #000000; }
  .sidebar .sidebar-top {
    -webkit-flex: 0 0;
       -moz-box-flex: 0;
            flex: 0 0; }
    .sidebar .sidebar-top .toggle-menu-button {
      background-color: transparent;
      border: 0;
      float: right;
      position: relative;
      right: 10px;
      top: 5px; }
      @media screen and (min-width: 1340px) {
        .sidebar .sidebar-top .toggle-menu-button {
          display: none; } }
    .sidebar .sidebar-top .toggle-menu-icon {
      display: inline-block;
      line-height: 6em;
      vertical-align: middle;
      width: 2em;
      -webkit-transform: rotate(180deg);
         -moz-transform: rotate(180deg);
           -o-transform: rotate(180deg);
              transform: rotate(180deg); }
      .sidebar .sidebar-top .toggle-menu-icon svg path {
        fill: #000000; }
    .sidebar .sidebar-top .sidebar-support-number {
      color: #111c2b;
      font-weight: normal;
      text-decoration: none; }
    .sidebar .sidebar-top .dropdown {
      position: relative; }
    .sidebar .sidebar-top .dropdown__list {
      -webkit-transition: max-height .6s ease-out;
      -o-transition: max-height .6s ease-out;
      -moz-transition: max-height .6s ease-out;
      transition: max-height .6s ease-out;
      max-height: 0;
      overflow: hidden;
      margin: 0; }
      .sidebar .sidebar-top .dropdown__list li span.label {
        font-weight: 300;
        margin-left: 1rem; }
    .sidebar .sidebar-top .dropdown__list--active {
      max-height: 800px;
      opacity: 1; }
    .sidebar .sidebar-top .dropdown__list-item {
      cursor: pointer;
      list-style: none;
      position: relative; }
      .sidebar .sidebar-top .dropdown__list-item .dropdown__toggler--active span.icon {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg); }
  .sidebar .sidebar-item {
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    background-color: #fff;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 500;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    outline: 0;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    width: 100%; }
    .sidebar .sidebar-item .icon {
      display: inline-block;
      line-height: 3rem;
      vertical-align: middle;
      width: 1.125em; }
    .sidebar .sidebar-item .label {
      line-height: 3rem;
      vertical-align: middle; }
    .sidebar .sidebar-item.active {
      color: #000000; }
      .sidebar .sidebar-item.active .icon path, .sidebar .sidebar-item.active .icon * {
        fill: #000000; }
      .sidebar .sidebar-item.active .label {
        opacity: 1;
        border-bottom: 2px solid #000000;
        padding-bottom: 0.5em; }
    .sidebar .sidebar-item:hover {
      color: #000000;
      text-decoration: none; }
  .sidebar ul.sidebar-item-subitems {
    margin-left: 1rem;
    font-size: 0.8em; }

.personnal-space-program-manager {
  overflow-x: hidden; }
  .personnal-space-program-manager .personnal-space-program-manager-title-group {
    margin-bottom: 32px;
    margin-top: 24px; }
  .personnal-space-program-manager .container-content {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: baseline;
       -moz-box-align: baseline;
            align-items: baseline; }
  .personnal-space-program-manager .program-variant-choice-block {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -moz-border-radius: 4px;
         border-radius: 4px;
    margin-top: 20px;
    background-color: white;
    flex-start: right;
    max-width: 1140px;
    width: 100%; }
    @media (min-width: 1340px) {
      .personnal-space-program-manager .program-variant-choice-block {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row; } }
    .personnal-space-program-manager .program-variant-choice-block .program-variant-choice-row {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column-reverse;
         -moz-box-orient: vertical;
         -moz-box-direction: reverse;
              flex-direction: column-reverse;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between;
      -webkit-flex-grow: 1;
         -moz-box-flex: 1;
              flex-grow: 1;
      margin-top: 24px;
      -webkit-align-items: center;
         -moz-box-align: center;
              align-items: center; }
      @media (min-width: 768px) {
        .personnal-space-program-manager .program-variant-choice-block .program-variant-choice-row {
          margin-top: 0;
          width: 100%;
          -webkit-flex-direction: row;
             -moz-box-orient: horizontal;
             -moz-box-direction: normal;
                  flex-direction: row;
          -webkit-justify-content: center;
             -moz-box-pack: center;
                  justify-content: center;
          -webkit-flex: 1.5 1;
             -moz-box-flex: 1.5;
                  flex: 1.5 1;
          padding: 50px 10px; }
          .personnal-space-program-manager .program-variant-choice-block .program-variant-choice-row > div:nth-child(1) {
            margin-right: 20px; } }
      .personnal-space-program-manager .program-variant-choice-block .program-variant-choice-row .time-variant-choice {
        width: 250px;
        min-height: 460px; }
    .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme {
      background-color: #FBFBFB;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      width: 100%;
      -webkit-flex: 1.5 1;
         -moz-box-flex: 1.5;
              flex: 1.5 1;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column; }
      .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-title {
        font-weight: 600;
        color: #1F3A64;
        padding: 24px 0 0 32px;
        font-size: 18px;
        -webkit-align-self: center;
                align-self: center; }
        @media (min-width: 1340px) {
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-title {
            -webkit-align-self: initial;
                    align-self: initial; } }
      .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column;
        background-color: #FFFFFF;
        margin: 24px 24px 0 24px;
        -moz-border-radius: 8px;
             border-radius: 8px;
        -webkit-box-shadow: 0 3px 6px #0000000D;
           -moz-box-shadow: 0 3px 6px #0000000D;
                box-shadow: 0 3px 6px #0000000D;
        padding: 16px 16px 16px 16px;
        -webkit-align-self: center;
                align-self: center; }
        @media (min-width: 768px) {
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper {
            padding-left: 56px; } }
        @media (min-width: 1340px) {
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper {
            -webkit-align-self: initial;
                    align-self: initial; } }
        .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-head-div {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-align-items: center;
             -moz-box-align: center;
                  align-items: center;
          -webkit-justify-content: space-evenly;
             -moz-box-pack: space-evenly;
                  justify-content: space-evenly; }
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-head-div .head-div-title {
            font-weight: 600;
            color: #1F3A64;
            font-size: 18px; }
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-head-div .vaginisme-program-icon svg {
            height: 140px; }
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-head-div .vaginisme-price-div {
            margin-left: 32px; }
        .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-bottom-div {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-flex-direction: column;
             -moz-box-orient: vertical;
             -moz-box-direction: normal;
                  flex-direction: column;
          margin-top: 24px; }
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-bottom-div .bottom-div-title {
            font-size: 16px;
            font-weight: normal;
            color: #1F3A64; }
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-bottom-div .vaginisme-tick-div-wrapper {
            margin-top: 16px; }
            .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-bottom-div .vaginisme-tick-div-wrapper .vaginisme-tick-div {
              display: -webkit-flex;
              display: -moz-box;
              display: flex;
              font-size: 14px;
              color: #002865;
              margin-bottom: 8px; }
              .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-program-wrapper .vaginisme-bottom-div .vaginisme-tick-div-wrapper .vaginisme-tick-div .vaginisme-tick-icon {
                height: 17px;
                width: 17px;
                margin-right: 8px; }
      .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-button {
        margin: 24px 0 32px 0;
        -webkit-align-self: center;
                align-self: center; }
        @media (min-width: 1340px) {
          .personnal-space-program-manager .program-variant-choice-block .program-variant-vaginisme .vaginisme-button {
            margin-right: 24px;
            -webkit-align-self: flex-end;
                    align-self: flex-end;
            margin-bottom: 0; } }
  .personnal-space-program-manager .program-sale-banner {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #AF6345;
    height: 56px;
    right: 0;
    color: white;
    z-index: 200; }
    @media (min-width: 1340px) {
      .personnal-space-program-manager .program-sale-banner {
        width: -webkit-calc(100% - 20.3125em);
        width: -moz-calc(100% - 20.3125em);
        width: calc(100% - 20.3125em); } }
  .personnal-space-program-manager .emoji-wrapper {
    width: 32px;
    height: 32px; }

.program-variants-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center; }
  @media (min-width: 768px) {
    .program-variants-container {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      -webkit-align-items: stretch;
         -moz-box-align: stretch;
              align-items: stretch;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; } }

.program-reduction-percent {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  height: 32px;
  background: #FFFFFF;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -moz-border-radius: 20px;
       border-radius: 20px;
  position: relative;
  color: #000000;
  border: 2px solid #000000;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  font-family: 'MaisonNeue', sans-serif; }

.program-reduction-percent-wrapper {
  height: 0;
  position: relative;
  -webkit-align-self: center;
          align-self: center;
  top: -40px; }
  @media (min-width: 1200px) {
    .program-reduction-percent-wrapper.big-pill {
      top: -52px; }
      .program-reduction-percent-wrapper.big-pill .program-reduction-percent {
        font-weight: 700;
        font-size: 1.25rem;
        padding: 0.75rem 2rem;
        -moz-border-radius: 16px;
             border-radius: 16px;
        height: 48px; } }

.program-reduction-percent-position-clinic {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }

.funnel-program {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }

.funnel-sommeil-program .program-reduction-percent {
  color: #AF6345;
  border: 2px solid #AF6345; }

.funnel-time-program .program-reduction-percent {
  color: #3D7791;
  border: 2px solid #3D7791; }

.funnel-master-program .program-reduction-percent {
  color: #3D7791;
  border: 2px solid #3D7791; }



.patient-space-personal-info {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -moz-border-radius: 4px;
       border-radius: 4px;
  margin-top: 20px;
  flex-start: right;
  max-width: 1140px;
  width: 100%; }
  @media (min-width: 1340px) {
    .patient-space-personal-info {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }
  @media screen and (max-width: 768px) {
    .patient-space-personal-info .form-label {
      display: none; } }
  .patient-space-personal-info .patient-space-form {
    background-color: #FBFBFB;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    width: 100%;
    -webkit-flex: 1.5 1;
       -moz-box-flex: 1.5;
            flex: 1.5 1;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -moz-border-radius: 8px;
         border-radius: 8px;
    padding: 16px 16px 24px;
    -webkit-box-shadow: 0 0 10px #0000000D;
       -moz-box-shadow: 0 0 10px #0000000D;
            box-shadow: 0 0 10px #0000000D;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
    @media screen and (max-width: 768px) {
      .patient-space-personal-info .patient-space-form {
        margin-bottom: 60px; } }
  .patient-space-personal-info .patient-space-payment-row {
    background-color: #FBFBFB;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    width: 100%;
    -webkit-flex: 1.5 1;
       -moz-box-flex: 1.5;
            flex: 1.5 1;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -moz-border-radius: 8px;
         border-radius: 8px;
    -webkit-box-shadow: 0 0 10px #0000000D;
       -moz-box-shadow: 0 0 10px #0000000D;
            box-shadow: 0 0 10px #0000000D; }
    @media (min-width: 1340px) {
      .patient-space-personal-info .patient-space-payment-row {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row; } }
    .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc {
      padding: 24px 32px; }
      @media (max-width: 992px) {
        .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc {
          -moz-border-radius-bottomright: 0 !important;
               border-bottom-right-radius: 0 !important;
          -moz-border-radius-bottomleft: 0 !important;
               border-bottom-left-radius: 0 !important; } }
      @media (min-width: 992px) {
        .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc {
          -webkit-align-items: flex-start !important;
             -moz-box-align: start !important;
                  align-items: flex-start !important;
          -webkit-flex: 1.5 1;
             -moz-box-flex: 1.5;
                  flex: 1.5 1; } }
      .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .patient-space-step-title {
        margin-bottom: 32px;
        text-align: center; }
        @media (min-width: 768px) {
          .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .patient-space-step-title {
            text-align: left;
            text-align: initial; } }
        .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .patient-space-step-title .step-title {
          color: #1F3A64;
          font-size: 18px;
          font-weight: 600; }
        .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .patient-space-step-title .step-description {
          margin-top: 8px;
          color: #6590BB;
          font-size: 14px; }
      .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .step-div {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        color: #1F3A64;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 16px;
        -webkit-align-items: center;
           -moz-box-align: center;
                align-items: center; }
        @media (min-width: 992px) {
          .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .step-div {
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                    flex-direction: column;
            -webkit-align-items: flex-start;
               -moz-box-align: start;
                    align-items: flex-start; } }
        .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .step-div div:last-child {
          font-size: 18px;
          font-weight: 500;
          margin-left: 8px; }
          @media (min-width: 992px) {
            .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .step-div div:last-child {
              margin-left: 0;
              margin-top: 8px;
              margin-bottom: 8px; } }
      .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .patient-space-form-input-wrapper {
        display: grid;
        grid-template-columns: 255px;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        margin-bottom: 24px;
        grid-column-gap: 16px;
        -webkit-column-gap: 16px;
           -moz-column-gap: 16px;
                column-gap: 16px; }
        @media (min-width: 768px) {
          .patient-space-personal-info .patient-space-payment-row .patient-space-container-bloc .patient-space-form-input-wrapper {
            grid-template-columns: 220px 220px;
            -webkit-justify-content: initial;
               -moz-box-pack: initial;
                    justify-content: initial; } }

.checkout-container-bloc {
  margin-right: 24px;
  margin-left: 24px; }
  @media (min-width: 768px) {
    .checkout-container-bloc {
      margin-right: 214px;
      margin-left: 214px; } }

.sub-step-stepper {
  background-color: black;
  height: 4px;
  width: 0;
  -webkit-transition: width ease-in-out 0.5s;
  -o-transition: width ease-in-out 0.5s;
  -moz-transition: width ease-in-out 0.5s;
  transition: width ease-in-out 0.5s; }
  @media screen and (min-width: 576px) {
    .sub-step-stepper {
      height: 6px; } }


.checkout-drugs-prescription-main {
  padding: 32px 24px;
  max-width: 1205px; }
  @media (min-width: 1340px) {
    .checkout-drugs-prescription-main {
      padding: 24px;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between;
      margin-left: auto;
      margin-right: auto; } }
  .checkout-drugs-prescription-main .block-content {
    max-width: 621px; }
  .checkout-drugs-prescription-main .uc-prescription-card .block-content {
    margin-bottom: 32px; }
  .checkout-drugs-prescription-main .uc-prescription-card h4 {
    border-bottom: 1px solid black;
    padding-bottom: 8px;
    margin-bottom: 32px; }
  .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-heading-section {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    margin-bottom: 32px; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-heading-section .prescription-card-img {
      width: 88px;
      height: 88px;
      border: 2px solid #000000;
      -moz-border-radius: 16px;
           border-radius: 16px;
      margin-right: 16px; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-heading-section .prescription-card-heading .prescription-card-heading-title {
      font-size: 1rem;
      font-weight: 700; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-heading-section .prescription-card-heading .prescription-card-heading-subtitle {
      font-family: 'Sweet Sans Pro', sans-serif;
      font-size: 0.875rem;
      font-weight: 400; }
  .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-drugs-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 4px;
    margin-bottom: 16px;
    font-weight: 500; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-drugs-title svg {
      width: 24px;
      height: 24px;
      margin-right: 8px; }
  .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-prescription-list .prescription-list-title {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 8px; }
  .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-prescription-list .item-list {
    -webkit-padding-start: 25px;
       -moz-padding-start: 25px;
            padding-inline-start: 25px; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-prescription-list .item-list > li {
      margin-bottom: 8px; }
      .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-prescription-list .item-list > li > span {
        text-decoration: underline; }
  .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-cgu {
    margin-top: 32px;
    margin-bottom: 32px; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-cgu > input {
      margin-top: 4px; }
    .checkout-drugs-prescription-main .uc-prescription-card .prescription-card-cgu > div {
      font-weight: 400;
      font-size: 0.75rem; }
  .checkout-drugs-prescription-main .dom-tom-delivery-block {
    font-size: 16px;
    font-weight: 700; }
    .checkout-drugs-prescription-main .dom-tom-delivery-block .block-content {
      margin-bottom: 32px; }
    .checkout-drugs-prescription-main .dom-tom-delivery-block .dom-tom-delivery-title {
      margin-bottom: 24px; }
    .checkout-drugs-prescription-main .dom-tom-delivery-block button {
      padding: 16px 18px;
      width: 100%; }
  .checkout-drugs-prescription-main .section-arguments-wrapper {
    border-top: 2px solid black;
    padding-top: 40px;
    display: -webkit-flex;
    display: -moz-box;
    display: flex; }
    @media (min-width: 1340px) {
      .checkout-drugs-prescription-main .section-arguments-wrapper {
        border-top: none;
        padding-left: 64px;
        padding-top: 0;
        margin-top: 72px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }

.phyto-card-subscription-time-picker {
  position: relative;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  height: 38px;
  border: 2px solid #111c2b;
  -moz-border-radius: 12px;
       border-radius: 12px;
  -webkit-justify-content: space-evenly;
     -moz-box-pack: space-evenly;
          justify-content: space-evenly;
  cursor: pointer; }
  .phyto-card-subscription-time-picker-clone {
    height: 0;
    border: none;
    margin: 0; }
  .phyto-card-subscription-time-picker .phyto-card-subscription-time-picker-item {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    -moz-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s; }
    .phyto-card-subscription-time-picker .phyto-card-subscription-time-picker-item .phyto-card-subscription-time-picker-item-title {
      pointer-events: none; }
    .phyto-card-subscription-time-picker .phyto-card-subscription-time-picker-item-active {
      position: relative;
      z-index: 10;
      color: white; }
    .phyto-card-subscription-time-picker .phyto-card-subscription-time-picker-item-phantom-selector {
      position: relative;
      width: 0;
      height: 0;
      -webkit-transition: left 0.5s ease-in-out;
      -o-transition: left 0.5s ease-in-out;
      -moz-transition: left 0.5s ease-in-out;
      transition: left 0.5s ease-in-out; }
    .phyto-card-subscription-time-picker .phyto-card-subscription-time-picker-item-selector {
      -webkit-transform: translateY(-41px);
         -moz-transform: translateY(-41px);
           -o-transform: translateY(-41px);
              transform: translateY(-41px);
      background-color: #111c2b;
      color: white;
      height: 44px;
      -moz-border-radius: 12px;
           border-radius: 12px; }
  .phyto-card-subscription-time-picker .phyto-card-recommended {
    margin-top: 12px;
    margin-bottom: 32px;
    text-align: center; }
  .phyto-card-subscription-time-picker .phyto-card-price-options {
    margin-bottom: 40px; }
    .phyto-card-subscription-time-picker .phyto-card-price-options .phyto-card-price-options-item {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 8px; }
      .phyto-card-subscription-time-picker .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-title {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        font-weight: 500;
        -webkit-align-items: center;
           -moz-box-align: center;
                align-items: center;
        cursor: pointer; }
        .phyto-card-subscription-time-picker .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-title > input {
          margin-right: 16px;
          cursor: pointer; }
        .phyto-card-subscription-time-picker .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-title > label {
          cursor: pointer;
          margin-bottom: 0; }
      .phyto-card-subscription-time-picker .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-description {
        margin-left: 29px; }
  .phyto-card-subscription-time-picker .back-to-phyto-description {
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
    -webkit-align-self: center;
            align-self: center;
    cursor: pointer; }
  .phyto-card-subscription-time-picker .phyto-card-toggle-title {
    font-size: 0.875rem;
    margin-right: 16px;
    font-weight: 600; }

.checkout-phyto-main {
  padding: 32px 24px 0; }
  .checkout-phyto-main h3 {
    margin-bottom: 1rem; }
    @media screen and (min-width: 768px) {
      .checkout-phyto-main h3 {
        margin-bottom: 2rem; } }
  .checkout-phyto-main .phyto-card-wrapper {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center; }
    @media (min-width: 768px) {
      .checkout-phyto-main .phyto-card-wrapper {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center; } }
    .checkout-phyto-main .phyto-card-wrapper .phyto-card {
      width: 327px;
      margin-bottom: 32px; }
      @media (min-width: 768px) {
        .checkout-phyto-main .phyto-card-wrapper .phyto-card {
          width: 363px; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card:first-child {
            margin-right: 40px; } }
      .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part {
        -webkit-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out; }
        @media (min-width: 768px) {
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part {
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            overflow: hidden; } }
        @media (min-width: 768px) {
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-blocks {
            min-width: 295px; } }
        .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-flex-direction: column;
             -moz-box-orient: vertical;
             -moz-box-direction: normal;
                  flex-direction: column;
          border-bottom: 1px solid #111c2b;
          font-size: 0.875rem; }
          @media (min-width: 768px) {
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block {
              border-bottom: none;
              margin-right: 32px; } }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block .phyto-card-image {
            margin-bottom: 32px;
            width: 160px;
            height: 160px;
            border: 2px solid #111c2b;
            -webkit-filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
                    filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
            -moz-border-radius: 16px;
                 border-radius: 16px;
            max-width: 240px;
            -webkit-align-self: center;
                    align-self: center; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block .phyto-card-disclaimer {
            color: #757575; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block .phyto-card-information {
            text-decoration: underline;
            font-weight: 500; }
          @media (min-width: 768px) {
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block .phyto-card-next-page-button {
              padding: 8px 22px;
              text-align: left;
              margin-bottom: 40px; } }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block .phyto-card-next-page-button .phyto-card-next-page-button-text {
            margin-right: 24px; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-first-block .phyto-card-next-page-button .phyto-card-next-page-button-icon {
            width: 24px;
            -webkit-transform: rotate(-90deg);
               -moz-transform: rotate(-90deg);
                 -o-transform: rotate(-90deg);
                    transform: rotate(-90deg); }
        .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block {
          padding-top: 16px;
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-flex-direction: column;
             -moz-box-orient: vertical;
             -moz-box-direction: normal;
                  flex-direction: column; }
          @media (min-width: 768px) {
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block {
              padding-top: 0; } }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block > h4 {
            margin-bottom: 16px; }
            @media (min-width: 768px) {
              .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block > h4 {
                margin-bottom: 24px; } }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker {
            position: relative;
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            -webkit-align-items: center;
               -moz-box-align: center;
                    align-items: center;
            height: 38px;
            border: 2px solid #111c2b;
            -moz-border-radius: 12px;
                 border-radius: 12px;
            -webkit-justify-content: space-evenly;
               -moz-box-pack: space-evenly;
                    justify-content: space-evenly;
            cursor: pointer; }
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker-clone {
              height: 0;
              border: none;
              margin: 0; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker-item {
            display: -webkit-flex;
            display: -moz-box;
            display: flex;
            -webkit-align-items: center;
               -moz-box-align: center;
                    align-items: center;
            -webkit-justify-content: center;
               -moz-box-pack: center;
                    justify-content: center;
            width: 100%;
            height: 100%;
            -webkit-transition: all ease-in-out 0.5s;
            -o-transition: all ease-in-out 0.5s;
            -moz-transition: all ease-in-out 0.5s;
            transition: all ease-in-out 0.5s; }
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker-item .phyto-card-subscription-time-picker-item-title {
              pointer-events: none; }
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker-item-active {
              position: relative;
              z-index: 10;
              color: white; }
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker-item-phantom-selector {
              position: relative;
              width: 0;
              height: 0;
              -webkit-transition: left 0.5s ease-in-out;
              -o-transition: left 0.5s ease-in-out;
              -moz-transition: left 0.5s ease-in-out;
              transition: left 0.5s ease-in-out; }
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-subscription-time-picker-item-selector {
              -webkit-transform: translateY(-41px);
                 -moz-transform: translateY(-41px);
                   -o-transform: translateY(-41px);
                      transform: translateY(-41px);
              background-color: #111c2b;
              color: white;
              height: 44px;
              -moz-border-radius: 12px;
                   border-radius: 12px; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-recommended {
            margin-top: 12px;
            margin-bottom: 32px;
            text-align: center; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-price-options {
            margin-bottom: 40px; }
            .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-price-options .phyto-card-price-options-item {
              font-size: 1rem;
              font-weight: 400;
              margin-bottom: 8px; }
              .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-title {
                display: -webkit-flex;
                display: -moz-box;
                display: flex;
                font-weight: 500;
                -webkit-align-items: center;
                   -moz-box-align: center;
                        align-items: center;
                cursor: pointer; }
                .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-title > input {
                  margin-right: 16px;
                  cursor: pointer; }
                .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-title > label {
                  cursor: pointer;
                  margin-bottom: 0; }
              .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .phyto-card-price-options .phyto-card-price-options-item .phyto-card-price-options-item-description {
                margin-left: 29px; }
          .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-carousel-part .phyto-card-second-block .back-to-phyto-description {
            text-decoration: underline;
            text-align: center;
            font-weight: 500;
            -webkit-align-self: center;
                    align-self: center;
            cursor: pointer; }
      .checkout-phyto-main .phyto-card-wrapper .phyto-card .phyto-card-toggle-title {
        font-size: 0.875rem;
        margin-right: 16px;
        font-weight: 600; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 24px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  -o-transition: .4s;
  -moz-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  background-color: white;
  -webkit-transition: .4s;
  -o-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
  border: 1px solid #ccc; }

input:checked + .slider {
  background-color: #50E19C; }

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -moz-transform: translateX(24px);
    -o-transform: translateX(24px);
       transform: translateX(24px); }

/* Rounded sliders */
.slider.round {
  -moz-border-radius: 30px;
       border-radius: 30px; }

.slider.round:before {
  -moz-border-radius: 50%;
       border-radius: 50%; }

.checkout-choice-bullets-svg {
  width: 32px;
  height: 32px; }

.checkout-choice-bullets-body {
  max-width: 214px;
  padding-left: 8px; }

.checkout-presentation-bullets-svg {
  width: 40px;
  height: 40px; }

.checkout-presentation-bullets-body {
  font-weight: 500;
  margin-left: 8px; }

.checkout-button-view-more {
  width: 100%;
  display: block; }
  @media (min-width: 768px) {
    .checkout-button-view-more {
      max-width: 330px; } }

.header-presentation {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 992px) {
    .header-presentation {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: space-around;
              justify-content: space-around; } }
  .header-presentation .title-body-presentation {
    text-align: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    width: 100%;
    padding-bottom: 32px; }
    @media (min-width: 992px) {
      .header-presentation .title-body-presentation {
        -webkit-justify-content: start;
           -moz-box-pack: start;
                justify-content: start;
        text-align: left;
        max-width: 524px;
        padding-bottom: 0px; } }
  .header-presentation .subtitle-price {
    font-weight: 700; }
    @media (min-width: 992px) {
      .header-presentation .subtitle-price {
        padding-bottom: 40px; } }
  .header-presentation .step-header {
    text-align: center; }
    @media (min-width: 992px) {
      .header-presentation .step-header {
        text-align: left; } }
  .header-presentation .subtitle-presentation {
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    padding-bottom: 16px; }
    @media (min-width: 768px) {
      .header-presentation .subtitle-presentation {
        font-weight: 400; } }
  .header-presentation .subtitle-presentation-duration {
    text-decoration: underline; }
  .header-presentation .video-container {
    border: 2px solid #000000;
    -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
       -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-border-radius: 16px;
         border-radius: 16px;
    overflow: hidden;
    -webkit-flex: 2 1;
       -moz-box-flex: 2;
            flex: 2 1;
    margin-bottom: 32px; }
    @media screen and (max-width: 320px) {
      .header-presentation .video-container {
        width: 280px; } }
    @media (min-width: 992px) {
      .header-presentation .video-container {
        margin-bottom: 0px;
        max-height: 212px;
        max-width: 488px; } }
    @media screen and (min-width: 1200px) {
      .header-presentation .video-container {
        max-height: 274px;
        margin-left: 66px;
        -webkit-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        width: 522px; }
        .header-presentation .video-container:hover {
          -webkit-transform: scale(1.02);
             -moz-transform: scale(1.02);
               -o-transform: scale(1.02);
                  transform: scale(1.02); } }

.info-container-academy .info-row-academy {
  -webkit-justify-content: space-around;
          justify-content: space-around; }
  @media (min-width: 992px) {
    .info-container-academy .info-row-academy {
      padding-bottom: 40px; }
      .info-container-academy .info-row-academy .info-body {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .info-container-academy .info-row-academy .info-body {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row;
        -webkit-justify-content: center space-around;
           -moz-box-pack: center space-around;
                justify-content: center space-around; }
        .info-container-academy .info-row-academy .info-body svg {
          width: 40px;
          height: 40px; } }

.selection-program-title {
  font-size: 18px;
  font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
  font-weight: 500;
  margin-bottom: 32px; }
  .selection-program-title .selection-program-title-price {
    font-size: 12px; }

.block-content-delivery-checkout {
  padding: 32px 24px !important;
  max-width: 700px; }
  @media (min-width: 768px) {
    .block-content-delivery-checkout {
      padding: 24px 32px !important; } }

.delivery-div {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center; }
  .delivery-div .delivery-warning-phyto {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row;
    margin-top: 16px;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    margin-bottom: 24px; }
    .delivery-div .delivery-warning-phyto svg {
      max-width: 32px;
      width: 100%;
      margin-right: 16px; }
    .delivery-div .delivery-warning-phyto div {
      padding-left: 16px; }
  .delivery-div .payment-checkout-separator {
    border: 1px solid #111c2b;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #000000;
    opacity: 20%;
    margin-top: 12px;
    margin-bottom: 16px; }
  .delivery-div .delivery-selection-div {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    margin-top: 32px; }
    .delivery-div .delivery-selection-div .delivery-selection-content {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column; }
      .delivery-div .delivery-selection-div .delivery-selection-content .delivery-selection-label {
        padding-top: 16px; }
        @media (min-width: 768px) {
          .delivery-div .delivery-selection-div .delivery-selection-content .delivery-selection-label {
            padding-top: 0px; }
            .delivery-div .delivery-selection-div .delivery-selection-content .delivery-selection-label:nth-child(1) {
              padding-bottom: 16px; } }
        @media (min-width: 768px) {
          .delivery-div .delivery-selection-div .delivery-selection-content .delivery-selection-label {
            padding-right: 36px;
            max-width: 286px; } }
        .delivery-div .delivery-selection-div .delivery-selection-content .delivery-selection-label .delivery-selection-text {
          font-size: 16px;
          font-weight: 400; }
          .delivery-div .delivery-selection-div .delivery-selection-content .delivery-selection-label .delivery-selection-text .delivery-selection-text-description {
            font-size: 13px; }
  .delivery-div .checkout-delivery-button {
    margin-top: 32px; }

.payment-checkout .payment-checkout-block .payment-checkout-block-title {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row; }
  .payment-checkout .payment-checkout-block .payment-checkout-block-title .block-title-text {
    margin-left: 8px;
    font-size: 16px;
    font-family: 'MaisonNeue';
    font-weight: 500; }
  .payment-checkout .payment-checkout-block .payment-checkout-block-title svg {
    width: 24px; }

.payment-checkout .payment-checkout-block .payment-checkout-separator {
  border: 1px solid #111c2b;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #000000;
  opacity: 20%;
  margin-top: 12px;
  margin-bottom: 16px; }

.payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-subtitle {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px; }

.payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-dropdown-div {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 768px) {
    .payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-dropdown-div {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between; } }
  .payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-dropdown-div .payment-checkout-content-dropdown {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    width: 100%;
    max-height: 34px;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-bottom: 16px;
    margin-top: 16px;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -moz-border-radius: 8px;
         border-radius: 8px; }
    @media (min-width: 768px) {
      .payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-dropdown-div .payment-checkout-content-dropdown {
        max-width: 278px;
        margin-top: 0px;
        margin-bottom: 0px; } }
    .payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-dropdown-div .payment-checkout-content-dropdown .dropdown-option {
      background-color: #FFFFFF;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; }

.payment-checkout .payment-checkout-block .payment-checkout-content .payment-checkout-content-price {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px; }

.payment-checkout .payment-checkout-separator-black {
  border: 1px solid #111c2b;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #000000;
  margin-top: 12px;
  margin-bottom: 16px;
  margin-top: 40px; }

.payment-checkout .payment-checkout-total-price {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-weight: 500;
  font-size: 20px;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  margin-bottom: 24px; }

.confirmation-payment-checkout .confirmation-payment-header {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  text-align: center; }
  .confirmation-payment-checkout .confirmation-payment-header svg {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    max-width: 80px;
    margin-bottom: 32px; }
  .confirmation-payment-checkout .confirmation-payment-header .confirmation-payment-header-text {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 36px; }

.confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-content-block .content-block-title {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  margin-bottom: 20px; }
  .confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-content-block .content-block-title svg {
    width: 32px;
    margin-right: 14px; }
  .confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-content-block .content-block-title span {
    font-weight: 500;
    font-size: 16px; }

.confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-content-block .payment-checkout-separator {
  border: 1px solid #111c2b;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #000000;
  opacity: 20%;
  margin-top: 16px;
  margin-bottom: 36px; }

.confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-button {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  margin-top: 32px; }
  @media (min-width: 768px) {
    .confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-button {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      -webkit-align-items: flex-start;
         -moz-box-align: start;
              align-items: flex-start; } }
  .confirmation-payment-checkout .confirmation-payment-content .confirmation-payment-button .button-start-program {
    padding-left: 16px;
    padding-right: 16px; }

.delivery-disclaimer {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  margin-bottom: 2rem; }
  .delivery-disclaimer .delivery-info-icon {
    margin-right: 16px; }
    .delivery-disclaimer .delivery-info-icon svg {
      width: 28px;
      height: 28px; }

@font-face {
  font-family: 'SFProDisplay';
  font-display: swap;
  src: url(/static/media/SF-Pro-Display-Regular.ae18f767.woff2); }

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Geomanist-Regular.de66f0a1.woff2); }

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/Geomanist-Medium.996e7927.woff2); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/NotoSans-Regular.21f62354.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/NotoSans-Medium.4e940118.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/NotoSans-SemiBold.e2c2bc20.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/NotoSans-Bold.6a1f7cfe.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/NotoSans-ExtraBold.7d73bb09.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/NotoSans-Italic.85c1b6fe.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/NotoSans-MediumItalic.21489cf8.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/NotoSans-SemiBoldItalic.3fda80b8.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/NotoSans-BoldItalic.c55a2462.ttf); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/NotoSans-ExtraBoldItalic.05a7045f.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Poppins-Regular.8b6af8e5.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/Poppins-Medium.f61a4eb2.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/Poppins-SemiBold.4cdacb8f.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/Poppins-Bold.a3e0b5f4.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/Poppins-ExtraBold.544fa4f2.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/Poppins-Italic.5e956c44.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/Poppins-MediumItalic.1749e4b6.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/Poppins-SemiBoldItalic.378a091b.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/Poppins-BoldItalic.09775bde.ttf); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/Poppins-ExtraBoldItalic.29f7dd01.ttf); }

@font-face {
  font-family: 'Imperial URW';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/imperialurw-reg-webfont.32587fe5.woff2) format("woff2"); }

@font-face {
  font-family: 'Imperial URW';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/static/media/imperialurw-extbol-webfont.d0c45bff.woff2) format("woff2"); }

@font-face {
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/MaisonNeueExtendedWEB-Medium.e2e14594.woff2) format("woff2"); }

@font-face {
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/MaisonNeueExtendedWEB-Demi.61a36992.woff2) format("woff2"); }

@font-face {
  font-family: 'SweetSansPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/sweetsanspro-regular.900780bf.woff2) format("woff2"); }

@font-face {
  font-family: 'SweetSansPro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/static/media/sweetsanspro-medium.dbd83ae1.woff2) format("woff2"); }

@font-face {
  font-family: 'SweetSansPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/sweetsanspro-bold.62ef09b5.woff2) format("woff2"); }

.survey-container .sv_container {
  margin-right: -5px;
  margin-left: -5px; }
  .survey-container .sv_container .sv_completed_page, .survey-container .sv_container .sd-completedpage {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-align: center;
    padding: 20px; }
    .survey-container .sv_container .sv_completed_page h3, .survey-container .sv_container .sd-completedpage h3 {
      margin-bottom: 0; }

.page-content .survey-container .sv_container {
  margin-right: -1.5rem;
  margin-left: -1.5rem; }

.question-header {
  text-align: center;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .question-header {
      margin-bottom: 2.5rem; } }

.question-label {
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  margin-bottom: 1rem; }

.sv_row:not(:last-child) {
  padding-bottom: 2em; }

.sv_qstn {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding-bottom: 0.5em; }

.sv_qcbc {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: stretch;
     -moz-box-align: stretch;
          align-items: stretch; }

.sv_qstn .sv-q-col-1 {
  padding: 0 !important; }

.sv_q_required_text {
  display: none; }

.survey-error {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #D74848; }

table.sv_q_matrix .sd-table__row {
  text-align: left; }

table.sv_q_matrix label {
  margin-bottom: 0; }

@media screen and (min-width: 768px) {
  table.sv_q_matrix thead th {
    font-size: .9em;
    font-weight: normal;
    min-width: 92px;
    vertical-align: middle;
    text-align: center; }
  table.sv_q_matrix tbody tr td:not(:first-child), table.sv_q_matrix tbody th td:not(:first-child) {
    padding: 0;
    vertical-align: middle;
    text-align: center; }
    table.sv_q_matrix tbody tr td:not(:first-child) label.sv_q_m_label, table.sv_q_matrix tbody th td:not(:first-child) label.sv_q_m_label {
      cursor: pointer;
      position: static;
      display: block;
      width: 100%; }
      table.sv_q_matrix tbody tr td:not(:first-child) label.sv_q_m_label input, table.sv_q_matrix tbody th td:not(:first-child) label.sv_q_m_label input {
        cursor: pointer; } }

@media screen and (max-width: 767px) {
  table.sv_q_matrix thead {
    display: none; }
  table.sv_q_matrix tr {
    display: block;
    margin-top: 8px; }
  table.sv_q_matrix td {
    display: block;
    position: relative; }
    table.sv_q_matrix td:first-child {
      font-weight: 500; }
    table.sv_q_matrix td:first-child, table.sv_q_matrix td:nth-child(2) {
      border-top: none; }
    table.sv_q_matrix td:not(:first-child) label.sv_q_m_label {
      cursor: pointer;
      left: 0;
      padding-left: inherit;
      position: absolute;
      margin: 0px;
      display: block;
      width: 100%; }
    table.sv_q_matrix td:not(:first-child):after {
      padding-left: 1.5em;
      content: attr(data-responsive-title) attr(title); } }

.onboarding-choice {
  text-align: left;
  background-color: white;
  padding: 1rem 1.5rem;
  min-width: 100px;
  border: 2px solid black;
  font-weight: 500;
  cursor: pointer;
  -webkit-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-border-radius: 1.25rem;
       border-radius: 1.25rem; }
  .onboarding-choice:hover {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
    font-weight: bold; }
  .onboarding-choice:active, .onboarding-choice:focus, .onboarding-choice.active {
    border: 3px solid black;
    font-weight: bold; }

.onboarding-choice.disable-onboarding-choice {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  pointer-events: none; }

.survey-item.survey-item-disabled .onboarding-choice, .survey-item.survey-item-disabled .onboarding-choice:hover, .survey-item.survey-item-disabled .onboarding-choice:active, .survey-item.survey-item-disabled .onboarding-choice:focus, .survey-item.survey-item-disabled .onboarding-choice.active, .sv_q_rating.survey-item-disabled .onboarding-choice, .sv_q_rating.survey-item-disabled .onboarding-choice:hover, .sv_q_rating.survey-item-disabled .onboarding-choice:active, .sv_q_rating.survey-item-disabled .onboarding-choice:focus, .sv_q_rating.survey-item-disabled .onboarding-choice.active, .survey-imgsel.survey-item-disabled .onboarding-choice, .survey-imgsel.survey-item-disabled .onboarding-choice:hover, .survey-imgsel.survey-item-disabled .onboarding-choice:active, .survey-imgsel.survey-item-disabled .onboarding-choice:focus, .survey-imgsel.survey-item-disabled .onboarding-choice.active {
  border: 2px solid rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3);
  cursor: default; }

.survey-item:not(.survey-item-disabled).sd-item--checked .onboarding-choice, .survey-item:not(.survey-item-disabled).checked .onboarding-choice, .sv_q_rating:not(.survey-item-disabled).sd-item--checked .onboarding-choice, .sv_q_rating:not(.survey-item-disabled).checked .onboarding-choice, .survey-imgsel:not(.survey-item-disabled).sd-item--checked .onboarding-choice, .survey-imgsel:not(.survey-item-disabled).checked .onboarding-choice {
  border: 3px solid black;
  font-weight: bold; }

.survey-item:not(.survey-item-disabled):not(.sd-item--checked) .onboarding-choice:not(.active):hover, .survey-item:not(.survey-item-disabled):not(.checked) .onboarding-choice:not(.active):hover, .sv_q_rating:not(.survey-item-disabled):not(.sd-item--checked) .onboarding-choice:not(.active):hover, .sv_q_rating:not(.survey-item-disabled):not(.checked) .onboarding-choice:not(.active):hover, .survey-imgsel:not(.survey-item-disabled):not(.sd-item--checked) .onboarding-choice:not(.active):hover, .survey-imgsel:not(.survey-item-disabled):not(.checked) .onboarding-choice:not(.active):hover {
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
  font-weight: bold; }

.survey-item:not(.survey-item-disabled) .onboarding-choice:active, .survey-item:not(.survey-item-disabled) .onboarding-choice:focus, .survey-item:not(.survey-item-disabled) .onboarding-choice.active, .sv_q_rating:not(.survey-item-disabled) .onboarding-choice:active, .sv_q_rating:not(.survey-item-disabled) .onboarding-choice:focus, .sv_q_rating:not(.survey-item-disabled) .onboarding-choice.active, .survey-imgsel:not(.survey-item-disabled) .onboarding-choice:active, .survey-imgsel:not(.survey-item-disabled) .onboarding-choice:focus, .survey-imgsel:not(.survey-item-disabled) .onboarding-choice.active {
  border: 3px solid black;
  font-weight: bold; }

.survey-item:not(.survey-item-disabled) .onboarding-choice .sv_q_radiogroup_control_item, .sv_q_rating:not(.survey-item-disabled) .onboarding-choice .sv_q_radiogroup_control_item, .survey-imgsel:not(.survey-item-disabled) .onboarding-choice .sv_q_radiogroup_control_item {
  display: none; }

.survey-item:not(.survey-item-disabled) .onboarding-choice input:focus, .sv_q_rating:not(.survey-item-disabled) .onboarding-choice input:focus, .survey-imgsel:not(.survey-item-disabled) .onboarding-choice input:focus {
  outline: none; }

.survey-item label.form-check-label, .sv_q_rating label.form-check-label, .survey-imgsel label.form-check-label {
  cursor: pointer; }

.sd-action-bar {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px; }

.sv-hidden {
  display: none; }

.sv-action--hidden {
  display: none; }

.survey-checkbox-control {
  display: none; }

.survey-checkbox-decorator {
  line-height: 0;
  margin-right: 1rem;
  position: relative; }
  .survey-checkbox-decorator::after {
    content: ' ';
    border: 2px solid black;
    -moz-border-radius: 3px;
         border-radius: 3px;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    left: 0; }

.survey-checkbox-svg {
  height: 16px;
  fill: transparent;
  width: 16px; }

.survey-item.sd-checkbox--checked .survey-checkbox-svg {
  fill: black; }

.survey-item.sd-checkbox--allowhover .survey-checkbox-svg {
  fill: transparent; }

.survey-item.sd-checkbox--allowhover.survey-item-disabled .survey-checkbox-decorator::after {
  border-color: rgba(0, 0, 0, 0.3); }

@media screen and (min-width: 992px) {
  .survey-root-imgsel {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    grid-gap: 16px;
    gap: 16px; }
    .survey-root-imgsel .survey-imgsel-inline {
      -webkit-flex: 1 1;
         -moz-box-flex: 1;
              flex: 1 1; } }

.survey-root-imgsel .survey-imgsel-label {
  width: 100%;
  height: 100%; }
  .survey-root-imgsel .survey-imgsel-label div {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    text-align: center;
    -webkit-justify-content: start;
       -moz-box-pack: start;
            justify-content: start; }
  @media screen and (max-width: 992px) {
    .survey-root-imgsel .survey-imgsel-label .survey-imgsel-image {
      margin-right: .5rem; } }
  @media screen and (min-width: 992px) {
    .survey-root-imgsel .survey-imgsel-label {
      padding-top: 2rem;
      padding-bottom: 2rem; }
      .survey-root-imgsel .survey-imgsel-label .survey-imgsel-image {
        margin-bottom: 1rem; } }

.sv_q_file .file-list {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center; }

.sv_q_file .survey-file-decorator {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 24px 0; }
  @media screen and (min-width: 768px) {
    .sv_q_file .survey-file-decorator {
      border: 2px dashed #AF6345;
      padding: 24px 32px; } }
  .sv_q_file .survey-file-decorator .sv_q_file_choose_button svg {
    display: none; }

.sv_q_file .sv_q_file_preview {
  position: relative;
  display: block;
  margin-top: 1rem;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content; }
  .sv_q_file .sv_q_file_preview img {
    height: 200px; }
  .sv_q_file .sv_q_file_preview .file-sign-bottom {
    font-size: 0.8rem;
    word-break: break-all;
    min-width: 100px; }

.sv_q_file .survey-remove-file-svg {
  position: absolute;
  height: 2rem;
  width: 2rem;
  z-index: 5;
  top: -1rem;
  left: -1rem;
  cursor: pointer; }
  .sv_q_file .survey-remove-file-svg svg {
    fill: #AF6345; }

.sd-rating__item input {
  display: none; }

.root-body-funnel {
  background-color: #FAF8F3; }
  .root-body-funnel .onboarding-container {
    padding-top: 1.5rem; }
    @media (min-width: 1340px) {
      .root-body-funnel .onboarding-container {
        padding-top: 2.5rem; } }
    .root-body-funnel .onboarding-container .images-doctor {
      max-height: 130px;
      height: 100%;
      border: 2px solid #000000;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      -moz-border-radius: 16px;
           border-radius: 16px;
      -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
         -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
              box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1); }
    .root-body-funnel .onboarding-container .images-doctors {
      max-height: 130px;
      height: 100%;
      -o-object-fit: contain;
         object-fit: contain; }
    .root-body-funnel .onboarding-container .choice-bullets-svg {
      width: 32px;
      height: 32px; }
    .root-body-funnel .onboarding-container .choice-bullets-body {
      max-width: 214px;
      padding-left: 8px; }
    .root-body-funnel .onboarding-container .presentation-bullets-svg {
      width: 40px;
      height: 40px; }
    .root-body-funnel .onboarding-container .presentation-bullets-body {
      font-weight: 500;
      margin-left: 8px; }
  .root-body-funnel .onboarding-container-bloc {
    margin-right: auto;
    margin-left: auto;
    padding: 16px; }
    .root-body-funnel .onboarding-container-bloc .post-payment-notice {
      font-size: 14px;
      font-family: "MaisonNeue";
      line-height: 16px;
      font-weight: 500;
      text-align: left;
      display: -webkit-flex;
      display: -moz-box;
      display: flex; }
    .root-body-funnel .onboarding-container-bloc .form-edition-container {
      display: block; }
    .root-body-funnel .onboarding-container-bloc .informations-confirmation .infos, .root-body-funnel .onboarding-container-bloc .informations-confirmation .patient-infos, .root-body-funnel .onboarding-container-bloc .informations-confirmation .consultation-infos {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column;
      -webkit-justify-content: center;
         -moz-box-pack: center;
              justify-content: center; }
    .root-body-funnel .onboarding-container-bloc .informations-confirmation .patient-infos {
      border-bottom: 1px solid #000000;
      margin-bottom: 1em; }
      @media (min-width: 768px) {
        .root-body-funnel .onboarding-container-bloc .informations-confirmation .patient-infos {
          border-right: 1px solid #000000;
          border-bottom: none;
          text-align: left;
          margin-bottom: 0; } }
      .root-body-funnel .onboarding-container-bloc .informations-confirmation .patient-infos .patient-name {
        font-size: 20px;
        font-weight: 500; }
      .root-body-funnel .onboarding-container-bloc .informations-confirmation .patient-infos .edit-infos {
        margin-bottom: 1em; }
        .root-body-funnel .onboarding-container-bloc .informations-confirmation .patient-infos .edit-infos span {
          cursor: pointer; }
    .root-body-funnel .onboarding-container-bloc .informations-confirmation .consultation-infos .doctor-name {
      font-weight: 500;
      margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .root-body-funnel .onboarding-container-bloc {
        margin-bottom: 60px;
        padding: 16px 0px; } }
    .root-body-funnel .onboarding-container-bloc .onboarding-step-title {
      margin-bottom: 32px;
      text-align: center; }
      @media (min-width: 768px) {
        .root-body-funnel .onboarding-container-bloc .onboarding-step-title {
          text-align: left;
          text-align: initial; } }
      .root-body-funnel .onboarding-container-bloc .onboarding-step-title .step-title {
        color: #1F3A64;
        font-size: 18px;
        font-weight: 600; }
      .root-body-funnel .onboarding-container-bloc .onboarding-step-title .step-description {
        margin-top: 8px;
        color: #6590BB;
        font-size: 14px; }
    .root-body-funnel .onboarding-container-bloc .header-presentation {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: column;
         -moz-box-orient: vertical;
         -moz-box-direction: normal;
              flex-direction: column; }
      @media (min-width: 992px) {
        .root-body-funnel .onboarding-container-bloc .header-presentation {
          -webkit-flex-direction: row;
             -moz-box-orient: horizontal;
             -moz-box-direction: normal;
                  flex-direction: row; } }
      .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation {
        text-align: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center;
        width: 100%;
        padding-bottom: 32px; }
        @media (min-width: 992px) {
          .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation {
            -webkit-justify-content: start;
               -moz-box-pack: start;
                    justify-content: start;
            text-align: left;
            max-width: 524px;
            padding-bottom: 0px; } }
        .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation .subtitle-price {
          font-weight: 500; }
          @media (min-width: 992px) {
            .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation .subtitle-price {
              padding-bottom: 40px; } }
        .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation .step-header {
          text-align: center; }
          @media (min-width: 992px) {
            .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation .step-header {
              text-align: left; } }
        .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation .subtitle-presentation {
          -webkit-flex-direction: column;
             -moz-box-orient: vertical;
             -moz-box-direction: normal;
                  flex-direction: column;
          padding-bottom: 16px; }
        .root-body-funnel .onboarding-container-bloc .header-presentation .title-body-presentation .subtitle-presentation-duration {
          text-decoration: underline; }
    .root-body-funnel .onboarding-container-bloc .info-container .info-row {
      -webkit-justify-content: space-around;
              justify-content: space-around; }
      @media (min-width: 992px) {
        .root-body-funnel .onboarding-container-bloc .info-container .info-row {
          padding-bottom: 40px; } }
      .root-body-funnel .onboarding-container-bloc .info-container .info-row .info-body {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
                flex-direction: column; }
        @media (min-width: 992px) {
          .root-body-funnel .onboarding-container-bloc .info-container .info-row .info-body {
            -webkit-flex-direction: row;
               -moz-box-orient: horizontal;
               -moz-box-direction: normal;
                    flex-direction: row;
            -webkit-justify-content: center space-around;
               -moz-box-pack: center space-around;
                    justify-content: center space-around; } }
        .root-body-funnel .onboarding-container-bloc .info-container .info-row .info-body svg {
          width: 40px;
          height: 40px; }
    .root-body-funnel .onboarding-container-bloc .video-container {
      border: 2px solid #000000;
      -webkit-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
         -moz-box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
              box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
      -moz-border-radius: 16px;
           border-radius: 16px;
      overflow: hidden;
      -webkit-flex: 2 1;
         -moz-box-flex: 2;
              flex: 2 1;
      margin-bottom: 32px; }
      @media screen and (max-width: 320px) {
        .root-body-funnel .onboarding-container-bloc .video-container {
          width: 280px; } }
      @media (min-width: 992px) {
        .root-body-funnel .onboarding-container-bloc .video-container {
          margin-bottom: 0px;
          max-height: 212px;
          max-width: 488px; } }
      @media screen and (min-width: 1200px) {
        .root-body-funnel .onboarding-container-bloc .video-container {
          max-height: 274px;
          margin-left: 66px;
          -webkit-transition: all .3s ease-in-out;
          -o-transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
          width: 522px; }
          .root-body-funnel .onboarding-container-bloc .video-container:hover {
            -webkit-transform: scale(1.02);
               -moz-transform: scale(1.02);
                 -o-transform: scale(1.02);
                    transform: scale(1.02); } }
    .root-body-funnel .onboarding-container-bloc .onboarding-form-input-wrapper {
      display: grid;
      grid-template-columns: 255px;
      margin-bottom: 24px; }
      @media (min-width: 768px) {
        .root-body-funnel .onboarding-container-bloc .onboarding-form-input-wrapper {
          grid-template-columns: 255px 255px;
          grid-column-gap: 16px;
          -webkit-column-gap: 16px;
             -moz-column-gap: 16px;
                  column-gap: 16px; } }
    .root-body-funnel .onboarding-container-bloc .personal-info-form-control {
      max-width: 400px; }
    .root-body-funnel .onboarding-container-bloc .step-header {
      text-align: center;
      margin-bottom: 25px; }
  .root-body-funnel .onboarding-payment-bloc {
    -moz-border-radius-bottomright: 0;
         border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
         border-bottom-left-radius: 0; }
    @media (min-width: 992px) {
      .root-body-funnel .onboarding-payment-bloc {
        -moz-border-radius: 8px 0 0 8px;
             border-radius: 8px 0 0 8px;
        -webkit-align-items: flex-start;
           -moz-box-align: start;
                align-items: flex-start;
        -webkit-flex: 1.5 1;
           -moz-box-flex: 1.5;
                flex: 1.5 1;
        padding: 24px 32px; } }
  @media screen and (max-width: 768px) {
    .root-body-funnel .onboarding-container-footer {
      background-color: #FAF8F3E6;
      -webkit-box-shadow: 0 5px 20px 0 #3cb97f1a;
         -moz-box-shadow: 0 5px 20px 0 #3cb97f1a;
              box-shadow: 0 5px 20px 0 #3cb97f1a;
      position: fixed;
      padding: .5rem;
      bottom: 0;
      left: 0;
      right: 0; } }
  @media screen and (min-width: 768px) {
    .root-body-funnel .onboarding-container-footer {
      padding-bottom: 2rem; } }
  .root-body-funnel .onboarding-container-footer .onb-prev-btn {
    display: none; }
  .root-body-funnel .onboarding-container-footer .onb-complete-btn, .root-body-funnel .onboarding-container-footer .onb-start-btn, .root-body-funnel .onboarding-container-footer .onb-next-btn {
    margin: auto !important;
    min-width: 200px;
    display: block; }
  .root-body-funnel .onboarding-small-inner-container {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .root-body-funnel .onboarding-small-inner-container {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        width: 300px; } }
  .root-body-funnel .onboarding-medium-inner-container {
    width: 100%; }
    @media screen and (min-width: 768px) {
      .root-body-funnel .onboarding-medium-inner-container {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        max-width: 440px; } }
  .root-body-funnel .onboarding-payment-cta {
    width: 187px; }

@media screen and (max-width: 768px) {
  .personal-info-step .form-label {
    display: none; } }

.onboarding-thankyou {
  text-align: center;
  font-weight: 500;
  margin: 16px 0; }
  .onboarding-thankyou .thankyou-text .thankyou-text-bold {
    color: #52A2D1;
    font-weight: 600; }
  .onboarding-thankyou .thankyou-image {
    max-width: 300px;
    display: block;
    margin: auto; }

.choice-selection-card {
  display: contents;
  padding-bottom: 32px;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 768px) {
    .choice-selection-card {
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row; } }

.bloc-content-size {
  max-width: 326px; }
  @media (min-width: 768px) {
    .bloc-content-size {
      margin-right: 2rem; } }

.promotion-form {
  padding: 16px 0; }

.phone-disclaimer {
  margin-bottom: 16px;
  color: #1F3A64;
  font-size: 14px; }

.onboarding-choice .form-check {
  padding-left: 0; }

.onboarding-choice .form-check-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0); }

.onboarding-choice .card-text {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between; }
  .onboarding-choice .card-text .unavailable {
    font-style: italic;
    font-size: 14px;
    font-weight: 400; }

.hiw-program-onboarding {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  @media (min-width: 1340px) {
    .hiw-program-onboarding {
      padding: 48px 39px 0px 39px; } }
  .hiw-program-onboarding h2 {
    position: relative;
    z-index: 2; }
  .hiw-program-onboarding .hiw-program-black-underline {
    display: block;
    width: 183px;
    -webkit-align-self: center;
            align-self: center;
    position: relative;
    z-index: 1;
    top: -22px; }
  .hiw-program-onboarding .hiw-program-container {
    margin-top: 96px; }
    .hiw-program-onboarding .hiw-program-container .hiw-start-button {
      margin-bottom: 40px; }
      @media (min-width: 1340px) {
        .hiw-program-onboarding .hiw-program-container .hiw-start-button {
          margin-bottom: 88px; } }
  @media (min-width: 1340px) {
    .hiw-program-onboarding .hiw-program-row {
      margin-bottom: 64px; } }
  .hiw-program-onboarding .hiw-program-row .hiw-program-col {
    margin-bottom: 64px; }
    @media (min-width: 1340px) {
      .hiw-program-onboarding .hiw-program-row .hiw-program-col {
        margin-bottom: 0; } }
    .hiw-program-onboarding .hiw-program-row .hiw-program-col:last-child {
      margin-bottom: 32px; }
      @media (min-width: 1340px) {
        .hiw-program-onboarding .hiw-program-row .hiw-program-col:last-child {
          margin-bottom: 0; } }
    .hiw-program-onboarding .hiw-program-row .hiw-program-col:first-child .hiw-program-col-img {
      overflow: visible;
      overflow: initial; }
      .hiw-program-onboarding .hiw-program-row .hiw-program-col:first-child .hiw-program-col-img img {
        display: block;
        width: auto;
        width: initial;
        height: 321px;
        margin-top: -79px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px; }
        @media screen and (min-width: 320px) {
          .hiw-program-onboarding .hiw-program-row .hiw-program-col:first-child .hiw-program-col-img img {
            width: 100%; } }
    .hiw-program-onboarding .hiw-program-row .hiw-program-col .hiw-program-col-img {
      -moz-border-radius: 30px;
           border-radius: 30px;
      -webkit-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
         -moz-box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid black;
      width: 100%;
      height: 246px;
      overflow: hidden; }
      .hiw-program-onboarding .hiw-program-row .hiw-program-col .hiw-program-col-img:first-child {
        background-color: #B7D4E1; }
      .hiw-program-onboarding .hiw-program-row .hiw-program-col .hiw-program-col-img img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .hiw-program-onboarding .hiw-program-row .hiw-program-col .hiw-program-col-title {
      margin-top: 32px;
      margin-bottom: 16px; }

.confirmation-program-bloc {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column; }
  .confirmation-program-bloc .confirmation-program-bloc-title {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column; }
    @media (min-width: 1340px) {
      .confirmation-program-bloc .confirmation-program-bloc-title {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row;
        -webkit-justify-content: center;
           -moz-box-pack: center;
                justify-content: center; } }
  .confirmation-program-bloc .title {
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 8px; }
  .confirmation-program-bloc .sub-title {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    padding-bottom: 32px;
    max-width: 528px; }
  .confirmation-program-bloc .icon {
    -webkit-align-self: center;
            align-self: center;
    padding-bottom: 18px; }
    .confirmation-program-bloc .icon svg {
      width: 40px; }
  .confirmation-program-bloc .body-confirmation-program .body-title {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
            flex-direction: row; }
    .confirmation-program-bloc .body-confirmation-program .body-title svg {
      width: 32px;
      padding-right: 8px; }
    .confirmation-program-bloc .body-confirmation-program .body-title .title {
      font-weight: 500;
      font-size: 20px; }
  .confirmation-program-bloc .body-detail {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
            flex-direction: column; }
    @media (min-width: 768px) {
      .confirmation-program-bloc .body-detail {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row;
        -webkit-align-items: center;
           -moz-box-align: center;
                align-items: center; } }
    .confirmation-program-bloc .body-detail .image-doctor {
      contain: content;
      -webkit-align-self: center;
              align-self: center;
      max-width: 130px;
      max-height: 130px;
      height: 100%;
      width: 100%;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
              filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
      -moz-border-radius: 16px;
           border-radius: 16px; }
    .confirmation-program-bloc .body-detail .body-detail-content {
      display: -webkit-flex;
      display: -moz-box;
      display: flex; }
      .confirmation-program-bloc .body-detail .body-detail-content .body-detail-content-text {
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row;
        padding-bottom: 8px; }
        @media (min-width: 768px) {
          .confirmation-program-bloc .body-detail .body-detail-content .body-detail-content-text {
            -webkit-flex-direction: column;
               -moz-box-orient: vertical;
               -moz-box-direction: normal;
                    flex-direction: column;
            padding-left: 24px; } }
        .confirmation-program-bloc .body-detail .body-detail-content .body-detail-content-text .body-detail-title {
          font-size: 18px;
          font-weight: 500;
          line-height: 40px; }
        .confirmation-program-bloc .body-detail .body-detail-content .body-detail-content-text .body-detail-text {
          font-size: 14px; }
    .confirmation-program-bloc .body-detail .body-detail-content-price {
      font-family: 'MaisonNeue';
      -webkit-align-self: center;
              align-self: center;
      font-size: 16px;
      font-weight: 500; }
    .confirmation-program-bloc .body-detail .body-detail-price {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400; }
      @media (min-width: 1340px) {
        .confirmation-program-bloc .body-detail .body-detail-price {
          padding-top: 8px; } }
      .confirmation-program-bloc .body-detail .body-detail-price .body-detail-price-underligne {
        font-weight: 500;
        text-decoration: underline; }

.checkout-confirmation-program {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  font-family: 'MaisonNeue'; }
  .checkout-confirmation-program .checkout-confirmation-title {
    height: 64px;
    background: #3D7791;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    -moz-border-radius: 18px 18px 0px 0px;
         border-radius: 18px 18px 0px 0px; }
    .checkout-confirmation-program .checkout-confirmation-title .title {
      text-align: center;
      color: #FFFF;
      font-size: 24px;
      font-weight: 500;
      padding-top: 8px; }
  .checkout-confirmation-program .checkout-confirmation-program-body {
    padding: 24px 32px; }
    .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body {
      padding-bottom: 40px; }
      .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body .checkout-confirmation-body-text {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
                flex-direction: row;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
                justify-content: space-between; }
        .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body .checkout-confirmation-body-text:nth-child(1) {
          padding-bottom: 16px; }
        .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body .checkout-confirmation-body-text .text {
          font-size: 18px;
          font-weight: 500; }
        .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body .checkout-confirmation-body-text .price {
          color: rgba(0, 0, 0, 0.6);
          font-weight: 500;
          font-size: 16px; }
    .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body-price {
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
      -webkit-flex-direction: row;
         -moz-box-orient: horizontal;
         -moz-box-direction: normal;
              flex-direction: row;
      -webkit-justify-content: space-between;
         -moz-box-pack: justify;
              justify-content: space-between;
      padding-bottom: 40px; }
      .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body-price .text {
        font-size: 24px;
        font-weight: 500; }
      .checkout-confirmation-program .checkout-confirmation-program-body .checkout-confirmation-body-price .price {
        font-weight: 500;
        font-size: 24px; }

.block-content-confiramtion {
  width: 100%;
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  -webkit-box-shadow: 10px 10px 0 black;
     -moz-box-shadow: 10px 10px 0 black;
          box-shadow: 10px 10px 0 black;
  padding: 32px 24px;
  margin-bottom: 16px;
  margin-right: 10px;
  background-color: #fff;
  max-width: 592px; }
  .block-content-confiramtion .block-with-header-inside {
    padding-top: 32px; }

.block-content-checkout-confirmation {
  width: 100%;
  font-family: 'MaisonNeue';
  max-width: 592px;
  border: 2px solid black;
  -moz-border-radius: 20px;
       border-radius: 20px;
  -webkit-box-shadow: 10px 10px 0 black;
     -moz-box-shadow: 10px 10px 0 black;
          box-shadow: 10px 10px 0 black;
  margin-bottom: 16px;
  margin-right: 10px;
  background-color: #fff; }
  .block-content-checkout-confirmation .block-with-header-inside {
    padding-top: 32px; }

.separation-body-onboarding-program {
  border: 1px solid #000000; }

.root-funnel.academy .onboarding-container-bloc {
  margin-bottom: 0; }

.root-funnel.academy .onboarding-charles-advantages {
  margin-top: 0; }

html {
  font-size: 16px; }

body {
  font-family: SweetSansPro, Calibri, Helvetica, Sans-Serif !important;
  color: #000;
  background-color: #FAF8F3; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #000;
  font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
  font-weight: 500; }

h1, .h1 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem; }
  @media (min-width: 1340px) {
    h1, .h1 {
      font-size: 3.5rem;
      line-height: 4rem; } }

h2, .h2 {
  font-size: 1.5rem;
  line-height: 2rem; }
  @media (min-width: 1340px) {
    h2, .h2 {
      font-size: 2rem;
      line-height: 3rem; } }

h3, .h3 {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 1340px) {
    h3, .h3 {
      font-size: 1.5rem;
      line-height: 2.5rem; } }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 1340px) {
    h4, .h4 {
      font-size: 1.25rem;
      line-height: 2rem; } }

h5, .h5, h6, .h6 {
  font-size: 1rem; }

.refbs-info {
  color: #647591; }

.text-highlight {
  color: #52A2D1;
  font-weight: 600; }

.tooltip-info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 15px;
  font-size: 15px;
  font-weight: 400;
  -moz-border-radius: 50%;
       border-radius: 50%;
  color: #fff;
  background-color: #000;
  text-align: center;
  cursor: pointer; }

.max-width-500 {
  max-width: 500px; }

.text-black {
  color: black !important; }

.cursor-pointer {
  cursor: pointer; }

.maintenance-icon svg {
  width: 3em; }

