$target-platform: charles;
@import "~constants/sass/vars";

// MainContainer
.main-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: $charles-clinique-main-background; }

.right-panel {
  flex: 1;
  height: 100%;
  overflow: auto; }


.page-container.page-container-unlimited {
  max-width: initial; }

.page-container {
  max-width: 1270px;
  padding-bottom: 3rem;
  margin-top: $adhoc-navbar-height;
  @media (min-width: $sidebar-hiding-breakpoint) {
    margin-top: 0; }

  .page-content {}

  .page-content-y-padding {
    padding-top: 2rem;
    @media (min-width: $sidebar-hiding-breakpoint) {
      padding-top: 2.5rem; } }

  .page-content-x-padding {
    padding-left: $page-pl-xs;
    padding-right: $page-pr-xs;
    @media (min-width: $sidebar-hiding-breakpoint) {
      padding-left: $page-pl-xl;
      padding-right: $page-pr-xl; } }

  .page-content-local-cancel-pl {
    margin-left: -$page-pl-xs;
    @media (min-width: $sidebar-hiding-breakpoint) {
      margin-left: -$page-pl-xl; } }
  .page-content-local-cancel-pr {
    margin-right: -$page-pr-xs;
    @media (min-width: $sidebar-hiding-breakpoint) {
      margin-right: -$page-pr-xl; } } }
