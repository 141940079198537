$target-platform: charles;
@import "~constants/sass/vars";

.weight-drugs-support {
  .weight-drugs-support-card {
    margin-bottom: 60px; }
  .weight-drugs-support-title {
    margin-bottom: 32px;
    @media(min-width: $bootstrap-break-xl) {
      display: flex;
      margin-top: 32px; }
    .weight-drugs-support-subtitle {
      @media(min-width: $bootstrap-break-xl) {
        margin-top: 4px;
        margin-left: 8px; } } }
  .weight-drugs-support-arguments {
    margin-bottom: 32px;
    &>div {
      margin-bottom: 16px;
      @media(min-width: $bootstrap-break-xl) {
        margin-top: 24px; } }
    .icon {
      margin-right: 8px;
      svg {
        width: 16px;
        height: 16px; } } }
  .weight-drugs-support-image {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -24px;
    @media(min-width: $bootstrap-break-xl) {
      margin-left: 24px;
      margin-right: 32px; } }

  label {
    cursor: pointer;
    width: fit-content; }
  .input-radio-label {
    display: flex;
    font-family: 'MaisonNeue', sans-serif;
    font-size: 20px;
    @media(min-width: $bootstrap-break-xl) {
      margin-bottom: 24px; }

    input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--form-background);

      font: inherit;
      color: currentColor;
      width: 20px;
      height: 20px;
      border: 2px solid currentColor;
      border-radius: 50%;

      display: grid;
      place-content: center;
      flex-shrink: 0;
      margin-right: 8px;
      margin-top: 4px; }

    input[type="radio"]::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: currentColor; }

    input[type="radio"]:checked {
      //border-color: black
      &::before {
        transform: scale(1); } }

    input[type="radio"]:focus {
      outline-offset: 2px; } }

  .input-checkbox-label {
    display: flex;
    @media(min-width: $bootstrap-break-xl) {
      margin-bottom: 24px;
      margin-left: 24px; }

    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--form-background);
      position: relative;

      font: inherit;
      color: currentColor;
      width: 20px;
      height: 20px;
      border: 2px solid currentColor;
      border-radius: 15%;

      display: grid;
      place-content: center;
      flex-shrink: 0;
      margin-right: 8px;
      margin-top: 4px; }

    input[type="checkbox"]::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 1px;
      width: 7px;
      height: 12px;
      border: solid black;
      border-width: 0 2px 2px 0;
      transition: 120ms transform ease-in-out;
      transform: rotate(45deg) scale(0); }

    input[type="checkbox"]:checked {
      //border-color: black
      &::before {
        transform: rotate(45deg) scale(1); } }

    input[type="checkbox"]:focus {
      outline-offset: 2px; }

    .error-input {
      border-color: $red-error; } }

  .small-text {
    font-size: 16px; } }

.error-message {
  color: $red-error; }


