$target-platform: charles;
@import "~constants/sass/vars";

.registered-card-container {

  .card-container-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 8px; }
  .paymentCardTitle {
    color: $black;
    font-size: 125% !important;
    font-weight: 400; }
  .paymentExpiry {
    color: $black;
    font-size: 1em;
    font-weight: 400;
    text-align: right; }
  .card-list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: .5rem;
    overflow: hidden;
    text-align: left;
    .card-list-element {
      display: block;
      position: relative;
      padding-top: .5rem;
      padding-bottom: .5rem;
      label {
        z-index: 9;
        cursor: pointer;
        margin: 0;
        transition: all 0.25s linear;
        -webkit-transition: all 0.25s linear; }
      &:hover {
        label {
          color: #000000; } }

      &.expired {
        color: #aaaaaa;
        .card-list-element-type {
          text-decoration: line-through; } } } }


  .newCard {
    text-align: initial;
    padding: 2%;
    color: $blue !important;
    font-weight: 450 !important;
    cursor: pointer;
    list-style-type: disc;
    &:hover {
      text-decoration: underline; } }

  .acceptCguError {
    color: red !important;

    a {
      color: red !important; } } }

