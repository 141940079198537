$target-platform: charles;
@import "~constants/sass/vars";

.store-redirection-screen-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .store-redirection-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 150px;
    @media (min-width: $bootstrap-break-sm) {
      margin-top: 200px; }

    .store-redirection-name {
      color: #44962B; }

    .store-redirection-loader {
      margin-top: 50px;
      display: flex;             // quick fix for react-spinner bug https://github.com/davidhu2000/react-spinners/issues/387
      justify-content: center;
      align-items: center;
      text-align: left;
      *, *::before, *::after {
        box-sizing: content-box; } }

    .store-redirection-label {
      font-weight: 500;
      font-size: 1.2rem; } } }
