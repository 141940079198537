$target-platform: charles;
@import "~constants/sass/vars";

.mangopay-banner {
  background-color: #9F0D0D;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 8px 20px;
  text-align: center;
  font-size: 12px;
  @media(min-width: $bootstrap-break-lg) {
    font-size: 14px;
    flex-direction: row;
    height: 40px;

    a {
      margin-bottom: 8px;
      @media(min-width: $bootstrap-break-lg) {
        margin-bottom: 0; } } } }
