$target-platform: charles;
@import "~constants/sass/vars";

.block-header {
  margin-top: 2rem;
  .block-header-title {
    .icon {
      svg {
        width: 32px; } } }

  .block-header-cta {} }

.block-with-header-inside {
  .block-header {
    margin-top: 0;
    margin-bottom: 1rem;
    .block-header-title {
      margin-left: 0; } } }
