$target-platform: charles;
@import "~constants/sass/vars";


.digital-therapy-black-separator-doctor-div {
  padding-left: 24px;
  padding-right: 24px;
  background-color: #FAF8F3;
  .digital-therapy-black-separator-doctor {
    background-color: #000000;
    height: 2px;
    width: 100%;
    padding: 0 10%; } }


.program-presentation {
  .program-section {
    .program-section-block {
      .program-section-block-title {
        color: #000000;
        margin-top: 32px;
        font-size: 24px;
        @media ( min-width: $bootstrap-break-old-xl ) {
          width: 544px;
          font-size: 40px; } } }

    .program-section-title {
      font-size: 24px;
      margin-bottom: 0px; }
    .program-section-text {
      margin-top: 16px;
      font-size: 16px;
      color: #000000; }
    .program-section-content {
      padding: 0px;
      padding-top: 20px;
      @media ( min-width: $bootstrap-break-old-xl ) {
        padding: 20px; }

      .coach-details-content {
        display: flex;
        @media ( min-width: $bootstrap-break-old-xl ) {
          margin-top: 0px;
          width: 900px; } }
      .featured-doctor {
        justify-content: center;
        @media ( min-width: $bootstrap-break-old-xl ) {
          padding-top: 80px; }
        .featured-image {
          width: 320px;
          object-fit: cover;
          object-position: top;
          @media ( min-width: $bootstrap-break-old-xl ) {
            width: 384px; }
          @media screen and (max-width: 320px) {
            width: 280px; } } }




      .profile {
        padding: 0px;
        margin-top: 44px;
        justify-content: center;
        @media ( min-width: $bootstrap-break-old-xl ) {
          padding: 16px; }

        .doctor-title {
          justify-content: center;
          width: 100%;
          font-size: 18px; }
        .doctor-presentation {
          color: #000000;
          font-size: 16px;
          margin-top: 8px;
          width: 323px;
          @media screen and (max-width: 320px) {
            width: 280px; } } } } }

  .program-background-1 {
    background-color: #E5E5E5; }
  .program-background-2 {
    background-color: #FAF8F3 !important;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 70px; } }


.advantages-bloc {

  .testimony-section {}
  display: flex;
  align-items: center;
  @media(min-width: $bootstrap-break-lg) {
    padding-bottom: 14px; }

  .testimony-book-div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 920px;
    @media(min-width: 1250px) {
      flex-direction: row; }

    .testimony-book {
      display: none;
      &:nth-child(3) {
        margin-top: 56px;
        margin-left: 0;
        margin-right: 40px;
        align-self: flex-end; }
      height: 298px;
      background-color: white;
      color: #003060;
      font-size: 16px;
      align-self: center;
      @media(min-width: $bootstrap-break-md) {
        display: flex;
        margin-left: 40px;
        align-self: flex-start; }


      .testimony-svg-div {
        width: 0;
        .testimony-desktop-svg {
          position: relative; }
        .testimony-desktop-flower {
          left: -30px;
          top: -30px;
          svg {
            height: 60px; } }

        .testimony-desktop-target {
          left: -20px;
          top: 240px;
          svg {
            height: 60px; } } }

      .name-age {
        font-weight: 600;
        margin-top: 8px; }
      .left-page {
        display: flex;
        width: 286px;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #003060;
        padding-left: 30px;
        padding-right: 30px;
        border-right: none;
        @media(min-width: $bootstrap-break-lg) {
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px; } }
      .right-page {
        display: flex;
        width: 286px;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #003060;
        border-left: 0;
        @media(min-width: $bootstrap-break-lg) {
          padding: 16px 20px 16px 30px;
          justify-content: space-between; } } }

    .border-div {
      border: 1px solid #003060;
      border-left: 0;
      width: 5px; } }

  .responsive-testimony-book {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;
    border: 1px solid #003060;
    padding: 16px;
    font-size: 14px;
    margin: 16px;
    @media(min-width: $bootstrap-break-md) {
      display: none; }
    .responsive-testimony-author {
      margin-top: 16px;
      font-weight: bold; } }

  .cta-testimony-desktop {
    margin-top: 56px;
    margin-bottom: 44px; }

  .secure-by {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #647591;
    padding: 24px 0;
    div {
      margin-bottom: 16px; } }

  .payment-secure-logo {
    padding-left: 8px;
    padding-right: 8px; }

  .payment-first-div {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .payement-bullet-div {
      display: flex;
      width: 90%;
      max-width: 308px;
      flex-direction: column;
      align-items: center;
      background-color: #FCFCFC;
      color: #1F3A64;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      padding: 12px 16px;
      margin-bottom: 16px;
      box-shadow: 0 0 6px #0000001A;
      border-radius: 8px;
      @media(min-width: $bootstrap-break-lg) {
        flex-direction: row;
        max-width: 100%;
        div {
          text-align: left;
          margin-left: 1em; } }
      &:last-child {
        margin-bottom: 24px; }

      img {
        height: 60px; }
      .payement-vaginisme-argument-icon {
        margin-right: 8px;
        @media(min-width: $bootstrap-break-old-xl) {
          margin-right: 0; }
        svg {
          height: 60px; } } } } }
.programe-faq {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  padding-bottom: 148px;
  @media (min-width: $bootstrap-break-old-xl) {
    flex-direction: row; }
  .programe-faq-container {
    width: 100%; }
  .program-faq-title {
    margin-top: 24px;
    margin-bottom: 0;
    font-family: $secondary-font; }
  .program-faq-text {
    font-size: 16px;
    margin-bottom: 32px; }
  .program-faq-content {}
  .program-faq-block {
    display: flex;
    flex-direction: column;
    padding: 29px 25px;
    width: 100%;
    height: 100%;
    max-width: 384px;
    max-height: 337px;
    @media (min-width: $bootstrap-break-old-xl) {
      margin-left: 64px; }

    .program-faq-block-title {
      font-size: 1.25rem;
      font-family: $secondary-font;
      margin-bottom: 28px; }
    .program-faq-block-bullet {
      font-size: 1rem;
      cursor: pointer;
      margin-bottom: 16px;
      a {
        font-weight: 400;
        font-size: 1rem;
        color: black; } }
    .program-faq-block-svg {
      margin-right: 8px;
      svg {
        width: 100%;
        max-width: 32px; } } } }

.creator-program {
  padding-top: 64px;
  padding-bottom: 64px;
  @media(min-width: $bootstrap-break-old-xl) {
    padding-bottom: 88px; }

  h2 {
    margin-bottom: 16px;
    text-align: center; }
  .creator-program-paragraph {
    margin-bottom: 40px; }

  .creator-program-caption {
    margin-top: 54px;
    @media(min-width: $bootstrap-break-old-xl) {
      margin-top: 0;
      margin-left: 40px; } } }


.academy-purchase-bloc-for-patients {
  width: 100%;

  .onboarding-payment-div {
    .payment-div-wrapper {
      background: white;
      margin-top: 48px;
      padding: 16px;
      padding-bottom: 0;
      border-radius: 8px; } }
  .onboarding-container-bloc {
    color: #002874; }

  .coupon-input-bloc {
    color: #002874; } }

.quick-payment {
  max-width: 800px;
  margin: auto; }

.academy-landing-page {
  padding-bottom: 0px !important; }
.digital-therapy-main-div {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 20px 0;
  color: #000000;
  .program-section-title-descritpion {
    align-self: start;
    font-weight: bold;


    &:nth-child(1) {
      padding-top: 34px; } }

  .hiw-program-black-underline {
    display: block;
    width: 130px;
    align-self: center;
    position: relative;
    z-index: 1;
    top: -22px;
    margin-left: 102px;
    @media( min-width: $bootstrap-break-old-xl ) {
      margin-left: 175px;
      width: 182px; }

    svg {
      path {
        stroke: #3D7791; } } }


  .digital-therapy-black-separator {
    background-color: #000000;
    height: 2px;
    width: 100%;
    padding: 0 10%;
    margin-bottom: 48px; }

  h1 {
    color: white;
    text-align: center;
    font-size: 25px;
    @media(min-width: $bootstrap-break-lg) {
      font-size: 40px; } }

  .digital-therapy-image {
    width: 100vw !important;
    height: 15vh !important;
    @media(min-width: $bootstrap-break-lg) {
      width: 100vw !important;
      height: 300px !important; } }

  .programs-wrapper {
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    // align-items: center
    max-width: 900px;

    .program-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 32px;
      padding-top: 32px;
      align-self: start;
      @media(min-width: $bootstrap-break-lg) {
        margin-bottom: 56px;
        flex-direction: row;
        align-items: center; }

      .text-wrapper {
        flex-direction: column;
        align-self: self-start;
        padding: 0;
        width: 100%;
        max-width: 498px;
        @media(min-width: 575px) {
          padding-left: 15vw;
          padding-right: 15vw; }
        @media(min-width: $bootstrap-break-lg) {
          padding-left: 0;
          flex-direction: column;
          padding-right: 25px; }
        a {
          width: fit-content;
          display: flex;
          font-size: 16px;
          @media(min-width: $bootstrap-break-lg) {
            font-size: 18px; }
          .chevron-image {
            transform: rotate(180deg);
            svg {
              width: 28px;
              height: 20px;
              margin-top: -4px;
              padding-right: 10px; }


            transition: margin-left 0.5s; }
          &:hover {
            text-decoration: none;
            .chevron-image {
              margin-left: 10px; } } }
        .description {
          font-size: 14px;
          font-weight: bold;
          color: #002865;
          margin-bottom: 16px;
          @media(min-width: $bootstrap-break-lg) {
            font-size: 20px;
            font-weight: 500; } }
        .explaination {
          margin-top: 16px !important;
          @media(min-width: $bootstrap-break-lg) {
            margin-top: 0;
            margin-bottom: 32px; }
          @media screen and (max-width: 320px) {
            width: 280px; } }
        .program-presentation-link {
          margin-top: 32px; }
        .program-title {
          font-size: 20px;
          margin-bottom: 0px !important;
          font-weight: 500; }
        .program-presentation-description {
          font-size: 18px;
          padding-top: 16px;
          font-weight: 500; } } }

    .program-section-title-underline {
      width: 183px;
      margin-top: -14px;
      @media (min-width: $bootstrap-break-lg) {
        width: 660px;
        padding-left: 384px;
        margin-top: -19px; }
      @media (min-width: 320) {
        width: 261px;
        padding-left: 57px;
        margin-top: -14px;
        @media screen and (max-width: 320px) {
          padding-left: 56px;
          width: 259px;
          margin-top: -14px; } } }




    .program-section-title-underline-2 {
      width: 131px;
      margin-top: -14px;
      @media (min-width: $bootstrap-break-lg) {
        width: 565px;
        padding-left: 324px;
        margin-top: -19px; }
      @media screen and (max-width: 320px) {
        padding-left: -11px;
        width: 187px;
        margin-top: -14px; } }



    .video-container {
      border: 2px solid #000000;
      box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      overflow: hidden;
      flex: 2;
      margin-top: 24px;
      width: 327px;
      @media screen and (max-width: 320px) {
        width: 280px; }
      @media(min-width: $bootstrap-break-lg) {
        transition: all .3s ease-in-out;
        width: 522px;
        &:hover {
          transform: scale(1.02); } } } } }

.program-presentation {
  .onboarding-charles-advantage-main-item {
    margin-bottom: .5rem;
    margin-top: .5rem; } }
