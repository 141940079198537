$target-platform: charles;
@import "~constants/sass/vars";

.specialty-categories-picker {
  .specialty-categories-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: .5rem;

    @media (min-width: $bootstrap-break-md) {
      column-gap: 1rem; }

    .specialty-categories-item-separator {
      flex: 0 0 auto;
      display: none;
      @media (max-width: $bootstrap-break-md) {
        &:nth-child(4n) {
          flex: 0 0 100%;
          display: block; } } }

    .specialty-categories-item-selector {
      width: 9.3rem;
      height: 9rem;
      @media (min-width: $bootstrap-break-old-xl) {
        width: 11rem;
        height: 10.5rem; }

      background-color: white;
      border: 3px solid black;
      border-radius: 1.25rem;
      box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: .5rem;
      @media (min-width: $bootstrap-break-md) {
        margin-bottom: 2rem; }

      &:hover {
        box-shadow: none; }

      @media (min-width: $bootstrap-break-md) {
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
        flex: 0 0 auto; } }

    .specialty-category-name-chevron {
      width: 6px;
      height: 6px;
      margin-left: 5px;
      margin-top: 2px;
      border-top-width: 2px; // Setting only width so that color is inherited
      border-right-width: 2px; // Setting only width so that color is inherited
      border-bottom-width: 0px; // Setting only width so that color is inherited
      border-left-width: 0px; // Setting only width so that color is inherited
      border-style: solid;
      transform: rotate(45deg); } }

  .specialty-category-icon {
    display: block;
    margin-bottom: 1rem;
    svg {
      width: 40px;
      height: 40px;
      @media (min-width: $bootstrap-break-old-xl) {
        width: 60px;
        height: 60px; } } } }

.specialty-picker {
  .specialty-category-icon {
    display: block;
    svg {
      width: 48px;
      height: 48px; } }

  .teleconsultation-doctor-image-img {
    height: 69px !important;
    width: 69px !important;
    border-radius: 12px !important;
    @media (max-width: $bootstrap-break-sm) {
      height: 112px !important;
      width: 112px !important; } }


  .block-content {
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 1rem 2rem 1.125rem;
    width: 667px;
    @media (max-width: $bootstrap-break-sm) {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      padding-bottom: 3.25rem;
      width: 314px; } }

  .doctor-name {
    font-size: 1rem;
    line-height: 19px;
    @media (max-width: $bootstrap-break-sm) {
      font-size: 1.125rem;
      line-height: 24px; } }

  .doctor-specialty {
    font-size: 0.875rem;
    line-height: 14px;
    @media (max-width: $bootstrap-break-sm) {
      font-size: 1rem;
      line-height: 24px; } }

  .doctor-button {
    padding: 1rem 1.125rem; }

  .specialty-breaker {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 2px solid gray;
    margin: auto;
    width: 678px;
    @media (max-width: $bootstrap-break-sm) {
     max-width: 100%; } }

  .home-text {
    max-width: 680px;
    margin: 0 auto 40px; }

  .see-more-button {
    border: 2px solid gray;
    padding: 1rem 1.5rem;
    font-weight: 600;
    border-radius: 1.25rem;
    min-width: 100px;
    svg {
      width: 16px;
      height: 16px;
      @media (min-width: $bootstrap-break-old-xl) {
        width: 16px;
        height: 16px; } } }

  .specialties-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;

    @media (min-width: $bootstrap-break-md) {
      column-gap: 2rem; }

    .specialties-list-item {
      flex: 0 0 100%;
      margin-bottom: 1rem;
      @media (min-width: $bootstrap-break-md) {
        flex: 0 0 auto;
        margin-bottom: 1.5rem; }

      .specialties-list-item-button {
        width: 100%;
        @media (min-width: $bootstrap-break-md) {
          width: 280px; } } }

    .specialties-list-item-separator {
      flex: 0 0 auto;
      display: none;
      @media (min-width: $bootstrap-break-md) {
        &:nth-child(4n) {
          flex: 0 0 100%;
          display: block; } } } } }
