$target-platform: charles;
@import "~constants/sass/vars";

$left-side-width: 320px;

.chatbox-v2-component {
  .show-finished-consult {
    .show-consult-toggle {
      margin-bottom: 24px;
      svg {
        width: 16px; } } }
  .ongoing-chats-container-mobile {
    .ongoing-chat {
      border: 2px solid $black;
      border-radius: 20px;
      box-shadow: 10px 10px 0 $black;
      margin-right: 10px;
      background-color: #F5F5F5;

      .ongoing-chat-doctor {
        .doctor-toggle {
          font-size: 14px;
          svg {
            width: 16px; } } }

      .ongoing-chat-footer {
        .chat-list {
          border-top: 2px solid $black;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          height: 400px; }
        .chat-form {
          border-top: 2px solid $black; } } }
    .ongoing-chats-container-mobile-svg {
      width: 16px;
      margin-right: 4px;
      * {
        fill: black; } } }


  // COMMON MOBILE / DESKTOP
  .ongoing-chats-doctor {
    cursor: pointer;
    width: 100%;

    &:not(.active).finished {
      opacity: .6; }

    .doctor-image {
      border: 2px solid #000000; }

    .doctor-right {
      .unread-message {
        font-size: 14px;
        font-weight: bold;
        color: #3D7791 !important; }
      .doctor-right-name {
        font-size: 16px;
        font-weight: bold;
        color: $black !important; }
      .doctor-right-specialty {
        font-size: 14px;
        font-weight: normal;
        color: $black !important; }
      .doctor-right-last-message {
        color: $charles-clinique-grey !important;
        height: 45px;
        font-size: 14px;
        font-weight: normal;
        text-overflow: ellipsis;
        max-width: 145px;
        overflow: hidden; }
      .doctor-right-last-message-at {
        color: #757575 !important;
        align-self: self-start;
        font-weight: 500;
        font-size: 14px; } } }

  .chat-list {
    background-color: $white;
    text-align: left;
    overflow-y: scroll;
    padding: 5px 10px; }

  .chat-patient-notification {
    background-color: $primary-color;
    color: white;
    font-size: .8em;
    margin: 0;
    opacity: 0;
    padding: .5em;
    text-align: center;
    width: 100%;
    transition: opacity 1s ease;
    display: none;
    margin-top: 20px;

    @media (min-width: $bootstrap-break-sm) {
      font-size: .9em; }

    @media (min-width: $bootstrap-break-md) {
      font-size: 1em;
      padding: 1em; }

    &.active {
      opacity: 1;
      display: block; } }

  .chat-block-info {
    position: relative;

    .message-body {
      white-space: pre-wrap;

      &:hover {
        + .chat-block-date {
          opacity: 1; } } } }

  .chat-message-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }

  .chat-block-date {
    color: $dark-grey;
    font-size: .8em;
    margin: 0 1rem;
    opacity: 0;
    transition: opacity .3s ease;

    @media (min-width: $bootstrap-break-sm) {
      font-size: 1em; }

    @media (min-width: $bootstrap-break-md) {
      opacity: 0; }

    span {
      display: block;
      font-size: .8em; } }

  .chat {
    display: flex;
    font-size: .8em;

    .displayed-message {
      padding: 0px;
      max-width: 100%; }

    p.message-body {
      transition: margin-right .3s ease;
      word-break: break-word;
      border: 2px solid $black;
      border-radius: 20px;

      a {
        text-decoration: underline; }
      p {
        @extend .displayed-message; }
      ul, ol {
        @extend .displayed-message;
        margin: 0px 0px 0px 15px; }
      li {
        br {
          display: none; } } }

    &.left {
      p {
        background-color: $black;
        color: $white;

        a {
          color: black;
          &:hover {
            color: $label-blue; } } }

      .chat-block-date {
        right: -4rem; } }

    &.right {
      justify-content: flex-end;

      .chat-message-container {
        flex-direction: row-reverse; }

      p {
        background-color: $white;
        color: $black;
        a {
          color: $black;
          &:hover {
            color: $black; } } }

      .chat-block-date {
        left: -5rem; } }

    @media (min-width: $bootstrap-break-sm) {
      font-size: .8em;
      list-style: none; }

    p {
      line-height: 1.3em;
      margin: .5em 0;
      max-width: 95%;
      padding: .8em;

      @media (min-width: $bootstrap-break-sm) {
        padding: 1em; }

      @media (min-width: $bootstrap-break-md) {
        max-width: 80%;
        padding: 1.2em; } }

    .chat-block-date {
      bottom: .8em;
      text-align: center;
      width: 3.125rem; } }

  .chat-box-container {
    @media (min-width: $bootstrap-break-sm) {
      margin-right: 2.5em;
      width: 100%; } }

  .video {
    list-style: none;
    width: 100%; }

  .chat-form-disable {
      display: none; } }
