$target-platform: charles;
@import "~constants/sass/vars";

.prescription-table {
  border: 1px solid $medium-grey;
  border-radius: 3px;
  margin: 15px 0;
  text-align: left;
  padding: 0px 8px;

  span {
    font-weight: 450; }
  ul {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  li {
    margin-bottom: 8px;
    a {
      text-decoration: underline; }

    label {
      margin-bottom: 0px; }

    h4 {
      color: $dark-grey;
      font-size: 15px;
      font-weight: 450; } } }

.phyto-prescription-explanation {
  text-align: left;
  padding: 10px 0;
  line-height: 1.3em;
  font-size: 1.1em;
  font-style: italic;

  .explanation-highlight {
    font-weight: 500; } }

.home-page-carousel {
  // Uncommenting that will cause the last slide to stop on the edge of the screen.
  // margin-right: -$page-pr-xs
  @media (min-width: $sidebar-hiding-breakpoint) {
    margin-right: -$page-pr-xl; }

  .swiper-slide {
    max-width: 400px; } }

.treatments-container {
  .carousel-cta {
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    svg {
      width: 23px !important; } }

  &.d-flex {
    .home-page-carousel {
      margin-right: 10px !important; } } } // Remove margin-right when carousel is inside a flex

.price-card-offer-custom-padding {
  padding: 24px 31px !important; }
.home-page-card {
  @media (min-width: $bootstrap-break-sm) {
    width: 100%;
    max-width: 327px; }

  .program-variant-bullets {
    .program-variant-row {
      margin-bottom: 24px;
      &:last-of-type {
        margin-bottom: 48px; } } }

  .home-page-card-list {
    list-style-position: inside;
    padding-left: 0; }

  &.program-recommendation-card {
    min-height: 400px; } }
