$target-platform: charles;
$academy-blue: #6C9EEB;
$academy-dark-blue: rgba(0, 0, 0, .1);
$academy-highlight: #ECECEC;

.lessons-list-item {
  cursor: pointer;

  &:hover {
    background-color: $academy-highlight; }
  &.selected {
    background-color: $academy-highlight;

    .lessons-list-item-title {
      text-decoration: underline; } }

  .lessons-list-item-thumbnail {
    min-width: 110px;
    min-height: 74px;
    border-radius: 8px;
    border: 1px solid #000000;

    .lesson-list-icon {
      svg {
        width: 18px;
        position: relative;
        bottom: -18px;

        path {
          fill: rgba(0, 0, 0, .5); } } } }

  .lessons-list-item-duration {
    color: white; } }
