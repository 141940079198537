$target-platform: charles;
@import "~constants/sass/vars";

.consultation-footer {
  margin-top : 1rem; }
.user-medias {
  text-align: center; }

.corona-alert {
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  color: black;
  margin-bottom: 1rem;
  border-radius: 3px;
  font-size: 16px;

  .ca-heading {
    border-radius: 5px 5px 0 0;
    background-color: #EB4749;
    font-weight: bold;
    font-size: 24px;
    color: white; }

  .ca-body {
    background-color: white;
    border-radius: 0 0 5px 5px;
    font-size: 18px; }

  svg {
    width: 32px; } }
