$target-platform: charles;
@import "~constants/sass/vars";

.block-content {
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 10px 10px 0 black;
  padding: 24px 32px;
  margin-bottom: 16px;
  background-color: $white;

  .block-with-header-inside {
    padding-top: 32px; } }

.block-content-md {
  border: 2px solid black;
  border-radius: 20px;
  background-color: $white;
  padding: 6px;

  @media (min-width: $bootstrap-break-sm) {
    padding: 24px 32px;
    box-shadow: 10px 10px 0 black; } }

.new-block-content {
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 10px 10px 0 black;
  padding: 32px 24px;
  margin-bottom: 16px;
  background-color: $white;

  .block-with-header-inside {
    padding-top: 32px; } }
