$target-platform: charles;
@import "~constants/sass/vars";

.custom-table {
  background-color: $white;
  border-collapse: collapse;
  padding: 30px 40px;
  width: 100%;

  tr {
    width: 100% !important;
    &:nth-child(even) {
      background-color: $light-grey; }

    &.legend-table {
      background-color: transparent;
      .th {
        vertical-align: center; } }

    &.table-content {
      background-color: #F1F2F9; } }

  th {
    color: #ACACAC;
    font-size: 16px;
    font-weight: 500;
    &::first-letter {
      text-transform: uppercase; } }

  td {
    color: $black;
    font-size: 14px;
    font-weight: 500;

    a {
      color: $dark-grey;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      text-decoration: underline; } }

  th,
  td {
    &:first-child {
      text-align: left; }

    &.actions {
      align-items: baseline;
      display: flex;
      justify-content: space-between; } }

  .button {
    margin: auto; } }
