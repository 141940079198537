$target-platform: charles;
@import "~constants/sass/vars";

.credit-card-block-v2 {
  .switch-credit-card-method {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
    display: block;
    text-align: right;
    margin: 1rem 0 2rem; }

  .credit-card-security {
    font-weight: bold;
    justify-content: space-between;
    align-content: center;
    align-items: flex-end;
    margin-bottom: 1.5em;

    .credit-card-payment-options {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      box-shadow: 0 1px 0 0 $v2-text-color;

      .credit-card-payment-option {
        width: 50%;
        cursor: pointer;
        margin: 0;
        text-align: center;
        padding: 20px 5px;

        &.selected {
          box-shadow: 0 -3px 0 0 $v2-text-color inset; }

        input[type='radio'] {
          vertical-align: middle;
          display: none; }

        svg {
          height: 1rem; } } } }

  .cgu-label {
    font-size: .8rem;
    cursor: pointer; } }

.field-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .field-container {
    flex-basis: 100%;
    text-align: left;
    margin-bottom: .25em;
    margin-top: .25em;

    @media (min-width: $bootstrap-break-sm) {
      margin-top: .5em;
      margin-bottom: .5em; }

    .input-text {
      display: flex;
      align-items: center; } }

  .field-container-mid {
    flex-basis: 49%;

    @media (min-width: $bootstrap-break-sm) {
      flex-basis: 48%; } }

  .input {
    margin: 0 !important; } }


.doctor-info {
  color: $blue-grey;
  font-size: .8em;
  margin: .5em;
  text-align: center;

  @media (min-width: $bootstrap-break-sm) {
    font-size: 1em; }

  @media (min-width: $bootstrap-break-md) {
    margin: .75em; }

  @media (min-width: $bootstrap-break-lg) {
    margin: 1em; }

  &::before,
  &::after {
    border-top: .01em solid $grey;
    content: '';
    display: table-cell;
    opacity: .5;
    width: 50%; } }

.payment-block {
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-height: auto;

  @media (min-width: $bootstrap-break-md) {
    padding: 14px 33px 49px; } }


.payment-container {
  margin: auto;
  max-width: 600px;
  text-align: left; }

.payment-form {
  width: 100%; }

.payment-name,
.payment-number,
.payment-expiry,
.payment-verification_value {
  .input-label {
    font-size: .6em;
    text-align: left; }

  @media (min-width: $bootstrap-break-sm) {
    font-size: 1em; } }


.accept-checkbox {
  cursor: pointer;
  font-size: 1em;
  margin: 0.5em 0;
  text-align: left;
  font-size: 0.8em;

  input {
    margin-right: .5em;
    width: initial; }

  // handle checkbox
  // sass-lint:disable force-pseudo-nesting, force-element-nesting
  [type='checkbox'],
  [type='radio'] {
    &.checked,
    &:not(.checked) {
      left: -9999px;
      position: absolute; } }

  [type='checkbox'],
  [type='radio'] {
    &.checked + label,
    &:not(.checked) + label {
      cursor: pointer;
      display: inline-block;
      padding-left: 35px;
      font-size: 0.8em;
      position: relative; } }

  [type='checkbox'],
  [type='radio'] {
    &.checked + label::before,
    &:not(.checked) + label::before {
      background: $white;
      // border-radius: 100%
      content: '';
      height: 18px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 18px; } }

  [type='checkbox'],
  [type='radio'] {
    &.checked + label::before {
      border: 1px solid #000;
      border-radius: .2em; } }

  [type='checkbox'],
  [type='radio'] {
    &:not(.checked) + label::before {
      border: 1px solid #000;
      border-radius: .2em; } }

  [type='checkbox'],
  [type='radio'] {
    &.checked + label::after,
    &:not(.checked) + label::after {
      background: url("data:image/svg+xml,<svg width=\"10\" height=\"8\" viewBox=\"0 0 10 8\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1 3.5L4 6.5L9 1\" stroke=\"black\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>");
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
      height: 12px;
      left: 3px;
      position: absolute;
      top: calc(50% - 6px);
      transform: translateY(-50%);
      transition: all .2s ease; // sass-lint:disable-line no-transition-all
      width: 12px; } }

  [type='checkbox'],
  [type='radio'] {
    &:not(.checked) + label::after {
      opacity: 0;
      transform: scale(0); } }

  [type='checkbox'],
  [type='radio'] {
    &.checked + label::after {
      opacity: 1;
      transform: scale(1); } }

  [type='checkbox'],
  [type='radio'] {
    &:not(.checked) + label.error-field::before {
      border: 1px solid $red-error; } }

  label {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    .pharmacist-delivery-note {
      font-size: 14px;
      padding-top: 20px; } }

  .label-cgu {
    width: 100%; }

  .accept-cgu-label {
    text-align: left;
    width: 100%; } }
.oldCard {
	text-align: initial;
	padding: 2%;
	color: $blue;
	font-weight: 450;
	cursor: pointer;
	list-style-type: disc;
	padding-bottom: 4%;
	align-self: flex-start;
	justify-self: flex-start;
	&:hover {
		text-decoration: underline; } }

.paypal-payment-form {
  .paypal-branding {
    text-align: center;
    padding: 30px; }

  .paypal-label {
    font-size: 25px; }

  .paypal-logo {
    margin: auto;
    margin-top: 20px;
    width: 50%; } }
