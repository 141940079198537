$target-platform: charles;
.medical-upload-form {
  text-align: center;

  input:not(.button) {
    display: none; }

  svg {
    width: 20px;
    height: 20px; } }
