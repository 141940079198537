$target-platform: charles;
@import "~constants/sass/vars";

.consultation-slots-container {
  margin-top: 16px;

  // Presentation of slots for all doctors combined
  .consultation-day-slots-container {
    margin-bottom: 16px;
    .consultation-day-slots-timezone-disclaimer {
      font-size: 14px; }

    .consultation-day-slots-bucket-title {
      font-weight: 600;
      margin-top: 24px; }

    .consultation-day-slots-all {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 8px;
      column-gap: 8px;
      max-width: 500px;
      margin: 1.5rem auto;
      @media screen and (min-width: $bootstrap-break-md) {
        grid-template-columns: repeat(4, 1fr); } } }

  // Presentation of slots of one doctor
  .consultation-slots-all-doctors {

    .consultation-slot-all-doctors-day-container {
      margin: 1.5rem auto; }

    .consultation-slots-all-doctors-day-title {
      font-size: 14px;
      font-weight: 600; }

    .consultation-slots-all-doctors-slots-container {
      display: grid;
      row-gap: 8px;
      column-gap: 8px;
      grid-template-columns: repeat(3, 1fr);
      @media screen and (min-width: $bootstrap-break-md) {
        grid-template-columns: repeat(6, 1fr); } }

    .consultation-slots-show-more {
      text-align: center;
      cursor: pointer;
      font-weight: 600; } } }
