$target-platform: charles;
@import "~constants/sass/vars";

.payment-checkout {
  .payment-checkout-block {
    .payment-checkout-block-title {
      display: flex;
      flex-direction: row;
      .block-title-text {
        margin-left: 8px;
        font-size: 16px;
        font-family: 'MaisonNeue';
        font-weight: 500; }
      svg {
        width: 24px; } }
    .payment-checkout-separator {
      border: 1px solid $black;
      padding-left: 24px;
      padding-right: 24px;
      background-color: #000000;
      opacity: 20%;
      margin-top: 12px;
      margin-bottom: 16px; }

    .payment-checkout-content {
      .payment-checkout-content-subtitle {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 500;
        margin-top: 16px; }

      .payment-checkout-content-dropdown-div {
        display: flex;
        flex-direction: column;
        @media (min-width: $bootstrap-break-md) {
          flex-direction: row;
          justify-content: space-between; }
        .payment-checkout-content-dropdown {
          display: flex;
          width: 100%;
          max-height: 34px;
          justify-content: space-around;
          margin-bottom: 16px;
          margin-top: 16px;
          align-items: center;
          border-radius: 8px;
          @media (min-width: $bootstrap-break-md) {
            max-width: 278px;
            margin-top: 0px;
            margin-bottom: 0px; }
          .dropdown-option {
            background-color: #FFFFFF;
            display: flex;
            justify-content: center; } } }

      .payment-checkout-content-price {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        margin-top: 16px; } } }
  .payment-checkout-separator-black {
    border: 1px solid $black;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #000000;
    margin-top: 12px;
    margin-bottom: 16px;
    margin-top: 40px; }
  .payment-checkout-total-price {
    display: flex;
    font-weight: 500;
    font-size: 20px;
    justify-content: space-between;
    margin-bottom: 24px; } }
