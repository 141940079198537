$target-platform: charles;
@import "~constants/sass/vars";
.booking-payment-confirmed-icon {
  display: block;
  margin-bottom: 1.1875rem;
  svg {
    height: 56px; } }

.step-back {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  .step-back-icon {
    svg {
      height: 16px;
      margin-right: 8px; } } }

.booking-payment-yellow-highlight {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='287' height='12' viewBox='0 0 287 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 8.05696C20.9349 6.461 40.0418 5.6498 59.063 4.79696C87.3406 3.5291 115.637 2.45949 143.974 2.14147C179.335 1.74461 214.875 2.07253 250.081 4.55948C261.502 5.36626 272.817 6.68062 284.207 7.58199C284.459 7.60196 285.192 7.53338 284.953 7.47404C283.69 7.16067 281.993 7.13887 280.726 7.02067C264.808 5.53535 249.214 5.12621 233.111 5.03445C191.47 4.79715 149.715 4.60371 108.107 5.98438C85.4718 6.7355 62.9732 8.67184 40.3528 9.52503C39.2277 9.56747 35.6134 9.15753 35.007 10' stroke='%23E1C341' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    top: 1.2em;
    bottom: -2em; // Large value
    width: 105%; } }

.lucas-change-onboarding-container-main {
  .confirmation-text {
    margin-bottom: 2rem; }

  .open-consultation {
    .block-content {
      padding-bottom: 1rem;
      @media (max-width: $bootstrap-break-sm) {
        padding-bottom: 1.42rem; } } }

  .return-home-button {
    display: none;
    padding: 1rem 1.125rem;
    margin-top: 2rem;
    font-size: 0.9rem;
    @media (max-width: $bootstrap-break-sm) {
      display: block !important; } } }
