
// fonts
@font-face {
  font-family: 'SFProDisplay';
  font-display: swap;
  src: url('~assets/fonts/SFPro/SF-Pro-Display-Regular.woff2'); }

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/Geomanist/Geomanist-Regular.woff2'); }

@font-face {
  font-family: 'Geomanist';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/Geomanist/Geomanist-Medium.woff2'); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-Regular.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-Medium.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-SemiBold.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-Bold.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-ExtraBold.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-Italic.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-MediumItalic.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-SemiBoldItalic.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-BoldItalic.ttf'); }

@font-face {
  font-family: 'noto-sans';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/Noto/NotoSans-ExtraBoldItalic.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-Regular.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-Medium.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-SemiBold.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-Bold.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-ExtraBold.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-Italic.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-MediumItalic.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-BoldItalic.ttf'); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf'); }

@font-face {
  font-family: 'Imperial URW';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/ImperialURW/imperialurw-reg-webfont.woff2') format('woff2'); }

@font-face {
  font-family: 'Imperial URW';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('~assets/fonts/ImperialURW/imperialurw-extbol-webfont.woff2') format('woff2'); }

@font-face {
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/MaisonNeue/MaisonNeueExtendedWEB-Medium.woff2') format('woff2'); }

@font-face {
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('~assets/fonts/MaisonNeue/MaisonNeueExtendedWEB-Demi.woff2') format('woff2'); }

@font-face {
  font-family: 'SweetSansPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~assets/fonts/SweetSansPro/sweetsanspro-regular.woff2') format('woff2'); }

@font-face {
  font-family: 'SweetSansPro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~assets/fonts/SweetSansPro/sweetsanspro-medium.woff2') format('woff2'); }

@font-face {
  font-family: 'SweetSansPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('~assets/fonts/SweetSansPro/sweetsanspro-bold.woff2') format('woff2'); }

