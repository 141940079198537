$target-platform: charles;
@import "~constants/sass/vars";

.login-container {
  flex-shrink: 0;
  margin: 1em;
  margin-top: 25px;
  max-width: 448px;
  width: 100%;
  @media (max-width: $bootstrap-break-sm) {
    .block-content {
      margin-left: 20px;
      margin-right: 30px;
      padding: 20px 24px; } } }

.login-form {
  width: 100%; }

.login-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 1em;

  .form-label {
    font-weight: 600;
    font-family: "MaisonNeue"; }

  .forgot-password {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #000; }

  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    @media (max-width: $bootstrap-break-sm) {
      font-size: 18px;
      line-height: 24px; } } }

.login-page {
  align-items: center;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  form {
    text-align: left; }

  a {
    text-decoration: underline; } }

.logo {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;

  img {
    margin: 0 auto;
    padding: 25px 0;
    width: 200px;
    @media (max-width: $bootstrap-break-sm) {
      width: 120px; } } }
