$target-platform: charles;
@import "~constants/sass/vars";

.checkout-phyto-main {
  padding: 32px 24px 0;
  h3 {
    margin-bottom: 1rem;
    @media screen and (min-width: $bootstrap-break-md) {
      margin-bottom: 2rem; } }

  .phyto-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: $bootstrap-break-md) {
      flex-direction: row;
      justify-content: center; }
    .phyto-card {
      width: 327px;
      margin-bottom: 32px;
      @media(min-width: $bootstrap-break-md) {
        width: 363px;
        &:first-child {
          margin-right: 40px; } }
      .phyto-card-carousel-part {
        transition: opacity 0.5s ease-in-out;
        @media(min-width: $bootstrap-break-md) {
          display: flex;
          overflow: hidden; }
        .phyto-card-blocks {
          @media(min-width: $bootstrap-break-md) {
            min-width: 295px; } }
        .phyto-card-first-block {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $black;
          font-size: 0.875rem;
          @media(min-width: $bootstrap-break-md) {
            border-bottom: none;
            margin-right: 32px; }
          .phyto-card-image {
            margin-bottom: 32px;
            width: 160px;
            height: 160px;
            border: 2px solid $black;
            filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
            border-radius: 16px;
            max-width: 240px;
            align-self: center; }
          .phyto-card-description {}
          .phyto-card-disclaimer {
            color: #757575; }
          .phyto-card-information {
            text-decoration: underline;
            font-weight: 500; }
          .phyto-card-next-page-button {
            @media(min-width: $bootstrap-break-md) {
              padding: 8px 22px;
              text-align: left;
              margin-bottom: 40px; }
            .phyto-card-next-page-button-text {
              margin-right: 24px; }
            .phyto-card-next-page-button-icon {
              width: 24px;
              transform: rotate(-90deg); } } }


        .phyto-card-second-block {
          padding-top: 16px;
          display: flex;
          flex-direction: column;
          @media(min-width: $bootstrap-break-md) {
            padding-top: 0; }
          &>h4 {
            margin-bottom: 16px;
            @media(min-width: $bootstrap-break-md) {
              margin-bottom: 24px; } }
          .phyto-card-subscription-time-picker {
            position: relative;
            display: flex;
            align-items: center;
            height: 38px;
            border: 2px solid $black;
            border-radius: 12px;
            justify-content: space-evenly;
            cursor: pointer;
            &-clone {
              height: 0;
              border: none;
              margin: 0; } }
          .phyto-card-subscription-time-picker-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            transition: all ease-in-out 0.5s;
            .phyto-card-subscription-time-picker-item-title {
              pointer-events: none; }
            &-active {
              position: relative;
              z-index: 10;
              color: white; }
            &-phantom-selector {
              position: relative;
              width: 0;
              height: 0;
              transition: left 0.5s ease-in-out; }
            &-selector {
              transform: translateY(-41px);
              background-color: $black;
              color: white;
              height: 44px;
              border-radius: 12px; } }

          .phyto-card-recommended {
            margin-top: 12px;
            margin-bottom: 32px;
            text-align: center; }
          .phyto-card-price-options {
            margin-bottom: 40px;
            .phyto-card-price-options-item {
              font-size: 1rem;
              font-weight: 400;
              margin-bottom: 8px;
              .phyto-card-price-options-item-title {
                display: flex;
                font-weight: 500;
                align-items: center;
                cursor: pointer;
                &>input {
                  margin-right: 16px;
                  cursor: pointer; }
                &>label {
                  cursor: pointer;
                  margin-bottom: 0; } }
              .phyto-card-price-options-item-description {
                margin-left: 29px; } } }

          .back-to-phyto-description {
            text-decoration: underline;
            text-align: center;
            font-weight: 500;
            align-self: center;
            cursor: pointer; } } }

      .phyto-card-toggle-title {
        font-size: 0.875rem;
        margin-right: 16px;
        font-weight: 600; } } } }
