$target-platform: charles;
@import "vars";
@import "fonts";
@import "survey";
@import "pages/onboarding";
@import "pages/consultation";
@import "pages/academy";
@import "stepzilla";

html {
  font-size: 16px; }

body {
  font-family: $primary-font !important;
  color: $v2-text-color;
  background-color: $v2-body-color; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $v2-title-color;
  font-family: $secondary-font;
  font-weight: 500; }

h1, .h1 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  @media (min-width: $bootstrap-break-old-xl) {
    font-size: 3.5rem;
    line-height: 4rem; } }

h2, .h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  @media (min-width: $bootstrap-break-old-xl) {
    font-size: 2rem;
    line-height: 3rem; } }

h3, .h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  @media (min-width: $bootstrap-break-old-xl) {
    font-size: 1.5rem;
    line-height: 2.5rem; } }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  @media (min-width: $bootstrap-break-old-xl) {
    font-size: 1.25rem;
    line-height: 2rem; } }

h5, .h5, h6, .h6 {
  font-size: 1rem; }

.refbs-info { // Should be replaced by text-muted
  color: $v2-muted-text-color; }

.text-highlight {
  color: $v2-highlight-text-color;
  font-weight: 600; }

.tooltip-info-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 15px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 50%;
  color: #fff;
  background-color: $v2-text-color;
  text-align: center;
  cursor: pointer; }

.max-width-500 {
  max-width: 500px; }

.text-black {
  color: black !important; }

.cursor-pointer {
  cursor: pointer; }
