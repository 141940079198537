$target-platform: charles;
@import "~constants/sass/vars";

.pharma-icon {
  svg {
    width: 18px; } }

.prescription-header {
  display: grid;
  grid-template-columns: 100px auto auto;

  .header-item {
    .doctor-image {
      border: 2px solid #000; }

    &.prescription-expiry {
      padding-top: 35px;
      text-align: right;
      font-size: 14px; }

    &.doctor-infos {
      padding-top: 5px;
      @media (min-width: 768px) {
        padding-top: 20px; }

      .expiry-ordo {
        margin-top: 15px;
        font-size: 14px; }

      .doctor-specialty {
        font-size: 14px; } } } }

.treatment-title {
  margin-top: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CDCDCD;

  .pharma-icon {
    svg {
      width: 32px; } } }

.prescription-items {
  padding-left: 20px;
  li {
    padding-top: 16px; }

  .molecule-details {
    text-decoration: underline; } }
