$target-platform: charles;
@import "~constants/sass/vars";

.credit-card {
  align-items: center;
  border: 1px solid $grey;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  justify-content: space-around;
  margin: .5em;
  max-width: 350px;
  min-width: 250px;
  padding: .25em;
  width: 100%;

  .card {
    align-items: center;
    display: flex; }


  .credit-card-icon {
    svg {
      height: 3em;
      width: 4em; } }

  .number {
    margin: .5em; }

  .exp {
    color: $grey;
    margin: .5em; }

  .delete-icon {
    cursor: pointer;

    svg {
      height: 1.2em;
      width: 1.2em; } } }
