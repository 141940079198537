$target-platform: charles;
// TODO V2 Necessary  ?
.download-prescription {
  .thank-you-container {
    text-align: center;
    padding: 5px 15px;

    .prescription-icon {
      svg {
        width: 80px;
        height: 80px; } } }

  .accept-cgu-label {
    font-size: 1.2em;
    color: #a4a4a4;

    a {
      color: #a4a4a4; } } }
