$target-platform: charles;
@import "~constants/sass/vars";

$left-side-width: 320px;

.chatbox-v2-component {
  // DESKTOP ONLY
  .ongoing-chats-container-desktop {
    border: 2px solid black;
    border-radius: 20px;
    box-shadow: 10px 10px 0 black;
    margin-right: 10px;
    background-color: $white;
    height: 60vh;
    min-height: 500px;
    overflow: hidden;

    .ongoing-chats-left {
      min-width: $left-side-width;
      overflow-y: scroll;
      border-right: 2px solid $black;

      .nav-pills.ongoing-doctor-list {
        width: $left-side-width;
        .nav-link {
          border-radius: 0; }

        .nav-link.active {
          background-color: #EEEEEE; }

        .ongoing-chats-header {
          background-color: $theme-blue;
          color: white; } } }

    .ongoing-chats-right {
      .chatbox-v2 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .chatroom {
          display: flex;
          flex-direction: column;
          margin: .5em;

          .aligned-to-left {
            text-align: left; }

          .aligned-to-center {
            text-align: center;
            list-style-position: inside; }

          .aligned-to-right {
            text-align: right;
            list-style-position: inside; }

          .underlined-message {
            text-decoration: underline; }

          .bold-message {
            font-weight: bold; }

          .italic-message {
            font-style: italic; }

          @media (min-width: $bootstrap-break-sm) {
            display: flex;
            justify-content: space-between; }

          ul {
            padding: 1em .8em 0; }

          li {
            background-color: initial;
            padding: 0; }

          .chat-form {
            margin-top: 10px;
            color: $dark-grey;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 0;
            line-height: 3em;
            outline: none;
            width: 100%;
            min-height: 100px;

            .tox-tinymce {
              margin-bottom: 10px; }

            .tox-statusbar {
              display: none; }

            .chatbox-textarea {
              border: 0;
              margin-top: 20px;
              margin-bottom: 10px;
              line-height: 1.5em;
              font-size: 1em;
              border: solid 1px $medium-grey;
              padding: 5px 10px;

              @media (min-width: $bootstrap-break-sm) {
                font-size: 1em; } } } }

        .chat-form-disable {
          height: 131px;
          width: 100%;
          display: grid;
          place-items: center;
          border-top: 2px solid black;
          @media (max-width: $bootstrap-break-sm) {
            display: none; }

          .chat-form-disable-text {
            font-weight: 500;
            font-size: 1.25rem; } } } } } }

