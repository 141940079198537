$target-platform: charles;
@import "~constants/sass/vars";

.dowload-prescription-container {
  .download-prescription-block {
    .block-content {
      padding-left: 20px;
      padding-right: 20px; }
    .recommendation-title {
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: black 1px solid;
      @media (min-width: $bootstrap-break-lg) {
        font-size: 2rem;
        margin-top: 24px;
        padding-bottom: 16px; } }
    .download-prescription-links {
      font-size: 12px;
      color: #000000;
      text-decoration: underline;
      word-break: break-word;
      u {
        font-weight: 300; } } } }
