$target-platform: charles;
@import "~constants/sass/vars";

.setup-media-wizard {
  .grant-wording {
    font-weight: 700;
    position: relative; }

  .grant-underline {
    position: absolute;
    top: 20px;
    left: -25%;
    width: 150%; }

  .central-icon {
    width: 180px;
    display: block;
    margin: 20px auto;

    @media (max-width: $bootstrap-break-sm) {
      width: 100px; } }

  .configuration-error-message {
    color: red;
    font-weight: bold; }

  .logo-icon {
    display: block;
    width: 126px;
    height: 126px;
    margin: 20px auto; } }
