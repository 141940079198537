$target-platform: charles;
@import "~constants/sass/vars";

.treatment-recommendation {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 1rem;
  margin-bottom: 1.5rem;
  .treatment-recommendation-title {
    display: flex;
    padding-bottom: 8px;
    font-size: 1.25rem;
    line-height: 32px;
    font-weight: 500;
    border-bottom: 2px solid black;
    @media (max-width: $bootstrap-break-sm) {
      font-size: 1rem;
      line-height: 24px; } }

  .treatment-prescription {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    border-radius: 16px;
    padding: 0px 0px 0px 24px;
    gap: 24px;
    @media (max-width: $bootstrap-break-sm) {
      padding: 0px 0px 0px 16px;
      gap: 16px; }

    .treatment-prescription-items {
      width: 599px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;
      gap: .5rem;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      @media (max-width: $bootstrap-break-lg) {
        width: 157px; }
      .treatment-prescription-items-list {
        display: none;
        list-style-position: inside;
        padding-left: 0;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        @media (max-width: $bootstrap-break-sm) {
          display: block; } }

      .treatment-prescription-items-large {
        list-style-type: none;
        padding-left: 0;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        .treatment-molecule-details {
          list-style-type: disc;
          margin-left: 1rem;
          padding-bottom: .5rem; }
        @media (max-width: $bootstrap-break-sm) {
          display: none; } } }

    .treatment-plan-btn {
      width: 105px;
      background: $treatment-order-btn-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 700 !important;
      padding: 10px;
      gap: 4px;
      border-left: 2px solid #000000;
      border-radius: 0px 16px 16px 0px;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      cursor: pointer;
      font-size: 12px;
      line-height: 14px;
      @media (max-width: $bootstrap-break-md) {
        padding: 8px !important; }
      .icon {
        margin-right: 0px !important; }
      .treatment-plan-btn-label {
        text-align: center; }
      @media (max-width: $bootstrap-break-lg) {
        width: 89px; } }

    .program-btn {
      padding: 0px;
      gap: 10px;
      @media (max-width: $bootstrap-break-sm) {
        padding: 0px 8px !important; }

      .program-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: 20px;
        gap: 4px;
        @media (max-width: $bootstrap-break-sm) {
          padding: 8px; } }

      .external-program-link {
        @extend .program-link;
        padding: 0px;
        text-align: center; } }


    .treatment-order-btn-disabled {
      opacity: 0.3;
      pointer-events: none; }

    .treatment-prescription-warning {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      border-top: 1px solid #CCCCCC;
      padding-top: .5rem;
      order: 1;
      align-self: stretch;
      flex-grow: 0; }

    .treatment-program {
      width: 599px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px 0px;
      gap: 8px;
      order: 0;
      align-self: stretch;
      flex-grow: 1;
      @media (max-width: $bootstrap-break-lg) {
        width: 157px; }
      .treatment-program-description {
        font-size: 16px;
        line-height: 24px;
        @media (max-width: $bootstrap-break-sm) {
          font-size: 14px; }
        .treatment-program-title {
          font-weight: 500; } } } } }

.text-decoration-underline {
  text-decoration: underline; }

.w-fit-content {
  width: fit-content; }

.green-prescription-card {
  .h2, h2 {
    color: white; } }

.prescription-program-wrapper {
  margin: 32px 0; }

.grey-prescription-card {
  .saxenda-card-list {
    color: #808080; } }

.big-green-text {
  font-size: 24px;
  font-weight: 500;
  color: #DFF39E; }

.refund-text {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  @media(min-width: $bootstrap-break-xl) {
    font-size: 14px; } }

.saxenda-card {
  padding: 24px !important;
  max-width: 768px;
  .saxenda-card-price {
    font-size: 24px; }

  .saxenda-card-grid {
    display: grid;
    grid-template-columns: 4fr minmax(90px, 1fr);
    grid-auto-rows: 1fr;
    border-bottom: 1px solid #8294736B;
    border-right: 1px solid #8294736B;
    border-radius: 0 16px 16px 16px;
    margin-bottom: 34px;
    @media(min-width: $bootstrap-break-xl) {
      grid-template-columns: 4fr 1fr 1fr; }

    &>div {
      border-top: 1px solid #8294736B;
      border-left: 1px solid #8294736B;
      padding: 12px 8px;
      display: flex;
      align-items: center;
      @media(min-width: $bootstrap-break-xl) {
        padding: 12px 16px; }
      &:nth-child(2) {
        @media(min-width: $bootstrap-break-xl) {
          border-radius: 16px 0 0 0; } }
      &:nth-child(3) {
        border-radius: 0 16px 0 0; } }
    .empty-cell {
      border: none;
      grid-area: 1 / 1 / 1 / 1; }
    .double-cell {
      padding: 12px 4px;
      @media(min-width: $bootstrap-break-xl) {
        grid-column: span 2; } }
    .centered-cell {
      display: flex;
      justify-content: center;
      align-items: center; }
    .logo-cell {
      grid-column: span 2;
      border-radius: 16px 16px 0 0;
      @media(min-width: $bootstrap-break-xl) {
        grid-column: initial;
        border-radius: 0 16px 0 0; } }
    .top-left-rounded {
      @media(min-width: $bootstrap-break-xl) {
        border-radius: 16px 0 0 0; } }
    .bottom-left-rounded {
      border-radius: 0 0 0 16px; }
    .charles-icon {
      width: 80px;
      svg {
        path {
          fill: white; } } } }
  .saxenda-card-list {
    margin-bottom: 32px;
    .saxenda-card-list-argument {
      .saxenda-card-list-argument-icon {} } } }


