$target-platform: charles;
@import "~constants/sass/vars";

.chatbox-v1 {
  .chatroom {
    display: flex;
    flex-direction: column;
    margin: .5em;

    .aligned-to-left {
      text-align: left; }

    .aligned-to-center {
      text-align: center;
      list-style-position: inside; }

    .aligned-to-right {
      text-align: right;
      list-style-position: inside; }

    .underlined-message {
      text-decoration: underline; }

    .bold-message {
      font-weight: bold; }

    .italic-message {
      font-style: italic; }

    @media (min-width: $bootstrap-break-sm) {
      display: flex;
      justify-content: space-between; }

    ul {
      padding: 1em .8em 0; }

    li {
      background-color: initial;
      padding: 0; }

    .chat-form {
      margin-top: 10px;
      color: $dark-grey;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      line-height: 3em;
      outline: none;
      width: 100%;
      min-height: 100px;

      .tox-tinymce {
        margin-bottom: 10px; }

      .tox-statusbar {
        display: none; }

      .chatbox-textarea {
        border: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        line-height: 1.5em;
        font-size: 1em;
        border: solid 1px $medium-grey;
        padding: 5px 10px;

        @media (min-width: $bootstrap-break-sm) {
          font-size: 1em; } } }

    button {
      &[type='submit'] {
        background-color: $primary-color;
        border: 0;
        color: $white;
        font-size: .8em;
        font-weight: 500;
        text-align: center;
        min-height: 30px;

        @media (min-width: $bootstrap-break-sm) {
          font-size: 1em; }

        &:hover {
          background-color: darken($primary-color, 5%); } } } }

  .chats {
    background-color: $white;
    border: solid 1px $medium-grey;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    text-align: left;

    .chat-list {
      max-height: 550px;
      overflow-y: scroll;
      padding: 5px 10px; } }

  .chat-patient-notification {
    background-color: $primary-color;
    color: white;
    font-size: .8em;
    margin: 0;
    opacity: 0;
    padding: .5em;
    text-align: center;
    width: 100%;
    transition: opacity 1s ease;
    display: none;
    margin-top: 20px;

    @media (min-width: $bootstrap-break-sm) {
      font-size: .9em; }

    @media (min-width: $bootstrap-break-md) {
      font-size: 1em;
      padding: 1em; }

    &.active {
      opacity: 1;
      display: block; } }

  .chat-block-info {
    position: relative;

    .message-body {
      white-space: pre-wrap;

      &:hover {
        + .chat-block-date {
          opacity: 1; } } } }

  .chat-message-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }

  .chat-block-date {
    color: $dark-grey;
    font-size: .8em;
    margin: 0 1rem;
    opacity: 1;
    transition: opacity .3s ease;

    @media (min-width: $bootstrap-break-sm) {
      font-size: 1em; }

    @media (min-width: $bootstrap-break-md) {
      opacity: 0; }


    span {
      display: block;
      font-size: .8em; } }

  .chat {
    display: flex;
    font-size: .8em;

    .displayed-message {
      padding: 0px;
      max-width: 100%; }

    .message-body {
      transition: margin-right .3s ease;
      word-break: break-word;
      a {
        text-decoration: underline; }
      p {
        @extend .displayed-message; }
      ul, ol {
        @extend .displayed-message;
        margin: 0px 0px 0px 15px; }
      li {
        br {
          display: none; } } }

    &.left {
      p {
        background-color: $light-grey;
        a {
          color: black;
          &:hover {
            color: $label-blue; } } }

      .chat-block-date {
        right: -4rem; } }

    &.right {
      justify-content: flex-end;

      .chat-message-container {
        flex-direction: row-reverse; }

      p {
        background-color: $primary-color;
        color: $white;
        a {
          color: $white;
          &:hover {
            color: $light-grey; } } }

      .chat-block-date {
        left: -5rem; } }

    @media (min-width: $bootstrap-break-sm) {
      background-color: $white;
      font-size: .8em;
      list-style: none; }


    p {
      line-height: 1.3em;
      margin: .5em 0;
      max-width: 95%;
      padding: .8em;

      @media (min-width: $bootstrap-break-sm) {
        padding: 1em; }

      @media (min-width: $bootstrap-break-md) {
        max-width: 80%;
        padding: 1.2em; } }


    .chat-block-date {
      bottom: .8em;
      text-align: center;
      width: 3.125rem; } }



  .chat-box-container {
    @media (min-width: $bootstrap-break-sm) {
      margin-right: 2.5em;
      width: 100%; } }

  .video {
    list-style: none;
    width: 100%; } }
