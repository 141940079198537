$target-platform: charles;
@import "~constants/sass/vars";

.analysis-notice {
  color: white;
  padding: 4px 16px 3.5px 16px;
  border-radius: 8px;
  width: auto;
  font-size: 12px;
  margin-right: 0px;

  &.consultation_needed {
    background-color: #FF270E; }

  &.consultation_not_needed {
    background-color: #26D126; } }

.close-popup {
  text-align: center; }

.doctor-bloc-note {
  border: 2px solid #ccc;
  background-color: #eee;
  border-radius: 5px;
  padding: 16px;
  margin: 16px 0;

  &::after {
    content: "";
    clear: both;
    display: table; }

  .doctor-name {
    color: #228be6;
    font-size: 18px; }

  .consultation-result {
    font-size: 16px;
    color: #000;
    margin-right: 5px; }

  .analysis-infos {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.1px;
    line-height: 1.24;
    margin-right: 15px;
    margin-top: 8px; }

  .extra-margin {
    margin-top: 15px; }

  @media (min-width: $bootstrap-break-sm) {
    .container-warning {
      margin-left: 100px; }

    .doctor-image {
      float: left;
      margin-right: 20px;
      border-radius: 50%; }

    .analysis-comment-body {
      margin-left: 100px;
      margin-top: 15px;
      font-size: 14px;
      line-height: 1.5; } }

  @media (max-width: $bootstrap-break-sm) {
    text-align: center;
    .doctor-image {
      margin: auto;
      float: none;
      display: block; }

    .analysis-comment-body {
      text-align: left;
      line-height: 1.5;
      font-size: 14px; } } }

%shared-analysis-warn {
  position: relative;
  padding: 0;
  margin: 0 !important; }

.analysis-warning-message {
  color: #FF270E;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px; }

.analysis-warn {
  @extend %shared-analysis-warn;
  font-size: 14px;
  color: red;
  &::before {
    @extend %shared-analysis-warn; }
  &::after {
    @extend %shared-analysis-warn; }

  &.analysis-warning {
    display: inline-block;
    top: 0.225em;
    width: 1.15em;
    height: 1.15em;
    overflow: hidden;
    border: none;
    background-color: transparent;
    border-radius: 0.625em;

    &::before {
      content: "";
      display: block;
      top: -0.08em;
      left: 0.0em;
      position: absolute;
      border: transparent 0.6em solid;
      border-bottom-color: #fd3;
      border-bottom-width: 1em;
      border-top-width: 0; }

    &::after {
      color: $red !important;
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 0 1px;
      text-align: center;
      font-family: "Garamond";
      content: "!";
      font-size: 0.65em;
      font-weight: bold;
      color: #FFF; } } }
