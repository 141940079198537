$target-platform: charles;
@import "~constants/sass/vars";

.display-row {
  @media (min-width: $bootstrap-break-sm) {
    display: flex;
    flex-direction: row; } }


.chat-form-block {
  margin-bottom: 1em;

  @media (min-width: $bootstrap-break-sm) {
    margin-bottom: 3em;
    margin-right: 3em; } }


.chat-title {
  line-height: 1.3em; }

.chat-cta {
  color: $deep-blue;
  text-decoration: underline; }

.chat-picto {
  path {
    fill: $blue; }

  svg {
    height: 1.5em; } }

.chat-link {
  color: $deep-blue; }
