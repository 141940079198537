$target-platform: charles;
@import "~constants/sass/vars";

.green-card-pill {
  background-color: #4F785F;
  color: white;
  .program-reduction-percent {
    color: black;
    background-color: #50E19C; } }

.grey-card-pill {
  background-color: white;
  color: black;
  .program-reduction-percent {
    background-color: #B3B3B3; } }
