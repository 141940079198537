$target-platform: charles;
.custom-slider {
  .navigation-controls {
    display: flex;
    align-items: center;
    justify-content: center; }

  .navigation-control {
    position: relative;
    padding: 0;
    border-radius: 50%;
    height: 48px;
    width: 48px;

    &:after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-top-width: 2px; // Setting only width so that color is inherited
      border-right-width: 2px; // Setting only width so that color is inherited
      border-bottom-width: 0px; // Setting only width so that color is inherited
      border-left-width: 0px; // Setting only width so that color is inherited
      border-style: solid; }

    &.slider-prev:after {
      top: 18px;
      left: 20px;
      transform: rotate(-135deg); }

    &.slider-next:after {
      top: 18px;
      left: 18px;
      transform: rotate(45deg); } }

  .swiper-pagination-bullets {
    display: flex;
    .swiper-pagination-bullet {
      margin: 0 4px;
      &.swiper-pagination-bullet-active {
        background: #000000; } } }

  .swiper-scrollbar {
    display: none; } }


.custom-slider.custom-slider-stretch-items {
  .swiper-wrapper {
    align-items: stretch; }
  .swiper-slide {
    height: auto; }
  .block-content {
    height: 98%; } }

