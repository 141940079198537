$target-platform: charles;
@import "~constants/sass/vars";


.confirmation-payment-checkout {
  .confirmation-payment-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    svg {
      display: flex;
      justify-content: center;
      max-width: 80px;
      margin-bottom: 32px; }
    .confirmation-payment-header-text {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 36px; } }

  .confirmation-payment-content {
    .confirmation-payment-content-block {
      .content-block-title {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        svg {
          width: 32px;
          margin-right: 14px; }
        span {
          font-weight: 500;
          font-size: 16px; } }

      .payment-checkout-separator {
        border: 1px solid $black;
        padding-left: 24px;
        padding-right: 24px;
        background-color: #000000;
        opacity: 20%;
        margin-top: 16px;
        margin-bottom: 36px; } }

    .confirmation-payment-button {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      @media ( min-width: $bootstrap-break-md ) {
        flex-direction: row;
        align-items: flex-start; }

      .button-start-program {
        padding-left: 16px;
        padding-right: 16px; } } } }



