$target-platform: charles;
.sticky-cta {
  position: fixed;
  background-color: white;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-top: 2px solid black; }

.checkout-cart {
  font-weight: 500; }

.checkout-cart-product-count {}

.checkout-cart-total {}

