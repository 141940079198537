$target-platform: charles;

@import "~constants/sass/vars";

.orders-history {
  .custom-table {
    a,
    &:first-child {
      color: $medium-grey;
      text-decoration: none; } }

  ul {
    border-bottom: 1px solid $grey;
    margin: .5em;
    padding: .5em; }

  .button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > button {
      width: 100%; }

    @media (min-width: 1024px) {
      > button {
        max-width: 325px; } } }

  p {
    font-weight: 450;
    margin-bottom: 50px; } }

