$target-platform: charles;
@import "~constants/sass/vars";

.join-container {
  text-align: center;
  padding-top: 2.5em;
  max-width: 76%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: $bootstrap-break-sm) {
    max-width: 100%; }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0; }

  .block {
    text-align: left;
    max-width: 75%;
    margin: auto;
    background: $white;
    padding: 20px;
    border-radius: 1.25rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    .sub-block {
      text-align: center;
      padding: 1em;
      background: $v2-body-color;
      border-radius: 1.25rem;

      span.line {
        display: inline-block; }

      svg {
        width: 135px;
        margin-right: 0.25em;
        margin-bottom: 0.5em; }

      .password-section {
        background: $white;
        border: 1px solid $black;
        border-radius: 0.75rem;
        padding: 0.4em 1em;
        margin: 1.5em 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $bootstrap-break-sm) {
          flex-direction: column; }

        p {
          margin: auto; }

        svg {
          width: 2em;
          margin-bottom: 0; } }

      .list-section {
        ul {
          padding-left: 0;
          list-style-position: inside; } }

      .cercle-presentation {
        width: 100%;
        margin-bottom: 1.5em; } } } }
