$target-platform: charles;
.visio-box-with-setup-container {
  padding: 20px;
  background-color: lightgrey;

  .visio-box-with-setup-content {
    max-width: 448px;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    text-align: center; } }
