$target-platform: charles;
@import "~constants/sass/vars";

.onboarding-charles-advantages {
  text-align: left;
  padding-left: 0;
  list-style: none;
  padding: 1rem 0;
  white-space: pre-wrap;

  &:first-child {
    padding-top: 0; }

  .onboarding-charles-advantage {
    padding-top: 1rem;
    padding-bottom: 1rem;
    svg {
      height: 32px;
      margin-right: 8px; }
    &-main {}
    &-details {
      margin-top: 1rem; }

    .onboarding-charles-advantage-main {
      &.clickable {
        cursor: pointer; }

      .expand-button {
        &::before {
          display: inline-block;
          content: "";
          height: 8px;
          width: 8px;
          border-right: 0 solid #000;
          border-top: 0 solid #000;
          border-color: #000;
          border-style: solid;
          border-width: 2px 2px 0 0;
          box-sizing: border-box;
          transform: rotate(135deg);
          transition-duration: .2s;
          margin-right: 2px; } }

      &.expanded {
        .expand-button {
          &::before {
            transform: rotate(-45deg); } } } } }

  &.with-details {
    .onboarding-charles-advantage {
      &:not(:last-child) {
        border-bottom: 1px solid black; } } }

  &:not(:last-child) {
    border-bottom: 2px solid black; } }

.onboarding-charles-advantages {
  &.white-variant {
    .onboarding-charles-advantage-main-item {
      color: white; }
    .expand-button {
      &::before {
        border-right: 0 solid #ffffff;
        border-top: 0 solid #ffffff;
        border-color: #ffffff;
        border-style: solid;
        border-width: 2px 2px 0 0; } } } }

