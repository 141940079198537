$target-platform: charles;
@import "~constants/sass/vars";

.infos-container {
  flex: 1;
  max-width: 100%;

  .section-arguments {
    @media (min-width: $bootstrap-break-md) {
      max-width: 400px;
      margin-left: 20px; } } }

.error-tag {
  color: #AC0E1D;
  padding-left: 36px; }

.pharmacy-choice {
  .onboarding-charles-advantages {
    padding: 0; }
  .notice-block {
    font-size: 18px;
    margin-bottom: 16px;
    text-align: left;
    text-decoration: underline;
    @media (max-width: 767px) {
      padding-left: 5px;
      margin-top: 15px;
      margin-bottom: 40px;
      text-decoration: none; }
    @media(min-width: $bootstrap-break-md) {
      margin-bottom: 56px; }
    @media(min-width: $bootstrap-break-lg) {
      margin-bottom: 16px; }

    .warning-icon {
      svg {
        width: 20px;
        @media (min-width: 768px) {
          width: 22px;
          padding-bottom: 4px; } } } } }

.clinic-medical-container {
  .clinic-medical-row {
    text-align: left;
    vertical-align: text-top;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 20px;

    svg {
      width: 20px;
      height: 20px; }

    .doctor-section {
      .doctor-infos-block {
        display: grid;
        grid-template-columns: 100px auto;
        .doctor-full-name {
          margin-bottom: 1em; }
        img {
          border: 2px solid #000; } } }

    .context-link {
      @media (max-width: $bootstrap-break-sm) {
        text-align: center; }
      max-width: 210px;
      text-decoration: underline;
      a {
        max-width: 210px;
        font-weight: normal; } }

    .date-section {
      @media (max-width: $bootstrap-break-sm) {
        text-align: center; }
      max-width: 210px;
      font-size: 14px;
      .range {
        font-weight: 500; } } } }

//Page Home
.page {
  .prescription-purchase-container {
    max-width: 600px; }
  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $bootstrap-break-old-xl) {
      flex-direction: row;

      & > div + div {
        margin-left: 3em; } }

    .container-content {
      text-align: center;

      .consultation-countdown {
        color: $blue;
        font-size: 1.2em;
        font-weight: 500;
        margin: 0; }

      .consultation-notification {
        font-weight: 500; } }

    .button-container {
      display: flex;
      justify-content: center; } } }

// Page medical info
.medical-info {
  .medical-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $bootstrap-break-lg) {
      flex-direction: row; } } }


.consultations-history,
.prescriptions-history {
  border-bottom: 1px solid $nav-grey;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > * {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    width: 100%;

    > div {
      width: 50%; } }

  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center; }

  .item-cta {
    align-items: center;
    display: flex;
    flex-direction: row; }

  label {
    color: $grey;
    font-size: .8em;
    font-weight: 500;
    text-transform: uppercase; }

  p {
    font-size: .8em;
    font-weight: 500; }

  a {
    cursor: pointer;
    margin: auto;
    text-decoration: underline; } }

// Page new consultation
.new-consultation,
.pharmacy,
.page {
  .select-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px; }

  .select-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @media (min-width: $bootstrap-break-sm) {
      margin-bottom: 0; } }

  .icon {
    display: inline-block;
    height: 26px;
    margin-right: 10px;
    width: 26px;

    &.reverse {
      transform: rotate(180deg); } } }

.home {
  .container-title {
    .icon {
      svg {
        g {
          fill: $white; } } } }

  .health-professional-info-container {
    .right-block {
      .button {
        margin: .5px; } } } }

.new-consultation {
  .prev {
    margin-top: 40px; }

  .consultations-block,
  .media-block {
    align-items: flex-start;
    border-radius: 3px;

    .block-title {
      color: $label-blue;
      text-align: left;
      width: 100%; }

    .infos-consultation {
      margin-top: 45px;
      width: 100%; }

    .info-item {
      align-items: flex-start;
      border-bottom: 1px solid $border-label;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding: 15px 0;

      label {
        cursor: pointer;
        font-weight: 450;
        position: relative;
        text-align: left;
        width: 100%;

        span {
          background-color: $label-blue;
          border-radius: 50px;
          color: $white;
          height: 16px;
          padding: 2px 5px 0;
          position: absolute;
          right: 0;
          text-align: center;
          top: 5px;
          width: 16px; } }

      p {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        height: 0;
        justify-content: center;
        margin: 0;
        min-height: 0;
        overflow: hidden;
        transition: min-height, height, .5s; }

      &.active {
        p {
          height: auto;
          margin: 1em 0;
          min-height: 40px; } }

      &:last-child {
        border-bottom: 0; } } }

  .media-block,
  .name-info-block {
    width: 100%; }

  .confirmation {
    .container-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      > * {
        text-align: center;
        width: 100%; }

      .img-container {
        align-items: center;
        display: flex; }

      @media (min-width: $bootstrap-break-lg) {
        flex-direction: row;
        > * {
          width: 50%; } } } }

  .confirmation {
    .container-content {
      @media (min-width: $bootstrap-break-md) {
        flex-direction: column;
        > * {
          width: auto; } }

      .doctor-image {
        border-radius: .5em;
        height: 3.5em;
        margin: 1em 0 .5em;
        width: 3.5em;

        @media (min-width: $bootstrap-break-sm) {
          height: 3.125em;
          margin: 0 0 .5em;
          width: 3.125em; } } } } }


.pharmacy {
  .links-container {
    margin: 0px;
    text-align: center;

    .submit-delivery {
      margin-top: 12px;
      font-size: 18px;
      width: 100%; }

    .button {
      align-items: center;
      border: 0;
      display: flex;
      justify-content: center;
      margin: 0 auto 1em; } } }

.pharmacist {
  margin-bottom: 1em;

  &.pharmacist-infos {
    text-align: left;
    padding-right: 60px;
    border-right: 1px solid $medium-grey;
    @media (max-width: $bootstrap-break-lg) {
      padding-bottom: 40px;
      padding-right: 0px;
      border-right: none;

      &.pharmacist-infos-custom {
        border-bottom: 2px solid #000; } } }

  .prescription-history-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 24px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    @media(min-width: $bootstrap-break-md) {
      flex-direction: row; }
    &:last-of-type {
      border-bottom: none; }
    a {
      text-decoration: underline;
      cursor: pointer; }
    .doctor-profile-specialty-name {
      font-weight: 500;
      font-size: 16px; }
    .doctor-profile-specialty-prescription {
      font-weight: 400;
      font-size: 14px; }
    .prescription-availability-div {
      @media(min-width: $bootstrap-break-lg) {
        max-width: 200px;
        width: 100%; }
      font-size: 14px;
      .prescription-availability-date {
        font-weight: 500; } }
    .show-prescription-button {
      margin-bottom: 32px;
      @media(min-width: $bootstrap-break-lg) {
        margin-bottom: 0;
        max-width: 220px;
        width: 100%; } }

    .prescription-delivery-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      @media(min-width: $bootstrap-break-lg) {
        width: initial;
        justify-content: initial; }
      .prescription-delivery-button {
        width: 258px;
        text-decoration: none;
        @media(min-width: $bootstrap-break-lg) {
          width: initial; } } }

    .prescription-delivery-button-placeholder {
      visibility: hidden;
      pointer-events: none; } } }

.consultation-history-title {
  margin-top: 48px;
  margin-bottom: 16px; }

.prescription-history-row-icon {
  margin-right: 8px;
  svg {
    width: 20px;
    height: 20px; } }

.doctor-profile-div {
  min-width: 210px;
  @media(min-width: $bootstrap-break-lg) {
    max-width: 222px;
    width: 100%; } }
.doctor-profile-picture-prescription {
  width: 60px;
  height: 60px;
  border: 1px solid black;
  border-radius: 12px;
  margin-right: 8px; }

.consultation-calendar-picture {
  display: flex;
  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px; } }

.consultation-calendar-text {
  font-size: 14px; }

.consultation-calendar-date {
  font-weight: 500; }

.consultation-history-link {
  @media(min-width: $bootstrap-break-lg) {
    min-width: 200px; } }

.consultations-history-row {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 32px;
  @media(min-width: $bootstrap-break-lg) {
    justify-content: space-between;
    align-items: center; }
  &:first-of-type {
    padding-top: 0; }
  &:last-of-type {
    border: none; } }

.consultation-advise-card {
  font-size: 16px;
  text-decoration: underline;
  a {
    font-weight: 400; } }
.consultation-status-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  border-radius: 16px;
  border: 2px solid #000000;
  height: 35px;
  font-weight: 700;
  font-size: 16px; }

.started-status-label {
  background-color: #50E19C;
  color: white;
  border: none; }

.finished-status-label {
  opacity: 50%; }
// account
.existing-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .credit-card {
    flex: 0 0 23.5%; } }


// Page download prescription
.download-prescription {
  .container-content {
    text-align: center;

    p {
      color: $grey;
      font-size: 16px;
      font-weight: normal;
      margin: 15px auto;
      max-width: 300px; } }

  h3 {
    font-size: 20px; }

  .health-professional-info-container {
    margin: 1em 0;

    img {
      border-radius: 2px solid #000; }

    .right-block {
      flex-direction: column;

      h4 {
        color: $light-dark;
        font-size: 13px; }
      p {
        color: $grey;
        font-size: 16px;
        font-weight: normal;
        text-align: right;
        text-decoration: underline; } } } }
