@import "~constants/sass/vars";

.form-control {
  &:focus {
    border-width: 2px;
    box-shadow: none; } }

.form-label {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: .875rem; }

.invalid-feedback {
  font-weight: 500;
  font-size: .875rem; }
