@charset "UTF-8";
.prescription-card .prescription-card-origin-icon svg {
  width: 4rem; }

.prescription-card .prescription-card-list {
  list-style-type: none; }
  .prescription-card .prescription-card-list .prescription-card-item svg {
    width: 24px;
    height: 24px;
    margin-right: 8px; }
  .prescription-card .prescription-card-list .prescription-card-item .prescription-card-item-dosage::before {
    content: "•";
    margin: 0 8px; }
  .prescription-card .prescription-card-list .prescription-card-item-show-more {
    display: flex;
    justify-content: center;
    font-family: 'Sweet Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    cursor: pointer; }
    .prescription-card .prescription-card-list .prescription-card-item-show-more svg {
      width: 16px;
      margin-right: 8px; }

.prescription-card .prescription-card-expired {
  opacity: 0.40; }

.prescription-card .expiry-label {
  color: #d93868;
  text-align: center; }
