$target-platform: charles;
@import "~constants/sass/vars";

.bulletpoint-container {
  .bulletpoint-item {
    text-align: center;
    padding: 1rem;
    width: 100%;
    height: 100%;
    min-width: 140px;

    .values-section {
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }

    h3 {
      font-size: 16px;
      line-height: 24px;
      font-weight: lighter;
      margin: 0; }

    .hour-value {
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      margin: 0; }

    .action {
      padding-top: 5px;
      padding-left: 10px;
      font-size: 14px; } } }
