$target-platform: charles;
@import "~constants/sass/vars";


// Page pharmacy
.pharmacy-container {

  &::after {
    content: "";
    clear: both;
    display: table; }

  .column {
    float: left;
    width: 8%;
    padding: 5px; }

  .delivery-img {
    padding-right: 0px;
    padding-top: 10px;

    .img-fluid {
      width: 113px; } }

  .delivery-infos-block {
    float: left;
    width: 92%;
    padding: 5px;

    .delivery-infos {
      font-weight: bold;
      color: #708DBB;
      line-height: 1.3em;

      li {
        font-size: 12px; } } } }
