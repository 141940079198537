$target-platform: charles;
@import "~constants/sass/vars";

.checkout-choice-bullets-svg {
  width: 32px;
  height: 32px; }
.checkout-choice-bullets-body {
  max-width: 214px;
  padding-left: 8px; }
.checkout-presentation-bullets-svg {
  width: 40px;
  height: 40px; }
.checkout-presentation-bullets-body {
  font-weight: 500;
  margin-left: 8px; }
.checkout-button-view-more {
  width: 100%;
  display: block;
  @media(min-width: $bootstrap-break-md) {
    max-width: 330px; } }

.header-presentation {
  display: flex;
  flex-direction: column;
  @media(min-width: $bootstrap-break-lg) {
    flex-direction: row;
    display: flex;
    justify-content: space-around; }
  .title-body-presentation {
    text-align: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 32px;
    @media(min-width: $bootstrap-break-lg) {
      justify-content: start;
      text-align: left;
      max-width: 524px;
      padding-bottom: 0px; } }
  .subtitle-price {
    font-weight: 700;
    @media(min-width: $bootstrap-break-lg) {
      padding-bottom: 40px; } }
  .step-header {
    text-align: center;
    @media(min-width: $bootstrap-break-lg) {
      text-align: left; } }
  .subtitle-presentation {
    flex-direction: column;
    padding-bottom: 16px;
    @media(min-width: $bootstrap-break-md) {
      font-weight: 400; } }
  .subtitle-presentation-duration {
    text-decoration: underline; }
  .video-container {
    border: 2px solid #000000;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    flex: 2;
    margin-bottom: 32px;
    @media screen and (max-width: 320px) {
      width: 280px; }
    @media(min-width: $bootstrap-break-lg) {
      margin-bottom: 0px;
      max-height: 212px;
      max-width: 488px; }
    @media screen and (min-width: 1200px) {
      max-height: 274px;
      margin-left: 66px;
      transition: all .3s ease-in-out;
      width: 522px;
      &:hover {
        transform: scale(1.02); } } } }

.info-container-academy {
  .info-row-academy {
    justify-content: space-around;
    @media(min-width: $bootstrap-break-lg) {
      padding-bottom: 40px;

      .info-body {
        display: flex;
        flex-direction: column;
        @media(min-width: $bootstrap-break-lg) {
          flex-direction: row;
          justify-content: center space-around;
          svg {
            width: 40px;
            height: 40px; } } } } } }


.selection-program-title {
  font-size: 18px;
  font-family: MaisonNeue, Calibri, Helvetica, Sans-Serif;
  font-weight: 500;
  margin-bottom: 32px;
  .selection-program-title-price {
    font-size: 12px; } }



