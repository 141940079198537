$target-platform: charles;
@import "~constants/sass/vars";

.sb-question-body {
  background-color: $white;
  margin-bottom: 1em;
  padding: 0;

  @media (min-width: $bootstrap-break-sm) {
    margin-bottom: 0; } }


.sb-question-summary {
  display: flex; }

.sb-question-title {
  color: $light-blue;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -.1px;
  line-height: 1.5em;
  margin: 1.5em 0 0; }

.sb-answer {
  background-color: $light-grey;
  color: $grey;
  font-weight: 500;
  margin: 0 1em 0 0;
  padding: .5em 1em; }

.thumbY {
  background-color: $deep-blue !important; } // sass-lint:disable-line no-important
