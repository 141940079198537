
.survey-container {
  .sv_container {
    // HACK to fix 300 min-width imposed by surveyJS (before I can find a way to disable it)
    margin-right: -5px;
    margin-left: -5px;
    .sv_completed_page, .sd-completedpage {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      text-align: center;
      padding: 20px;
      h3 {
        margin-bottom: 0; } } } }

.page-content {
  .survey-container {
    .sv_container {
      // HACK to fix 300 min-width imposed by surveyJS (before I can find a way to disable it)
      margin-right: -1.5rem;
      margin-left: -1.5rem; } }

 }  // SURVEY SPECIFIC
.question-header {
  text-align: center;
  margin-bottom: 2rem;
  @media (min-width: $bootstrap-break-md) {
    margin-bottom: 2.5rem; } }


.question-label {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }

.sv_row:not(:last-child) {
  padding-bottom: 2em; }

.sv_qstn {
  align-items: center;
  padding-bottom: 0.5em; }

.sv_qcbc {
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.sv_qstn {
  .sv-q-col-1 {
    padding: 0 !important; } } // This is fixed in other themes of surveyjs but not the default one

.sv_q_required_text {
  display: none; }

.survey-error {
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #D74848; }

table.sv_q_matrix {

  .sd-table__row {
    text-align: left; }

  label {
    margin-bottom: 0; }
  @media screen and (min-width: $bootstrap-break-md) {
    thead {
      th {
        font-size: .9em;
        font-weight: normal;
        min-width: 92px;
        vertical-align: middle;
        text-align: center; } }
    tbody {
      tr, th {
        td:not(:first-child) {
          padding: 0;
          vertical-align: middle;
          text-align: center;

          label.sv_q_m_label {
            cursor: pointer;
            position: static;
            display: block;
            width: 100%;

            input {
              cursor: pointer; } } } } } }

  @media screen and (max-width: $bootstrap-break-md - 1) {
    thead {
      display: none; }
    tr {
      display: block;
      margin-top: 8px; }
    td {
      display: block;
      position: relative;
      &:first-child {
        font-weight: 500; }
      &:first-child, &:nth-child(2) {
        border-top: none; }
      &:not(:first-child) {
        label.sv_q_m_label {
          cursor: pointer;
          left: 0;
          padding-left: inherit;
          // Force 100% width of label to allow clicking on label in mobile to select the answer
          position: absolute;
          margin: 0px;
          display: block;
          width: 100%; }
        &:after {
          padding-left: 1.5em;
          // Keeps fallback title attribute for legacy surveyjs that could hold a matrix in v1
          content: attr(data-responsive-title) attr(title); } } } } }

$default-question-color: black;
$disabled-question-color: rgba(0,0,0, .3);

// Global answer styling
@mixin hover-onboarding-choice {
  box-shadow: none;
  font-weight: bold; }

@mixin active-onboarding-choice {
  border: 3px solid $default-question-color;
  font-weight: bold; }

@mixin disabled-onboarding-choice {
  border: 2px solid $disabled-question-color;
  color: $disabled-question-color;
  cursor: default; }

.onboarding-choice {
  // Shared styling
  text-align: left;
  background-color: white;
  padding: 1rem 1.5rem;
  min-width: 100px;
  border: 2px solid $default-question-color;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;

  &:hover {
    @include hover-onboarding-choice; }

  &:active, &:focus, &.active {
    @include active-onboarding-choice; } }

.onboarding-choice.disable-onboarding-choice {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  pointer-events: none; }

.survey-item, .sv_q_rating, .survey-imgsel {
  &.survey-item-disabled {
    .onboarding-choice {
      &, &:hover, &:active, &:focus, &.active {
        @include disabled-onboarding-choice; } } }

  &:not(.survey-item-disabled) {
    &.sd-item--checked, &.checked {
      .onboarding-choice {
        @include active-onboarding-choice; } }

    // Heterogenous handling by surveyjs for active & checked classes for different questions
    // .checked is set on the item for radiogroup & checkbox questions
    // .active is set on the label for ratings
    &:not(.sd-item--checked), &:not(.checked) {
      .onboarding-choice {
        &:not(.active):hover {
          @include hover-onboarding-choice; } } }

    .onboarding-choice {
      &:active, &:focus, &.active {
        @include active-onboarding-choice; }

      .sv_q_radiogroup_control_item {
        display: none; }

      input:focus {
        outline: none; } } }

  label {
    &.form-check-label {
      cursor: pointer; } } }

.sd-action-bar {
  display: flex;
  justify-content: center;
  column-gap: 8px; }

.sv-hidden {
  display: none; }

.sv-action--hidden {
  display: none; }
// END Global answer styling

// Custom checkboxes
.survey-checkbox-control {
  display: none; }

.survey-checkbox-decorator {
  line-height: 0;
  margin-right: 1rem;
  position: relative;

  &::after {
    content: ' ';
    border: 2px solid $default-question-color;
    border-radius: 3px;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 0;
    left: 0; } }

.survey-checkbox-svg {
  height: 16px;
  fill: transparent;
  width: 16px; }

.survey-item {
  &.sd-checkbox--checked {
    .survey-checkbox-svg {
      fill: $default-question-color; } }

  &.sd-checkbox--allowhover {
    .survey-checkbox-svg {
      fill: transparent; }
    &.survey-item-disabled {
      .survey-checkbox-decorator::after {
        border-color: $disabled-question-color; } } } }


// END Custom checkboxes


// Start image selector
.survey-root-imgsel {

  @media screen and (min-width: $bootstrap-break-lg) {
    display: flex;
    gap: 16px;

    .survey-imgsel-inline {
      flex: 1 1 0; } }

  .survey-imgsel-label {
    width: 100%;
    height: 100%;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: start; }

    @media screen and (max-width: $bootstrap-break-lg) {
      .survey-imgsel-image {
        margin-right: .5rem; } }

    @media screen and (min-width: $bootstrap-break-lg) {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .survey-imgsel-image {
        margin-bottom: 1rem; } } } }

// End image selector

// Start file upload
.sv_q_file {
  .file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center; }
  .survey-file-decorator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 24px 0;

    @media screen and (min-width: $bootstrap-break-md) {
      border: 2px dashed $theme-rust;
      padding: 24px 32px; }

    .sv_q_file_choose_button {
      svg {
        display: none; } } }


  .sv_q_file_preview {
    position: relative;
    display: block;
    margin-top: 1rem;
    width: min-content;

    img {
      height: 200px; }

    .file-sign-bottom {
      font-size: 0.8rem;
      word-break: break-all;
      min-width: 100px; } }

  .survey-remove-file-svg {
    position: absolute;
    height: 2rem;
    width: 2rem;
    z-index: 5;
    top: -1rem;
    left: -1rem;
    cursor: pointer;

    svg {
      fill: $theme-rust; } } }
// End file upload

// Start custom rating

.sd-rating__item {
  input {
    display: none; } }

// End custom rating
