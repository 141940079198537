$target-platform: charles;
@import "~constants/sass/vars";

.attendance-survey-container {
  background-color: white;
  border: 2px solid black;
  border-radius: 20px;

  .sv_main {
    span {
      word-break: initial; } }
  .sv_page_title {
    padding: 32px 32px 24px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    .sv-string-viewer {
      min-width: 300px;
      flex: 1 1 100%;
      width: 100%; } }

  .question-header {
    text-align: left;
    margin-bottom: 1rem;
    align-self: flex-start; }

  .sv_row {
    padding: 0 32px 0;
    display: flex;
    justify-content: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    &:not(:last-child) {
      padding-bottom: 3rem; }
    .sv_qstn {
      align-items: flex-start;
      .question-title {
        font-family: $primary-font;
        font-size: 16px;
        font-weight: 500;
        text-align: left; }

      textarea {
        resize: vertical !important;
        min-height: 160px;
        max-height: 500px; }
      &>div {
        width: 100%; }
      @media(min-width: $bootstrap-break-md) {
        .sv_qcbc {
          display: grid;
          grid-template-columns: 35% 35%;
          grid-template-rows: auto auto;
          grid-auto-flow: column;
          width: 100%;
          &>.survey-item {
            display: flex;
            flex-basis: 35%;
            &.checked {
              .question-label {
                box-shadow: none; } } } } }

      .question-label {
        text-align: left;
        background-color: initial;
        padding: 0 1rem 0 0;
        min-width: initial;
        border: initial;
        font-weight: initial;
        box-shadow: initial;
        &:active, &:focus, &.active, &.checked {
          box-shadow: none !important; }
        .sv_q_radiogroup_control_item {
          display: initial !important;
          margin-right: 1rem; } } } }

  .sv_q {
    width: 100% !important;

    @media (min-width: $bootstrap-break-sm) {
      width: 60% !important; } }

  .sv_q_matrix {
    .sv_q_m_cell:first-child {
      padding-left: 0;
      @media (min-width: $bootstrap-break-md) {
        padding-left: 12px; } }
    @media (min-width: $bootstrap-break-md) {
      thead {
        tr:first-child {
          td {
            border-top: none; }
          th {
            border-top: none;
            border-bottom-width: 1px;
            min-width: initial; } } } } }

  .sv_completed_page, .sd-completedpage {
    padding: 20px 10px; }

  .onboarding-container-footer {
    margin-top: 2rem;
    margin-bottom: 2rem; } }
