$target-platform: charles;
@import "~constants/sass/vars";

.pharmacist-profile {
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    padding: 1em; }

  .pharmacist-name {
    display: flex;
    color: $v2-dark-text-color;
    font-size: 20px;
    font-weight: bold; }

  .pharmacy-reinsurance-wrapper {
    gap: 8px;
    flex-wrap: wrap;
    .pharmacy-reinsurance {
      border-radius: 8px;
      border: 1px solid #79747E;
      display: flex;
      align-items: center;
      padding: 4px 16px 4px 8px;
      font-weight: normal;
      font-size: 14px;
      .pharmacy-reinsurance-icon {
        width: 16px; } } }

  .pharmacy-reinsurance-title {
    margin-top: 28px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500; }

  .pharmacy-info-icon {
    cursor: pointer;
    svg {
      width: 20px;
      @media (min-width: 768px) {
        width: 22px; } } }

  .pharmacist-name-hidden {
    display: none; }

  .pharmacy-address-info {
    &:hover {
      .pharmacist-name-hidden {
        display: flex; } } }


  .pharmacist-address {
    color: $v2-dark-text-color;
    font-weight: 400; } }

.popin {
  position: relative;
  .popin-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    visibility: hidden;
    width: 180px;
    background-color: white;
    border-radius: 16px;
    padding: 8px 12px;
    text-align: left;
    position: absolute;
    z-index: 1;
    top: 125%;
    right: 9px;
    transform: translate(10%);
    border: 1px solid black;
    font-size: 14px;
    font-weight: 400;
    @media (min-width: $bootstrap-break-lg) {
      right: 11px; }


    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      right: 13px;
      @media (min-width: $bootstrap-break-lg) {
        right: 12px; }
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #000000 transparent; } } }


.popin-content.show-popin {
    visibility: visible;
    transition: opacity 1s; }


// TODO V2 REFACTOR - all of this is used elsewhere
.health-professional-info-container {
  background-color: $bg-white;
  border: solid 1px $light-grey;
  color: $black;
  display: flex;
  flex-direction: column;
  margin: .5em;
  padding: .5em;

  @media (min-width: $bootstrap-break-sm) {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between; }

  @media (min-width: $bootstrap-break-md) {
    flex-direction: colum;
    align-items: center; }

  @media (max-width: $bootstrap-break-md) {
    // move center block to bottom on mobile
    .center-block {
      order: 99; } }

  .health-profesionnal-qualifications {
    list-style: inherit;
    text-align: left;
    font-size: 0.9em; }

  &.pharmacist {
    border: solid 1px $blue; }

  .left-block,
  .right-block {
    align-items: center;
    display: flex;

    @media (min-width: $bootstrap-break-sm) {
      align-items: center; } }

  .left-block {
    flex-direction: row;

    @media (min-width: $bootstrap-break-sm) {
      justify-content: flex-start; }

    @media (max-width: $bootstrap-break-sm) {
      justify-content: center; } }

  .right-block {
    flex-direction: column;
    font-size: .8em;
    justify-content: center;

    @media (min-width: $bootstrap-break-lg) {
      flex-direction: row; } } }


.health-professional-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: $bootstrap-break-sm) {
    flex-direction: row; } }

.selected-slot-day {
  color: $black;
  font-size: 1.3em;
  font-weight: 500; }

.selected-slot-hour {
  // background-color: $light-grey
  // border-radius: 2em
  color: $black;
  font-size: 1.3em;
  font-weight: 500;
  margin-left: 1.5em;
  text-align: center;

  @media (min-width: $bootstrap-break-sm) {
    margin-left: .5em; } }

.health-pro-name {
  span, a {
    color: $black; } }


.health-pro-identity {
  display: flex;
  flex-direction: column;
  height: 3.5em;
  justify-content: center;
  margin: .7em;
  text-align: left; }

.doctor-image {
  border-radius: .5em;
  height: 5em;
  width: 5em;
  margin: .5em 0; }


.health-pro-info {
  color: $blue-grey;
  font-size: .9em;
  margin: .3em 0;
  text-align: center;

  @media (min-width: $bootstrap-break-sm) {
    text-align: left; } }


.filled-green-circle {
  background-color: $green;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px; }

.filled-red-circle {
  @extend .filled-green-circle;
  background-color: $red; }

.hours-available {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;

  @media (min-width: $bootstrap-break-sm) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em; } }

.recap {
  background-color: $green-success;
  border: $green-success;

  &:hover {
    color: $white; } }

.recap-card {
  margin: 0; }
