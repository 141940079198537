$target-platform: charles;
@import "~constants/sass/vars";

.step-academy-payment-variants {
  display: block;
  width: 100%;

  .program-payment-variant {
    background-color: initial;
    width: 100%;

    .program-payment-variant-title {
      font-weight: 700;
      font-size: 18px; }

    .program-payment-variant-content {
      font-size: 14px; }

    .program-payment-variant-due-date {
      font-weight: bold;
      margin-right: 4px; } } }

.price-div {
  margin-bottom: 16px;
  margin-top: -16px;

  .time-variant-economy {
    width: 159px;
    height: 32px;
    border: #00E094 1px solid;
    color: #00E094;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -16px;
    right: -44px;
    background-color: white;
    text-transform: uppercase;

    &.visibility-hidden {
      visibility: hidden; } } }

.time-variant-choice {
  box-shadow: 0 3px 6px #0000000D;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 16px;
  max-width: 250px;
  margin-bottom: 32px;
  @media(min-width: $bootstrap-break-lg) {
    margin-bottom: 0; }
  &--svg {
    svg {
      height: 140px; } }

  &--title {
    font-size: 18px;
    font-weight: bold;
    color: #1F3A64;
    margin-top: 16px;
    margin-bottom: 8px; }

  &--price {
    font-size: 20px;
    font-weight: bold;
    color: #1F3A64; }

  &--comparative-full-price {
    margin-top: 4px;
    font-size: 12px;
    font-weight: normal;
    margin-left: 4px; }

  &--bullets {
    font-size: 16px;
    &>div {
      margin-bottom: 8px;
      div {
        margin-left: 4px; } } }

  &--button {
    font-size: 16px;
    padding: 6px 32px; } }

.phone-charles-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.phone-charles-block {

  @media (min-width: $bootstrap-break-md) {
    margin-top: 10px;
    margin-bottom: 10px; }

  p.phone-charles-block-inner {
    border-radius: 3px;
    align-items: center;
    background: $blue;
    color: $white;
    display: flex;
    font-size: .7em;
    justify-content: center;
    padding: .2em;
    position: inherit;
    margin-top: 1rem;
    margin-bottom: 0;

    .icon {
      display: inline-block;
      width: 14px;

      @media (min-width: $bootstrap-break-md) {
        width: 16px; } }

    .phone-icon {
      margin-right: 5px; }

    a {
      color: $white;
      text-decoration: none; }

    @media (min-width: $bootstrap-break-md) {
      font-size: .9em;
      padding: 0 1em; } } }

.step-academy-payment-submenu {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 5px;
  @media (min-width: $bootstrap-break-md) {
    grid-gap: 10px; }

  .step-academy-payment-submenu-block {
    display: flex;
    justify-content: space-between;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    padding: 5px 8px;
    cursor: pointer;
    @media (min-width: $bootstrap-break-md) {
      padding: 16px; }

    &.selected {
      background-color: #F8FAFF;
      border: 1px solid $primary-color; }

    .step-academy-payment-submenu-title {
      align-self: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;

      .step-academy-payment-submenu-price {
        font-weight: normal; } }

    .step-academy-payment-submenu-right {
      display: flex;

      .step-academy-payment-submenu-radio-input {
        align-self: center;
        transform: scale(1.5); } } }

  .step-academy-payment-submenu-block-mia {
    &.selected {
      background-color: white; } } }

.academy-payment-container {
  margin: auto;
  max-width: 600px;
  text-align: left; }

label.no-color-on-errors {
  color: $v2-text-color !important; }

.academy-thankyou-image {
  svg {
    width: 100px; } }
