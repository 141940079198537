$target-platform: charles;
@import "~constants/sass/vars";

.block-content-delivery-checkout {
  padding: 32px 24px !important;
  max-width: 700px;
  @media ( min-width: $bootstrap-break-md ) {
    padding: 24px 32px !important; } }



.delivery-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  .delivery-warning-phyto {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    align-items: center;
    margin-bottom: 24px;
    svg {
      max-width: 32px;
      width: 100%;
      margin-right: 16px; }
    div {
      padding-left: 16px; } }
  .payment-checkout-separator {
    border: 1px solid $black;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #000000;
    opacity: 20%;
    margin-top: 12px;
    margin-bottom: 16px; }
  .delivery-selection-div {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .delivery-selection-content {
      display: flex;
      flex-direction: column;
      .delivery-selection-label {
        padding-top: 16px;
        @media ( min-width: $bootstrap-break-md ) {
          padding-top: 0px;
          &:nth-child(1) {
            padding-bottom: 16px; } }

        @media ( min-width: $bootstrap-break-md ) {
          padding-right: 36px;
          max-width: 286px; }
        .delivery-selection-text {
          font-size: 16px;
          font-weight: 400;
          .delivery-selection-text-description {
            font-size: 13px; } } } } }

  .checkout-delivery-button {
    margin-top: 32px; } }

