$target-platform: charles;
.secure-challenge-popup {
  width: 100%;
  max-width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  row-gap: 16px;
  text-align: center; }

.secure-challenge-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  *, *::before, *::after {
    box-sizing: content-box; } }

.secure-challenge-svg {
  svg {
    width: 32px; } }
