$target-platform: charles;
@import "~constants/sass/vars";
@import "~constants/sass/mixins";

.program-lessons-list-container {
  border: 2px solid #000000;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vw * 0.45);
  margin-top: 15px;
  background-color: $white;

  .program-lessons-list {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll; }

  @media (max-width: $bootstrap-break-md) {
    height: auto;
    overflow-y: visible;
    border: none;
    box-shadow: none;

    .program-lessons-list {
      height: auto;
      overflow-y: visible;
      background-color: $theme-beige;
      position: relative;

      .lessons-list-item.selected {
        background-color: transparent; } } }

  @media (min-width: $bootstrap-break-md) {
    margin-top: 0px; }

  @media (min-width: $bootstrap-break-old-xl) {
    height: 600px; } }


.lessons-list-container {
  .button {
    width: inherit; } }

.program-presentation-button {
  margin-top: 32px;
  margin-bottom: 40px; }

.video-thumbnail-container {
  padding-bottom: 56.25%;
  height: 0;
  position: relative;

  #video-player {
    background: #C9C9C9; }

  #video-player, .video-thumbnail-cover {
    border: 2px solid #000000;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    z-index: 1; }

  .video-thumbnail-cover {
    background-color: rgba(0, 0, 0, .6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    line-height: 1.2em;
    font-weight: 500;
    font-size: 14px;

    @media (min-width: $bootstrap-break-md) {
      font-size: 18px; } }

  img.video-thumbnail-cover-image {
    border-radius: 16px; } }
