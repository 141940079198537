$target-platform: charles;
@import "~constants/sass/vars";

.program-presentation {
  @media ( min-width: $bootstrap-break-lg) {
    margin-left: -8px;
    margin-right: -8px; }
  .container {}

  .program-section {
    .img-fluid {
      width: 40px; }
    &:not(:first-child) {
      padding-top: 40px; }
    &:not(:last-child) {
      padding-bottom: 40px; } }

  .program-section-content {
    padding: 20px;

    .program-section-image {
      object-fit: contain;
      width: 100%; } }

  .program-section-title {
    text-align: center;
    margin-bottom: 30px; }

  .text-wrapper {
    display: flex; }

  @media(max-width: 756px) {
    .argument-box {
      margin-bottom: 2em; } }

  .program-bullet-icon {
    margin-bottom: 24px; }
  .program-efficiency-icon {
    margin-bottom: 24px; }
  .program-efficiency-label {
    font-weight: 500; }

  .program-why-efficient-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    @media(min-width: $bootstrap-break-lg) {
      flex-direction: row;
      align-items: initial; } }

  .program-why-efficient-card {
    background-color: #FFFFFF;
    max-width: 300px;
    width: 100%;
    border-radius: 8px;
    max-height: 570px;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    margin: 16px 8px;
    @media(min-width: $bootstrap-break-lg) {
      margin: 0 8px; } }

  .program-how-it-works-bullet-container {
    @media(min-width: $bootstrap-break-lg) {
      &:nth-child(2) {
        margin-top: 64px; }
      &:nth-child(3) {
        margin-top: 128px; } }
    .program-how-it-works-bullet-content {
      padding: 22px;

      .program-how-it-works-icon {
        background-color: white;
        border-radius: 8px;
        width: 360px;
        padding: 28px;
        box-shadow: 0 0 15px #0000001A; }

      .program-how-it-works-bullet-content-body {
        max-width: 360px;
        margin: auto;

        .program-how-it-works-number {
          color: #647591;
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 20px;
          @media(min-width: $bootstrap-break-lg) {
            margin-bottom: 40px; } } } } }


  &.mia {
    .program-section-title {
      display: flex;
      justify-content: center;
      margin-bottom: 0; } } }

.academy-testimonial {
  height: 100%;
  padding: 20px;
  background-color: white;

  .academy-testimonial-comment {}
  .academy-testimonial-author {
    svg {
      width: 20px !important;
      margin-right: 10px; } } }

.program-testimony-div {
  max-width: 900px; }

.testimony-program {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: $bootstrap-break-old-xl) {
    flex-direction: row;
    padding-top: 64px; }

  .testimony-program-text {
    width: 100%; }

  .testimony-program-title {
    padding-top: 56px;
    font-family: $secondary-font;
    font-size: 24px;
    margin-bottom: 16px;
    @media( min-width: $bootstrap-break-old-xl) {
      padding-top: 0;
      font-size: 32px; } }

  .testimony-program-subtitle {
    font-size: 16px;
    margin-bottom: 40px; }
  .testimony-program-container {}
  .testimony-program-author {
    padding-top: 16px; }
  .navigation-controls {
     margin-top: 32px !important; }
  .testimony-program-carousel {
    width: 100%;
    max-width: 492px;
    .testimony-program-carousel-slide {
      .testimony-program-carousel-slide-div {
        padding: 29px 25px; } } } }

.testimony-program-charles {
  padding-bottom: 76px;
  margin-left: -1rem;
  margin-right: -1rem; }


.program-testimony-wrapper {
  display: flex;
  background-color: white;
  box-shadow: 0 0 6px #0000001A;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
  max-width: 100%;
  @media(min-width: $bootstrap-break-lg) {
    max-width: 60%; }
  &:nth-child(2) {
    align-self: flex-end; } }

.program-testimony-icon {
  margin-right: 4px;
  svg {
    width: 30px; } }

.program-testimony-text {
  color: #647591;
  font-size: 16px; }

.program-testimony-info {
  color: #647591;
  font-size: 16px;
  font-weight: 600;
  align-self: flex-end; }

.landing-text-accordion {
  font-size: 20px;
  line-height: 1.2rem;
  text-align: left;
  color: #647591;

  .accordion__button {
    font-weight: bold;
    font-size: 18px;
    outline: none;
    color: #1F3A64; }
  .accordion__panel {
    font-size: 18px;
    line-height: 1.1rem;
    padding: 0; } }

.accordion__item + .accordion__item {}
// border-top: 1px solid rgba(0, 0, 0, 0.1)

.mia-text-accordion {
  font-size: 20px;
  line-height: 1.2rem;
  text-align: left;
  color: #003060;
  .program-section-title {
    padding-top: 16px; }
  .accordion-item {
    margin-bottom: 16px;
    background-color: #FFF6E9;
    padding-bottom: 16px;
    @media(min-width: $bootstrap-break-md) {
      min-width: 300px; }
    max-width: 824px;
    width: 100%;
    &:last-child {
      margin-bottom: 0; } }
  .accordion-item-div {
    width: 30px;
    margin-right: 16px;
    margin-left: 10px;
    .accordion-button-icon {
      background-size: 30px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F9D6C2;
      padding: 6px;
      width: 30px !important;
      height: 30px !important;
      svg {
        width: 18px;
        height: 18px; } } }

  .accordion__button {
    padding-top: 16px;
    padding-bottom: 0; }

  .accordion-button-label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    * {
      margin-bottom: 0;
      margin-top: 0; }
    @media(min-width: $bootstrap-break-lg) {
      font-size: 1rem;
      font-weight: 500; } }

  .program-section-content {
    display: flex;
    flex-direction: column;
    align-items: center; }

  .accordion-body {
    background-color: #FFFFFF;
    font-size: 14px;
    margin: 16px 24px 0 24px;
    padding: 8px 16px; } }

.faq-custom-accordion-button {
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  &:focus {
    outline: none; } }

.accordion-button-label {
  font-size: 20px;
  font-weight: 700 !important;
  * {
    line-height: 1.4em;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0; } }

.accordion__button {
  cursor: pointer;
  padding: 20px 0 8px;
  width: 100%;
  border: none;
  position: relative; }

.accordion__button {
  &:focus {
    outline: none; }

  .accordion-chevron-wrapper {
    height: 24px;
    width: 24px;
    background-color: #002865;
    border-radius: 50%;
    flex-shrink: 0;
    @media(min-width: $bootstrap-break-sm) {
      height: 28px;
      width: 28px; } }

  .accordion-chevron {
    position: relative;
    color: white; }

  .accordion-chevron:before {
    content: ' ';
    display: block;
    height: 10px;
    width: 10px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
    margin-left: -3px;
    text-decoration: none; } }

.accordion__button[aria-expanded='true'],
.accordion__button[aria-selected='true'] {
  .accordion-chevron::before {
    transform: rotate(45deg);
    margin-left: 1px;
    margin-bottom: 2px;
    text-decoration: none; } }

.accordion__panel {
  padding: 20px 0;
  animation: fadein 0.35s ease-in;
  transition: visibility 0.5s; }

.accordion-body {
  font-size: 18px;
  margin-left: 38px;
  transition: visibility 0.5s;

  .accordion-item {
    margin-bottom: 16px;
    background-color: #FFF6E9;
    padding-bottom: 16px;
    min-width: 300px;
    max-width: 824px;
    width: 75vw;
    &:last-child {
      margin-bottom: 0; } } }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.academy-section-coach {
  background-color: #F9F9F9;

  .academy-coach-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 25px;
      color: #1F3A64;
      font-weight: 500;
      text-align: center;
      width: 321px;
      margin: 32px 0;
      @media(min-width: $bootstrap-break-lg) {
        font-size: 35px;
        width: auto;
        margin: 56px 0; } } } }

.featured-doctor {
  .featured-image {
    border-radius: 30px;
    box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
    border: 2px solid black;
    height: 246px;
    width: 327px;
    object-fit: cover;
    object-position: top;
    @media screen and (max-width: 320px) {
      width: 280px; } }

  span {
    width: 100%;
    max-width: 590px;
    font-size: 15px;
    display: flex;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 16px;
    color: #1F3A64; }

  .description {
    width: 100%;
    max-width: 590px;
    color: #1F3A64;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 24px; } }

.profile {
  margin-left: 60px;
  border-radius: 4px;
  padding: 16px;

  .doctor-informations {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: $bootstrap-break-lg) {
      align-items: flex-start; }

    p {
      color: #647591;
      font-size: 18px;
      margin-bottom: 24px; } }

  .doctor-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0; }

  .doctor-presentation {
    font-size: 14px;
    color: #1F3A64;
    margin-bottom: 0;
    text-align: justify; }

  .doctor-description {
    font-size: 14px;
    color: #1F3A64;
    margin-bottom: 0;
    @media(min-width: $bootstrap-break-lg) {
      padding-inline-start: 20px; } }

  @media(max-width: $bootstrap-break-md) {
    margin: auto; }

  .program-access {
    color: white;
    margin-left: -6px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center; }

  .buttonStyle {
    background-color: #6d9eeb !important;
    border-color: grey;
    width: 100%;
    border-radius: 9px;
    font-size: 17px;
    font-weight: bold;
    padding: 5px; } }

.profile.mia {
  @media(min-width: $bootstrap-break-lg) {
    padding: 0;
    width: 384px; }

  .doctor-informations {
    margin-top: 24px;
    &:first-child {
      margin-top: 0; } }

  .doctor-description {
    font-size: 14px;
    color: #1F3A64;
    margin-bottom: 0;
    margin-top: 8px;
    @media(min-width: $bootstrap-break-lg) {
      padding-inline-start: initial; } } }


.doctor-rounded-thumbnail {
  width: 70px; }


.coach-details-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $bootstrap-break-md) {
    width: 308px; }
  @media(min-width: $bootstrap-break-old-xl) {
    flex-direction: row;
    width: auto;
    justify-content: center;
    margin-top: 64px;
    align-items: flex-start; } }

.coach-details-title {
  text-align: center; }


.testimony-section {
  display: flex;
  align-items: center;
  @media(min-width: $bootstrap-break-lg) {
    padding-bottom: 14px; }

  .testimony-book-div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 920px;
    @media(min-width: 1250px) {
      flex-direction: row; }

    .testimony-book {
      display: none;
      &:nth-child(3) {
        margin-top: 56px;
        margin-left: 0;
        margin-right: 40px;
        align-self: flex-end; }
      height: 298px;
      background-color: white;
      color: #003060;
      font-size: 16px;
      align-self: center;
      @media(min-width: $bootstrap-break-md) {
        display: flex;
        margin-left: 40px;
        align-self: flex-start; }


      .testimony-svg-div {
        width: 0;
        .testimony-desktop-svg {
          position: relative; }
        .testimony-desktop-flower {
          left: -30px;
          top: -30px;
          svg {
            height: 60px; } }

        .testimony-desktop-target {
          left: -20px;
          top: 240px;
          svg {
            height: 60px; } } }

      .name-age {
        font-weight: 600;
        margin-top: 8px; }
      .left-page {
        display: flex;
        width: 286px;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #003060;
        padding-left: 30px;
        padding-right: 30px;
        border-right: none;
        @media(min-width: $bootstrap-break-lg) {
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px; } }
      .right-page {
        display: flex;
        width: 286px;
        flex-direction: column;
        justify-content: center;
        border: 1px solid #003060;
        border-left: 0;
        @media(min-width: $bootstrap-break-lg) {
          padding: 16px 20px 16px 30px;
          justify-content: space-between; } } }

    .border-div {
      border: 1px solid #003060;
      border-left: 0;
      width: 5px; } }

  .responsive-testimony-book {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;
    border: 1px solid #003060;
    padding: 16px;
    font-size: 14px;
    margin: 16px;
    @media(min-width: $bootstrap-break-md) {
      display: none; }
    .responsive-testimony-author {
      margin-top: 16px;
      font-weight: bold; } }

  .cta-testimony-desktop {
    margin-top: 56px;
    margin-bottom: 44px; } }

.about-program {
  margin-left: -1rem;
  margin-right: -1rem;
  color: white;
  @media ( min-width: $bootstrap-break-old-xl) {
    padding: 48px 39px 0px 39px; }


  .about-program-container {
    padding-bottom: 60px;
    border-bottom: 1px solid black;
    .about-program-h2 {
      color: white;
      padding-top: 48px;
      text-align: center;
      @media(min-width: $bootstrap-break-old-xl) {
        text-align: left;
        margin-bottom: 60px; } }
    .about-program-second-col {
      @media(min-width: $bootstrap-break-old-xl) {
        margin-top: -45px; } }
    .about-program-block {
      padding: 32px;
      .about-program-block-content {
        margin-bottom: 40px; }
      .about-program-block-bottom {
        font-weight: 700; } }
    .about-program-block-image {
      width: 70px;
      display: block;
      align-self: flex-end; } } }

.hiw-program {
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-direction: column;
  // padding-top: 48px
  @media ( min-width: $bootstrap-break-old-xl ) {
    padding: 48px 39px 0px 39px; }

  h2 {
    position: relative;
    z-index: 2; }
  .hiw-program-black-underline {
    display: block;
    width: 183px;
    align-self: center;
    position: relative;
    z-index: 1;
    top: -22px; }

  .hiw-program-container {
    margin-top: 96px;
    .hiw-start-button {
      margin-bottom: 40px;
      @media(min-width: $bootstrap-break-old-xl) {
        margin-bottom: 88px; } } }

  .hiw-program-row {
    @media(min-width: $bootstrap-break-old-xl) {
      margin-bottom: 64px; }
    .hiw-program-col {
      margin-bottom: 64px;
      @media(min-width: $bootstrap-break-old-xl) {
        margin-bottom: 0; }
      &:last-child {
        margin-bottom: 32px;
        @media(min-width: $bootstrap-break-old-xl) {
          margin-bottom: 0; } }
      &:first-child {
        .hiw-program-col-img {
          overflow: initial;
          img {
            display: block;
            width: initial;
            height: 321px;
            margin-top: -79px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 20px;
            @media screen and ( min-width: 320px ) {
              width: 100%; } } } }


      .hiw-program-col-img {
        border-radius: 30px;
        box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
        border: 2px solid black;
        width: 100%;
        height: 246px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

      .hiw-program-col-title {
        margin-top: 32px;
        margin-bottom: 16px; } } } }

.program-press {
  padding-top: 32px;
  //padding-bottom: 40px
  .container {
    padding-bottom: 40px;
    border-bottom: 1px solid black; }
  .h4 {
    margin-bottom: 24px; }
  .program-press-img-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid black;
    padding-top: 28px;
    margin-bottom: 26px;
    .program-press-img {
      svg {
        max-height: 48px;
        max-width: 121px;
        width: 100%;
        @media(min-width: $bootstrap-break-old-xl) {
          max-height: 56px;
          max-width: 157px;
          width: 100%;
          height: 100%;
          object-fit: contain; } } } }


  .program-press-find-out-more {
    display: flex;
    align-content: center;
    justify-content: center;
    &>div {
      font-weight: 700;
      font-size: 16px; }
    .program-press-find-out-more-arrow {
      margin-left: 10px;
      display: flex;
      transform: rotate(180deg);
      width: 16px; } } }


.detail-program {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-top: 48px;
  @media(min-width: $bootstrap-break-old-xl) {
    padding-top: 64px; }
  h2 {
    margin-bottom: 16px; }
  .container {
    max-width: initial;
    @media(min-width: $bootstrap-break-md) {
      max-width: 720px; } }
  .detail-program-desc {
    margin-bottom: 40px;
    @media(min-width: $bootstrap-break-old-xl) {
      margin-bottom: 64px; } }
  .detail-program-carousel-slide {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    .detail-carousel-special-image {
      object-position: right; }
    img {
      align-self: center;
      width: 100%;
      max-width: 271px;
      height: 276px;
      //height: 200px
      object-fit: cover;
      border-radius: 30px;
      box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid black;
      margin-bottom: 24px; }
    .detail-program-carousel-slide-title {
      margin-bottom: 8px;
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: 500;
      text-align: left; } }

  .navigation-controls {
    margin-bottom: 0px !important; }

  .detail-program-xl-container {
    @media(min-width: $bootstrap-break-old-xl) {
      max-width: 1090px; }
    .detail-program-xl-carousel-slide {
      display: flex;
      margin-bottom: 24px;
      img {
        width: 240px;
        height: 167px;
        object-fit: cover;
        border-radius: 30px;
        box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
        border: 2px solid black;
        margin-right: 28px; }
      .detail-program-carousel-slide-title {
        margin-bottom: 8px;
        font-family: $secondary-font;
        font-size: 20px;
        font-weight: 500;
        text-align: left; } }

    .detail-program-xl-border-container {
      padding-bottom: 64px;
      border-bottom: 1px solid black; } } }


.efficient-program {
  display: flex;
  flex-direction: column;
  .efficient-program-h2 {
    font-size: 24px;
    padding-top: 64px;
    @media(min-width: $bootstrap-break-old-xl) {
      font-size: 2rem;
      margin-bottom: 16px; } }
  .efficient-program-description {
    padding-right: 39px;
    padding-left: 39px;
    font-size: 1rem;
    @media(min-width: $bootstrap-break-old-xl) {
      align-self: center;
      width: 100%;
      max-width: 807px; } }


  .efficient-program-container {
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    @media( min-width: $bootstrap-break-old-xl) {
      padding-top: 64px; }

    .efficient-program-col {
      padding-top: 64px;
      @media(min-width: $bootstrap-break-old-xl) {
        padding-top: 0px; }

      &:nth-child(1) {
        padding-top: 0px; }
      .efficient-program-col-img {
        border: 1px solid black;
        border-radius: 30px;
        box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 246px;
        object-fit: cover;
        @media ( min-width: $bootstrap-break-old-xl ) {
          width: 334px;
          height: 288px; } }

      .efficient-program-col-title {
        font-family: $secondary-font;
        padding-top: 32px;
        font-size: 20px; }
      .efficient-program-col-text {
        padding-top: 16px;
        font-size: 16px;
        text-align: left;
        @media ( min-width: $bootstrap-break-old-xl) {
          padding-top: 8px; } } }

    .efficient-program-button {
      padding-top: 32px;
      @media( min-width: $bootstrap-break-old-xl) {
        padding-top: 40px; } }

    .efficient-program-border {
      padding-top: 40px;
      @media( min-width: $bootstrap-break-old-xl) {
        padding-top: 88px; } } } }

.programe-faq-1 {
  display: flex;
  flex-direction: column;
  padding-bottom: 148px;
  @media (min-width: $bootstrap-break-old-xl) {
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px; }
  .programe-faq-container {
    width: 100%; }
  .program-faq-title {
    margin-top: 24px;
    margin-bottom: 0;
    font-family: $secondary-font; }
  .program-faq-text {
    font-size: 16px;
    margin-bottom: 32px; }
  .program-faq-content {}
  .program-faq-block {
    display: flex;
    flex-direction: column;
    padding: 29px 25px;
    width: 100%;
    height: 100%;
    max-width: 384px;
    max-height: 378px;
    @media (min-width: $bootstrap-break-old-xl) {
      margin-left: 64px; }
    @media screen and ( max-width: 320px ) {
      max-height: 500px !important;
      max-width: 500px !important; }


    .program-faq-block-title {
      font-size: 1.25rem;
      font-family: $secondary-font;
      margin-bottom: 28px; }
    .program-faq-block-bullet {
      font-size: 1rem;
      cursor: pointer;
      margin-bottom: 16px;
      a {
        font-weight: 400;
        font-size: 1rem;
        color: black; } }
    .program-faq-block-svg {
      margin-right: 8px;
      svg {
        width: 100%;
        max-width: 32px; } } } }

.creator-program {
  padding-top: 64px;
  padding-bottom: 64px;
  @media(min-width: $bootstrap-break-old-xl) {
    padding-bottom: 88px; }

  h2 {
    margin-bottom: 16px;
    text-align: center; }
  .creator-program-paragraph {
    margin-bottom: 40px; }

  .creator-program-caption {
    margin-top: 54px;
    @media(min-width: $bootstrap-break-old-xl) {
      margin-top: 0;
      margin-left: 40px; } } }

