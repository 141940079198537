$target-platform: charles;
@import "~constants/sass/vars";

.treatments-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px; }

.option-block {
  margin-bottom: 32px; }

.weight-treatment {
  ul.exit-bullets {
    list-style: inherit;
    margin-bottom: 40px;
    padding-left: 0;
    li {
      padding-top: 6px;
      list-style: none; }

    .exit-bullets-icon {
      margin-top: 8px;
      @media(min-width: $bootstrap-break-md) {
        margin-top: 0; }
      svg {
        width: 24px;
        margin-right: 8px; } } } }


.weight-treatment-subtitle {
  background-color: #E8F6E8;
  border-left: 4px solid #388E3C;
  width: 100%;
  padding: 12px 0 12px 12px;
  @media(min-width: $bootstrap-break-lg) {
    padding-left: 32px; } }

ul.exit-bullets {
  list-style: inherit;
  margin-bottom: 40px;
  padding-left: 0;
  @media(min-width: $bootstrap-break-md) {
    padding-left: 40px; }
  li {
    padding-top: 6px;
    list-style: none; }

  .exit-bullets-icon {
    margin-top: 8px;
    @media(min-width: $bootstrap-break-md) {
      margin-top: 0; }
    svg {
      width: 24px;
      margin-right: 8px; } } }

.check-presc-container {
  .thumbnail-container {
    @media (min-width: $bootstrap-break-md) {
      padding-left: 200px;
      text-align: right; }

    .presc-thumbnail {
      border-radius: 16px;
      border: 2px solid #000;
      width: 135px;
      height: 191px; } }

  .details-container {
    .details-wording {
      font-weight: 500;
      max-width: 340px;
      margin-bottom: 20px; } } }
