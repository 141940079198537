$target-platform: charles;
@import "~constants/sass/vars";

.status-steps-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 .5em;
  position: relative;
  width: 100%;

  &::after {
    border-bottom: 1px $medium-grey solid;
    bottom: 50%;
    content: '';
    margin: 0 2em;
    position: absolute;
    width: calc(100% - 4em);
    z-index: 0; }

  @media screen and(min-width: $bootstrap-break-lg) {
    flex-direction: row; }


  .step {
    align-items: center;
    background-color: $white;
    border: 1px solid $medium-grey;
    border-radius: 3px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: .5em 0;
    padding: 1em 1.5em;
    z-index: 1;

    @media screen and(min-width: $bootstrap-break-lg) {
      margin: 1.5em; }

    @media screen and(min-width: $bootstrap-break-old-xl) {
      margin: 2em; }


    p {
      color: $light-dark;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 0;
      text-align: center; } }

  .icon-status {
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    height: 25px;
    justify-content: center;
    position: relative;
    text-align: center;
    top: -2px;
    width: 25px;

    &.validated,
    &.confirmed,
    &.started,
    &.finished,
    &.exhaust {
      background-color: $green-success; }

    &.in_progress {
      background-color: $green-success; }

    &.none,
    &.pending {
      background-color: $orange-alert;

      &::before {
        color: $white;
        content: '!';
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: 2px; } }

    &.canceled {
      background-color: $red-error;

      &::before {
        color: $white;
        content: 'x';
        font-size: 16px;
        font-weight: 500;
        position: relative;
        top: 1px; } }

    svg {
      position: relative;
      top: 2px;
      width: 80%; } } }

.last-consultation {
  text-align: center;

  .button {
    &.xl {
      font-size: .8em;
      height: auto;
      line-height: normal;

      span {
        line-height: 2em; }

      @media screen and(min-width: 375px) {
        height: 3.125em;
        line-height: 3.125em;

        span {
          line-height: 3.125em; } }

      @media screen and(min-width: $bootstrap-break-sm) {
        font-size: 1em; } }


    &.grey {
      height: 3.125em;
      line-height: 3.125em; } }

  .download-link {
    text-decoration: underline;

    a {
      cursor: pointer;
      display: flex;
      font-size: .8em;
      height: 30px;
      justify-content: center;

      @media screen and(min-width: $bootstrap-break-sm) {
        font-size: 1em; } } } }
