$target-platform: charles;
@import "~constants/sass/vars";

.rating-container {
  font-size: 16px;
  margin-top: 22px;
  display: grid;
  grid-template-columns: auto auto;

  .rating-in-words {
    text-align: right;
    margin-right: 8px;
    font-weight: 700;
    line-height: 27px; }

  .rating-stars {
    display: flex;
    gap: 1px;
    .rating-star {
      svg {
        width: 16px; } } } }


.phone-info {
  color: grey;
  line-height: 1.2rem;
  text-align: center; }
