$target-platform: charles;
@import "~constants/sass/vars";

.phyto-card-subscription-time-picker {
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  border: 2px solid $black;
  border-radius: 12px;
  justify-content: space-evenly;
  cursor: pointer;
  &-clone {
    height: 0;
    border: none;
    margin: 0; }
  .phyto-card-subscription-time-picker-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.5s;
    .phyto-card-subscription-time-picker-item-title {
      pointer-events: none; }
    &-active {
      position: relative;
      z-index: 10;
      color: white; }
    &-phantom-selector {
      position: relative;
      width: 0;
      height: 0;
      transition: left 0.5s ease-in-out; }
    &-selector {
      transform: translateY(-41px);
      background-color: $black;
      color: white;
      height: 44px;
      border-radius: 12px; } }

  .phyto-card-recommended {
    margin-top: 12px;
    margin-bottom: 32px;
    text-align: center; }
  .phyto-card-price-options {
    margin-bottom: 40px;
    .phyto-card-price-options-item {
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 8px;
      .phyto-card-price-options-item-title {
        display: flex;
        font-weight: 500;
        align-items: center;
        cursor: pointer;
        &>input {
          margin-right: 16px;
          cursor: pointer; }
        &>label {
          cursor: pointer;
          margin-bottom: 0; } }
      .phyto-card-price-options-item-description {
        margin-left: 29px; } } }

  .back-to-phyto-description {
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
    align-self: center;
    cursor: pointer; }

  .phyto-card-toggle-title {
    font-size: 0.875rem;
    margin-right: 16px;
    font-weight: 600; } }

