.c-pill-fill-green {
  color: white;
  background-color: #50E19C;
  border-color: #50E19C; }

.c-pill-outline-green {
  color: #50E19C;
  border: 2px solid #50E19C;
  background-color: none; }

.c-pill-outline-black {
  color: black;
  border: 2px solid black;
  background-color: none; }

.c-pill-fill-red {
  background-color: #DA4242;
  border-color: #DA4242;
  color: white; }


