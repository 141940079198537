$target-platform: charles;
@import "~constants/sass/vars";

.show-comment-button {
  height: 30px !important;
  margin-top: 0px !important; }

.import-button {
  text-align: center; }

.medical-docs-container {
  font-weight: 500;

  .documents-not-found {
    padding: 1em;
    margin: 1em;
    font-size: 18px;
    font-weight: 500;
    text-align: center; }

  .sent-documents {
    border-bottom: 1px solid #000; }

  .documents-list {
    .document-row {
      padding: 15px 0px;
      @media (max-width: $bootstrap-break-md) {
        border-bottom: 1px solid $grey; }
      .filename {
        word-wrap: break-word; }

      svg {
        width: 20px;
        height: 20px; }

      .check-comment {
        text-decoration: underline;
        cursor: pointer; } } } }
