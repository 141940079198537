$target-platform: charles;
@import "~constants/sass/vars";

.patient-space-personal-info {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 20px;
  flex-start: right;
  max-width: 1140px;
  width: 100%;

  @media(min-width: $bootstrap-break-old-xl) {
    flex-direction: row; }

  .form-label {
    @media screen and (max-width: $bootstrap-break-md) {
      display: none; } }

  .patient-space-form {
    background-color: #FBFBFB;
    display: flex;
    width: 100%;
    flex: 1.5 1;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px 16px 24px;
    box-shadow: 0 0 10px #0000000D;
    align-items: center;

    @media screen and (max-width: $bootstrap-break-md) {
      margin-bottom: 60px; } }

  .patient-space-payment-row {
    background-color: #FBFBFB;
    display: flex;
    width: 100%;
    flex: 1.5 1;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 0 10px #0000000D;
    @media(min-width: $bootstrap-break-old-xl) {
      flex-direction: row; }

    .patient-space-container-bloc {
      padding: 24px 32px;
      @media(max-width: $bootstrap-break-lg) {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important; }
      @media(min-width: $bootstrap-break-lg) {
        align-items: flex-start !important;
        flex: 1.5; }
      .patient-space-step-title {
        margin-bottom: 32px;
        text-align: center;
        @media(min-width: $bootstrap-break-md) {
          text-align: initial; }
        .step-title {
          color: #1F3A64;
          font-size: 18px;
          font-weight: 600; }
        .step-description {
          margin-top: 8px;
          color: #6590BB;
          font-size: 14px; } }
      .step-div {
        display: flex;
        color: #1F3A64;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 16px;
        align-items: center;
        @media(min-width: $bootstrap-break-lg) {
          flex-direction: column;
          align-items: flex-start; }
        div:last-child {
          font-size: 18px;
          font-weight: 500;
          margin-left: 8px;
          @media(min-width: $bootstrap-break-lg) {
            margin-left: 0;
            margin-top: 8px;
            margin-bottom: 8px; } } }

      .patient-space-form-input-wrapper {
        display: grid;
        grid-template-columns: 255px;
        justify-content: center;
        margin-bottom: 24px;
        @media(min-width: $bootstrap-break-md) {
          grid-template-columns: 220px 220px;
          justify-content: initial; }
        column-gap: 16px; } } } }


