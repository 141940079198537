$target-platform: charles;
@import "~constants/sass/vars";

.checkout-container-bloc {
    margin-right: 24px;
    margin-left: 24px;
    @media(min-width: $bootstrap-break-md) {
        margin-right: 214px;
        margin-left: 214px; } }

.sub-step-stepper {
  background-color: black;
  height: 4px;
  width: 0;
  transition: width ease-in-out 0.5s;
  @media screen and (min-width: $bootstrap-break-sm) {
    height: 6px; } }
