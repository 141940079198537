$target-platform: charles;
@import "~constants/sass/vars";

.doctor-profile-container {
  flex-wrap: wrap;
  background-color: white;
  text-align: left;

  // TODO OVERRIDE block-content. Should probably reduce padding straight in block-content.
  @media (max-width: $bootstrap-break-sm) {
    padding: 24px 20px; }

  // Layout & borders related CSS
  // Desktop vs Mobile
  // A | B      AAA
  // -----      ---
  // CCCCC      BBB
  //            ---
  //            CCC
  .doctor-main-profile {
    flex: 1 1 auto;
    order: 1; }

  .doctor-profile-right-block {
    order: 3;
    @media (max-width: $bootstrap-break-md) {
      flex: 1 0 100%;
      margin-top: 0.5rem;
      border-top: 1px solid black;
      padding-top: 1.5rem; }
    @media (min-width: $bootstrap-break-md) {
      flex: 0 0 30%;
      border-left: 1px solid black;
      padding-left: 1.5rem; } }

  .doctor-details-container {
    flex: 0 0 100%;
    order: 2;
    margin-top: 1.5rem;
    border-top: 1px solid black;
    padding-top: 1.5rem;
    @media (min-width: $bootstrap-break-md) {
      order: 4; } }

  //
  // End of layout
  //

  // Start of styling
  .doctor-profile-img {
    border: 2px solid black;
    border-radius: 1rem;
    height: 88px;
    width: 88px;
    @media (min-width: $bootstrap-break-md) {
      border-radius: 1.875rem;
      height: 136px;
      width: 136px; } }

  .doctor-profile-see-profile {
    margin-top: 1rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: underline; }
    .icon {
      svg {
        width: 16px;
        margin-top: -4px;
        margin-right: 4px; } } }

  .doctor-details-container {
    display: none;
    max-height: 0px;
    overflow: hidden;
    transition: max-height, .25s;

    &.active {
      display: block;
      margin-top: 1rem;
      max-height: 150px; } }

  .doctor-profile-right-block {
    text-align: center; }

  .doctor-profile-selected-slot {
    svg {
      height: 24px; }
    row-gap: 8px; }

  .doctor-profile-availability {
    margin-bottom: 1rem; }

  .doctor-profile-unavailability {
    @extend .doctor-profile-availability; } }
