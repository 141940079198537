$target-platform: charles;
@import "~constants/sass/vars";

$controls-size: 2.5em;

.VisioBox { // sass-lint:disable-line class-name-format
  margin: .5em;
  text-align: center;
  width: 100%; }

.VisioBox-main { // sass-lint:disable-line class-name-format
  border: 1px solid $deep-blue;
  height: 480px;
  margin: auto;
  position: relative;
  width: 100%; }

.VisioBox-control-container { // sass-lint:disable-line class-name-format
  align-items: center;
  bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;

  &.hidden {
    display: none; }

  .ots-video-control {
    align-items: center;
    background-color: $deep-blue;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: $controls-size;
    justify-content: center;
    margin: 0 20px !important; // sass-lint:disable-line no-important
    width: $controls-size;

    &.power-off {
      background-color: $red; }

    &.hidden {
      display: none; }

    svg {
      display: block;
      fill: $white;
      margin: auto;
      width: 65%; }

    .disable {
      svg {
        display: none; } }

    // disable hover effect
    // &:hover
    //   .enable
    //     svg
    //       display: none

    //   .disable
    //     svg
    //       display: block

    &.toggled {
      .enable {
        svg {
          display: none; } }

      .disable {
        svg {
          display: block; } }

      // disable hover effect
      // &:hover
      //   .enable
      //     svg
      //       display: block

      //   .disable
      //     svg
 } } }      //       display: none

.VisioBox-video-container { // sass-lint:disable-line class-name-format
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  @media (max-width: $bootstrap-break-sm) {
    flex-direction: column-reverse; } }

.VisioBox-mask { // sass-lint:disable-line class-name-format
  align-items: center;
  background-color: $bg-grey;
  color: $deep-blue;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  .react-spinner {
    position: absolute; }

  .message {
    display: block;
    font-weight: 200;

    &.error {
      color: $red-error; }

    &.with-spinner {
      position: absolute;
      top: 55%; }

    &.button {
      border: 1px solid $deep-blue;
      border-radius: 6px;
      line-height: 3em;
      height: 3em;
      vertical-align: middle;

      &.error {
        border-color: $red-error; } } } }

.VisioBox-video-container .video-container { // sass-lint:disable-line class-name-format force-element-nesting
  display: flex;
  height: 100%;
  width: 100%;

  &.small {
    border: 1px solid $white;
    height: 96px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 160px;
    z-index: 2;

    &.left {
      border: 1px solid $deep-blue;
      left: 20px; } }

  &.hidden {
    display: none; }

  &.active-2 .OT_subscriber { // sass-lint:disable-line class-name-format force-element-nesting
    width: 50%; }

  &.active-3 .OT_subscriber { // sass-lint:disable-line class-name-format force-element-nesting
    width: calc(100%/3) !important; } // sass-lint:disable-line no-important

  &.active-4 {
    flex-wrap: wrap;

    .OT_subscriber { // sass-lint:disable-line class-name-format
      height: 50% !important; // sass-lint:disable-line no-important
      width: 50% !important; } } } // sass-lint:disable-line no-important
