$target-platform: charles;
@import "~constants/sass/vars";

.contact-form-container {

  .contact-form-highlight {
    color: $v2-highlight-text-color;
    font-weight: 600; }

  .contact-form-textarea {
    min-height: 200px; }

  .icon-send {
    display: inline-block;
    margin-right: .5em;

    svg {
      height: 24px;
      margin-right: 8px; }

    path {
      fill: $white; } } }
