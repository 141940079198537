$target-platform: charles;
@import "~constants/sass/vars";
@import "src/components/Sidebar/styles";

.personnal-space-program-manager {
  overflow-x: hidden;
  .personnal-space-program-manager-title-group {
    margin-bottom: 32px;
    margin-top: 24px; }
  .container-content {
    display: flex;
    flex-direction: column;
    align-items: baseline; }
  .program-variant-choice-block {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-top: 20px;
    background-color: white;
    flex-start: right;
    max-width: 1140px;
    width: 100%;

    @media(min-width: $bootstrap-break-old-xl) {
      flex-direction: row; }

    .program-variant-choice-row {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      flex-grow: 1;
      margin-top: 24px;
      align-items: center;

      @media(min-width: $bootstrap-break-md) {
        margin-top: 0;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        flex: 1.5;
        padding: 50px 10px;
        &>div:nth-child(1) {
          margin-right: 20px; } }

      .time-variant-choice {
        width: 250px;
        min-height: 460px; } }

    .program-variant-vaginisme {
      background-color: #FBFBFB;
      display: flex;
      width: 100%;
      flex: 1.5 1;
      flex-direction: column;
      .vaginisme-title {
        font-weight: 600;
        color: #1F3A64;
        padding: 24px 0 0 32px;
        font-size: 18px;
        align-self: center;
        @media(min-width: $bootstrap-break-old-xl) {
          align-self: initial; } }
      .vaginisme-program-wrapper {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        margin: 24px 24px 0 24px;
        border-radius: 8px;
        box-shadow: 0 3px 6px #0000000D;
        padding: 16px 16px 16px 16px;
        align-self: center;
        @media(min-width: $bootstrap-break-md) {
          padding-left: 56px; }
        @media(min-width: $bootstrap-break-old-xl) {
          align-self: initial; }
        .vaginisme-head-div {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .head-div-title {
            font-weight: 600;
            color: #1F3A64;
            font-size: 18px; }
          .vaginisme-program-icon {
            svg {
              height: 140px; } }
          .vaginisme-price-div {
            margin-left: 32px; } }

        .vaginisme-bottom-div {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          .bottom-div-title {
            font-size: 16px;
            font-weight: normal;
            color: #1F3A64; }
          .vaginisme-tick-div-wrapper {
            margin-top: 16px;
            .vaginisme-tick-div {
              display: flex;
              font-size: 14px;
              color: #002865;
              margin-bottom: 8px;
              .vaginisme-tick-icon {
                height: 17px;
                width: 17px;
                margin-right: 8px; } } } } }

      .vaginisme-button {
        margin: 24px 0 32px 0;
        align-self: center;
        @media(min-width: $bootstrap-break-old-xl) {
          margin-right: 24px;
          align-self: flex-end;
          margin-bottom: 0; } } } }

  .program-sale-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $theme-rust;
    height: 56px;
    right: 0;
    color: white;
    z-index: 200;
    @media(min-width: $bootstrap-break-old-xl) {
      width: calc(100% - #{$sidebar-width}); } }

  .emoji-wrapper {
    width: 32px;
    height: 32px; } }


// Clinic rebranding
.program-variants-container {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media(min-width: $bootstrap-break-md) {
    flex-direction: row;
    align-items: stretch;
    justify-content: center; } }

.program-reduction-percent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //width: 180px
  height: 32px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  color: #000000;
  border: 2px solid #000000;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  font-family: 'MaisonNeue', sans-serif; }

.program-reduction-percent-wrapper {
  height: 0;
  position: relative;
  align-self: center;
  top: -40px;
  @media(min-width: $bootstrap-break-xl) {
    &.big-pill {
      top: -52px;
      .program-reduction-percent {
        font-weight: 700;
        font-size: 1.25rem;
        padding: 0.75rem 2rem;
        border-radius: 16px;
        height: 48px; } } } }

.program-reduction-percent-position-clinic {
  display: flex;
  width: max-content; }
//  font-size: 20px
//font-weight: 700

.funnel-program {
  display: flex;
  flex-direction: column; }

.funnel-sommeil-program {
  .program-reduction-percent {
    color: #AF6345;
    border: 2px solid #AF6345; } }

.funnel-time-program {
  .program-reduction-percent {
    color: #3D7791;
    border: 2px solid #3D7791; } }

.funnel-master-program {
  .program-reduction-percent {
    color: #3D7791;
    border: 2px solid #3D7791; } }
