$target-platform: charles;
@import "~constants/sass/vars";

.perso-info-block {
  display: flex;
  flex-direction: column;
  font-weight: 400;

  // @media (min-width: $bootstrap-break-lg)
  //   padding: 10px 12px


  .text {
    border: 1px solid $bg-grey; } }

// .perso-info-card
//   display: flex
//   flex-direction: column
//   flex-wrap: wrap
//   width: 100%
//   align-items: baseline

//   > *
//     width: 100%

//   @media (min-width: $bootstrap-break-lg)
//     flex-direction: row

//     > *
//       width: initial

.perso-info-content {
  color: $dark-grey;
  font-size: 14px;
  margin-top: 5px; }

.perso-info-input-content {
  margin: 0;
  padding: 0;

  .react-phone-number-input__phone {
    border: 0;
    height: 32px; }

  .error-input-text {
    border-color: $red; } }

.perso-info-label {
  color: $grey;
  font-size: .7em; }

.personnal-info-button {
  display: flex;
  margin: auto;
  max-width: 288px;
  text-decoration: none;
  button {
    border: 1px;
    text-decoration: none; } }

.doctor-info-input {
  input {
    background-color: $white;
    border: none;
    border-radius: .2em;
    color: $dark-grey;
    cursor: pointer;
    outline: none;
    padding: 0;
    width: 100%; } }
