$target-platform: charles;
@import "~constants/sass/vars";

.delivery-disclaimer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  .delivery-info-icon {
    margin-right: 16px;
    svg {
      width: 28px;
      height: 28px; } } }
