$target-platform: charles;
@import "~constants/sass/vars";

.responsive-tabs {
  text-align: center;

  .responsive-tabs-item-title {
    cursor: pointer;
    font-weight: bold; } }

.responsive-tabs-desktop {
  position: relative;
  background-color: white;
  border: 3px solid black;
  border-radius:  1.875rem;
  box-shadow: 10px 10px 0 black;
  margin-right: 10px;
  margin-bottom: 16px;
  overflow: hidden;

  .responsive-tabs-titles {
    color: white;
    display: flex;
    border-bottom: 3px solid black;
    background-color: $theme-blue;

    .responsive-tabs-titles-arrow {
      cursor: pointer;
      align-self: center;
      width: 18px;
      svg {
        path {
          stroke: white; }
        line {
          stroke: white; } }
      &-back {
        transform: rotate(180deg); } }

    .responsive-tabs-titles-container {
      flex-grow: 1;
      display: flex; }

    .responsive-tabs-item-title {
      margin-top: 9px;
      padding: 14px 14px 24px;
      border: 3px solid transparent;
      margin-bottom: -3px;
      white-space: nowrap;
      flex-grow: 1;
      text-align: center;
      max-width: 300px;

      &.disabled {
        opacity: 0.5; }

      &.selected {
        color: black;
        border: 3px solid black;
        background-color: white;
        border-bottom-width: 0;
        border-radius: 1rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 10px 10px 0 black; } } }

  .responsive-tabs-content {
    background-color: white;
    z-index: 100;
    position: relative;
    padding: 14px; } }

.responsive-tabs-mobile {
  .responsive-tabs-item {
    border: 2px solid black;
    border-radius:  1.25rem;
    overflow: hidden;
    margin-bottom: 1rem;
    background-color: $theme-blue;

    &.disabled {
        opacity: 0.5; }

    .responsive-tabs-item-title {
      padding: 1rem;
      color: white;
      display: flex;

      .expand-button {
        &::before {
          display: inline-block;
          content: "";
          height: 8px;
          width: 8px;
          border-right: 0 solid white;
          border-top: 0 solid #white;
          border-color: #white;
          border-style: solid;
          border-width: 2px 2px 0 0;
          box-sizing: border-box;
          transform: rotate(135deg);
          transition-duration: .2s;
          margin-right: 2px; } }

      &.selected {
        .expand-button {
          &::before {
            transform: rotate(-45deg); } } } }

    .responsive-tabs-content {
      border-top: 2px solid black;
      background-color: white;
      z-index: 100;
      position: relative;
      padding: 1rem; } } }
