$target-platform: charles;
@import "~constants/sass/vars";
$stepper-circle-size: .5625rem;
$stepper-spacing: .375rem;
$stepper-color: black;
$stepper-disabled-color: #AAAAAA;

.c-stepper-container {
  max-width: 650px;
  flex: auto;

  &.c-stepper-container-unlimited {
    max-width: initial; } }

.c-stepper {
  display: flex;
  padding: 0;
  margin: 0;
  @media screen and (min-width: $bootstrap-break-sm) {
    margin-bottom: .5rem; } }

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  justify-content: space-between;

  .c-stepper__circle {
    &:before {
      content: "";
      display: block;
      width: $stepper-circle-size;
      height: $stepper-circle-size;
      border-radius: 50%;
      background-color: $stepper-color;
      opacity: 0.5;
      margin: 0 auto; } }

  &:not(:last-child) {
    .c-stepper__circle {
      &:after {
        display: block;
        content: "";
        position: relative;
        bottom: 50%;
        width: calc(100% - #{$stepper-circle-size} - calc(#{$stepper-spacing} * 2));
        left: calc(50% + calc(#{$stepper-circle-size} / 2 + #{$stepper-spacing}));
        height: 1px;
        background-color: #e0e0e0;
        order: -1; } } }

  &.progtrckr-done, &.progtrckr-doing, {
    .c-stepper__circle {
      &:before {
        background-color: $stepper-color;
        opacity: 1; }
      &:after {
        background-color: $stepper-color; } } }

  &.progtrckr-todo {
    .c-stepper__circle {
      &:before {
        background-color: white;
        border: 1px solid $stepper-disabled-color;
        opacity: 1; }
      &:after {
        background-color: $stepper-disabled-color; } }
    .c-stepper__title {
      color: #808080; } } }

.c-stepper__title {
  font-weight: 500;
  font-size: .875rem;
  margin-bottom: .5rem;
  line-height: 1; }

.c-stepper__desc {
  color: grey;
  font-size: .7rem;
  padding-left: $stepper-spacing;
  padding-right: $stepper-spacing; }


