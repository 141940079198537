$target-platform: charles;
@import "~constants/sass/vars";

.navbar.personal-space-navbar {
  background-color: white;
  height: 69px;
  padding: 1rem 1.5rem;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;

  @media (min-width: $sidebar-hiding-breakpoint) {
    display: none; }

  .navbar-brand {
    svg {
      height: 37px; } }

  .navbar-burger {
    cursor: pointer;
    svg {
      width: 24px; } } }
