$target-platform: charles;
@import "~constants/sass/vars";

.custom-select-dropdown {
  position: relative;
  .my-custom-select {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    border: 1px solid black;
    background: #FFFFFF;
    border-radius: 16px;
    z-index: 20;
    cursor: pointer;
    .caret-icon {
      width: 16px;
      height: 16px;
      display: flex; } }
  .custom-select-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% - 16px);
    padding-top: 16px;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid black;
    border-top: none;
    border-radius: 0 0 16px 16px;
    z-index: 10;
    transform: scaleY(0);
    transform-origin: top center;
    transition: all 0.2s ease-in-out;
    &-active {
      transform: scaleY(1); }
    .select-option {
      padding: 12px 16px;
      cursor: pointer;
      &:last-of-type {
        border-radius: 0 0 16px 16px; }
      &:hover {
        background: #C4DCFF; } } } }
