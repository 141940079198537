.btn-primary {
  @include button-variant($primary-color, $primary-color, transparentize($primary-color, .4), transparent, transparentize($primary-color, .4), transparent); }

.charles-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: none; } }

.btn {
  font-family: $primary-font;

  &.btn-secondary {
    &:hover {
      &:not(:disabled):not(.disabled) {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $secondary-color; } } }

  .button-loader {
    display: flex;             // quick fix for react-spinner bug https://github.com/davidhu2000/react-spinners/issues/387
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-right: .4em;
    *, *::before, *::after {
      box-sizing: content-box; } }

  &.loading.disabled {
    opacity: 0.6; }

  &.loading {
    svg {
      display: none; } }
  .button-inline-svg {
    display: block;
    margin: auto .625rem auto 0;
    height: 18px;
    width: 18px; }

  &.btn-small {
    padding: 10px 24px; } }


a.btn.link-btn {
  width: min-content;
  display: block; }
