@import "~constants/sass/vars";

$box-shadow-color: #3cb97f1a;
$footer-transparent-gray: #{$theme-beige}E6;
$onboarding-max-width: 1000px;

.root-body-funnel {
  background-color: $theme-beige;

  .onboarding-container {
    padding-top: 1.5rem;
    @media(min-width: $bootstrap-break-old-xl) {
      padding-top: 2.5rem; }
    .images-doctor {
      max-height: 130px;
      height: 100%;
      border: 2px solid #000000;
      box-sizing: border-box;
      border-radius: 16px;
      box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1); }
    .images-doctors {
      max-height: 130px;
      height: 100%;
      object-fit: contain; }
    .choice-bullets-svg {
      width: 32px;
      height: 32px; }
    .choice-bullets-body {
      max-width: 214px;
      padding-left: 8px; }
    .presentation-bullets-svg {
      width: 40px;
      height: 40px; }
    .presentation-bullets-body {
      font-weight: 500;
      margin-left: 8px; } }

  .onboarding-container-bloc {
    margin-right: auto;
    margin-left: auto;
    padding: 16px;

    .post-payment-notice {
      font-size: 14px;
      font-family: "MaisonNeue";
      line-height: 16px;
      font-weight: 500;
      text-align: left;
      display: flex; }

    .form-edition-container {
      display: block; }

    .informations-confirmation {
      .infos {
        display: flex;
        flex-direction: column;
        justify-content: center; }

      .patient-infos {
        @media(min-width: $bootstrap-break-md) {
          border-right: 1px solid #000000;
          border-bottom: none;
          text-align: left;
          margin-bottom: 0; }
        @extend .infos;
        border-bottom: 1px solid #000000;
        margin-bottom: 1em;

        .patient-name {
          font-size: 20px;
          font-weight: 500; }

        .edit-infos {
          span {
            cursor: pointer; }
          margin-bottom: 1em; } }

      .consultation-infos {
        @extend .infos;
        .doctor-name {
          font-weight: 500;
          margin-bottom: 0; } } }


    @media screen and (max-width: $bootstrap-break-md) {
      margin-bottom: 60px;
      padding: 16px 0px; }

    .onboarding-step-title {
      margin-bottom: 32px;
      text-align: center;
      @media(min-width: $bootstrap-break-md) {
        text-align: initial; }
      .step-title {
        color: #1F3A64;
        font-size: 18px;
        font-weight: 600; }
      .step-description {
        margin-top: 8px;
        color: #6590BB;
        font-size: 14px; } }

    .header-presentation {
      display: flex;
      flex-direction: column;
      @media(min-width: $bootstrap-break-lg) {
        flex-direction: row; }
      .title-body-presentation {
        text-align: center;
        justify-content: center;
        width: 100%;
        padding-bottom: 32px;
        @media(min-width: $bootstrap-break-lg) {
          justify-content: start;
          text-align: left;
          max-width: 524px;
          padding-bottom: 0px; }
        .subtitle-price {
          font-weight: 500;
          @media(min-width: $bootstrap-break-lg) {
            padding-bottom: 40px; } }
        .step-header {
          text-align: center;
          @media(min-width: $bootstrap-break-lg) {
            text-align: left; } }
        .subtitle-presentation {
          flex-direction: column;
          padding-bottom: 16px; }
        .subtitle-presentation-duration {
          text-decoration: underline; } } }

    .info-container {
      .info-row {
        justify-content: space-around;
        @media(min-width: $bootstrap-break-lg) {
          padding-bottom: 40px; }

        .info-body {
          display: flex;
          flex-direction: column;
          @media(min-width: $bootstrap-break-lg) {
            flex-direction: row;
            justify-content: center space-around; }
          svg {
            width: 40px;
            height: 40px; } } } }


    .video-container {
      border: 2px solid #000000;
      box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      overflow: hidden;
      flex: 2;
      margin-bottom: 32px;
      @media screen and (max-width: 320px) {
        width: 280px; }
      @media(min-width: $bootstrap-break-lg) {
        margin-bottom: 0px;
        max-height: 212px;
        max-width: 488px; }
      @media screen and (min-width: 1200px) {
        max-height: 274px;
        margin-left: 66px;
        transition: all .3s ease-in-out;
        width: 522px;
        &:hover {
          transform: scale(1.02); } } }

    .onboarding-form-input-wrapper {
      display: grid;
      grid-template-columns: 255px;
      margin-bottom: 24px;
      @media(min-width: $bootstrap-break-md) {
        grid-template-columns: 255px 255px;
        column-gap: 16px; } }

    .personal-info-form-control {
      max-width: 400px; }

    .step-header {
      text-align: center;
      margin-bottom: 25px; } }

  .onboarding-payment-bloc {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    @media(min-width: $bootstrap-break-lg) {
      border-radius: 8px 0 0 8px;
      align-items: flex-start;
      flex: 1.5;
      padding: 24px 32px; } }

  .onboarding-container-footer {
    // Fixed footer on mobile
    @media screen and (max-width: $bootstrap-break-md) {
      background-color: $footer-transparent-gray;
      box-shadow: 0 5px 20px 0 $box-shadow-color;
      position: fixed;
      padding: .5rem;
      bottom: 0;
      left: 0;
      right: 0; }

    @media screen and (min-width: $bootstrap-break-md) {
      padding-bottom: 2rem; }

    .onb-prev-btn {
      display: none; }

    .onb-complete-btn, .onb-start-btn, .onb-next-btn {
      margin: auto !important;
      min-width: 200px;
      display: block; } }

  .onboarding-small-inner-container {
    width: 100%;
    @media screen and (min-width: $bootstrap-break-md) {
      margin-left: auto;
      margin-right: auto;
      width: auto;
      width: 300px; } }

  .onboarding-medium-inner-container {
    width: 100%;
    @media screen and (min-width: $bootstrap-break-md) {
      margin-left: auto;
      margin-right: auto;
      width: auto;
      max-width: 440px; } }

  .onboarding-payment-cta {
    width: 187px; } }

.personal-info-step {

  .form-label {
    @media screen and (max-width: $bootstrap-break-md) {
      display: none; } } }

.onboarding-thankyou {
  text-align: center;
  font-weight: 500;
  margin: 16px 0;

  .thankyou-text {
    .thankyou-text-bold {
      color: $v2-highlight-text-color;
      font-weight: 600; } }

  .thankyou-image {
    max-width: 300px;
    display: block;
    margin: auto; } }

.choice-selection-card {
  display: contents;
  padding-bottom: 32px;
  flex-direction: column;
  @media (min-width: $bootstrap-break-md) {
    flex-direction: row; } }

.bloc-content-size {
  max-width: 326px;
  @media (min-width: $bootstrap-break-md) {
    margin-right: 2rem; } }


.promotion-form {
  padding: 16px 0; }

.phone-disclaimer {
  margin-bottom: 16px;
  color: #1F3A64;
  font-size: 14px; }

.onboarding-choice {
  .form-check {
    padding-left: 0; }
  .form-check-input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0); }
  .card-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .unavailable {
      font-style: italic;
      font-size: 14px;
      font-weight: 400; } } }


.hiw-program-onboarding {
  display: flex;
  flex-direction: column;
  // padding-top: 48px
  @media ( min-width: $bootstrap-break-old-xl ) {
    padding: 48px 39px 0px 39px; }

  h2 {
    position: relative;
    z-index: 2; }
  .hiw-program-black-underline {
    display: block;
    width: 183px;
    align-self: center;
    position: relative;
    z-index: 1;
    top: -22px; }

  .hiw-program-container {
    margin-top: 96px;
    .hiw-start-button {
      margin-bottom: 40px;
      @media(min-width: $bootstrap-break-old-xl) {
        margin-bottom: 88px; } } }

  .hiw-program-row {
    @media(min-width: $bootstrap-break-old-xl) {
      margin-bottom: 64px; }
    .hiw-program-col {
      margin-bottom: 64px;
      @media(min-width: $bootstrap-break-old-xl) {
        margin-bottom: 0; }
      &:last-child {
        margin-bottom: 32px;
        @media(min-width: $bootstrap-break-old-xl) {
          margin-bottom: 0; } }
      &:first-child {
        .hiw-program-col-img {
          overflow: initial;

          img {
            display: block;
            width: initial;
            height: 321px;
            margin-top: -79px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 20px;
            @media screen and ( min-width: 320px ) {
              width: 100%; } } } }


      .hiw-program-col-img {
        border-radius: 30px;
        box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
        border: 2px solid black;
        width: 100%;
        height: 246px;
        overflow: hidden;
        &:first-child {
          background-color: #B7D4E1; }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }

      .hiw-program-col-title {
        margin-top: 32px;
        margin-bottom: 16px; } } } }

.confirmation-program-bloc {
  display: flex;
  flex-direction: column;
  .confirmation-program-bloc-title {
    display: flex;
    flex-direction: column;
    @media(min-width: $bootstrap-break-old-xl) {
      flex-direction: row;
      justify-content: center; } }

  .title {
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 8px; }
  .sub-title {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    padding-bottom: 32px;
    max-width: 528px; }
  .icon {
    align-self: center;
    padding-bottom: 18px;
    svg {
      width: 40px; } }
  .body-confirmation-program {
    .body-title {
      display: flex;
      flex-direction: row;
      svg {
        width: 32px;
        padding-right: 8px; }
      .title {
        font-weight: 500;
        font-size: 20px; } } }
  .body-detail {
    display: flex;
    flex-direction: column;
    @media(min-width: $bootstrap-break-md) {
      flex-direction: row;
      align-items: center; }

    .image-doctor {
      contain: content;
      align-self: center;
      max-width: 130px;
      max-height: 130px;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      filter: drop-shadow(10px 10px 0px rgba(0, 0, 0, 0.1));
      border-radius: 16px; }
    .body-detail-content {
      display: flex;
      .body-detail-content-text {
        flex-direction: row;
        padding-bottom: 8px;
        @media(min-width: $bootstrap-break-md) {
          flex-direction: column;
          padding-left: 24px; }

        .body-detail-title {
          font-size: 18px;
          font-weight: 500;
          line-height: 40px; }
        .body-detail-text {
          font-size: 14px; } } }
    .body-detail-content-price {
      font-family: 'MaisonNeue';
      align-self: center;
      font-size: 16px;
      font-weight: 500; }
    .body-detail-price {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      @media(min-width: $bootstrap-break-old-xl) {
        padding-top: 8px; }
      .body-detail-price-underligne {
        font-weight: 500;
        text-decoration: underline; } } } }


.checkout-confirmation-program {
  display: flex;
  flex-direction: column;
  font-family: 'MaisonNeue';
  .checkout-confirmation-title {
    height: 64px;
    background: #3D7791;
    box-sizing: border-box;
    border-radius: 18px 18px 0px 0px;
    .title {
      text-align: center;
      color: #FFFF;
      font-size: 24px;
      font-weight: 500;
      padding-top: 8px; } }
  .checkout-confirmation-program-body {
      padding: 24px 32px;
      .checkout-confirmation-body {
        padding-bottom: 40px;
        .checkout-confirmation-body-text {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &:nth-child(1) {
            padding-bottom: 16px; }
          .text {
            font-size: 18px;
            font-weight: 500; }
          .price {
            color: rgba(0, 0, 0, 0.6);
            font-weight: 500;
            font-size: 16px; } } }
      .checkout-confirmation-body-price {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 40px;
          .text {
            font-size: 24px;
            font-weight: 500; }
          .price {
            font-weight: 500;
            font-size: 24px; } } } }



.block-content-confiramtion {
  width: 100%;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 10px 10px 0 black;
  padding: 32px 24px;
  margin-bottom: 16px;
  margin-right: 10px;
  background-color: $white;
  max-width: 592px;


  .block-with-header-inside {
    padding-top: 32px; } }

.block-content-checkout-confirmation {
  width: 100%;
  font-family: 'MaisonNeue';
  max-width: 592px;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 10px 10px 0 black;
  margin-bottom: 16px;
  margin-right: 10px;
  background-color: $white;

  .block-with-header-inside {
    padding-top: 32px; } }

.separation-body-onboarding-program {
  border: 1px solid #000000; }








