$target-platform: charles;
@import "~constants/sass/vars";

.popup {
  display: none;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 11;

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed; }

  .overlay {
    background-color: $bg-dark;
    height: 100%;
    opacity: .8;
    position: absolute;
    width: 100%; }

  .overlay-v2 {
    background-color: $light-grey;
    height: 100%;
    opacity: .8;
    position: absolute;
    width: 100%; }


  .popup-container {
    align-items: center;
    background-color: $white;
    border-radius: 3px;
    margin: auto {
     max-width: fit-content; }
    max-height: -webkit-fill-available;
    overflow: auto;
    position: relative;
    width: 90%;
    z-index: 100;

    @media (min-width: $bootstrap-break-sm) {
      width: 55%;
      left: 150px; } }

  .popup-container-v2 {
    @extend .popup-container;
    border: 2px solid $deep-blue;
    border-radius: 10px;

    .close-popup {
      text-align: center;
      .close-popup-button {
        color: $blue;
        text-decoration: underline;
        cursor: pointer; } } }

  .popup-header {
    background-color: black;
    display: flex;
    justify-content: space-between;
    max-height: 70px;
    padding: 20px;

    h2 {
      color: $white;
      font-size: 18px;
      font-weight: 500;
      margin: 0; }

    .icon {
      cursor: pointer;
      display: inline-block;
      width: 20px;

      path {
        fill: $white; } } }


  .popup-body {
    color: $light-dark;
    padding: 15px;

    @media (min-width: $bootstrap-break-sm) {
      padding: 32px; }


    h2 {
      font-size: 16px;
      font-weight: 450; }

    p {
      font-size: 14px;
      font-weight: normal;
      opacity: .8; } } }
