@import "~constants/sass/vars";

$sidebar-background: $white;
$sidebar-content: $charles-clinique-grey;
$sidebar-content-hover: $bg-dark;
$sidebar-content-active: $bg-dark;
$sidebar-width: 20.3125em;

.sidebar {
  background-color: $sidebar-background;
  color: $sidebar-content;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  width: $sidebar-width;
  min-height: 100vh;
  overflow: auto;
  transition: margin-left .2s;
  z-index: 1500;
  padding: 1.5rem 1rem 1rem;
  border-right: 2px solid $sidebar-content-active;

  &.animate {
    left: 0;
    position: absolute;
    z-index: 1039; // modals are at 1050
    margin-left: -$sidebar-width;

    @media screen and (min-width: $sidebar-hiding-breakpoint) {
      left: 0;
      position: inherit;
      margin: 0; } }

  // for mobile version active
  &.active {
    display: flex;
    margin-left: 0; }

  .logo-container {
    display: block;
    margin: 2.3em auto;
    width: 150px;
    svg {
      g {
        fill: $sidebar-content-active; } } }

  .sidebar-top {
    flex: 0 0;

    .toggle-menu-button {
      background-color: transparent;
      border: 0;
      float: right;
      position: relative;
      right: 10px;
      top: 5px;

      @media screen and (min-width: $sidebar-hiding-breakpoint) {
        display: none; } }

    .toggle-menu-icon {
      display: inline-block;
      line-height: 6em;
      vertical-align: middle;
      width: 2em;
      transform: rotate(180deg);
      svg {
        path {
          fill: $sidebar-content-active; } } }

    .sidebar-support-number {
      color: $black;
      font-weight: normal;
      text-decoration: none; }

    .dropdown {
      position: relative; }

    .dropdown__list {
      -webkit-transition: max-height .6s ease-out;
      transition: max-height .6s ease-out;
      max-height: 0;
      overflow: hidden;
      margin: 0;

      li span.label {
        font-weight: 300;
        margin-left: 1rem; } }


    .dropdown__list--active {
      max-height: 800px;
      opacity: 1; }

    .dropdown__list-item {
      cursor: pointer;
      list-style: none;
      position: relative;

      .dropdown__toggler--active {
        span.icon {
          transform: rotate(180deg); } } } }

  .sidebar-item {
    align-items: flex-start;
    background-color: $sidebar-background;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 500;
    justify-content: flex-start;
    outline: 0;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;

    .icon {
      display: inline-block;
      line-height: 3rem;
      vertical-align: middle;
      width: 1.125em; }

    .label {
      line-height: 3rem;
      vertical-align: middle; }

    &.active {
      color: $sidebar-content-active;
      .icon {
        path, * {
          fill: $primary-color; } }

      .label {
        opacity: 1;
        border-bottom: 2px solid #000000;
        padding-bottom: 0.5em; } }

    &:hover {
      color: $sidebar-content-hover;
      text-decoration: none; } }

  ul.sidebar-item-subitems {
    margin-left: 1rem;
    font-size: 0.8em; }

  .sidebar-bottom {} }
