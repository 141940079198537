$target-platform: charles;
@import "~constants/sass/vars";

.doctor-selection-container {
  .consultation-choice-title {
    padding-bottom: 1.5em; }
  .consultation-choices {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 16px;
    margin-bottom: 24px; }

  .consultation-choice-medium-svg {
    margin-right: 1.25rem;
    svg {
      height: 24px; } } }

// Doctor Notice Info
.infos-consultation {

  text-align: left;

  .infos-consultation-item {
    cursor: pointer;

    .infos-consultation-item-title {
      cursor: pointer;
      padding-right: 1em;
      position: relative;
      text-align: left;
      width: 100%; }

    .infos-consultation-item-body {
      height: 0;
      overflow: hidden;
      transition: min-height, height, .5s;
      padding-left: 5px;
      border-left: 2px solid $v2-cta-color; }

    &.active, &:hover {
      .infos-consultation-item-title {
        color: $v2-cta-color; } }
    &.active {
      .infos-consultation-item-body {
        height: auto;
        min-height: 40px; } } } }

// .split-screen-consultation-choice
//   display: flex
//   flex-direction: column

//   .consultation-choice-notice
//     margin-top: 1rem

//   @media (min-width: $bootstrap-break-old-xl)
//     flex-direction: row

//     .consultation-choice-infos
//       flex: 1 1 auto

//     .consultation-choice-notice
//       margin-top: 0
//       flex: 0 1 350px
//       margin-left: auto
//       padding-left: 50px
//       border-left: 1px solid black
