$target-platform: charles;
@import "~constants/sass/vars";

.checkout-drugs-prescription-main {
  padding: 32px 24px;
  max-width: 1205px;
  @media(min-width: $bootstrap-break-old-xl) {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto; }
  .block-content {
    max-width: 621px; }
  .uc-prescription-card {
    .block-content {
      margin-bottom: 32px; }
    h4 {
      border-bottom: 1px solid black;
      padding-bottom: 8px;
      margin-bottom: 32px; }
    .prescription-card-heading-section {
      display: flex;
      margin-bottom: 32px;
      .prescription-card-img {
        width: 88px;
        height: 88px;
        border: 2px solid #000000;
        border-radius: 16px;
        margin-right: 16px; }
      .prescription-card-heading {
        .prescription-card-heading-title {
          font-size: 1rem;
          font-weight: 700; }
        .prescription-card-heading-subtitle {
          font-family: 'Sweet Sans Pro', sans-serif;
          font-size: 0.875rem;
          font-weight: 400; } } }

    .prescription-card-drugs-title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 4px;
      margin-bottom: 16px;
      font-weight: 500;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px; } }

    .prescription-card-prescription-list {
      .prescription-list-title {
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: 8px; }
      .item-list {
        padding-inline-start: 25px;
        &>li {
          margin-bottom: 8px;
          &>span {
            text-decoration: underline; } } } }

    .prescription-card-cgu {
      margin-top: 32px;
      margin-bottom: 32px;
      &>input {
        margin-top: 4px; }
      &>div {
        font-weight: 400;
        font-size: 0.75rem; } } }

  .dom-tom-delivery-block {
    font-size: 16px;
    font-weight: 700;
    .block-content {
      margin-bottom: 32px; }
    .dom-tom-delivery-title {
      margin-bottom: 24px; }
    button {
      padding: 16px 18px;
      width: 100%; } }

  .section-arguments-wrapper {
    border-top: 2px solid black;
    padding-top: 40px;
    display: flex;
    @media(min-width: $bootstrap-break-old-xl) {
      border-top: none;
      padding-left: 64px;
      padding-top: 0;
      margin-top: 72px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      height: fit-content; } } }



