$target-platform: charles;
.assistance {
  .assistance-text {
    line-height: 2em; }

  .assistance-shifted-content {
    @media(min-width: 756px) {
      margin-left: 4em; }
    .support-phone-number {
      color: #012765; } }
  @media(max-width: 756px) {
    .shifted-button {
      text-align: center; } }

  .assistance-svg {
    svg {
      width: 2.5em; } }

  .assistance-input {
    height: 10em;
    margin: initial !important;
    border-color: #012765; } }
