$target-platform: charles;
@import "~constants/sass/vars";

.checkout-resume {
    display: flex;
    flex-direction: column;
    .checkout-doctor-info {
        display: flex;
        flex-direction: row;
        margin-bottom: 28px;
        .checkout-doctor-info-image {
            object-fit: cover;
            border-radius: 9px;
            width: 60px;
            height: 60px;
            border: 2px solid #000000;
            border-radius: 12px; }
        .checkout-doctor-info-text {
            display: flex;
            flex-direction: column;
            margin-left: 8px;
            line-height: 32px;
            .title {
                font-weight: 500; }
            .subtitle {
                font-size: 14px;
                font-weight: 400; } } }

    .checkout-resume-subtitle {
        display: flex;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 24px; } }

.checkout-stepper {
    margin-bottom: 32px;
    .step {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background-color: cream;
        &:last-child {
            .line {
                border-left: 3px;
                z-index: -1; } }

        .stepper-text {
            margin-left: 18px;
            flex-direction: column;
            .title {
                font-weight: 700; }
            .list {
                margin-top: 8px;
                font-size: 14px;
                font-weight: 400; } }


        .v-stepper {
            position: relative;

            .circle {
                background-color: white;
                border: 2px solid #000000;
                border-radius: 100%;
                width: 40px;
                height: 40px;
                display: inline-block;
                .stepper-circle-number {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21.6px;
                    margin-top: 4px;
                    margin-left: 12.5px; } }

            .line {
                top: 38px;
                left: 18px;
                height: 100%;
                position: absolute;
                border-left: 1px solid #000000; } } } }

.checkout-resume-button {
    .previous {
      color: black;
      background-color: white;
      border: 2px solid black;
      &:hover {
        color: white;
        background-color: black; } }
    display: flex;
    flex-direction: column;
    @media (min-width: $bootstrap-break-md) {
        flex-direction: row;
        justify-content: center; }
    .checkout-resume-button-continue {
        margin-bottom: 16px;
        @media (min-width: $bootstrap-break-md) {
            margin-bottom: 0px;
            margin-right: 32px; } } }
