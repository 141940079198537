$target-platform: charles;
@import "~constants/sass/vars";

.section-title-presentation-consult {
  font-size: 1.4rem;
  color: black;
  @media(min-width: $bootstrap-break-lg) {
    font-size: 2rem;
    line-height: 40px; } }
.start-text-general {
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 1rem;
  align-self: center;
  max-width: 700px; }
.container-family-last-step {
  padding: 0 24px;
  width: auto; }
.doctor-message-div {
  .doctor-talks {
    border: 2px solid black;
    border-radius: 20px;
    max-width: 490px;
    padding: 24px; }
  .doctor-round-face {
    border-radius: 50%;
    border: 2px solid black;
    height: 56px;
    width: 56px;
    z-index: 1; } // fix Safari
  .doctor-talks {
    background: white; } }
