$target-platform: charles;
@import "~constants/sass/vars";

// Global positionning
.lucas-change-onboarding-container {
  padding: .5rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  row-gap: 1.5rem;

  .lucas-change-onboarding-container-side {
    margin-top: 1rem; }

  @media (min-width: $bootstrap-break-old-xl) {
    flex-direction: row;
    column-gap: 1rem;

    .lucas-change-onboarding-container-main {
      flex: 1 1 auto; }

    .lucas-change-onboarding-container-side {
      margin-top: 0;
      flex: 0 1 400px;
      margin-left: auto;
      padding-left: 50px;
      border-left: 1px solid #E7E5E1; } }


  .lucas-change-onboarding-container-main-element {
    margin: auto;
    &-custom-small {
      @media (min-width: $bootstrap-break-old-xl) {
        max-width: 600px; } }
    &-small {
      max-width: 600px; }
    &-medium {
      max-width: 800px; }
    &-auto {
      max-width: none; } } }

// End global positionning
