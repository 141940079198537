$target-platform: charles;
@import "~constants/sass/vars";

.toggle-block {
  align-items: center;
  border: .05em $grey solid;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 1.5em 0;
  padding: 1rem;
  text-align: center;
  flex-direction: column;

  @media (min-width: $bootstrap-break-sm) {
    padding: 1.5em 2em;
    flex-direction: row; }

  .toggle-button {
    height: 22px !important;
    margin-right: 1.5em;
    width: 40px !important;

    span {
      height: 20px !important;
      width: 20px !important; } }

  .toggle-text {
    font-size: 1em;
    font-weight: 500;
    max-width: 320px; } }
