$target-platform: charles;
@import "~constants/sass/vars";

.notification-over {
  background-color: $white;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(189, 189, 189, 1);
  display: flex;
  font-size: .8em;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: height 1s;
  width: 100%;
  max-width: 447px;
  z-index: 2020;

  &.active {
    min-height: 70px;
    width: 100%; }

  .button-box {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: $primary-font;
    .button-box-close {
      color: rgba(29, 29, 29, 0.3);
      align-self: center;
      margin-bottom: 0px; } }

  .picto-container {
    flex-shrink: 0; }

  &.info {
    .picto-container {
      background-color: $blue; } }

  &.error {
    .picto-container {
      background-color: $red-error; } }

  &.warning {
    .picto-container {
      background-color: $orange-alert; } }

  &.success {
    .picto-container {
      background-color: $green-success; } }

  @media (min-width: $bootstrap-break-md) {
    height: 113px;
    right: -370px;
    top: 20px;
    transition: width .5s, right 1s;
    width: 0;

    &.active {
      height: 113px;
      right: 20px;
      width: 370px; } }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-left: auto;
    padding: 0 20px;

    &:focus {
      outline: none; } }

  .icon-state {
    align-self: center;
    padding-left: 8px;
    width: 44px; } }

.picto-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 16px;

  .icon {
    display: flex;
    flex-direction: column;
    height: 30px;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    width: 30px; } }

.message-container {
  display: flex;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 18px;
  width: 294px;


  .message-body {
    align-self: center;
    flex-direction: column;
    overflow: auto;
    font-family: $primary-font;

    h6 {
      color: $black;
      font-size: 14px;
      font-weight: 700;
      margin: 0; }

    p {
      color: rgba(29, 29, 29, 0.6);
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 400; } } }



.notification-separator-barre {
  border: 1px solid rgba(29, 29, 29, 0.3);
  min-height: 71px; }

.notification {
  background-color: $white;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(189, 189, 189, 1);
  display: flex;
  font-size: .8em;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transition: height 1s;
  width: 100%;
  max-width: 447px;
  z-index: 2020;

  &.active {
    min-height: 70px;
    width: 100%; }

  .button-box {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: $primary-font;
    .button-box-close {
      color: rgba(29, 29, 29, 0.3);
      align-self: center;
      margin-bottom: 0px; } }

  .picto-container {
    flex-shrink: 0; }

  &.info {
    .picto-container {
      background-color: $blue; } }

  &.error {
    .picto-container {
      background-color: $red-error; } }

  &.warning {
    .picto-container {
      background-color: $orange-alert; } }

  &.success {
    .picto-container {
      background-color: $green-success; } }

  @media (min-width: $bootstrap-break-md) {
    min-height: 70px;
    right: -370px;
    top: 20px;
    transition: width .5s, right 1s;
    width: 0;

    &.active {
      min-height: 70px;
      right: 20px;
      width: 370px; } }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin-left: auto;
    padding: 0 20px;

    &:focus {
      outline: none; } }

  .icon-state {
    align-self: center;
    padding-left: 8px;
    width: 44px; } }

.picto-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 16px;

  .icon {
    display: flex;
    flex-direction: column;
    height: 30px;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    width: 30px; } }

.message-container {
  display: flex;
  overflow: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 18px;
  width: 294px;


  .message-body {
    align-self: center;
    flex-direction: column;
    overflow: auto;
    font-family: $primary-font;

    h6 {
      color: $black;
      font-size: 14px;
      font-weight: 700;
      margin: 0; }

    p {
      color: rgba(29, 29, 29, 0.6);
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 400; } } }



.notification-separator-barre {
  border: 1px solid rgba(29, 29, 29, 0.3);
  min-height: 70px;
  width: 2px; }
