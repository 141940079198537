$target-platform: charles;
@import "~constants/sass/vars";

.onboarding-navbar {

  .onboarding-navbar {
    flex: 1; }

  .navbar-nav {
    flex: 1; }

  .navbar {
    height: 100%;
    padding: 0; }

  .charles-logo-svg {
    svg {
      height: 20px;
      @media (min-width: $bootstrap-break-sm) {
        height: 50px; } } }
  .charles-academy-logo-svg {
    svg {
      height: 36px;
      @media (min-width: $bootstrap-break-sm) {
        height: 50px; } } }
  .mia-academy-logo-svg {
    svg {
      height: 26px;
      @media (min-width: $bootstrap-break-sm) {
        height: 40px; } } }

  background-color: white;
  height: 80px;
  padding: 0 1rem;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: $bootstrap-break-sm) {
    height: 80px;
    padding: 0 2rem;
    box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.1); }

  .onboarding-back {
    flex: 1;
    cursor: pointer;

    .onboarding-back-icon {
      svg {
        height: 16px; } } }

  .onboarding-phone-icon {
    svg {
      height: 16px; } }

  .onboarding-navbar-help {
    cursor: pointer;
    font-weight: normal; }

  .onboarding-phone-help {
    border: 2px solid black;
    border-radius: 1rem;
    padding: .5rem 1rem;
    white-space: nowrap;

    svg {
      height: 21px; } } }
